<template>
    <div class="list">

        <template v-for="item in list" :key="item.id">

            <template v-if="!item.depth_level || item.depth_level == level || showAll">

                <div v-if="!item.childs || item.childs.length == 0 || showAll" class="item" v-ripple.300
                    @click="onOptionClick(item.id)">
                    <div class="item-top">

                        <div v-if="item.icon">
                            <span class="icon fi" :class="item.icon"></span>
                        </div>

                        <div class="name-wrap">
                            <div class="name roboto-4-regular" v-html="formatName(item)"></div>
                            <div class="description roboto-3-regular" v-html="getDesciption(item)"></div>
                        </div>

                        <div class="check" v-if="showCheck">

                            <template v-if="isItemSelected(item)">

                                <span v-if="multiple" class="ic fi fi-save-bold"></span>
                                <div v-else class="ic dot"></div>

                            </template>

                        </div>

                    </div>

                </div>

                <div v-else class="item" :class="{ expanded: !!item.expanded }">

                    <div class="item-top" @click="toggleCollapse(item)">
                        <div class="name-wrap">
                            <div class="name roboto-4-regular" v-html="formatName(item)"></div>
                            <div class="description roboto-3-regular" v-html="getDesciption(item)"></div>
                        </div>
                        <div class="collapser">
                            <span class="fi fi-arrow-down"></span>
                        </div>
                    </div>

                    <div class="childs ">

                        <div class="item item-parent" v-ripple.300 @click="onOptionClick(item.id)">
                            <div class="item-top">
                                <div class="name-wrap">
                                    <div class="name roboto-4-regular"
                                        v-html="!multiple ? formatName(item) : '(выбрать все)'"></div>
                                    <div class="description roboto-3-regular" v-html="getDesciption(item)"></div>
                                </div>

                                <div class="check" v-if="showCheck">
                                    <template v-if="isItemSelected(item)">
                                        <span v-if="multiple" class="ic fi fi-save-bold"></span>
                                        <div v-else class="ic dot"></div>
                                    </template>
                                </div>

                            </div>
                        </div>

                        <sbs-control-select-sheet-list :list="item.childs" :level="level + 1" :showAll="showAll"
                            :multiple="multiple" :modelValue="modelValue" :modelValues="modelValues"
                            :showCheck="showCheck" @onOptionClick="onOptionClick" />

                    </div>
                </div>

            </template>

        </template>

    </div>
</template>

<script>

export default {
    name: "sbs-control-select-sheet-list",
    components: {
    },
    emits: ["onOptionClick"],
    data() {
        return {
            selected: this.modelValues.map((v) => v.id),
        }
    },

    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        level: {
            type: Number,
            default() {
                return 1
            }
        },
        showAll: {
            type: Boolean,
            default() {
                return false
            }
        },

        multiple: {
            type: Boolean,
            default() {
                return false;
            },
        },

        modelValue: {
            type: [Object, Boolean, String],
            default() {
                return {
                    id: "",
                    name: "",
                };
            },
        },
        modelValues: {
            type: Array,
            default() {
                return [];
            },
        },

        showCheck: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },

    watch: {
        modelValues: {
            handler() {
                this.selected = this.modelValues.map((v) => v.id);
            },
            deep: true,
        },
    },

    methods: {

        formatName(item) {
            let name = item.name;
            if (item.last_name !== undefined) {
                name = item.last_name + " " + item.name;
            }
            if (item.count !== undefined) {
                name += " (" + item.count + ")";
            }
            return name;
        },

        /**
         * Возвращает описание к пункту
         */
        getDesciption(item) {
            if (item.department_name !== undefined) {
                return item.department_name;
            }

            return "";
        },

        /**
         * Проверяет является ли элемент выбранным
         */
        isItemSelected(item) {
            if (!this.multiple) {
                return this.modelValue && this.modelValue.id == item.id;
            } else {
                return this.selected.includes(item.id);
            }
        },

        onOptionClick(id) {
            this.$emit("onOptionClick", id);
        },

        toggleCollapse(item) {
            item.expanded = !item.expanded;
        }
    },

}
</script>