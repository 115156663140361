<template>
  <div class="sbs-equipment-item" :class="{ disabled: item.active != 'Y' }" @click="onItemClick(item)">

    <div class="type nunito-2-bold">{{ item.type_name }}</div>
    <div class="name nunito-4-bold" v-html="item.name"></div>
    <div class="description nunito-3-regular" v-if="item.description.trim() != ''" v-html="item.description"></div>

    <div class="status nunito-2-bold" :class="{ active: item.active == 'Y' }">
      {{ item.active == "Y" ? $t('common.active') : $t('common.disabled') }}
    </div>

    <div class="support">
      <div v-if="item.have_qr_code" class="option">
        <span class="fi fi-qr-scanner"></span>
      </div>
      <div v-if="item.have_nfc_id" class="option">
        <span class="fi fi-nfc"></span>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "sbs-equipment-item",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods: {
    /**
     * Переход к оборудованию
     */
    onItemClick(item) {
      this.$router.push({
        name: "equipment-view",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss">
.sbs-equipment-item {
  position: relative;
  padding: 15px;
  border-radius: 15px;
  background: var(--audit-cards-audit-cards-bg-color);
  box-shadow: 0px 2px 4px 0px var(--shadow-secondary);

  min-height: 80px;
  margin-bottom: 10px;
  cursor: pointer;

  .type {
    padding-right: 120px;
    color: var(--news-card-news-card-secondary-text-color);
    margin-bottom: 3px;
  }

  .name {
    padding-right: 120px;
    color: var(--audit-cards-audit-cards-title-text-color);
    margin-bottom: 5px;
  }

  .status {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 6px 8px;

    color: var(--audit-status-audit-status-completed-text-color);
    background: var(--audit-status-audit-status-completed-background-color);
    border-radius: 10px;
    line-height: 1;

    &.active {
      color: var(--blue-700);
      background: var(--blue-200);
    }
  }

  .description {
    padding-right: 40px;
    color: var(--audit-cards-audit-cards-title-text-color);
  }

  .support {
    position: absolute;
    bottom: 10px;
    right: 10px;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .option {
      float: left;
      margin-left: 5px;
      color: var(--news-card-news-card-icon-color);
    }
  }

  &.disabled {
    border: 1px solid var(--audit-cards-audit-cards-stroke-color);
    background: var(--audit-cards-audit-cards-bg-completed-color);

    .name,
    .description {
      color: var(--news-card-news-card-secondary-text-color);
    }


  }
}
</style>