<template>
    <div class="sbs-loader-inline"></div>
</template>

<script>
export default {
    name: 'sbs-loader-inline',
}
</script>

<style>
.sbs-loader-inline {
    display: inline-block;
    width: 42px;
    height: 42px;

    z-index: 10;
    transform-origin: 50%;
    animation: b1t-okraina-loader-spin 1s infinite linear;
    box-sizing: border-box;
    border: 4px solid var(--service-color-1);
    border-radius: 50%;
    border-top-color: transparent;
}

@keyframes b1t-okraina-loader-spin {
    100% {
        transform: rotate(360deg);
    }
}
</style>