<template>
  <div class="sbs-user-settings">

    <!-- Загрузка -->
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>

    <template v-else>

      <div class="section-title nunito-4-bold">{{ $t('settings.theme') }}</div>
      <div class="list">
        <div class="item" v-for="item in themes" :key="item.id">
          <div class="label roboto-4-regular" @click="setTheme(item.id)">{{ item.name }}</div>
          <div class="control">
            <sbs-control-radio name="theme" :value="item.id" v-model="themeInternal" />
          </div>
        </div>
      </div>

      <br />

      <div class="section-title nunito-4-bold">{{ $t('settings.push') }}</div>
      <div class="list">
        <div class="item" v-for="item in notifications" :key="item.name">
          <div class="label roboto-4-regular" @click="toggleNotification(item)">{{ item.title }}</div>
          <div class="control">
            <sbs-control-checkbox v-model="settings.notifications[item.name].push" value="Y" label="" defaultValue="N"
              :form="false" @change="onNotificationChange()" />
          </div>
        </div>

      </div>


    </template>

  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlRadio from "@/components/controls/radio.vue";
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import { mapGetters } from "vuex";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-user-settings",
  components: {
    sbsLoaderInline,
    sbsControlRadio,
    sbsControlCheckbox
  },
  data() {
    return {
      loading: true,
      themeInternal: this.theme,
      themes: [
        {
          id: "dark",
          name: this.$t('settings.theme_dark'),
        },
        {
          id: "light",
          name: this.$t('settings.theme_light'),
        },
      ],

      notifications: {},

      settings: {
        notifications: {}
      }

    };
  },
  computed: {
    ...mapGetters({ theme: "getTheme" }),
  },

  watch: {
    themeInternal: function (value) {
      if (value != this.theme) {
        this.$store.commit("setTheme", value);
      }
    },
    theme: function (value) {
      if (value != this.themeInternal) {
        this.themeInternal = value;
      }
    },
  },

  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;

      //запрашиваем данные
      rest
        .call("user.settings.get", {}, false)
        .then((data) => {
          if (data.success) {
            this.notifications = data.notifications;
            this.setFields();
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Устанавилвает поля настроек
     */
    setFields() {
      this.notifications.forEach(n => {
        this.settings.notifications[n.name] = {};
        this.settings.notifications[n.name].push = n.push;
      });
    },

    setTheme(theme) {
      this.themeInternal = theme;
    },

    toggleNotification(item) {
      this.settings.notifications[item.name].push = this.settings.notifications[item.name].push == "Y" ? "N" : "Y";

    },

    onNotificationChange() {
      this.save();
    },

    /**
     * Сохранение
     */
    save() {

      //запрашиваем данные
      rest
        .call("user.settings.update", {
          method: "post",
          data: this.settings,
        })
        .then((data) => {
          if (!data.success) {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },
  },

  /**
   * Создание эксземпляра компонента
   */
  created() {
    this.themeInternal = this.theme;

    //загружаем данные
    this.loadData();
  },
};
</script>

<style lang="scss">
.sbs-user-settings {

  .section-title {
    color: var(--service-color-1);
    margin-bottom: 20px;

  }

  .list {
    .item {
      position: relative;
      margin-bottom: 15px;

      padding-right: 40px;

      .label {
        color: var(--text-field-text-field-active-color-text);
      }

      .control {
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }
}
</style>