<template>
    <div class="sbs-edu-post-test" :class="{ 'view-horizontal-padding': !passageMode, 'passage-mode': passageMode }">

        <!--Если уже сдали  ИЛИ Достигнуто макс кол-во попыток ИЛИ текущий шаг - последний -->
        <template v-if="resultSuccess || limitReached || step > questions.length">

            <div class="result">

                <div class="name nunito-5-bold">{{ $t('education.post.test.test') }}: <br />«{{ item.name }}»</div>
                <div class="caption roboto-4-regular">{{ $t('education.post.test.result') }}:</div>

                <div class="circle-wrap">
                    <div class="circle" :class="{ success: success }">
                        <img :src="success ? imageTestSuccess : imageTestFail" />
                    </div>
                </div>

                <div class="status nunito-5-bold" :class="{ success: success }">
                    {{ success ? $t('education.post.test.success') : $t('education.post.test.fail') }}
                </div>
                <div class="status-text roboto-4-regular">
                    {{ success ? $t('education.post.test.success_text') : $t('education.post.test.fail_text') }}
                </div>

                <div class="stat nunito-2-bold" :set="cnt = resultSuccess ? questions.length : correctQuestions">
                    <span class="correct-cnt" :class="{ success: success }">{{ cnt }}</span>
                    {{ $t('education.post.test.correct_answers', cnt) }}
                    {{ $t('education.post.test.from') }}
                    {{ questions.length }}
                </div>

                <template v-if="limited && !success">
                    <div class="attempts-left nunito-2-bold">
                        {{ $t('education.post.test.limit_left', limit - attemptsCount) }}
                    </div>
                </template>


            </div>

            <sbs-teleport-wrapper :to="'#' + this.footerId">
                <div class="sbs-edu-post-test-btns">
                    <div class="container">
                        <div class="page-padding">
                            <template v-if="!success && !limitReached">
                                <div class="btn btn-block btn2" @click="reset">
                                    {{ $t('education.post.test.repass') }}
                                </div>
                                <br />
                            </template>

                            <div class="btn btn-block btn1" @click="complete" v-if="trainingId <= 0 || success">
                                {{ trainingId > 0 ? $t('education.post.test.next') : $t('education.post.test.complete')
                                }}
                            </div>

                        </div>
                    </div>
                </div>
            </sbs-teleport-wrapper>

        </template>

        <!--Иначе тест ещё не сдали -->
        <template v-else>

            <div class="name nunito-5-bold">{{ $t('education.post.test.test') }}: «{{ item.name }}»</div>

            <div class="counter nunito-2-bold">{{ $t('education.post.test.counter', {
        n: step, count: questions.length
    }) }}</div>

            <div v-if="question" class="question">
                <div class="question-name nunito-4-bold">{{ question.name }}</div>

                <div class="answers " :class="'view-' + question.view">

                    <!--Вид - текстовый -->
                    <template v-if="question.view == 'text'">
                        <template v-for="answer in answers" :key="answer.id">
                            <div class="answer"
                                :class="{ selected: this.isSelected(answer.id), fail: freeze && isSelected(answer.id) && answer.checked != 'Y', success: freeze && isSelected(answer.id) && answer.checked == 'Y' }"
                                @click="onAnswerClick(answer.id)">
                                <div>
                                    <div class="check">
                                        <template v-if="this.isSelected(answer.id)">
                                            <span v-if="questionIsMultiple" class="ic fi fi-save-bold"></span>
                                            <div v-else class="ic dot"></div>
                                        </template>
                                    </div>
                                </div>
                                <div class="roboto-3-regular">
                                    {{ answer.value }}
                                </div>
                            </div>
                        </template>
                    </template>

                    <!--Вид - визуальный -->
                    <template v-if="question.view == 'visual'">

                        <template v-for="answer in answers" :key="answer.id">

                            <div class="answer" @click="onAnswerClick(answer.id)"
                                :class="{ fail: freeze && isSelected(answer.id) && answer.checked != 'Y', success: freeze && isSelected(answer.id) && answer.checked == 'Y' }">

                                <div class="picture">
                                    <div class="image preloaded" v-if="answer.image"
                                        v-lazy:background-image="answer.image.preview">
                                        <div class="preload" v-if="answer.image.preload"
                                            :style="{ backgroundImage: 'url(\'' + answer.image.preload + '\')' }">
                                        </div>

                                        <div class="loader">
                                            <sbs-loader-inline />
                                        </div>
                                    </div>

                                    <div class="check">
                                        <template v-if="this.isSelected(answer.id)">
                                            <span v-if="questionIsMultiple" class="ic fi fi-save-bold"></span>
                                            <div v-else class="ic dot"></div>
                                        </template>
                                    </div>
                                </div>
                                <div class="value nunito-2-regular" v-if="answer.value" v-html="answer.value"></div>
                            </div>

                        </template>

                    </template>

                </div>

                <sbs-teleport-wrapper :to="'#' + this.footerId">
                    <div class="sbs-edu-post-test-btns">
                        <div class="container">
                            <div class="page-padding">
                                <div class="row">
                                    <div class="col-7">

                                    </div>
                                    <div class="col-5">
                                        <div class="btn btn-block btn1" @click="onNextClick">
                                            {{ $t('common.next') }}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </sbs-teleport-wrapper>
            </div>

        </template>
    </div>
</template>

<script>
import sbsTeleportWrapper from "@/components/teleport-wrapper.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";

import imageTestSuccessPath from "@/assets/images/test_success.svg";
import imageTestFailPath from "@/assets/images/test_fail.svg";

export default {
    name: "sbs-education-post-test",
    components: {
        sbsTeleportWrapper,
        sbsLoaderInline
    },
    emits: ["complete"],
    data() {
        return {
            step: 1,
            freeze: false,
            answers: [],
            result: {

            },
            data: {
                attempts: []
            },

            imageTestSuccess: imageTestSuccessPath,
            imageTestFail: imageTestFailPath
        };
    },

    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        trainingId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        passageMode: {
            type: Boolean,
            default() {
                return false
            }
        },
        footerId: {
            type: String,
            default() {
                return ""
            }
        }
    },

    computed: {

        questions() {
            if (!this.item.data || !this.item.data.questions) {
                return [];
            }

            return this.item.data.questions;
        },

        question() {
            if (this.step - 1 < this.questions.length) {
                return this.questions[this.step - 1];
            }

            return false;
        },

        /**
         * Является ли вопрос множественным (несколько ответов надо выбрать)
         */
        questionIsMultiple() {
            if (!this.question) {
                return 0;
            }

            return this.question.answers.filter(a => a.checked == 'Y').length > 1;
        },

        /**
         * Кол-во вопросов, пройденных успешно
         */
        correctQuestions() {
            let count = 0;

            //для каждого вопроса
            this.questions.forEach(question => {

                //получим список ИД правильных вариантов
                let correctIds = question.answers.filter(a => a.checked == 'Y').map(a => a.id);

                //если кол-во правильных вариантов не сопадает с кол-вом выбранных вариантов
                if (!this.result[question.id] || this.result[question.id].length != correctIds.length) {
                    return;
                }

                //проверим выбраны ли нужные варианты
                let same = true;
                correctIds.forEach(id => {
                    if (!this.result[question.id].includes(id)) {
                        same = false;
                    }
                });
                if (same) {
                    count++;
                }
            });

            return count;
        },

        success() {
            return this.resultSuccess || this.correctQuestions >= this.minCorrectQuestions && this.mandatoryQuestionsCorrect;
        },

        /**
         * Есть ли уже успешный результат по данному тесту
         */
        resultSuccess() {
            return this.item.result && this.item.result.status == 'S'
        },

        /**
         * Текущее кол-во попыток
         */
        attemptsCount() {
            return this.data.attempts ? this.data.attempts.length : 0;
        },

        /**
         * Имеет ли тест ограничение на кол-во попыток
         */
        limited() {
            return this.item.data.limit_attempts == 'Y'
        },

        /**
         * Максимальное кол-во попыток
         */
        limit() {
            return this.item.data.limit;
        },

        /**
         * Достигнут ли лимит попыток
         */
        limitReached() {
            return this.limited && this.limit <= this.attemptsCount
        },

        /**
         * Минимальное кол-во правильных ответов на вопросы
         */
        minCorrectQuestions() {
            if (this.item.data.min_correct_questions_enabled == "Y" && this.item.data.min_correct_questions > 0) {
                if (this.item.data.min_correct_questions < this.questions.length) {
                    return this.item.data.min_correct_questions;
                }
            }

            return this.questions.length;
        },

        /**
         * Все ли обязательные вопросы пройдены правильно (для порого прохождения)
         */
        mandatoryQuestionsCorrect() {

            if (this.item.data.min_correct_questions_enabled != "Y") {
                return true;
            }

            let mandatoryQuestions = this.questions.filter(q => q.mandatory == 'Y');

            let count = 0;
            //для каждого вопроса
            mandatoryQuestions.forEach(question => {

                //получим список ИД правильных вариантов
                let correctIds = question.answers.filter(a => a.checked == 'Y').map(a => a.id);

                //если кол-во правильных вариантов не сопадает с кол-вом выбранных вариантов
                if (!this.result[question.id] || this.result[question.id].length != correctIds.length) {
                    return;
                }

                //проверим выбраны ли нужные варианты
                let same = true;
                correctIds.forEach(id => {
                    if (!this.result[question.id].includes(id)) {
                        same = false;
                    }
                });
                if (same) {
                    count++;
                }
            });

            return count == mandatoryQuestions.length;
        }

    },
    watch: {
        step: function () {
            //инициализируем данные результата для шага
            this.initStep();
            //если последний шаг
            if (this.step > this.questions.length) {
                //сохраняем результат
                this.save();
            }
        },
    },

    methods: {

        /**
         * Инициализирует в результате место под ответ на вопрос
         */
        initStep() {
            if (!this.question) {
                return;
            }

            if (!this.result[this.question.id]) {
                this.result[this.question.id] = [];
            }

            //радомная сортировка вариантов ответа
            this.answers = this.item.data.questions[this.step - 1].answers;
            this.answers = this.answers.map(value => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value);

        },

        /**
         * клик по ответу
         */
        onAnswerClick(id) {

            //если выбор вариантов зафиксирован
            if (this.freeze) {
                return;
            }

            //если ответ уже был выбран
            if (this.isSelected(id)) {
                //то удалим его
                var index = this.result[this.question.id].indexOf(id);
                if (index !== -1) {
                    this.result[this.question.id].splice(index, 1);
                }
            } else {

                //если вопрос не множественный
                if (!this.questionIsMultiple) {
                    //то сбросим предыдущий выбор
                    this.result[this.question.id] = [];
                }

                //иначе добавим его
                this.result[this.question.id].push(id);
            }


        },

        /**
         * Переход к следующему вопросу
         */
        onNextClick() {
            if (this.result[this.question.id].length == 0) {
                bus.emit("SBS_MESSAGE_E_SHOW", { message: this.$t('education.post.test.answer_not_select') });
                return;
            }

            //если выбор вариантов ещё не зафиксирован
            if (!this.freeze) {
                //фиксируем, чтобы показать ошибки
                this.freeze = true;
                return;
            }

            this.step++;
            this.freeze = false;

        },

        /**
         * Проверяет выбран ли ответ
         */
        isSelected(id) {
            return this.result[this.question.id].includes(id);
        },

        /**
         * Пересдать
         */
        reset() {
            this.result = {};
            this.step = 1;
        },

        /**
         * Завершить
         */
        complete() {

            if (this.trainingId > 0) {

                this.$emit("complete");

            } else {

                window.history.length > 1
                    ? this.$router.go(-1)
                    : this.$router.push("/");
            }


        },

        /**
         * Сохранение результата
         */
        save() {

            this.data.attempts.push({
                date: moment().format("DD.MM.YYYY hh:mm:ss"),
                answers: this.result,
                status: this.success ? "S" : "F"
            });

            let fields = {
                id: this.item.id,
                training_id: this.trainingId,
                status: this.success ? "S" : "F",
                data: this.data
            };
            //запрашиваем данные
            rest
                .call("education.post.result", {
                    method: "post",
                    data: fields,
                })
                .then((data) => {
                    if (!data.success) {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    } else {
                        //генерим событие успешного сохранения результата
                        bus.emit("SBS_EDU_POST_RESULT_E_SAVE", fields);
                    }
                });
        }

    },

    created() {
        this.initStep();

        //устанвим текущее кол-во попыток, которые пользователь уже сделал
        if (this.item.result && this.item.result.data) {
            this.data = this.item.result.data;
            if (!this.data.attempts) {
                this.data.attempts = [];
            }
        }


    }
};
</script>

<style lang="scss">
.sbs-edu-post-test {

    &.passage-mode {
        padding-top: 15px;
    }

    .name {
        color: var(--news-card-news-card-primary-text-color);
        margin-bottom: 25px;
    }

    .counter {
        color: var(--news-card-news-card-secondary-text-color);
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .title {
        color: var(--bg-invert);
        margin-bottom: 20px;
    }

    .question {

        .question-name {
            color: var(--bg-invert);
            margin-bottom: 20px;
        }

        .answers {

            .check {
                position: relative;

                width: 25px;
                height: 25px;

                background: var(--chekbox-and-radiobutton-chekbox-dark-standard-pressed-bg-color);
                border-radius: 7px;
                box-shadow: 0px 2px 3px -1px var(--shadow-checkbox) inset;

                .ic {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    line-height: 1;
                    color: var(--service-color-1);

                    &.dot {
                        background-color: currentColor;
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                    }
                }

            }

            &.view-text {

                .answer {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;

                    padding: 15px;
                    border-radius: 15px;
                    background: var(--test-field-test-field-secondary-standart-background-color);
                    box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
                    margin-bottom: 10px;
                    cursor: pointer;

                    color: var(--test-field-test-field-text-color);

                    &.selected {

                        &.fail {
                            background: var(--test-field-test-field-pressed-false-background-color);
                        }

                        &.success {
                            background: var(--test-field-test-field-pressed-true-background-color);
                        }
                    }


                }
            }

            &.view-visual {
                display: grid;
                grid-template-columns: 0.5fr 0.5fr;
                gap: 11px;

                @media (min-width: 768px) {
                    grid-template-columns: 0.33fr 0.33fr 0.33fr;
                }

                .answer {
                    position: relative;
                    cursor: pointer;

                    .picture {
                        position: relative;
                        height: 0px;
                        padding-top: 62.5%;
                        border-radius: 8px;
                        overflow: hidden;

                        border: 2px solid transparent;

                        .image {
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            bottom: 0px;
                            left: 0px;
                            background-position: center center;
                            background-size: cover;
                            background-repeat: no-repeat;
                        }
                    }

                    .value {
                        color: var(--news-card-news-card-secondary-text-color);
                        margin-top: 5px;
                        text-align: center;
                    }

                    .check {
                        position: absolute;
                        z-index: 5;
                        bottom: 10px;
                        right: 10px;
                        background: var(--chekbox-and-radiobutton-chekbox-light-standard-pressed-bg-color);
                    }

                    &.fail {

                        .picture {
                            border-color: var(--test-field-test-field-pressed-false-stroke-color);
                        }

                        .check {
                            background: var(--chekbox-and-radiobutton-chekbox-light-false-bg-color2);

                            .ic {
                                color: var(--chekbox-and-radiobutton-chekbox-light-false-color);
                            }
                        }
                    }

                    &.success {
                        .picture {
                            border-color: var(--green-600);
                        }

                        .check {

                            .ic {
                                color: var(--green-600);
                            }
                        }
                    }

                }
            }
        }

    }

    .result {
        text-align: center;

        .caption {
            position: relative;
            color: var(--audit-cards-audit-cards-title-text-color);
            z-index: 10;
        }

        .circle-wrap {
            max-width: 320px;
            margin: 0px auto;
            margin-top: -50px;
        }

        .circle {
            padding-top: 100%;
            position: relative;
            background: radial-gradient(50% 50% at 50% 50%, var(--chekbox-and-radiobutton-chekbox-dark-false-bg-color2) 0%, var(--view-background-color) 86.33%);

            &.success {
                background: radial-gradient(50% 50% at 50% 50%, var(--chekbox-and-radiobutton-chekbox-dark-true-bg-color) 0%, var(--view-background-color) 86.33%);
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .status {
            color: var(--test-field-test-field-pressed-false-stroke-color);
            margin-bottom: 5px;
            margin-top: -40px;
            position: relative;
            z-index: 10;

            &.success {
                color: var(--test-field-test-field-pressed-true-stroke-color);
            }

        }

        .status-text {
            color: var(--bg-invert);
            position: relative;
            z-index: 10;
            margin-bottom: 25px;
        }

        .stat {
            color: var(--news-card-news-card-secondary-text-color);
            text-transform: uppercase;

            .correct-cnt {
                color: var(--test-field-test-field-pressed-false-stroke-color);

                &.success {
                    color: var(--test-field-test-field-pressed-true-stroke-color);
                }
            }
        }

        .attempts-left {
            color: var(--news-card-news-card-secondary-text-color);
            text-transform: uppercase;
        }

    }


}

.sbs-edu-post-test-btns {
    padding: 15px 0px;

}
</style>