<template>
    <div class="sbs-edu-post-item" @click="onItemClick(item)" ref="root">

        <div class="author-date">
            <div class="date roboto-2-regular">{{ formatDate() }}</div>
            <div class="author nunito-2-bold">
                <div>
                    <div class="avatar">
                        <div v-if="!item.user_photo" class="initials">
                            {{ item.user_last_name.charAt(0) + item.user_name.charAt(0) }}
                        </div>
                        <div v-else class="photo" :style="{ backgroundImage: 'url(' + item.user_photo.preview + ')', }">
                        </div>
                    </div>
                </div>
                <div>
                    <div class="fio">
                        {{ item.user_last_name }} {{ item.user_name }}
                    </div>
                </div>
            </div>
        </div>

        <div class="name nunito-5-bold" v-html="item.name"></div>

        <div class="tags" v-if="item.tags && item.tags.length > 0">
            <sbs-education-post-item-tags :item="item" />
        </div>

        <div class="description roboto-3-regular" v-if="item.description" v-html="item.description"></div>

        <div v-if="item.picture" class="picture preloaded" v-lazy:background-image="item.picture.preview"
            :style="{ paddingTop: item.picture.height / item.picture.width * 100 + '%' }">

            <div class="preload" v-if="item.picture.preload"
                :style="{ backgroundImage: 'url(\'' + item.picture.preload + '\')' }">
            </div>

            <div class="loader">
                <sbs-loader-inline />
            </div>
        </div>

        <div v-if="item.type == 'POST' && hasBlocks" class="post-view d-block d-sm-none">

            <div class="btn btn3 btn-block slim btn-view" @click="onShowClick" v-if="!showPost">
                {{ $t('education.posts.show') }}
            </div>

            <div class="post-inner" v-if="showPost">

                <div v-if="loading" class="post-loader">
                    <sbs-loader-inline />
                </div>

                <template v-if="itemLoaded">
                    <sbs-education-post-post-blocks :item="itemLoaded" />

                    <div class="btn-hide-wrap">
                        <div class="btn-hide nunito-3-bold" @click="onHideClick">
                            <span class="fi fi-arrow-up"></span>
                            {{ $t('education.posts.hide') }}
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="stat">
            <sbs-education-post-item-stat :item="item" />
        </div>

    </div>


</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationPostItemStat from "@/components/education/post.item.stat.vue";
import sbsEducationPostItemTags from "@/components/education/post.item.tags.vue";
import sbsEducationPostPostBlocks from "@/components/education/post.post.blocks.vue";

import moment from "moment";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import scrollparent from "scrollparent"

export default {
    name: "sbs-education-post-item",
    components: {
        sbsLoaderInline,
        sbsEducationPostItemStat,
        sbsEducationPostItemTags,
        sbsEducationPostPostBlocks
    },
    data() {
        return {
            loading: false,
            itemLoaded: false,
            showPost: false,
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        }
    },

    computed: {
        hasBlocks() {
            return this.item.data && this.item.data.blocks && this.item.data.blocks.length > 0;
        }
    },

    methods: {

        formatDate() {
            let date = moment(this.item.publication_date, "DD.MM.YYYY HH:mm:ss");
            let days = moment().diff(date, 'days');
            if (days <= 3) {
                return date.fromNow();
            }
            return date.format("DD MMMM YYYY");
        },

        /**
         * Переход к посту
         */
        onItemClick(item) {

            if (item.type == 'POST' && this.hasBlocks && window.innerWidth <= 767) {
                return;
            }


            this.$router.push({
                name: "education-post-view",
                params: { id: item.id },
            });
        },

        onShowClick() {

            this.showPost = true;
            if (this.showPost && !this.itemLoaded) {
                this.loadData();
            }

        },

        onHideClick() {

            //прокрутим список обратно до поста
            let y = this.$refs.root.offsetParent.offsetTop - 55;
            scrollparent(this.$refs.root).scrollTo({ top: y, behavior: 'smooth' });

            setTimeout(() => {

                this.showPost = false;

            }, 400);
        },

        loadData() {

            if (this.loading) {
                return;
            }
            this.loading = true

            //запрашиваем данные
            rest
                .call("education.post.get", {
                    method: "post",
                    data: {
                        id: this.item.id,
                        resize_options: {
                            PREVIEW: { WIDTH: 800, HEIGHT: 600 },
                            PRELOAD: "Y"
                        },
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.itemLoaded = data.post;
                        this.hit();
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        /**
         * Фиксация просмотра
         */
        hit() {
            rest
                .call("education.post.hit", {
                    method: "post",
                    data: {
                        id: this.item.id
                    },
                });

        }


    },
};
</script>

<style lang="scss">
.sbs-edu-post-item {
    position: relative;

    margin-bottom: 5px;
    cursor: pointer;

    padding: 15px;
    padding-bottom: 40px;
    border-radius: 15px;

    background: var(--news-card-news-card-bg-color);
    box-shadow: 0px 2px 4px 0px var(--shadow-secondary);

    .author-date {
        margin-bottom: 10px;

        .author {
            display: flex;
            column-gap: 10px;
            align-items: center;

            .avatar {
                position: relative;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: var(--news-card-news-card-line-color);

                .initials {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: var(--audit-cards-audit-cards-title-text-color);
                }

                .photo {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    background-size: cover;
                }
            }

            .fio {
                color: var(--news-card-news-card-secondary-text-color);
            }
        }

        .date {
            color: var(--news-card-news-card-secondary-text-color);
            float: right;
        }
    }

    .name {
        color: var(--news-card-news-card-primary-text-color);
        margin-bottom: 10px;
    }

    .tags {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--news-card-news-card-line-color);
        margin-bottom: 10px;
    }

    .description {
        margin-bottom: 10px;
        color: var(--news-card-news-card-primary-text-color);
    }

    .picture {
        height: 0px;
        background-size: cover;
        background-position: center center;
        border-radius: 12px;
    }

    .stat {
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 5px;
    }

    @media (min-width: 768px) {
        margin-bottom: 10px;
    }

    .post-view {
        margin-top: 12px;
        position: relative;


        .post-inner {
            padding-top: 12px;
        }

        .post-loader {
            text-align: center;
        }

        .btn-hide-wrap {
            position: sticky;
            bottom: 10px;

            &::after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .btn-hide {
            display: flex;
            float: right;

            padding: 12px 22px;
            justify-content: center;
            align-items: center;
            gap: 5px;
            cursor: pointer;

            border-radius: 15px;
            border: 1px solid var(--button-icon-secondary-button-icon-secondary-standard-stroke-color);
            background: var(--button-secondary-button-secondary-standart-background-color);


            box-shadow: 0px 2px 4px 0px var(--shadow-secondary);

            color: var(--blue-700, #0075FF);
        }



    }
}
</style>