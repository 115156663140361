<template>
  <div class="sbs-requests-statistics-ctbru">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend
);

import bus from "@/plugins/bus";
import { shallowRef } from "vue";

export default {
  name: "sbs-requests-statistics-closetime-by-responsible-users",
  components: {},
  data() {
    return {
      chart: false,
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    stacked() {
      return this.items.filter((i) => i.status_id > 0).length > 0;
    },
  },
  methods: {
    draw() {
      let ctx = this.$refs.canvas;

      Chart.defaults.font.family = 'Nunito';
      this.chart = shallowRef(
        new Chart(ctx, {
          plugins: [ChartDataLabels],
          type: "bar",
          data: this.data(),
          options: this.options(),
        })
      );
    },

    refresh() {
      this.chart.options = this.options();
      this.chart.update();
    },

    data() {
      let itemsSorted = this.items.map((i) => i);

      itemsSorted.sort(
        (a, b) =>
          parseFloat(b.close_period_avg) - parseFloat(a.close_period_avg)
      );

      //ограничим кол-во выводимых данных
      let max = 10;
      if (itemsSorted.length > max) {
        let other = itemsSorted.slice(max);
        itemsSorted = itemsSorted.slice(0, max);

        let close_period_avg = 0;
        other.forEach((item) => {
          close_period_avg += parseFloat(item.close_period_avg);
        });

        itemsSorted.push({ responsible_user_last_name: this.$t('requests.summary.other'), close_period_avg: close_period_avg });
      }

      let labels = itemsSorted.map(
        (item) =>
          item.responsible_user_last_name +
          (item.responsible_user_name
            ? " " + item.responsible_user_name.charAt(0) + "."
            : "")
      );

      let data = itemsSorted.map(
        (item) =>
          Math.round((parseInt(item.close_period_avg) / 60 / 60))
      );

      return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: window
              .getComputedStyle(document.documentElement)
              .getPropertyValue("--orange-400"),

            borderRadius: {
              topLeft: 0,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 0
            }
          },
        ],
      };
    },

    /**
     * Опции графика
     */
    options() {
      return {
        indexAxis: "y",
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          datalabels: {
            color: window
              .getComputedStyle(document.documentElement)
              .getPropertyValue("--gray-white")
          }
        },

        aspectRatio: this.windowWidth <= 767 ? 1.1 : 4,

        scales: {
          x: {
            stacked: this.stacked,
            ticks: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-secondary-text-color"),
            },
            grid: {
              borderColor: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-line-color"),
              display: false,
            },
          },
          y: {
            stacked: this.stacked,
            ticks: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-primary-text-color"),
            },
            grid: {
              borderColor: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-line-color"),
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-line-color"),
            },
            beginAtZero: true,
          },
        },
      };
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.refresh();
    }
  },

  created() {
    this.windowWidth = window.innerWidth;
  },

  mounted() {
    this.draw();

    //подписываемся на событие завершения смены темы
    bus.on("SBS_APP_E_THEME_CHANGED", this.refresh);
    window.addEventListener("resize", this.onResize);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_APP_E_THEME_CHANGED", this.refresh);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
