<template>
    <sbs-view :class="'sbs-v-gui'" :isMenuHidden="hideMenu" :refreshDisabled="true" ref="view">
        <template v-slot:header>
            <sbs-header />
        </template>

        <template v-slot:content>
            <div class="h1">GUI</div>

            <div class="h2">Контролы</div>
            <br />

            <sbs-control-input v-model="fields.text1" type="text" label="Текстовое поле" :required="true" />

            <sbs-control-input v-model="fields.text1" type="text" label="Поле с ошибкой" :required="true"
                class="form-group-error" />

            <sbs-control-select v-model="fields.select1" label="Выбор из списка" :required="true"
                method="dictionary.request.type.list" />

            <sbs-control-select v-model="fields.select2" label="Выбор из списка с иерархией" :required="true"
                method="department.list" :multiple="false" />

            <sbs-control-select-multiple v-model="fields.select3" label="Множественный выбор" :list="list1" />

            <sbs-control-datetime v-model="fields.date1" label="Дата и время" />

            <sbs-control-date v-model="fields.date2" label="Дата" />

            <sbs-control-time v-model="fields.time1" label="Время" />

            <sbs-control-time-duration v-model="fields.duration" label="Продолжительность" />

            <sbs-control-textarea v-model="fields.text2" label="Текст" :rows="5" />

            <sbs-control-checkbox v-model="fields.checkbox" value="Y" label="Чекбокс" />

            <sbs-control-radio v-model="fields.radio" value="val1" label="Вариант 1" :form="true" />
            <sbs-control-radio v-model="fields.radio" value="val2" label="Вариант 2" :form="true" />

            <br /><br />
            <sbs-control-photos v-model="photos" label="Фото" />


            <br />
            <div class="h2">Кнопки</div>

            <div class="row">
                <div class="col">
                    <div class="btn btn-block btn1">btn1</div>
                    <br />
                </div>
                <div class="col">
                    <div class="btn btn-block btn2">btn2</div>
                    <br />
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="btn btn-block btn1" disabled>Заблокировано</div>
                    <br />
                </div>
                <div class="col">
                    <div class="btn btn-block btn2" disabled>Заблокировано</div>
                    <br />
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="btn btn-block btn1 wait">
                        Кнопка 1
                        <div class="btn-loader"></div>
                    </div>
                    <br />
                </div>
                <div class="col">
                    <div class="btn btn-block btn2 wait">
                        Кнопка 2
                        <div class="btn-loader"></div>
                    </div>
                    <br />
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="btn btn-block btn1">
                        <div class="text-wrap">
                            <span class="icon fi fi-play-outline"></span>
                            Иконка
                        </div>
                    </div>
                    <br />
                </div>
                <div class="col">
                    <div class="btn btn-block btn2">
                        <div class="text-wrap">
                            <span class="icon fi fi-play-outline"></span>
                            Иконка
                        </div>
                    </div>
                    <br />
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="btn btn-block btn1 slim">Узкая</div>
                    <br />
                </div>
                <div class="col">
                    <div class="btn btn-block btn2 slim">Узкая</div>
                    <br />
                </div>
            </div>

            <br />
            <div class="h2">Блоки</div>

            <div class="block">
                <div class="block-title">Название</div>
                <div class="block-label">Примечание</div>
                <div class="block-text">Текстовое описание блока</div>
            </div>

            <div class="block">
                <sbs-props :props="props1" />
            </div>

            <div class="block">

                <div class="row">
                    <div class="col">
                        <br />
                        <div class="btn btn-block btn1">btn1</div>
                        <br />
                    </div>
                    <div class="col">
                        <br />
                        <div class="btn btn-block btn2">btn2</div>
                        <br />
                    </div>

                </div>

            </div>

            <br />
            <div class="h2">Заголовки</div>


            <div class="h1">Заголовок 1</div>
            <p>Текст в параграфе</p>
            <br />
            <div class="h2">Заголовок 2</div>
            <p>Текст в параграфе</p>
            <br />

            <br />
            <div class="h2">Тексты</div>
            <div style="color: var(--color-text-primary)">Основной текст</div>
            <div style="color: var(--color-text-label)">Метка</div>
            <div style="color: var(--color-text-title)">Заголовок</div>

        </template>

    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlSelect from "@/components/controls/select.vue";
import sbsControlSelectMultiple from "@/components/controls/select.multiple.vue";
import sbsControlDatetime from "@/components/controls/datetime.vue";
import sbsControlDate from "@/components/controls/date.vue";
import sbsControlTime from "@/components/controls/time.vue";
import sbsControlTimeDuration from "@/components/controls/time.duration.vue";
import sbsControlTextarea from "@/components/controls/textarea.vue";
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import sbsControlRadio from "@/components/controls/radio.vue";
import sbsProps from "@/components/props.vue";
import sbsControlPhotos from "@/components/controls/photos.vue";

export default {
    name: "sbs-v-request-templates",
    components: {
        sbsView,
        sbsHeader,
        sbsControlInput,
        sbsControlSelect,
        sbsControlSelectMultiple,
        sbsControlDatetime,
        sbsControlDate,
        sbsControlTime,
        sbsControlTimeDuration,
        sbsControlTextarea,
        sbsControlCheckbox,
        sbsControlRadio,
        sbsProps,
        sbsControlPhotos
    },
    data() {
        return {
            hideMenu: false,
            fields: {
                text1: "",
                select1: "",
                select2: "",
                select3: [],
                date1: "",
                date2: "",
                time1: "",
                duration: "",
                checkbox: "",
                photos: []
            },

            list1: [
                {
                    id: 1,
                    name: "Вариант 1"
                },
                {
                    id: 2,
                    name: "Вариант 2"
                },
                {
                    id: 3,
                    name: "Вариант 3"
                },
                {
                    id: 4,
                    name: "Вариант 4"
                },
            ],

            props1: [
                {
                    name: "Поле 1",
                    value: "Значение",
                    code: "prop1",
                    type: "string"
                },
                {
                    name: "Поле 2",
                    value: "Значение",
                    code: "prop2",
                    type: "string"
                }
            ]
        };
    },

    methods: {


    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
    },
}

</script>

<style lang="scss"></style>