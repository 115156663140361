<template>
  <div class="sbs-request-master-multi">

    <div v-if="step == 1">

      <h3>{{ $t('requests.master_multi.step1.title') }}</h3>
      <div class="description roboto-4-regular">
        {{ $t('requests.master_multi.step1.description') }}
      </div>
      <br /><br />

      <div class="select-btns nunito-3-bold">

        <div class="control" v-if="isNativePlatform" v-ripple.300 @click="scanNfc">
          <div><span class="ic fi fi-nfc"></span></div>
          <div>{{ $t('requests.master_multi.step1.scan_nfc') }}</div>
        </div>

        <div class="control" v-if="isNativePlatform" v-ripple.300 @click="scanQR">
          <div><span class="ic fi fi-qr-scanner"></span></div>
          <div>{{ $t('requests.master_multi.step1.scan_qr') }}</div>
        </div>

        <div class="control" v-ripple.300 @click="selectEquipment">
          <div><span class="ic fi fi-list-bulleted"></span></div>
          <div>{{ $t('requests.master_multi.step1.select') }}</div>
        </div>

      </div>

      <sbs-control-select-sheet v-model:visible="showEquipmentList" :title="$t('requests.master_multi.step1.equipment')"
        method="equipment.list" v-model="equipment" />

    </div>

    <div v-if="step == 2">
      <h3>{{ $t('requests.master_multi.step2.title') }}</h3>
      <br />

      <sbs-control-select v-model="fields.responsible_department_id"
        v-model:modelName="info.responsible_department_name"
        :label="$t('requests.master_multi.step2.responsible_department_id')" :required="true" method="department.list"
        :params="{
      filter: [
        {
          LOGIC: 'OR',
          active: 'Y',
          id: this.fields.responsible_department_id,
        },
      ],
      forResponsible: 'Y',
    }" />

      <sbs-control-select v-model="fields.type_id" v-model:modelName="info.type_name"
        :label="$t('requests.master_multi.step2.type_id')" :required="true" method="dictionary.request.type.list"
        :params="{
      filter: [
        {
          LOGIC: 'OR',
          ACTIVE: 'Y',
          ID: this.fields.type_id,
        },
      ],
      forRequestCreate: 'Y',
    }" />

      <div class="btn btn1 btn-block" v-ripple.300 @click="next">
        {{ $t('common.next') }}
      </div>


    </div>

    <div v-if="step == 3">
      <h3>{{ $t('requests.master_multi.step3.title') }}</h3>

      <div class="block">
        <sbs-props :props="displayProps" />
      </div>

      <sbs-control-input v-model="fields.name" type="text" :label="$t('requests.master_multi.step3.name')"
        :required="true">
        <template v-if="isNativePlatform" v-slot:icon>
          <div class="icon" @click="speechName">
            <span class="fi fi-mic"></span>
          </div>
        </template>
      </sbs-control-input>

      <sbs-control-select v-model="fields.priority_id" v-model:modelName="info.priority_name"
        :label="$t('requests.master_multi.step3.priority_id')" :required="true"
        method="dictionary.request.priorities.list" />

      <sbs-control-checkbox v-model="fields.report_required" value="Y"
        :label="$t('requests.master_multi.step3.report_required')" />

      <div>{{ $t('requests.master_multi.step3.photo_desc') }}</div>
      <br />

      <sbs-control-photos v-model="photos" />

      <div class="btn btn1 btn-block" v-ripple.300 @click="next">
        {{ $t('common.next') }}
      </div>
    </div>

    <div v-if="step == 4 && this.entityFields.length > 0">
      <h3>{{ $t('requests.master_multi.step4.title') }}</h3>

      <sbs-fields-edit v-model="entityValues" entity="REQUEST" :settingsKey="typeId" :showTitle="false" />

      <div v-if="templateId != 0" class="btn btn1 btn-block" v-ripple.300 @click="next">
        {{ $t('common.next') }}
      </div>
    </div>

    <div v-if="step == 4 && this.entityFields.length == 0 || step == 5">
      <h3>{{ $t('requests.master_multi.step5.title', { step: step }) }}</h3>
      <div class="block">
        <div class="name">{{ fields.name }}</div>

        <sbs-props :props="displayProps" />

        <sbs-fields-view :fields="entityFields" :values="entityValues" />
      </div>

      <div class="btn btn1 btn-block btn-save" :class="saving ? 'wait' : ''" v-ripple.300 @click="save">
        {{ $t('requests.master_multi.step5.save') }}
        <div v-if="saving" class="btn-loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlSelect from "@/components/controls/select.vue";
import sbsControlPhotos from "@/components/controls/photos.vue";
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import sbsFieldsEdit from "@/components/fields.edit.vue";
import sbsFieldsView from '@/components/fields.view.vue';
import sbsProps from "@/components/props.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";
import { mapGetters } from "vuex";
import validator from "@/plugins/validator";

export default {
  name: "sbs-request-master-multi",
  components: {
    sbsControlSelectSheet,
    sbsControlInput,
    sbsControlSelect,
    sbsControlPhotos,
    sbsControlCheckbox,
    sbsFieldsEdit,
    sbsFieldsView,
    sbsProps
  },
  emits: ["update:step", "update:skipBack"],
  data() {
    return {
      showEquipmentList: false,
      showDepartmentList: false,
      fields: {
        report_required: "Y",
      },
      info: {},
      equipment: {},
      department: {},
      defaults: {},
      photos: [],
      saving: false,

      entityFields: [],
      entityValues: {},

      blockClick: false
    };
  },
  props: {
    step: {
      type: Number,
      default() {
        return 1;
      },
    },
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters({
      isNativePlatform: "isNativePlatform",
    }),

    /**
     * Тукущий тип заявки
     */
    typeId() {
      return this.fields ? this.fields.type_id : false
    },

    /**
     * Отображаемые свойства
     */
    displayProps() {
      let list = [];

      if (this.step >= 3) {
        //оборудование
        list.push({
          code: "equipment",
          type: "string",
          name: this.$t('requests.master_multi.step3.equipment'),
          value: this.info.equipment_name
        });
        //подразделение-исполнитель
        list.push({
          code: "responsible_department",
          type: "string",
          name: this.$t('requests.master_multi.step2.responsible_department_id'),
          value: this.info.responsible_department_name
        });
      }
      if (this.step >= 4) {
        //приоритет
        list.push({
          code: "priority",
          type: "string",
          name: this.$t('requests.master_multi.step3.priority_id'),
          value: this.info.priority_name
        });
        //тип
        list.push({
          code: "type",
          type: "string",
          name: this.$t('requests.master_multi.step2.type_id'),
          value: this.info.type_name
        });
        //фото
        list.push({
          code: "PHOTO",
          type: "files",
          name: this.$t('requests.master_multi.step5.photo'),
          value: this.photos
        });
      }

      return list;
    }
  },
  watch: {
    step: function (newVal) {
      //шаг 1
      if (newVal == 1) {
        this.clear();
        this.loadDefaults();
      }
    },
    equipment: function () {
      if (this.equipment.id) {
        this.fields.equipment_id = this.equipment.id;
        this.info.equipment_name = this.equipment.name;
        setTimeout(() => {
          this.next();
        }, 400);
      }
    },

    department: {
      handler() {
        if (this.department.id) {
          this.fields.responsible_department_id = this.department.id;
          this.info.responsible_department_name = this.department.name;
          this.next();
        }
      },
      deep: true,
    },

    reload: function () {
      this.clear();
    },

    typeId() {
      this.loadEntityFields();
    }
  },

  methods: {

    /**
     * Очищает форму
     */
    clear() {

      this.fields = {
        name: "",
        equipment_id: "",
        responsible_department_id: "",
        type_id: this.fields.type_id ? this.fields.type_id : "",
        priority_id: this.fields.priority_id ? this.fields.priority_id : "",
        report_required: this.fields.report_required == "Y" ? "Y" : "N",
      };
      this.info = {
        equipment_name: "",
        responsible_department_name: "",
        type_name: this.info.type_name ? this.info.type_name : "",
        priority_name: this.info.priority_name ? this.info.priority_name : "",
      };

      this.photos = [];
      this.equipment = {};
      this.department = {};
    },

    /**
     * Сканпрование NFC-метки
     */
    scanNfc() {

      if (this.blockClick) {
        return;
      }
      this.blockClick = true;

      setTimeout(() => {

        let vm = this;
        bus.emit("SBS_NFC_SCANNER_E_OPEN", {
          resolve: function (item) {
            vm.fields.equipment_id = item.id;
            vm.info.equipment_name = item.name;
            vm.next();
          },
        });

        this.blockClick = false;

      }, 400);
    },

    /**
     * Сканпрование QR
     */
    scanQR() {
      if (this.blockClick) {
        return;
      }
      this.blockClick = true;

      setTimeout(() => {

        let vm = this;
        bus.emit("SBS_QR_SCANNER_E_OPEN", {
          resolve: function (item) {
            vm.fields.equipment_id = item.id;
            vm.info.equipment_name = item.name;
            vm.next();
          },
        });

        this.blockClick = false;

      }, 400);
    },

    /**
     * Клик по "Выбрать из списка"
     */
    selectEquipment() {
      if (this.blockClick) {
        return;
      }
      this.blockClick = true;

      setTimeout(() => {
        this.showEquipmentList = true;
        this.blockClick = false;

      }, 400);
    },

    /**
     * Следующий шаг
     */
    next() {
      //проверка введённых данных
      if (!validator.check({ container: this.$el })) {
        return;
      }

      this.$emit("update:step", this.step + 1);
    },

    /**
     * Клик по "выбрать из списка" ля подразделения
     */
    selectDepartment() {
      this.showDepartmentList = true;
    },

    /**
     * Распознование речи для навания
     */
    speechName() {
      bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
        resolve: (text) => {
          this.fields.name = text;
        },
      });
    },

    /**
     * Загружает список доп полей
     */
    loadEntityFields() {
      rest.call("field.entity.fields", {
        method: "post",
        data: {
          entity: "REQUEST",
          key: this.typeId,
        },
      })
        .then((data) => {
          if (data.success) {
            this.entityFields = data.fields;
          }
        });
    },

    /**
     * Сохранение
     */
    save() {
      if (this.saving) {
        return;
      }
      this.saving = true;

      let photoData = this.photos.map((p) => p.data);

      //выполняем сохранение
      rest
        .call("request.add", {
          method: "post",
          data: {
            fields: this.fields,
            photos: photoData,
            entityFields: this.entityValues
          },
          timeout: 0,
        })
        .then((data) => {
          if (data.success) {
            this.onSave(data.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },

    /**
     * Обработка успешного сохранения
     */
    onSave(id) {
      //показываем сообщение об Успехе
      bus.emit("SBS_MESSAGE_E_SHOW", {
        message: this.$t('requests.master_multi.step5.success'),
        type: "success",
      });

      //генерим событие успешного сохранения заявки
      bus.emit("SBS_REQUEST_FORM_E_SAVE", id);

      //сбрасываем название и фото
      this.photos = [];
      this.fields.name = "";
      //переходоим к шагу №3
      this.$emit("update:step", 3);
    },

    /**
     * Загружает значения по умолчанию
     */
    loadDefaults() {

      let arRequests = {
        departments: {
          ENTITY: "department",
          METHOD: "list",
          PARAMS: {
            filter: {
              active: 'Y'
            },
            forResponsible: 'Y'
          }
        },
        types: {
          ENTITY: "dictionary",
          METHOD: "request.type.list",
          PARAMS: {
            filter: {
              active: 'Y'
            },
            forRequestCreate: 'Y'
          }
        },
        defaultPriority: {
          ENTITY: "dictionary",
          METHOD: "request.priorities.list",
          PARAMS: {
            filter: {
              "!PROPERTY_IS_DEFAULT": false,
            },
          },
        },
      };

      //запрашиваем данные
      rest
        .batch(arRequests)
        .then((arResults) => {

          this.defaults = {};
          for (var key in arResults) {
            let data = arResults[key];
            if (key == "departments") {
              //если доступно только одно подразделени-исполнитель
              if (data.items.length == 1) {
                let department = data.items.find(i => i);
                this.defaults.department = department;
              }
            } else if (key == 'types') {
              //если доступен только один тип заявок
              if (data.items.length == 1) {
                let type = data.items.find(i => i);
                this.defaults.type = type;
              }
            } else if (key == 'defaultPriority') {
              //приоритет по умолчанию
              let priority = data.items.find(i => i);
              this.defaults.priority = priority;
            }
          }

          this.setDefaults();

        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        });
    },

    /**
     * Устанавливает значения по умолчанию
     */
    setDefaults() {

      //подразделение-исполнитель
      if (this.defaults.department) {
        this.fields.responsible_department_id = this.defaults.department.id;
        this.info.responsible_department_name = this.defaults.department.name;
      }

      //тип
      if (this.defaults.type) {
        this.fields.type_id = this.defaults.type.id;
        this.info.type_name = this.defaults.type.name;
      }

      //приоритет
      if (this.defaults.priority) {
        this.fields.priority_id = this.defaults.priority.id;
        this.info.priority_name = this.defaults.priority.name;
      }

    }
  },

  created() {
    //загружаем данные
    this.loadDefaults();
  },
};
</script>

<style lang="scss">
.sbs-request-master-multi {

  .description {
    color: var(--audit-cards-audit-cards-title-text-color, #FFF);
  }

  .select-btns {
    display: flex;

    align-items: center;
    justify-content: center;
    column-gap: 10px;

    max-width: 500px;
    margin: auto;

    .control {
      padding: 17px 5px;
      border-radius: 15px;
      background: var(--audit-cards-audit-cards-bg-color);
      box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
      cursor: pointer;
      text-align: center;
      min-height: 100%;

      flex-basis: 33%;

      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      gap: 7px;

      color: var(--service-color-1);

      text-align: center;

      .ic {
        font-size: 18px;
      }
    }
  }

  .btn {
    margin-bottom: 13px;
  }

  .btn-select-department {
    margin-bottom: 40px;
  }

  .block {
    padding: 15px 20px;
    border-radius: 15px;
    background: var(--audit-cards-audit-cards-bg-color);
    box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
    margin-bottom: 15px;

    .name {
      font-size: 16px;
      color: var(--text-field-text-field-active-color-text);
      line-height: 1.14;
      margin-top: 15px;
      margin-bottom: 10px;
    }

  }

}
</style>