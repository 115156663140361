<template>
    <div class="sbs-request-item" :class="{ expanded: expanded, finished: item.status_id == requestStatusFinished }"
        @click="onItemClick">

        <div class="idq">
            <div class="id nunito-1-bold">#{{ item.number }}</div>
            <div v-if="item.equipment_id > 0" class="equipment nunito-4-bold">
                {{
        item.equipment_short_name
            ? item.equipment_short_name
            : item.equipment_name
    }}
            </div>
        </div>
        <div class="status nunito-2-bold"
            :style="{ backgroundColor: item.status_color, color: item.status_color_text }">
            <span class="icon fi" :class="item.status_icon"></span>
            {{ item.status_name }}
        </div>

        <div class="unread nunito-2-medium" v-if="unreadCnt > 0" v-html="unreadCnt <= 99 ? unreadCnt : '99'">
        </div>

        <div class="name roboto-4-regular">
            {{ item.name }}
        </div>


        <!-- Раскрывашка до информации -->
        <div class="info">
            <div class="report_required nunito-2-bold" v-if="item.report_required == 'Y'">
                {{ $t('requests.list.report_required') }}
            </div>
            <div class="prop" v-if="item.equipment_id > 0">
                <div class="label nunito-1-bold">{{ $t('requests.list.equipment') }}:</div>
                <div class="value roboto-3-regular">{{ item.equipment_name }}</div>
            </div>
            <div class="prop">
                <div class="label nunito-1-bold">{{ $t('requests.list.responsible_department') }}:
                </div>
                <div class="value roboto-3-regular">{{ item.responsible_department_name }}</div>
            </div>
            <div class="prop" v-if="item.responsible_user_id > 0">
                <div class="label nunito-1-bold">{{ $t('requests.list.responsible_user') }}:</div>
                <div class="value roboto-3-regular">
                    {{ item.responsible_user_name }}
                    {{ item.responsible_user_last_name }}
                </div>
            </div>
            <div class="prop" v-if="item.created_by > 0">
                <div class="label nunito-1-bold">{{ $t('requests.list.created_by') }}:</div>
                <div class="value roboto-3-regular">
                    {{ item.created_by_user_name }}
                    {{ item.created_by_user_last_name }}
                </div>
            </div>
        </div>

        <div class="bottom nunito-2-bold">
            <div class="date" v-html="formatDate(item.date_create)"></div>
            <div class="rating" v-if="item.rating > 0">
                <span class="fi fi-star-filled icon"></span>
                {{ item.rating }}
            </div>
            <div class="views">
                <span class="fi fi-eye icon"></span>
                {{ item.viewers_count > 0 ? item.viewers_count : "0" }}
            </div>
            <div class="priority" :style="{ color: item.priority_color }">
                <span class="fi fi-fire"></span>
            </div>
        </div>



        <!-- Кнопки -->
        <div class="btn-wrap">
            <div class="row">

                <!-- Если исполнителя нет -->
                <template v-if="!item.responsible_user_id">

                    <!-- Взять и начать -->
                    <div class="col" v-if="item.permissions && item.permissions.includes('take')">
                        <div class="btn btn-block btn2 slim" :class="item.loading ? 'wait' : ''"
                            @click.stop="takeAndStart">
                            <div class="text-wrap">
                                <span class="icon fi fi-play-outline"></span>
                                {{ $t('requests.list.actions.start') }}
                            </div>

                            <div v-if="item.loading" class="btn-loader"></div>
                        </div>
                    </div>

                </template>

                <!-- Если исполнитель задан  -->
                <template v-else>

                    <!-- Начать -->
                    <div class="col"
                        v-if="item.status_id != requestStatusFinished && item.permissions && item.permissions.includes('start')">
                        <div class="btn btn-block btn2 slim" :class="item.loading ? 'wait' : ''" @click.stop="start">
                            <div class="text-wrap">
                                <span class="icon fi fi-play-outline"></span>
                                {{ $t('requests.list.actions.start') }}
                            </div>
                            <div v-if="item.loading" class="btn-loader"></div>
                        </div>
                    </div>

                    <!-- Завершить -->
                    <div class="col"
                        v-else-if="item.status_id == requestStatusStart && item.permissions && item.permissions.includes('complete')">
                        <div class="btn btn-block btn2 slim" :class="item.loading ? 'wait' : ''" @click.stop="complete">
                            <div class="text-wrap">
                                <span class="icon fi fi-stop-outline"></span>
                                {{ $t('requests.list.actions.complete') }}
                            </div>
                            <div v-if="item.loading" class="btn-loader"></div>
                        </div>
                    </div>

                    <!-- Назначить себе -->
                    <div class="col"
                        v-else-if="item.status_id != requestStatusFinished && item.permissions && (item.permissions.includes('delegate') || item.permissions.includes('take'))">
                        <div class="btn btn-block btn2 slim" :class="item.loading ? 'wait' : ''"
                            @click.stop="takeAndStart">
                            <div class="text-wrap">
                                <span class="icon fi fi-add-outline"></span>
                                {{ $t('requests.list.actions.take') }}
                            </div>
                            <div v-if="item.loading" class="btn-loader"></div>
                        </div>
                    </div>

                </template>

                <!-- Перейти -->
                <div class="col">
                    <div class="btn btn-block btn1 slim" v-ripple.300 @click.stop="goToItem">
                        {{ $t('requests.list.actions.view') }}
                    </div>
                </div>
            </div>
        </div>

    </div>

    <sbs-sheet v-model:visible="displayNfc">
        <template v-slot:body>

            {{ $t('requests.list.need_nfc') }}
            <br /><br />
            <div class="btn btn-block btn1" @click="scanNfc"> {{ $t('requests.list.scan_nfc') }}</div>

        </template>

    </sbs-sheet>

</template>

<script>
import sbsSheet from '@/components/sheet.vue'

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";

import { mapGetters } from "vuex";

export default {
    name: "sbs-request-item",
    components: {
        sbsSheet,
    },

    data() {
        return {
            expanded: false,
            displayNfc: false,
            loading: false,
            action: ""
        };
    },

    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    computed: {
        ...mapGetters({
            clientSettings: "getClientSettings",
            requestUnread: "getRequestUnread",
        }),

        //ИД статуса "Завершено"
        requestStatusFinished() {
            return this.clientSettings
                ? this.clientSettings.request_status_finished
                : false;
        },
        //ИД статуса "В работе"
        requestStatusStart() {
            return this.clientSettings
                ? this.clientSettings.request_status_start
                : false;
        },

        unreadCnt() {
            let r = this.requestUnread.find(r => r.id == this.item.id);
            if (r) {
                return r.unread_messages_cnt
            }
            return 0;
        }
    },

    methods: {

        /**
         * Клик по заявке
         */
        onItemClick() {
            this.expanded = !this.expanded;
        },

        /**
         * Форматирует дату
         */
        formatDate(date) {
            return moment(date, "DD.MM.YYYY hh:mm:ss").format("DD MMMM YYYY HH:mm");
        },

        /**
         * Переход к заявке
         */
        goToItem() {

            setTimeout(() => {
                this.$router.push({
                    name: "request-view",
                    params: { id: this.item.id, tab: "discussion" },
                });
            }, 500);

        },

        /**
         * Взять себе и начать выполнение
         */
        takeAndStart(item) {

            if (!item.need_start_by_nfc) {
                this.takeAndStartImmediatly(item);
            } else {
                this.displayNfc = true;
                this.loading = false;
                this.action = "takeAndStart";
            }
        },

        /**
         * Начать выполнение сразу
         */
        takeAndStartImmediatly() {
            if (this.loading) {
                return;
            }

            let arRequests = {
                take: {
                    ENTITY: "request",
                    METHOD: "take",
                    PARAMS: {
                        id: this.item.id,
                    },
                },
            };
            if (this.item.status_id != this.requestStatusStart) {
                arRequests.start = {
                    ENTITY: "request",
                    METHOD: "start",
                    PARAMS: {
                        id: this.item.id,
                    },
                };
            }
            this.loading = true;
            rest
                .batch(arRequests)
                .then((results) => {
                    for (var key in results) {
                        let data = results[key];
                        if (!data.success) {
                            //показываем сообщение об ошибке
                            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                            return;
                        }
                    }
                    bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
                    bus.emit("SBS_REQUEST_E_UPDATED", this.item.id);
                }).finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Начать выполнение
         */
        start(item) {

            if (!item.need_start_by_nfc) {
                this.startImmediatly();
            } else {
                this.displayNfc = true;
                this.loading = false;
                this.action = "start";
            }
        },

        /**
         * Начать выполнение
         */
        startImmediatly() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            //выполняем запрос
            rest
                .call("request.start", {
                    method: "post",
                    data: {
                        id: this.item.id,
                    },
                })
                .then((data) => {
                    if (data.success) {
                        bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
                        bus.emit("SBS_REQUEST_E_UPDATED", this.item.id);
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                }).finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Завершить
         */
        complete() {
            //если есть чеклист и он не пройден
            if (this.item.have_checklist == "Y" && this.item.checklist_completed == 'N') {
                this.$router.push({
                    name: "request-checklist-execute",
                    params: { id: this.item.id },
                });
                return;
            }
            //если требуется отчёт по завершении
            else if (this.item.report_required == "Y") {
                this.$router.push({
                    name: "request-report-execute",
                    params: { id: this.item.id },
                });
                return;
            }
            if (this.loading) {
                return;
            }
            this.loading = true;

            //выполняем запрос
            rest
                .call("request.complete", {
                    method: "post",
                    data: {
                        id: this.item.id,
                    },
                })
                .then((data) => {
                    if (data.success) {
                        bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
                        bus.emit("SBS_REQUEST_E_UPDATED", this.item.id);
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                }).finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Сканировать NFC метку
         */
        scanNfc() {
            let vm = this;
            //скрываем собщение о том, что нужно сканировать NFC
            this.displayNfc = false;

            //вызываем сканер NFC
            bus.emit("SBS_NFC_SCANNER_E_OPEN", {
                resolve: function (equipment) {

                    //проверям если ИД оборудования, который нашёл сканер совпадает с тем, что указан в заявке
                    if (equipment.id == vm.item.equipment_id) {
                        //если действие было "Взять и начать"
                        if (vm.action == "takeAndStart") {
                            vm.takeAndStartImmediatly();
                        }
                        //иначе дейсвтие было "Начать"
                        else {
                            vm.startImmediatly();
                        }
                    } else {
                        bus.emit("SBS_MESSAGE_E_SHOW", {
                            message:
                                "Метка не закрепленна за оборудованием по данной заявке.",
                        });
                    }
                },
            });
        },



    },
};
</script>

<style lang="scss">
.sbs-request-item {
    position: relative;
    padding: 15px;
    padding-bottom: 13px;
    border-radius: 15px;
    background: var(--audit-cards-audit-cards-bg-color);
    box-shadow: 0px 2px 4px 0px var(--shadow-secondary);

    margin-bottom: 10px;
    cursor: pointer;


    .idq {
        padding-right: 100px;
        min-height: 30px;

        .id {
            color: var(--news-card-news-card-secondary-text-color);

            margin-bottom: 7px;
        }

        .equipment {
            color: var(--audit-cards-audit-cards-title-text-color);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 5px;
        }
    }

    .status {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 6px 8px 6px 26px;

        border-radius: 10px;
        line-height: 1;

        .icon {
            position: absolute;
            top: 50%;
            left: 8px;
            transform: translateY(-50%);
        }
    }

    .unread {
        position: absolute;
        top: -10px;
        right: -10px;

        height: 22px;
        width: 22px;
        border-radius: 50%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1;

        color: var(--bottom-navigation-tab-bottom-navigation-tab-notification-text-color);

        border: 2px solid var(--bottom-navigation-tab-bottom-navigation-tab-standard-bg-color);
        background: var(--bottom-navigation-tab-bottom-navigation-tab-icon2-color);
    }

    .name {
        color: var(--audit-cards-audit-cards-title-text-color);
        margin-bottom: 7px;
        transition: font-size 0.5s ease-in-out, margin-bottom 0.5s ease-in-out;
    }

    .bottom {
        display: flex;
        align-items: center;
        column-gap: 10px;
        line-height: 1;

        color: var(--news-card-news-card-secondary-text-color);

        .date {
            flex-grow: 1;
            text-transform: uppercase;
        }

        .views,
        .rating {
            position: relative;
            padding-left: 20px;
            line-height: 1;

            .icon {
                position: absolute;
                top: 50%;
                left: 0px;
                font-size: 14px;
                transform: translateY(-50%);
            }
        }

    }

    .info {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.5s ease-in-out;

        .report_required {
            margin-bottom: 12px;
            color: var(--service-color-1);

        }

        .prop {
            margin-bottom: 12px;

            .label {
                color: var(--news-card-news-card-secondary-text-color);
                text-transform: uppercase;
                margin-bottom: 5px;
            }

            .value {
                color: var(--audit-cards-audit-cards-title-text-color);
            }
        }


    }

    .btn-wrap {
        margin-top: 0px;
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out;

        .row {
            margin: 0px -7px;

            >div {
                padding: 0px 7px;
            }
        }

        .btn {
            padding-left: 10px;
            padding-right: 5px;
        }
    }

    &.expanded {
        .name {

            margin-bottom: 20px;


        }

        .info {
            max-height: 300px;
        }

        .btn-wrap {
            margin-top: 10px;
            max-height: 50px;
        }
    }

    &.finished {
        border: 1px solid var(--audit-cards-audit-cards-stroke-color);
        background: var(--audit-cards-audit-cards-bg-completed-color);

        .idq .id,
        .idq .equipment,
        .name,
        .info .prop .label,
        .info .prop .value {
            color: var(--news-card-news-card-secondary-text-color);
        }
    }
}
</style>