<template>
    <div class="sbs-edu-pis">
        <div class="reaction-column">
            <sbs-control-reaction :modelValue="itemInternal.reaction" @change="reactionChange($event)" />
        </div>

        <div class="stat-item roboto-3-regular" v-if="reactionsCount > 0" @click.stop="openReactions">
            <div class="value">
                {{ reactionsCount }}
            </div>
            <div class="reactions">
                <template v-for="s in reactionsReduced" :key="s.value">
                    <span class="rv">{{ s.value }}</span>
                </template>
            </div>

        </div>

        <div v-if="item.comments_enabled == 'Y' && item.comment_private != 'Y'">
            <div class="stat-item roboto-3-regular" @click.stop="openComments">
                <div class="value">
                    {{ item.comments_count }}
                </div>
                <div class="icon">
                    <span class="fi fi-comments"></span>
                </div>
            </div>
        </div>
        <div>

            <div class="stat-item roboto-3-regular">
                <div class="value">
                    {{ item.views_count }}
                </div>
                <div class="icon">
                    <span class="fi fi-eye"></span>
                </div>
            </div>

        </div>

        <!-- Список пользователей, оставивших реакцию -->
        <sbs-education-post-reaction-list :id="item.id" v-if="reactionsCount > 0" v-model:visible="reactionsOpen"
            :stat="this.itemInternal.reactions" />

        <!-- Комментарии -->
        <sbs-education-post-comments-sheet :postId="item.id" v-model:visible="commentsOpen"
            :private="item.comments_private == 'Y'" />
    </div>
</template>

<script>
import sbsControlReaction from "@/components/controls/reaction.vue";
import sbsEducationPostReactionList from "@/components/education/post.reaction.list.vue";
import sbsEducationPostCommentsSheet from "@/components/education/post.comments.sheet.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-education-post-item-stat",
    components: {
        sbsControlReaction,
        sbsEducationPostReactionList,
        sbsEducationPostCommentsSheet
    },
    data() {
        return {
            itemInternal: this.item,
            reactionsOpen: false,
            commentsOpen: false,
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        }
    },

    computed: {

        reactionsCount() {
            if (!this.itemInternal.reactions) {
                return 0;
            }

            return this.itemInternal.reactions.reduce(function (a, b) {
                return a + parseInt(b.count);
            }, 0)

        },

        reactionsSorted() {
            if (!this.itemInternal.reactions) {
                return [];
            }
            return this.itemInternal.reactions.filter(s => s.count > 0).sort(({ count: a }, { count: b }) => b - a);
        },

        reactionsReduced() {

            return this.reactionsSorted.length <= 3 ? this.reactionsSorted : this.reactionsSorted.slice(0, 3);
        }
    },

    watch: {
        item: function () {
            this.itemInternal = this.item;
        },

    },

    methods: {
        reactionChange(value) {

            //1) запрос сохранения на сервер
            this.savePostReaction(value);

            //2) пересчёт в посте статистики по реакциям
            this.recalculateReactionsStat(value);

            //3) сохранение в посте "моей реакции"
            this.itemInternal.reaction = value;
        },

        /**
         * Сохранени реакции
         */
        savePostReaction(value) {

            rest
                .call("education.post.reaction.save", {
                    method: "POST",
                    data: {
                        post_id: this.item.id,
                        reaction: value
                    },
                    timeout: 0,
                })
                .then((data) => {
                    if (!data.success) {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                });
        },

        /**
         * Пересчёт статистики по реакциям
         */
        recalculateReactionsStat(value) {

            //уменьшаем по предыдущей реакции статистику
            let stat = this.itemInternal.reactions.find(r => r.value == this.itemInternal.reaction);
            if (stat) {
                stat.count = parseInt(stat.count) - 1;
            }

            //если оценки нет
            if (!value) {
                return;
            }

            //увеличиваем по новой реакции статистику
            stat = this.itemInternal.reactions.find(r => r.value == value);
            if (stat) {
                stat.count = parseInt(stat.count) + 1;
            } else {
                this.itemInternal.reactions.push({
                    value: value,
                    count: 1
                });
            }


        },

        openReactions() {
            this.reactionsOpen = true;
        },

        openComments() {
            this.commentsOpen = true;
        }

    }

}

</script>

<style lang="scss">
.sbs-edu-pis {
    display: flex;

    column-gap: 10px;
    align-items: center;

    .reaction-column {
        flex-grow: 1;
    }

    .stat-item {
        display: flex;
        align-items: center;
        column-gap: 5px;
        line-height: 1;

        .icon {
            color: var(--news-card-news-card-icon-color);
        }

        .value {
            color: var(--news-card-news-card-secondary-text-color);
        }

        .reactions {
            display: flex;

            .rv {
                margin-left: -7px;

                background: var(--news-card-news-card-bg-color);
                border-radius: 50%;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:first-of-type {
                    margin-left: 0px;
                }
            }
        }



    }
}
</style>