import { createStore } from 'vuex'
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import ws from "@/plugins/websocket";
import localStorage from "@/plugins/localStorage";

import connection from "@/store/connection";
import user from "@/store/user";
import { AxiosError } from 'axios';

export default createStore({
  modules: {
      connection: connection,
      user: user,
  },
  state: {
    
    //токен PUSH
    pushToken: null,
    //по умолчанию считаем, что Push включены
    hasPushPermission: true,
    online: true,
    isLoading: false,
    //переменная, отражающая что в данный момент идёт переход назад
    isNowGoBack: false,
    //меню приложения, нужно чтобы знать при переключении экранов нужно ли показывать анимацию перехода
    menu: [],
    //скрыть меню
    hideMenu: true,
    //текущий таб на экране заявок
    requestsTab: "active",
    //тема
    theme: "dark",
    //опции редактора фото
    pictureEditorParams: {},
    //UUID соединения вебсокета
    websocketId: "",
    //настройки клиента
    clientSettings: false,
    //безопасная область вывода (для учёта шторки)
    safeArea: false,
    //стек вызова для действия "Назад"
    backStack: [],
    //флаг приняти правил использования
    termsOfUseAccepted: false,
    //версия нативного приложения
    appVersion: false,

    //хранилище временных данных для обмена между экранами
    tempData: {},

    //фильтры
    filters: {},

    //сортировки
    sorts: {},

    //текущий сервис
    service: ""
  },
  getters: {
    
    getPushToken(state) {
      return state.pushToken;
    },
    getHasPushPermission(state) {
      return state.hasPushPermission;
    },
    getOnline(state) {
      return state.online;
    },
    getIsLoading(state) {
      return state.isLoading;
    },
    getIsNowGoBack(state) {
      return state.isNowGoBack;
    },
    getMenu(state) {
      return state.menu;
    },
    isRouteInMenu: state => url => {
      if (url.includes("/more/")) {
        return true;
      }
      let items = state.menu.filter( i => i.service == state.service);
      items = items.length > 4 ? items.slice(0, 3) : items

      return items.find(item => item.url === url) !== undefined;
    },
    isHideMenu(state) {
      return state.hideMenu;
    },
    getRequestsTab(state) {
      return state.requestsTab;
    },

    getRequestsTabFilterKey(state) {
      return ["active", "responsible", "owner", "unread", "all"].includes(state.requestsTab) ? "common": state.requestsTab ;
    },

    getTheme(state) {
      return state.theme;
    },

    getPictureEditorParams(state) {
      return state.pictureEditorParams;
    },
    
    getWebSocketId(state) {
      return state.websocketId;
    },
    
    /**
     * Настройки клиента
     */
    getClientSettings(state) {
      return state.clientSettings;
    },

    /**
     * Безопасная область экрана нативной платформы
     */
    getSafeArea(state) {
      return state.safeArea;
    },

    /**
     * Нативная платформа
     */
    isNativePlatform() {
      return Capacitor.isNativePlatform()
    },

    /**
     * платформа (web,android,ios)
     */
    getPlatform() {
      return Capacitor.getPlatform();
    },

    /**
     * Возвращает стэк функций-обработчиков возврата назад
     */
    getBackStack(state) {
      return state.backStack;
    },

    /**
     * Флаг принятия правил использования
     */
    getTermsOfUseAccepted(state) {
      return state.termsOfUseAccepted;
    },

    /**
     * Версия нативного приложения
     */
    getAppVersion(state) {
      return state.appVersion;
    },

    /**
     * Возвращает по ключу временные данные
     */
    getTempData: state => name => {
      if(state.tempData[name]) {
        return state.tempData[name];
      }
      
      return false;
    },

    /**
     * Возвращает по ключу фильтр
     */
    getFilter: state => name => {
      if(state.filters[name]) {
        return state.filters[name];
      }
      
      return {};
    },

    /**
     * Возвращает по ключу сортировку
     */
    getSort: state => name => {
      if(state.sorts[name]) {
        return state.sorts[name];
      }
      
      return {};
    },

    /**
     * Текущий сервис
     */
    getService(state) {
      return state.service;
    },
  },
  mutations: {
    
    setPushToken: (state, value) => {
      state.pushToken = value;
      localStorage.set("fcm_token", value);
    },
    setHasPushPermission: (state, value) => {
      state.hasPushPermission = value;
    },
    setOnline: (state, value) => {
      state.online = value;
    },
    setIsLoading: (state, value) => {
      state.isLoading = value;
    },
    setIsNowGoBack: (state, value) => {
      state.isNowGoBack = value;
    },
    setMenu: (state, value) => {
      state.menu = value;
      localStorage.set("menu", value);
    },
    setHideMenu: (state, value) => {
      state.hideMenu = value;
    },
    setRequestsTab: (state, value) => {
      state.requestsTab = value;
    },
    setTheme: (state, value) => {
      state.theme = value;
      localStorage.set("theme", value);
    },
    setPictureEditorParams: (state, value) => {
      state.pictureEditorParams = value;
    },
    setWebSocketId: (state, value) => {
      state.websocketId = value;
    },
    setClientSettings: (state, value) => {
      state.clientSettings = value;
    },
    setSafeArea: (state, value) => {
      state.safeArea =value;

      for (var key in value) {
        document.documentElement.style.setProperty(
            `--safe-area-${key}`,
            `${value[key]}px`,
        );
      }
    },

    /**
     * Добавляет метод в стек обработчика возврата назхад
     */
    addToBackStack: (state, value) => {
        state.backStack.push(value);
    },

    /**
     * Удаляет метод из стека обработчиков возврата назад
     */
    removeFromBackStack: (state, value) => {
        state.backStack = state.backStack.filter(f => f != value);
    },

    /**
     * Устанавливает факт принятия правил использования
     */
    setTermsOfUseAccepted: (state, value) => {
        state.termsOfUseAccepted = value;
    },

    /**
     * Устанавливает версиб нативного приложения
     */
    setAppVersion: (state, value) => {
        state.appVersion = value;
    },

    /**
     * Устанавилвает временнве данные по ключу
     */
    setTempData: (state, payload) => {
      state.tempData[payload.name] = payload.value;
    },

    /**
     * Устанавливает фильтр по ключу
     */
    setFilter: (state, payload) => {
      state.filters[payload.name] = JSON.parse(JSON.stringify( payload.value));
    },

    /**
     * Устанавливает фильтры
     */
    setFilters: (state, value) => {
      state.filters = value;
    },

    /**
     * Устанавливает сортировку по ключу
     */
    setSort: (state, payload) => {
      state.sorts[payload.name] = JSON.parse(JSON.stringify( payload.value));
    },

    /**
     * Устанавливает сортировки
     */
    setSorts: (state, value) => {
      state.sorts = value;
    },


    /**
     * Устанавливает текущий сервис
     */
    setService: (state, value) => {

      if(state.service) {
        document.documentElement.classList.remove('service-' + state.service.toLowerCase());
      }
      if(value) {
        document.documentElement.classList.add('service-' + value.toLowerCase());
      }

      state.service = value;
      localStorage.set("service", value);
    },
  },
  actions: {

    /**
     * Сброс данных
     */
    clear(context) {

      //очищаем подключения
      context.commit("setConnections", {});
      localStorage.set("connections", {});
      context.commit("setCurrentConnection", 0);

      //сбрасываем состояние, зависящее от текущего аккаунта
      context.dispatch("reset");
    },

    /**
     * Сброс данных, зависящий от аккаунта
     */
    reset(context) {

      //сброс меню
      context.commit('setMenu', []);
      //сброс фильтров
      context.commit('setFilters', {});
      //сброс сортировок
      context.commit('setSorts', {});

      //сброс профиля пользователя
      context.commit('setUserProfile', false);
      //сброс настроек клиента
      context.commit('setClientSettings', false);
      //сброс выбранного сервиса
      context.commit("setService", "");
    },

    /**
     * Загружает настройки клиента
     */
    loadClientSettings(context) {

      return new Promise((resolve,reject) => {

        //запрашиваем данные
        rest
          .call("system.settings", {}, false)
          .then((data) => {
            if (data.success) {
              context.commit('setClientSettings', data.settings);
            } else {
              //показываем сообщение об ошибке
              bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
              reject(new Error(data.errorText));
            }
            resolve();
        }).catch(error => {

          if(error instanceof AxiosError) {
            if(error.code == "ECONNABORTED") {
              bus.emit("SBS_MESSAGE_E_SHOW", { message: "Превышено время ожидания, пропробуйте перезагрузить приложение." });
              reject(new Error("Превышено время ожидания, пропробуйте перезагрузить приложение."));
            }
          }

          bus.emit("SBS_MESSAGE_E_SHOW", { message: error.message });
          reject(error);

        });
        
      });
      
    },

    /**
     * Загружает данные о нативном приложении
     */
    loadAppInfo(context) {
      App.getInfo().then((info) => {
        context.commit('setAppVersion', info.version);

      });
    },

    
    /**
     * Выход (сброс данных об авторизации)
     * @param {*} context 
     */
    logout(context) {

        //удаляем FCM токен с сервера (потенциально не будет выполнен, если срок действия токена истёк и срок действия рефреш-токена тоже)
        bus.emit("SBS_PUSH_E_DELETE_TOKEN_FROM_SERVER");
        //закрываем подключение по вебсокету
        ws.close();
        //очищаем хранилище
        localStorage.clear();

        //сбрасываем подключения
        context.dispatch("clear");
        
        //сбрасываем состояние, зависящее от текущего аккаунта
        context.dispatch("reset");

        //фиксируем факт, что идёт смена аккаунта (чтобы убрать из кеша экраны)
        context.commit("setConnectionChanging", true);
        setTimeout(() => {
            //завершаем смену аккаунта (чтобы кеширование экранов опять заработало)
            context.commit("setConnectionChanging", false);
        }, 100);

        //сообщаем приложению о выходе, чтобы оно могла перенаправить пользователя на экран входа
        bus.emit("SBS_APP_E_LOGOUT");

    }

  },
})
