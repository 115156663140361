<template>
    <div class="sbs-control-select-multiple form-group form-group-label form-control-icon" :class="[
        values.length > 0 ? 'filled' : '',
        noLabelAnimation && values.length > 0 ? 'no-label-animation' : '',
        open ? 'open' : '']" ref="el">

        <template v-if="label != ''">
            <div class="floating-label" v-on:click="onLabelClick">
                {{ label }}
                <span v-if="required && needShowRequired">*</span>
            </div>
        </template>

        <div class="form-control" @click="onClick">

            <TransitionGroup v-if="values.length > 0" class="values" name="values" tag="div">
                <div v-for="value in displayValues" :key="value.id" class="value nunito-2-regular"
                    @click.stop="removeValue(value.id)">
                    <div>
                        {{ value.name }}
                    </div>
                    <div>
                        <span class="ic fi fi-close"></span>
                    </div>
                </div>
            </TransitionGroup>

            <div class="icon">
                <span class="fi fi-more"></span>
            </div>

        </div>

        <input type="hidden" :class="required ? 'required' : ''" :value="values.join(',')" />

        <div v-if="description" v-html="description" class="description"></div>

        <sbs-control-select-sheet v-model:visible="open" :title="label" :multiple="true"
            v-model:modelValues="selectedValues" :list="list" />

    </div>
</template>

<script>
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";

export default {
    name: "sbs-control-select-multiple",
    components: {
        sbsControlSelectSheet
    },
    emits: ["update:modelValue"],
    data() {
        return {
            values: this.modelValue,
            selectedValues: [],
            open: false,
            loaded: false,
            items: this.list,
            search: "",
        };
    },
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        modelValue: {
            type: Array,
            default() {
                return [];
            },
        },
        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showRequired: {
            type: Boolean,
            default() {
                return true;
            },
        },
        description: {
            type: String,
            default() {
                return "";
            },
        },
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        noLabelAnimation: {
            type: Boolean,
            default() {
                return false;
            },
        },

    },
    computed: {

        needShowRequired() {
            return this.required && this.showRequired;
        },

        displayValues() {
            let items = [];

            this.values.forEach(v => {
                let item = this.items.find(i => i.id == v)
                if (item) {
                    items.push({
                        id: v,
                        name: item.name
                    })
                }
            });

            return items;
        },

    },
    watch: {
        modelValue: function () {
            this.values = this.modelValue;
            this.setSelectedValues();
        },
        open: function () {
            if (this.open) {
                this.items = this.list;
                this.loaded = true;
            }
        },

        selectedValues: function () {
            this.values = this.selectedValues.map(v => v.id);
            this.$emit("update:modelValue", this.values);
        }
    },
    methods: {

        /**
         * Клик по контролу
         */
        onClick() {
            this.open = !this.open;
        },

        onLabelClick() {
            if (this.values.length == 0) {
                this.open = !this.open;
            }
        },

        /**
         * Удаление значения
         */
        removeValue(id) {
            var index = this.values.indexOf(id);
            if (index !== -1) {
                this.values.splice(index, 1);
                this.setSelectedValues();
            }
        },

        /**
         * Устанавилвает выбранные варианты из values
         */
        setSelectedValues() {
            //очистим массив, не меняя при этом ссылку на переменну (чтобы в watch не ушл изменение)
            this.selectedValues.splice(0, this.selectedValues.length);
            this.items.forEach(i => {
                if (this.values.includes(i.id)) {
                    this.selectedValues.push({ id: i.id, name: i.name })
                }
            })
        }

    },

    created() {

        //установим выбранные варианты
        this.setSelectedValues();
    }

}
</script>

<style lang="scss">
.sbs-control-select-multiple {

    .form-control::before {
        content: "\00a0";
    }

    &.filled {
        .form-control::before {
            display: none;
        }
    }

    .values {
        display: flex;
        gap: 5px 5px;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 5px;

        .value {
            display: flex;
            align-items: center;
            column-gap: 4px;
            text-transform: none;
            padding: 8px 15px 8px 15px;
            border-radius: 20px;
            background: var(--tag-tag-bg-orange-color);
            color: var(--tag-tag-text-orange-color);

            text-align: left;

            .ic {
                font-size: 10px;
            }
        }
    }
    


    /**
        Аниция
    */

    .values-enter-active,
    .values-leave-active {
        transition: all 0.5s ease;
    }

    .values-enter-from,
    .values-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }
}
</style>