<template>
    <sbs-view :class="'sbs-v-edu-training-post-view'" :isMenuHidden="hideMenu" :noPaddingHorizontal="true"
        :noPaddingTop="true" @refresh="refresh" ref="view">
        <template v-slot:header>
            <sbs-header ref="header">
                <template v-slot:center>
                    <div class="title title-small" @click="$refs.header.onBtnBackClick">{{ $t('common.prev') }}</div>
                </template>
            </sbs-header>
        </template>

        <template v-slot:content>

            <template v-if="loading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>

            <template v-else>

                <template v-if="!post">
                    <div class="view-horizontal-padding">{{ $t("education.post.not_found") }}</div>
                </template>

                <template v-else>

                    <sbs-education-post-post v-if="post.type == 'POST'" :item="post" :trainingId="trainingId"
                        :footerId="footerId" @complete="onNextClick" />

                    <sbs-education-post-test v-else-if="post.type == 'TEST'" :item="post" :trainingId="trainingId"
                        :footerId="footerId" @complete="onNextClick" />

                    <sbs-education-post-feedback v-else-if="post.type == 'FEEDBACK'" :item="post"
                        :trainingId="trainingId" :footerId="footerId" @complete="onNextClick" />


                </template>

            </template>

        </template>

        <template v-slot:footer>
            <div :id="footerId"></div>
        </template>

    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationPostPost from "@/components/education/post.post.vue"
import sbsEducationPostTest from "@/components/education/post.test.vue"
import sbsEducationPostFeedback from "@/components/education/post.feedback.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-v-education-training-post-view",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsEducationPostPost,
        sbsEducationPostTest,
        sbsEducationPostFeedback
    },
    data() {
        return {
            hideMenu: false,
            loading: false,
            post: null,

            footerId: "sbs-edu-post-training-view-footer"
        };
    },
    props: {
        trainingId: {
            type: Number,
            default() {
                return 0;
            },
        },
        id: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    watch: {
        id: function () {
            this.loadData();
        },
        trainingId: function () {
            this.loadData();
        }
    },

    methods: {

        getBatchRequests() {
            let requests = {
                training: {
                    ENTITY: "education",
                    METHOD: "training.get",
                    PARAMS: {
                        mode: "public",
                        id: this.trainingId,
                        load_my_results: "Y",
                    },
                },

                post: {
                    ENTITY: "education",
                    METHOD: "training.post.get",
                    PARAMS: {
                        mode: "public",
                        training_id: this.trainingId,
                        id: this.id,
                        select: ["user_photo", "user_name", "user_last_name"],
                        load_my_result: "Y",
                        resize_options: {
                            PREVIEW: { WIDTH: 800, HEIGHT: 600 },
                            PRELOAD: "Y"
                        },
                    },
                }
            };

            return requests;
        },

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            if (this.loading) {
                return;
            }
            let requests = this.getBatchRequests();

            //покажем экран загрузки
            this.loading = resolve ? false : true;

            rest.batch(requests)
                .then((results) => {
                    for (var key in results) {
                        let data = results[key];
                        if (data.success) {
                            if (key == "training") {
                                this.training = data.training;
                            } else if (key == "post") {
                                this.post = data.post;
                            }
                        } else {
                            //показываем сообщение об ошибке
                            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                            return;
                        }

                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    //скроем экран загрузки
                    this.loading = false;
                });
        },

        /**
         * Перезагрузка данных
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * клик по "Далее"
         */
        onNextClick() {

            let index = this.training.data.posts.findIndex(p => p.id == this.id);

            //если ещё есть задания для прохождения
            if (index < this.training.data.posts.length - 1) {

                let next = this.training.data.posts[index + 1];
                this.$router.replace({
                    name: "education-training-post-view",
                    params: { id: next.id, trainingId: this.trainingId },
                });

            } else {

                bus.emit("SBS_EDU_TRAINING_RESULT_E_SAVE", { trainingId: this.trainingId });

                //возвращаемся на список постов тренировки
                this.$router.replace({
                    name: "education-trainings"
                });

            }
        }

    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.loadData();
    },


};
</script>

<style lang="scss"></style>