<template>
    <sbs-view :class="'sbs-v-rtf'" :isMenuHidden="hideMenu" :refreshDisabled="true">
        <template v-slot:header>
            <sbs-header>
                <template v-slot:center>
                    <div class="title title-small">
                        <template v-if="mode == 'ADD'"> {{ $t('requests.templates.form.title.add') }}</template>
                        <template v-else> {{ $t('requests.templates.form.title.edit') }} </template>
                    </div>
                </template>

                <template v-slot:right>
                    <!-- Действия в шапке-->
                    <template v-if="!loading && id > 0">
                        <div class="action" v-ripple.300 @click="openActions">
                            <span class="icon fi fi-actions"></span>
                        </div>
                    </template>
                </template>
            </sbs-header>
        </template>

        <template v-slot:content>

            <template v-if="loading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>
            <template v-else>
                <sbs-tabs :items="tabs" v-model:active="tab" />

                <div v-show="tab == 'fields'" class="tab">
                    <sbs-request-template-form-fields v-model="fields" v-model:modelNames="info" />
                </div>
                <div v-show="tab == 'checklist'" class="tab">
                    <sbs-request-template-form-checklist v-model="checkList" ref="checklist" />
                </div>
                <div v-show="tab == 'repeat'" class="tab">
                    <sbs-request-template-form-repeat v-model="fields" />
                </div>

                <div class="btn-wrap">
                    <div class="container">
                        <div class="page-padding">
                            <div class="btn btn1 btn-block btn-save" :class="saving ? 'wait' : ''" v-ripple.300
                                @click="save">
                                <template v-if="mode == 'ADD'">{{ $t('common.add') }}</template>
                                <template v-else>{{ $t('common.save') }}</template>
                                <div v-if="saving" class="btn-loader"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <sbs-request-template-actions v-model:show="showActions" :id="id" v-model:checkLeave="checkLeave" />
            </template>

        </template>

    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsTabs from "@/components/tabs.vue";
import sbsRequestTemplateFormFields from "@/components/request.templates/form.fields.vue";
import sbsRequestTemplateFormChecklist from "@/components/request.templates/form.checklist.vue";
import sbsRequestTemplateFormRepeat from "@/components/request.templates/form.repeat.vue";
import sbsRequestTemplateActions from "@/components/request.templates/actions.vue";

import bus from "@/plugins/bus";
import validator from "@/plugins/validator";
import rest from "@/plugins/rest";

export default {
    name: "sbs-v-request-template-form",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsTabs,
        sbsRequestTemplateFormFields,
        sbsRequestTemplateFormChecklist,
        sbsRequestTemplateFormRepeat,
        sbsRequestTemplateActions
    },
    data() {
        return {
            hideMenu: false,
            checkLeave: false,
            loading: true,
            saving: false,
            fields: {},
            info: {},
            checkList: [],
            tab: "fields",
            tabs: [
                {
                    code: "fields",
                    name: "Заявка",
                },
                {
                    code: "checklist",
                    name: "Чек лист",
                },
                {
                    code: "repeat",
                    name: "Повторение",
                },
            ],
            showActions: false
        };
    },
    props: {
        id: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    computed: {
        mode() {
            return this.id > 0 ? "EDIT" : "ADD";
        },
    },
    watch: {
        id: function () {
            this.loadData();
        },
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData() {
            this.showActions = false;

            if (this.mode == "ADD") {
                this.clear();
                this.loading = false;
                return;
            }

            //покажем экран загрузки
            this.loading = true;
            this.clear();

            //запрашиваем данные
            rest
                .call("request.template.get", {
                    method: "post",
                    data: {
                        id: this.id,
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.setFields(data.template);
                        this.checkList = data.checklist;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .catch(() => {
                    //в тихом режиме не обрабатваем ошибку
                })
                .finally(() => {
                    //скроем экран загрузки
                    this.loading = false;
                });

        },

        /**
         * Очищает форму
         */
        clear() {
            this.fields = {
                name: "",
                description: "",
                equipment_id: "",
                responsible_department_id: "",
                type_id: "",
                priority_id: "",
                report_required: "N",
                start_date_plan_after: "",
                end_date_plan_after: "",
                duration_plan: "",

                repeat: "N",
                repeat_period: "daily",
                repeat_params: {}
            };
            this.setRepeatParams();
            this.info = {
                equipment_name: "",
                responsible_department_name: "",
                type_name: "",
                priority_name: "",
            };
            this.checkList = [];
            this.tab = "fields";
        },

        /**
         * Устанавливает поля формы по данным заявки
         * @param Object request данные заявки
         */
        setFields(request) {
            //установим у полей флаг как буд-то бы поля заполнены
            //чтобы метки поднялись без анимации
            this.setFieldsFilled = true;

            for (let code in this.fields) {
                this.fields[code] = request[code];

                if (code == "repeat_params") {
                    this.setRepeatParams();
                } else if (code == "repeat_period" && !this.fields[code]) {
                    this.fields[code] = "daily"
                }
            }

            for (let code in this.info) {
                this.info[code] = request[code];
            }

            //снимаем флаг
            let vm = this;
            setTimeout(() => {
                vm.setFieldsFilled = false;
            }, 100);
        },

        /**
         * Выставляет/доставляет параметры повторения по умолчанию
         */
        setRepeatParams() {
            let code = "repeat_params";

            if (!this.fields[code]) {
                this.fields[code] = {};
            }

            if (!this.fields[code].times) {
                this.fields[code].times = ["09:00"];
            }
            if (!this.fields[code].start_date) {
                this.fields[code].start_date = "";
            }

            if (!this.fields[code].daily) {
                this.fields[code].daily = {};
            }
            if (!this.fields[code].daily.day) {
                this.fields[code].daily.day = 1;
            }

            if (!this.fields[code].weekly) {
                this.fields[code].weekly = {};
            }
            if (!this.fields[code].weekly.week) {
                this.fields[code].weekly.week = 1;
            }
            if (!this.fields[code].weekly.days) {
                this.fields[code].weekly.days = [];
            }

            if (!this.fields[code].monthly) {
                this.fields[code].monthly = {};
            }
            if (!this.fields[code].monthly.type) {
                this.fields[code].monthly.type = 1;
            }
            if (!this.fields[code].monthly.month_day) {
                this.fields[code].monthly.month_day = 1;
            }
            if (!this.fields[code].monthly.week_num) {
                this.fields[code].monthly.week_num = 1;
            }
            if (!this.fields[code].monthly.week_day) {
                this.fields[code].monthly.week_day = 1;
            }
        },

        /**
         * Сохранение
         */
        save() {
            //проверка введённых данных
            if (!validator.check({ container: this.$el })) {
                this.tab = "fields";
                return;
            }

            if (this.saving) {
                return;
            }
            this.saving = true;

            //если в чек листе что-то ввели, но забыли добавить
            this.$refs.checklist.onBeforeSave();

            //выполняем сохранение
            rest
                .call("request.template." + (this.mode == "ADD" ? "add" : "update"), {
                    method: "post",
                    data: {
                        id: this.id,
                        fields: this.fields,
                        checklist: this.checkList,
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.onSave(data.id);
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        /**
         * Обработка успешного сохранения
         */
        onSave(id) {
            //показываем сообщение об Успехе
            bus.emit("SBS_MESSAGE_E_SHOW", {
                message:
                    this.mode == "ADD"
                        ? "Шаблон создан успешно."
                        : "Шаблон изменен успешно.",
                type: "success",
            });

            //запрещает экрану запрашивать у пользователя подтверждение выхода с экрана
            this.checkLeave = false;

            //генерим событие успешного сохранения шаблона
            bus.emit("SBS_REQUEST_TEMPLATE_FORM_E_SAVE", id);

            //переход обратно
            this.$router.go(-1);

            //если режим создания
            if (this.mode == "ADD") {
                //очищаем форму с задержкой (пока переход анимируется ждем)
                let vm = this;
                setTimeout(function () {
                    vm.clear();
                }, 500);
            }
        },

        /**
         * Показать действия
         */
        openActions() {
            this.showActions = true;
        },

        /**
         * Обработка удаления шаблона
         */
        onTemplateDelete(id) {
            if (this.id == id) {
                this.checkLeave = false;
            }
        }
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.clear();
    },

    mounted() {
        //подписваемся на события
        bus.on("SBS_REQUEST_TEMPLATE_FORM_E_DELETE", this.onTemplateDelete);
    },

    unmounted() {
        //отписываемся от событий
        bus.off("SBS_REQUEST_TEMPLATE_FORM_E_DELETE", this.onTemplateDelete);
    },


    /**
     * Вызывается перед переходом в этот экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            //выставляем необходимость подтверждения выхода из экрана
            vm.checkLeave = true;
            if (vm.id > 0) {
                vm.loadData();
            } else {
                vm.loading = false;
            }
        });
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    async beforeRouteLeave(to) {
        if (to.meta.skipConfirm) {
            return true;
        }

        if (this.checkLeave) {
            let result = await new Promise((resolve) => {
                bus.emit("SBS_CONFIRM_E_SHOW", {
                    message:
                        this.mode == "ADD"
                            ? "Отменить создание шаблона?"
                            : "Отменить изменение шаблона?",
                    resolveText: "Продолжить заполнение",
                    rejectText: "Отменить",
                    resolve: function (value) {
                        resolve(value);
                    },
                    reverse: true,
                });
            });

            return result;
        }
        return true;
    },
}

</script>

<style lang="scss">
.sbs-v-rtf {

    .view-content {
        padding-bottom: 60px;
    }

    .btn-wrap {
        position: absolute;
        bottom: 15px;
        left: 0px;
        right: 0px;
    }
}
</style>
