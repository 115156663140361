<template>
    <div class="sbs-edu-post-comment-item">

        <div class="author-date">
            <div class="date roboto-2-regular">

                <!-- Время -->
                <template v-if="!item.saving && item.status != 'error'">
                    {{ formatDate(item) }}
                </template>
                <!-- Сохранение -->
                <template v-else-if="item.saving && !item.status">
                    <div class="status">
                        <span class="fi fi-time"></span>
                    </div>
                </template>
                <!-- Ошибка -->
                <template v-else-if="!item.saving && item.status == 'error'">
                    <div class="status" @click="retry">
                        <span class="fi fi-error"></span>
                    </div>
                </template>

            </div>

            <div class="author nunito-2-bold" @click="onUserClick">

                <div>
                    <div class="avatar">
                        <div v-if="!item.user_photo" class="initials">
                            {{ item.user_last_name ? item.user_last_name.charAt(0) : "" +
                    item.user_name ? item.user_name.charAt(0) : "" }}
                        </div>
                        <div v-else class="photo" :style="{
                    backgroundImage: 'url(' + item.user_photo.preview + ')',
                }"></div>
                    </div>
                </div>
                <div>
                    <div class="fio">{{ item.user_name }} {{ item.user_last_name }}</div>
                </div>
            </div>

        </div>

        <div class="comment roboto-3-regular">{{ item.comment }}</div>

        <div class="photos" v-if="photos.length > 0">
            <template v-for="photo in photos" :key="photo.id">
                <div class="photo" v-lazy:background-image="photo.preview" @click="openPhoto(photo.id)"></div>
            </template>
        </div>

        <div class="stat">
            <div class="reaction-column">
                <sbs-control-reaction :modelValue="itemInternal.reaction" @change="reactionChange($event)" />
            </div>
            <div class="stat-item" v-if="reactionsCount > 0" @click.stop="openReactions">
                <div>
                    {{ reactionsCount }}
                </div>
                <div class="reactions">
                    <template v-for="s in reactionsReduced" :key="s.value">
                        <span class="rv">{{ s.value }}</span>
                    </template>
                </div>

            </div>
        </div>

        <!-- Список пользователей, оставивших реакцию -->
        <sbs-education-post-comment-reaction-list :id="item.id" v-if="reactionsCount > 0"
            v-model:visible="reactionsOpen" :stat="this.itemInternal.reactions" />

    </div>
</template>

<script>
import sbsControlReaction from "@/components/controls/reaction.vue";
import sbsEducationPostCommentReactionList from "@/components/education/post.comment.reaction.list.vue";

import moment from "moment";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import utils from "@/plugins/utils";

import { Fancybox } from "@fancyapps/ui";
import { mapGetters } from "vuex";
import "@fancyapps/ui/dist/fancybox.css";

export default {
    name: "sbs-education-post-comment-item",
    emits: ["retry"],
    components: {
        sbsControlReaction,
        sbsEducationPostCommentReactionList
    },
    data() {
        return {
            itemInternal: this.item,
            reactionsOpen: false,
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        sheetMode: {
            type: Boolean,
            default() {
                return false
            }
        }
    },

    computed: {

        ...mapGetters({
            service: "getService"
        }),

        photos() {
            return this.item.files.filter(file => file.image);
        },

        gallery() {
            let images = [];

            this.photos.forEach(photo => {

                images.push({
                    src: photo.full ? photo.full : photo.src,
                    type: "image",
                    width: photo.width,
                    height: photo.height,
                    thumb: photo.preview ? photo.preview : photo.src,
                    caption: "",
                    id: photo.id,
                });
            });


            return images;
        },

        reactionsCount() {
            if (!this.itemInternal.reactions) {
                return 0;
            }

            return this.itemInternal.reactions.reduce(function (a, b) {
                return a + parseInt(b.count);
            }, 0)

        },

        reactionsSorted() {
            if (!this.itemInternal.reactions) {
                return [];
            }
            return this.itemInternal.reactions.filter(s => s.count > 0).sort(({ count: a }, { count: b }) => b - a);
        },

        reactionsReduced() {

            return this.reactionsSorted.length <= 4 ? this.reactionsSorted : this.reactionsSorted.slice(0, 4);
        }


    },

    watch: {
        item: function () {
            this.itemInternal = this.item;
        },

    },

    methods: {

        /**
         * форматирует дату
         */
        formatDate(item) {
            let date = moment(item.date, "DD.MM.YYYY HH:mm:ss");
            let days = moment().diff(date, 'days');
            if (days <= 3) {
                return date.calendar();
            }
            return date.format("DD MMMM YYYY HH:mm:ss");
        },

        /**
         * Переход к просмотру 
         */
        onUserClick() {

            if (this.sheetMode) {
                this.$router.go(-1);
                let vm = this;
                setTimeout(() => {
                    vm.goToUser();
                }, 300);

            } else {
                this.goToUser();
            }

        },

        goToUser() {
            this.$router.push({
                name: 'user-view',
                params: { id: this.item.user_id, service: this.service.toLowerCase() }
            });
        },

        /**
         * Попытка отправить повторно
         */
        retry() {
            this.$emit("retry");
        },

        /**
         * Открыть фото на просмотр
         */
        openPhoto(id) {
            let start = this.gallery.findIndex((i) => i.id == id);

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 0, //иначе видео mp4 слева/справа начинает сразу воспроизводиться
                infinite: false,
                Toolbar: {
                    display: [
                        { id: "prev", position: "center" },
                        { id: "counter", position: "center" },
                        { id: "next", position: "center" },
                        "zoom",
                        "slideshow",
                        "thumbs",
                        "close",
                    ],
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        utils.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        utils.fancyBoxOnClose();
                    },
                },
            });
        },

        reactionChange(value) {

            //1) запрос сохранения на сервер
            this.savePostReaction(value);

            //2) пересчёт  статистики по реакциям
            this.recalculateReactionsStat(value);

            //3) сохранение "моей реакции"
            this.itemInternal.reaction = value;
        },

        /**
         * Сохранени реакции
         */
        savePostReaction(value) {

            rest
                .call("education.post.comment.reaction.save", {
                    method: "POST",
                    data: {
                        comment_id: this.item.id,
                        reaction: value
                    },
                    timeout: 0,
                })
                .then((data) => {
                    if (!data.success) {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                });
        },

        /**
         * Пересчёт статистики по реакциям
         */
        recalculateReactionsStat(value) {

            //уменьшаем по предыдущей реакции статистику
            let stat = this.itemInternal.reactions.find(r => r.value == this.itemInternal.reaction);
            if (stat) {
                stat.count = parseInt(stat.count) - 1;
            }

            //если оценки нет
            if (!value) {
                return;
            }

            //увеличиваем по новой реакции статистику
            stat = this.itemInternal.reactions.find(r => r.value == value);
            if (stat) {
                stat.count = parseInt(stat.count) + 1;
            } else {
                this.itemInternal.reactions.push({
                    value: value,
                    count: 1
                });
            }


        },

        openReactions() {
            this.reactionsOpen = true;
        }


    },
};
</script>

<style lang="scss">
.sbs-edu-post-comment-item {
    margin-bottom: 28px;

    .author-date {
        margin-bottom: 12px;

        .author {
            display: flex;
            column-gap: 10px;
            align-items: center;

            .avatar {
                position: relative;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: var(--news-card-news-card-line-color);

                .initials {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: var(--audit-cards-audit-cards-title-text-color);
                }

                .photo {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    background-size: cover;
                }
            }

            .fio {
                color: var(--comments-comments-primary-text-color);
            }
        }

        .date {
            color: var(--news-card-news-card-secondary-text-color);
            float: right;
        }
    }

    .comment {
        color: var(--comments-comments-primary-text-color);
        margin-bottom: 10px;
    }

    .photos {
        margin-bottom: 2px;

        &::after {
            content: "";
            display: block;
            clear: both;
        }

        .photo {
            float: left;
            width: 60px;
            height: 45px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            margin-right: 8px;
            margin-bottom: 8px;
            cursor: pointer;

            border-radius: 5px;
        }
    }

    .stat {
        display: flex;

        column-gap: 30px;
        align-items: center;
        font-size: 12px;

        .reaction-column {
            flex-grow: 1;
        }

        .stat-item {
            display: flex;
            align-items: center;
            column-gap: 5px;
            line-height: 1;
            color: var(--color-chat-fio);

            .reactions {
                display: flex;

                .rv {
                    margin-left: -7px;

                    background: var(--news-card-news-card-bg-color);
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:first-of-type {
                        margin-left: 0px;
                    }
                }
            }
        }
    }

}
</style>