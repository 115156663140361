<template>
    <div class="sbs-rt-a">
        <sbs-control-select-sheet v-model:visible="display" :title="this.$t('equipment.view.actions.title')"
            v-model="action" :list="actions" :showCheck="false" :showButtons="false" />
    </div>
</template>

<script>
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";

export default {
    name: "sbs-request-template-actions",
    components: {
        sbsControlSelectSheet,
    },
    emits: ["update:show", "update:checkLeave"],
    data() {
        return {
            display: this.show,
            loading: false,
            action: false,
        };
    },
    props: {
        id: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        show: {
            type: Boolean,
            default() {
                return false;
            },
        },
        checkLeave: {
            type: Boolean,
            default() {
                return false;
            },
        }
    },

    computed: {

        actions() {
            let list = [];

            //Удалить шаблон
            list.push({
                id: "delete",
                name: this.$t('requests.templates.form.actions.delete'),
                icon: "fi-close",
                action: this.remove
            });

            //Создать заявку по шаблону
            list.push({
                id: "repeat",
                name: this.$t('requests.templates.form.actions.repeat'),
                icon: "fi-refresh",
                action: this.repeat
            });


            return list;
        }
    },

    watch: {
        show: function (newVal) {
            this.display = newVal;
        },
        display: function (newVal) {
            this.$emit("update:show", newVal);
        },

        action: function () {
            if (this.action && !!this.action.id) {
                setTimeout(() => {
                    this.action.action();
                    this.action = false;
                }, 400);

            }
        },
    },

    methods: {
        close() {
            this.$emit("update:show", false);
        },

        /**
         * Удаление
         */
        remove() {

            let vm = this;
            bus.emit("SBS_CONFIRM_E_SHOW", {
                message: this.$t('requests.templates.form.actions.delete_confirm'),
                resolveText: this.$t('common.delete'),
                rejectText: this.$t('common.cancel'),
                resolve: function (value) {
                    if (value) {
                        vm.removeImmediatly()
                    }
                },

            });

        },

        /**
         * Удаление заявки - запрос
         */
        removeImmediatly() {

            this.loading = true;

            //выполняем запрос
            rest
                .call("request.template.delete", {
                    method: "post",
                    data: {
                        id: this.id,
                    },
                })
                .then((data) => {
                    if (data.success) {

                        this.close();
                        bus.emit("SBS_REQUEST_TEMPLATE_FORM_E_DELETE", this.id);
                        this.$router.go(-1);

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Создание заявки по шаблону
         */
        repeat() {

            let vm = this;
            bus.emit("SBS_CONFIRM_E_SHOW", {
                message: this.$t('requests.templates.form.actions.repeat_confirm'),
                resolveText: this.$t('common.create'),
                rejectText: this.$t('common.cancel'),
                resolve: function (value) {
                    if (value) {
                        vm.repeatImmediatly()
                    }
                },

            });
        },

        /**
         * Создание заявки по шаблону
         */
        repeatImmediatly() {

            this.loading = true;

            //выполняем запрос
            rest
                .call("request.template.repeat", {
                    method: "post",
                    data: {
                        id: this.id,
                    },
                })
                .then((data) => {
                    if (data.success) {

                        //показываем сообщение об Успехе
                        bus.emit("SBS_MESSAGE_E_SHOW", {
                            message: this.$t('requests.templates.form.actions.repeat_success'),
                            type: "success",
                        });

                        this.close();
                        this.$emit("update:checkLeave", false);
                        this.$router.go(-1);


                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }


    },
};
</script>