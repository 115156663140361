<template>
    <div class="sbs-control-select-simple" :class="{ filled: value.id && displayValue }" @click="onClick">
        <div class="nunito-5-bold" v-html="displayValue"></div>
        <div class="icon fi fi-dropdown"></div>
    </div>

    <sbs-control-select-sheet v-model:visible="showDialog" :title="label" v-model="value" :list="list"
        :showButtons="showButtons" />
</template>

<script>
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";

export default {
    name: "sbs-control-select-simple",
    components: {
        sbsControlSelectSheet
    },
    data() {
        return {
            value: {
                id: this.modelValue,
                name: this.modelName,
            },
            showDialog: false,
        };
    },
    emits: ["update:modelValue", "update:modelName", "update:modelItem"],

    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        modelValue: {
            type: [String, Number],
            default() {
                return "";
            },
        },
        modelName: {
            type: String,
            default() {
                return "";
            },
        },
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        showButtons: {
            type: Boolean,
            default() {
                return true;
            },
        }
    },

    computed: {
        displayValue() {
            let text = this.value ? this.value.name : "";
            if (!this.value) {
                return text;
            }

            let selected = this.list.find(i => i.id == this.value.id);
            if (selected) {
                text = selected.name;
            }

            return text;
        },
    },
    watch: {
        modelValue: function (newVal) {
            if (newVal != this.value.id) {
                this.value.id = newVal;
            }
        },
        modelName: function (newVal) {
            if (newVal != this.value.name) {
                this.value.name = newVal;
            }
        },
        value: function () {
            this.$emit("update:modelValue", this.value.id);
            this.$emit("update:modelName", this.value.name);
            this.$emit("update:modelItem", this.value);
        },
    },

    methods: {

        onClick() {
            this.showDialog = true;
        },
    },
}

</script>

<style lang="scss">
.sbs-control-select-simple {
    position: relative;

    padding: 10px 45px 10px 15px;

    border-radius: 20px;
    border: 2px solid var(--bg);

    color: var(--audit-cards-audit-cards-title-text-color);


    &::before {
        content: "\00a0";
    }

    &.filled::before {
        display: none;
    }

    .icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
}
</style>