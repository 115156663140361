<template>
  <div class="sbs-auth-login">
    <div class="logo-wrap" v-if="mode != 'add'">
      <sbs-logo />
    </div>

    <form @submit.prevent="onSubmit" action="">

      <input type="submit" style="display: none;" />

      <sbs-control-input v-if="showServer" v-model="server" type="text" :label="$t('auth.login.server')"
        :mask="{ mask: 'U', tokens: { U: { pattern: /[0-9a-zA-Z\.\-\_]/, repeated: true } } }" :required="true"
        :showRequired="false" />

      <sbs-control-input v-model="login" :type="loginType" :mask="loginMask" :label="$t('auth.login.login')"
        :required="true" :showRequired="false" />

      <sbs-control-input v-model="password" :type="showPassword ? 'text' : 'password'"
        :label="$t('auth.login.password')" :required="true" :showRequired="false" class="password"
        @change="onPasswordChange">
        <template v-slot:icon>
          <div v-if="!showPassword" class="icon" @click="onShowPasswordClick">
            <span class="fi fi-eye"></span>
          </div>
          <div v-else class="icon" @click="onHidePasswordClick">
            <span class="fi fi-eye-close"></span>
          </div>
        </template>
      </sbs-control-input>

      <div class="restore" v-if="mode == 'login'">
        <span class="a" @click="goToRestore">{{ $t('auth.login.restore') }}</span>
      </div>

      <sbs-control-input v-if="needCaptcha" v-model="captchaWord" type="text" :label="$t('auth.login.captcha')"
        :captcha="captchaImg" :required="true" />

      <br />

      <div class="btn btn1 btn-block" :class="loading ? 'wait' : ''" v-ripple.300 v-on:click="onSubmit">
        <template v-if="mode == 'login'">{{ $t('auth.login.enter') }}</template>
        <template v-if="mode == 'add'">{{ $t('common.add') }}</template>

        <div v-if="loading" class="btn-loader"></div>
      </div>

    </form>

    <br />

    <div class="toggle-server">
      <div class="a" v-if="!showServer" @click="toggleServer">{{ $t('auth.login.show_server') }}</div>
      <div class="a" v-else @click="toggleServer">{{ $t('auth.login.default_server') }}</div>
    </div>

  </div>
</template>

<script>
import sbsLogo from "@/components/logo";
import sbsControlInput from "@/components/controls/input";
import bus from "@/plugins/bus";
import validator from "@/plugins/validator";
import rest from "@/plugins/rest";
import localStorage from "@/plugins/localStorage";

export default {
  name: "sbs-auth-login",
  components: {
    sbsControlInput,
    sbsLogo
  },
  data() {
    return {
      server: "",
      login: "",
      loginType: "text",
      loginMask: false,
      password: "",
      loading: false,
      showPassword: false,
      needCaptcha: false,
      captchaSid: "",
      captchaImg: "",
      captchaWord: "",
      showServer: false
    };
  },
  props: {
    mode: {
      type: String,
      default() {
        return "login";
      },
    },
  },
  watch: {
    login: function () {

      if (this.login.startsWith("8") && this.login.length == 1) {
        this.login = "+7";
      }

      if (this.login.startsWith("+") || /^\d+$/.test(this.login)) {
        this.loginType = "tel";
        this.loginMask = { mask: '+7 (###) ###-##-##' };
      } else {
        this.loginType = "text";
        this.loginMask = false;
      }
    },
  },
  methods: {

    onSubmit: function () {
      //првоерка введённых данных
      if (!validator.check({ container: this.$el })) {
        return;
      }

      let server = this.showServer ? this.server : process.env.VUE_APP_DEFAULT_SERVER;

      if (this.$store.getters.getConnections[server + "/" + this.login]) {
        bus.emit("SBS_MESSAGE_E_SHOW", { message: this.$t('auth.login.already_added') });
        return;
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      //запрашивает автооризацию
      rest
        .call(
          "auth.login",
          {
            method: "post",
            data: {
              login: this.login,
              password: this.password,
              captchaWord: this.captchaWord,
              captchaSid: this.captchaSid,
            },
          },
          false,
          "https://" + server + "/app/sbs/rest/"
        )
        .then((data) => {
          //если ошибка входа
          if (!data.success) {
            //если требуется капча
            if (data.captchaSid) {
              this.needCaptcha = true;
              this.captchaSid = data.captchaSid;
              this.captchaImg = data.captchaImg;
            }

            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          } else {
            //обработка успешного входа
            this.onSuccess(data, server);
          }

          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Обработка успехного входа
     */
    onSuccess: function (data, server) {
      let connection = {
        server: server,
        login: this.login,
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        profile: {
          name: data.profile.name,
          last_name: data.profile.last_name
        }
      };

      localStorage.set("last_server", server);
      localStorage.set("last_login", this.login);

      //сохраняем подключение в текущем состоянии
      this.$store.commit("addConnection", connection);

      //если режим входа
      if (this.mode == "login") {
        //устанавливаем подключение текущим
        this.$store.commit(
          "setCurrentConnection",
          connection.server + "/" + connection.login
        );

        //событие завершения входа пользователя
        bus.emit("SBS_APP_E_USER_LOGIN");
      }
      //иначе режим добавления
      else {
        //вернёмся назад
        this.$router.go(-1);
      }

      this.password = "";
      this.needCaptcha = false;
    },

    /**
     * Обработка изменения пароля
     */
    onPasswordChange() {
      //если мобильник, то изменение значения происходит только при нажатии -> в клавиатуре
      if (this.$isMobile()) {
        // this.onSubmit();
      }
    },

    /**
     * Клик по иконке "показать пароль"
     */
    onShowPasswordClick() {
      this.showPassword = true;
    },

    /**
     * Клик по иконке "скрыть пароль"
     */
    onHidePasswordClick() {
      this.showPassword = false;
    },

    /**
     * Показать/скрыть сервер
     */
    toggleServer() {
      this.showServer = !this.showServer
    },

    /**
     * Переход к восстановлению пароля
     */
    goToRestore() {
      this.$router.push({ name: "restore" });
    }
  },

  async created() {
    let lastServer = await localStorage.get("last_server");
    if (lastServer) {
      this.server = lastServer;
    }

    let lastLogin = await localStorage.get("last_login");
    if (lastLogin) {
      this.login = lastLogin;
    }
  },
};
</script>

<style lang="scss">
.sbs-auth-login {
  .logo-wrap {
    width: 165px;
    margin: 0px auto 110px;
  }

  .form-group-icon {
    .icon {
      font-size: 18px;
    }
  }

  .toggle-server {
    text-align: center;
    margin-bottom: 10px;
    color: var(--news-card-news-card-secondary-text-color);
  }

  .password {
    margin-bottom: 10px;
  }

  .restore {
    padding-left: 16px;
    color: var(--news-card-news-card-secondary-text-color);
  }
}
</style>
