<template>
    <div class="sbs-control-search">
        <input class="search roboto-3-regular" v-model="value" :placeholder="placeholder" @input="handleInput"
            @focus="onFocus" @blur="onBlur" ref="input" autocomplete="off" @change="onChange" />

        <div class="icon" v-if="value" @click="clearSearch">
            <span class="fi fi-close"></span>
        </div>

        <div v-if="isNative && !value" class="icon" @click="speech">
            <span class="fi fi-mic"></span>
        </div>
    </div>
</template>

<script>

import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "sbs-control-search",
    data() {
        return {
            value: this.modelValue,
            focused: false,
        };
    },
    emits: ["update:modelValue", "change"],
    props: {

        modelValue: {
            type: [String, Number],
            default() {
                return "";
            },
        },
        placeholder: {
            type: String,
            default() {
                return "";
            },
        },

    },

    computed: {
        ...mapGetters({
            isNative: "isNativePlatform",
        }),
    },

    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
    },

    methods: {
        handleInput() {
            this.$emit("update:modelValue", this.value);
        },
        onFocus() {
            this.focused = true;
            if (this.isNative) {
                setTimeout(() => {
                    this.$refs.input.scrollIntoView(false);
                }, 10);
            }

        },
        onBlur() {
            this.focused = false;
        },
        onChange() {
            this.$emit("change", this.value);
        },

        clearSearch() {
            this.value = "";
            this.$emit("update:modelValue", this.value);
        },

        /**
         * Распознование речи для навания
         */
        speech() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.value = text;
                    this.$emit("update:modelValue", this.value);
                },
            });
        },
    }
}
</script>

<style lang="scss">
.sbs-control-search {
    position: relative;

    .search {
        display: block;
        width: 100%;
        border-radius: 30px;
        background: var(--text-field-text-field-search-standard-filled-bg-color);
        padding: 16px 40px 16px 16px;
        outline: none;
        border: 0px;

        color: var(--text-field-text-field-active-color-text);

        &::placeholder {
            color: var(--text-field-text-field-body-color-text);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }

    }

    .icon {
        padding: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
        color: var(--text-field-text-field-body-color-text);
    }
}
</style>