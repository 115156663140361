<template>
    <sbs-sheet v-model:visible="visible">
        <template v-slot:body>
            <div class="sbs-speech">
                <div class="icon-wrap">
                    <div class="icon fi fi-mic"></div>
                </div>

                <template v-if="!error">
                    <div class="promt" v-if="!text">{{ $t('system.speech.promt') }}</div>
                    <div class="text" v-html="text"></div>
                </template>
                <template v-else>
                    <div class="error">{{ $t('system.speech.error') }}</div>

                    <div class="btn btn1 slim" @click="start">{{ $t('system.speech.retry') }}</div>
                </template>

            </div>
        </template>
    </sbs-sheet>
</template>

<script>

import sbsSheet from '@/components/sheet.vue'

import bus from "@/plugins/bus";

export default {
    name: "sbs-speech",
    components: {
        sbsSheet,

    },
    data() {
        return {
            visible: false,
            watchVisible: false,
            params: false,
            text: "",
            timer: 0,
            error: false
        }
    },
    methods: {

        open(params) {
            this.params = params;
            this.visible = true;
            this.text = "";
            this.watchVisible = true;

            this.start();

        },

        start() {
            this.error = false;
            let vm = this;
            setTimeout(() => {

                bus.emit("SBS_SPEECH_RECOGNITION_E_START", {
                    onStart: () => {
                        vm.waitFinish();
                    },
                    resolve: (text) => {
                        vm.text = text;
                        vm.waitFinish();
                    },
                });

            }, 200);
        },

        waitFinish() {
            if (this.timer > 0) {
                clearTimeout(this.timer);
            }

            let vm = this;
            this.timer = setTimeout(() => {
                vm.finish();
            }, this.text ? 2000 : 5000);
        },

        finish() {

            if (this.visible) {
                if (this.text) {
                    this.params.resolve(this.text);
                    this.close();

                } else {
                    this.error = true;
                }
            } else {
                this.close();
            }
        },

        close() {
            this.visible = false;
            bus.emit("SBS_SPEECH_RECOGNITION_E_STOP");
        }
    },
    mounted() {
        bus.on("SBS_SPEECH_RECOGNITION_E_GET", this.open);
    },
    unmounted() {
        bus.off("SBS_SPEECH_RECOGNITION_E_GET", this.open);
    },

}
</script>

<style lang="scss">
.sbs-speech {
    padding: 40px 20px 0px;
    text-align: center;

    .icon-wrap {
        position: relative;
        height: 80px;
        width: 80px;
        margin: 0px auto 20px;
        background-color: var(--color-color1);
        border-radius: 50%;
        box-shadow: rgba(1, 0, 61, 0.15) 0px 0px 15px;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: var(--color-background);
        }
    }

    .promt {
        font-size: 16px;
    }

    .error {
        margin-bottom: 10px;
    }


}
</style>