<template>
  <div class="sbs-radio" :class="{
    checked: checked,
    nolabel: label == '',
    'form-group': form,
    'value-not-empty': !modelValue,
  }">
    <label>

      <div class="box">
        <span v-if="checked" class="dot"></span>
      </div>


      <input type="radio" :class="required ? 'required' : ''" :name="name" :value="value" v-model="checked"
        @change="onChange" />

      <template v-if="label != ''">
        <div class="label roboto-4-regular">
          {{ label }}
        </div>
      </template>
      <template v-else> &nbsp; </template>
    </label>
  </div>
</template>

<script>
export default {
  name: "sbs-control-radio",
  data() {
    return {
      checked: this.modelValue == this.value ? this.value : false,
    };
  },
  emits: ["update:modelValue"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
    value: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    modelValue: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    form: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    modelValue: function (newVal) {
      if (newVal == this.value) {
        this.checked = newVal;
      } else {
        this.checked = false;
      }
    },
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.value);
    },
  },
};
</script>

<style lang="scss">
.sbs-radio {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin: 5px 0px;

  &.nolabel {
    width: 20px;
    height: 20px;
    padding-left: 20px;
  }

  input {
    display: none;
  }

  label {
    margin-bottom: 0px;
    cursor: pointer;
    font-weight: normal;
  }

  .box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;

    width: 25px;
    height: 25px;

    background: var(--chekbox-and-radiobutton-chekbox-dark-standard-pressed-bg-color);
    border-radius: 7px;

    box-shadow: 0px 2px 3px -1px var(--shadow-checkbox) inset;

    .dot {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1;
      color: var(--service-color-1);

      background-color: currentColor;
      width: 9px;
      height: 9px;
      border-radius: 50%;
    }
  }


  &.form-group {
    margin-bottom: 15px;
    display: block;
  }

  &.form-group-error {
    color: var(--color-control-label-error);
  }
}
</style>