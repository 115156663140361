<template>
  <sbs-control-input v-model="value" type="text" :label="label" :name="name" :required="required" :readonly="true"
    :classes="['date']" @click="onClick()">
    <template v-slot:icon>
      <div v-if="value == ''" class="icon">
        <span class="fi fi-date"></span>
      </div>
      <div v-else class="icon" @click.stop="clear">
        <span class="fi fi-close"></span>
      </div>
    </template>
  </sbs-control-input>

  <sbs-sheet v-model:visible="datePickerOpen" :maxWidth="400">
    <template v-slot:body>
      <div class="sbs-control-calendar-sheet">
        <sbs-control-calendar v-model="calendarValue" />

        <div class="footer">
          <div class="btn btn-block btn2" @click="clear">{{ $t('controls.calendar.reset') }}</div>
          <div class="btn btn-block btn1" @click="apply">{{ $t('controls.calendar.apply') }}</div>
        </div>
      </div>
    </template>
  </sbs-sheet>


</template>

<script>
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlCalendar from "@/components/controls/calendar.vue";
import sbsSheet from '@/components/sheet.vue'

import "vue-scroll-picker/lib/style.css";

export default {
  name: "sbs-control-date",
  components: {
    sbsControlInput,
    sbsSheet,
    sbsControlCalendar
  },
  data() {
    return {
      value: this.modelValue,
      calendarValue: this.modelValue,
      datePickerOpen: false,
    };
  },
  emits: ["update:modelValue"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },

    name: {
      type: String,
      default() {
        return "";
      },
    },
    modelValue: {
      type: String,
      default() {
        return "";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {

  },
  watch: {
    modelValue: function (newVal) {
      if (newVal !== this.value) {
        this.value = newVal;
        this.calendarValue = newVal;
      }
    },
    value: function () {
      this.$emit("update:modelValue", this.value);
    },
  },
  methods: {
    onClick() {
      this.calendarValue = this.value;
      this.datePickerOpen = true;
    },

    clear() {
      this.value = "";
      this.calendarValue = "";
      this.datePickerOpen = false;
    },

    apply() {
      this.value = this.calendarValue;
      this.datePickerOpen = false;
    }


  },

};
</script>

<style lang="scss">
.sbs-control-calendar-sheet {

  .footer {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    column-gap: 10px;
  }
}
</style>
