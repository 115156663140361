<template>
    <div class="sbs-department-rating">
        <div class="department nunito-3-regular" @click="goToDepartment" v-ripple.300>
            {{ user ? user.department_name : "&nbsp;" }}
        </div>

        <div class="rating" v-ripple.300 @click="goToRatingRequests">
            <div class="bg">
                <svg height="auto" viewBox="0 0 158 154" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_4460_16379)">
                        <path
                            d="M76.9575 41.6977C77.8451 40.0909 80.1549 40.0908 81.0425 41.6977L87.3032 53.0324C90.5488 58.9081 96.2556 63.0211 102.856 64.2416L115.749 66.6254C117.574 66.9629 118.294 69.193 117.01 70.5336L108.169 79.7674C103.466 84.6785 101.245 91.4579 102.131 98.1995L103.784 110.775C104.024 112.602 102.15 113.974 100.48 113.191L88.5173 107.584C82.4872 104.758 75.5128 104.758 69.4827 107.584L57.5196 113.191C55.8503 113.974 53.9757 112.602 54.2159 110.775L55.8686 98.1995C56.7545 91.4579 54.534 84.6785 49.8314 79.7674L40.9897 70.5336C39.706 69.193 40.4256 66.9629 42.2508 66.6254L55.1437 64.2416C61.7444 63.0211 67.4512 58.9081 70.6968 53.0324L76.9575 41.6977Z"
                            fill="url(#paint0_radial_4460_16379)" />
                    </g>
                    <defs>
                        <filter id="filter0_d_4460_16379" x="0.339355" y="0.492676" width="157.321" height="152.923"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur stdDeviation="20" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix"
                                values="0 0 0 0 1 0 0 0 0 0.572549 0 0 0 0 0.0392157 0 0 0 0.5 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4460_16379" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4460_16379"
                                result="shape" />
                        </filter>
                        <radialGradient id="paint0_radial_4460_16379" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(79 80) rotate(95.7106) scale(35.1746 37.4586)">
                            <stop stop-color="#A1440B" />
                            <stop offset="0.584964" stop-color="#461B04" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>

            <div v-if="rating > 0" class="value nunito-6-bold">{{ displayRating }}</div>
            <div v-else class="no-value">{{ $t("requests.summary.no-rating") }}</div>

        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: "sbs-department-rating",
    data() {
        return {

        };
    },
    props: {
        rating: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        filter: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    computed: {

        ...mapGetters({
            user: "getUserProfile",
            service: "getService"
        }),

        displayRating() {
            return Math.round(this.rating * 10) / 10;
        }
    },

    methods: {

        goToRatingRequests() {

            let filter = Object.assign(this.filter, {
                rating: [
                    {
                        id: 1,
                        name: "1"
                    },
                    {
                        id: 2,
                        name: "2"
                    },
                    {
                        id: 3,
                        name: "3"
                    },
                    {
                        id: 4,
                        name: "4"
                    },
                    {
                        id: 5,
                        name: "5"
                    }
                ],
                responsibleDepartment: [
                    {
                        id: this.user.department_id,
                        name: this.user.department_name
                    }
                ]
            });

            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", {
                name: "REQUEST-common",
                value: filter
            });

            //меняем вкладку на экране заявок
            this.$store.commit("setRequestsTab", "all");

            setTimeout(() => {
                this.$router.push({ name: "requests" });
            }, 400);

        },

        /**
         * Переход к просмотру подразделения
         */
        goToDepartment() {

            setTimeout(() => {
                this.$router.push({
                    name: "department-view",
                    params: { id: this.user.department_id, service: this.service.toLowerCase() },
                });
            }, 400);


        }
    }
};
</script>

<style lang="scss">
.sbs-department-rating {
    position: relative;
    border-radius: 10px;

    background: radial-gradient(35.68% 192.74% at 84.18% 53.23%, var(--requests-requests-gradient-color-2) 39.06%, var(--requests-requests-gradient-color-1) 100%);

    box-shadow: 0px 2px 4px 0px var(--shadow-secondary);

    font-family: Nunito;
    overflow: hidden;

    .department {
        display: flex;
        align-items: center;
        padding: 18px 105px 18px 15px;

        min-height: 62px;
    }


    .rating {

        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 82px;
        height: 82px;
        z-index: 2;

        .bg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 158px;
            height: 154px;

            svg {
                width: 100%;

                stop:first-of-type {
                    stop-color: var(--requests-requests-gradient-color-3);
                }

                stop:last-of-type {
                    stop-color: var(--requests-requests-gradient-color-2);
                }
            }

        }

        .value {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            color: var(--service-color-1);
            text-align: center;
            margin-top: 4px;
        }

        .no-value {

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--service-color-1);
            font-size: 12px;
            text-align: center;
            line-height: 110%;

        }

    }



}
</style>