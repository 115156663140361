<template>
    <Teleport to="body">

        <Transition :css="false" @enter="onTransitionEnter" @leave="onTransitionLeave" @click="onBackdropClick"
            @mousedown="onBackdropSwipeStart" @touchstart="onBackdropSwipeStart">
            <div class="sheet-backdrop" v-if="visibleInternal" :class="this.class">

                <div class="sheet" :class="{ swiping: swiping }" @click.stop="onSheetClick" :style="{
            'transform': 'translateY(' + swipePixels + 'px)',
            '--sheet-max-height': maxHeight + '%',
            '--sheet-min-height': minHeight + '%',
            '--sheet-max-width': maxWidth + 'px'
        }">

                    <div class="sheet-header" v-if="!noHeader" @mousedown.stop="onHeaderSwipeStart"
                        @touchstart.stop="onHeaderSwipeStart">
                        <div class="sheet-header-bar"></div>
                    </div>

                    <div class="sheet-body" :class="{ nopadding: noPadding }" ref="body">
                        <slot name="body"></slot>
                    </div>


                </div>

            </div>
        </Transition>

    </Teleport>
</template>

<script>

export default {

    name: "sbs-sheet-base",
    emits: ["update:visible"],
    components: {
    },
    data() {
        return {
            visibleInternal: this.visible,
            swipePixels: 0,
            swipeStartY: 0,
            swipeStarted: false,
            swiping: false,
            swipeHeader: false,
            listenBackdropClick: true
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        class: {
            type: String,
            default() {
                return "";
            },
        },
        maxWidth: {
            type: [String, Number],
            default() {
                return 680;
            },
        },
        minHeight: {
            type: Number,
            default() {
                return 10;
            },
        },
        maxHeight: {
            type: Number,
            default() {
                return 60;
            },
        },
        noStretch: {
            type: Boolean,
            default() {
                return false
            }
        },
        onlyHeaderSwipe: {
            type: Boolean,
            default() {
                return false
            }
        },
        noHeader: {
            type: Boolean,
            default() {
                return false
            }
        },
        threshold: {
            type: Number,
            default() {
                return 100
            }
        },
        noPadding: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
        },
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        }
    },

    methods: {

        /**
         * Анимация появления
         */
        onTransitionEnter(backdrop, done) {

            const sheet = backdrop.querySelector('.sheet');
            const currentBackground = window.getComputedStyle(backdrop).backgroundColor

            backdrop.animate(
                [
                    { backgroundColor: 'rgba(0, 0, 0, 0)' },
                    { backgroundColor: currentBackground },
                ],
                {
                    duration: 300,
                    easing: 'ease',
                },
            );

            let anim = sheet.animate(
                [
                    { transform: 'translateY(100%)' },
                    { transform: 'translateY(0%)' },
                ],
                {
                    duration: 300,
                    easing: 'ease',
                },
            )


            anim.onfinish = done;
        },

        /**
         * Анимация исчезания
         */
        onTransitionLeave(backdrop, done) {

            const sheet = backdrop.querySelector('.sheet');
            const currentBackground = window.getComputedStyle(backdrop).backgroundColor

            backdrop.animate(
                [
                    { backgroundColor: currentBackground },
                    { backgroundColor: 'rgba(0, 0, 0, 0)' },
                ],
                {
                    duration: 300,
                    easing: 'ease',
                },
            );

            let anim = sheet.animate(
                [
                    { transform: 'translateY(' + this.swipePixels + 'px)' },
                    { transform: 'translateY(100%)' },
                ],
                {
                    duration: 300,
                    easing: 'ease',
                },
            )

            anim.onfinish = () => {
                this.swipePixels = 0;
                done();
            }
        },

        /**
         * Клик по фону
         */
        onBackdropClick() {
            if (this.listenBackdropClick) {
                this.visibleInternal = false;
            }

        },

        /**
         * Начало тача по фону
         */
        onBackdropSwipeStart(e) {
            if (!this.onlyHeaderSwipe) {
                this.swipeHeader = false;
                this.handleSwipeStart(e);
            }

        },

        /**
         * Клик по странице
         */
        onSheetClick() {
            //ничего не делаем, просто нужен stop
        },

        /**
         * Начало тача по заголовку
         */
        onHeaderSwipeStart(e) {
            //if (this.onlyHeaderSwipe) {
            this.swipeHeader = true;
            this.handleSwipeStart(e);
            //}

        },

        /**
         * Начало тача
         */
        handleSwipeStart(e) {

            //фиксирует начальную точку
            if ('touches' in e)
                this.swipeStartY = e.touches[0].clientY
            else
                this.swipeStartY = e.clientY

            this.swipeStarted = true
        },

        /**
         * Прогресс тача
         */
        handleSwipe(e) {

            if (!this.swipeStarted) {
                return
            }

            let clientY = 0;
            if ('touches' in e) {
                clientY = e.touches[0].clientY
            }
            else {
                clientY = e.clientY
            }

            //если разница отрицательная - значит свайп вверх
            this.swipePixels = clientY - this.swipeStartY;
            if (this.swipePixels < 0) {
                this.swipePixels = 0;
            }

            //если контент страницы прокручен И свайп начался не с заголовка
            if (this.$refs.body && this.$refs.body.scrollTop > 0 && !this.swipeHeader) {
                //то не мешаем прокручиватся пока не будет 0
                this.swipeStartY = clientY;
                this.swiping = false
                this.swipePixels = 0;
                return;
            }

            if (clientY === this.swipeStartY) {
                this.swiping = false
                return
            }

            this.swiping = true;
            this.listenBackdropClick = false;

        },

        /**
         * Завершение тача
         */
        handleSwipeEnd() {
            if (!this.swipeStarted) {
                return
            }

            if (this.swipePixels >= this.threshold) {
                this.visibleInternal = false
            } else {

                this.swipePixels = 0;

                setTimeout(() => {
                    this.listenBackdropClick = true;
                });

            }

            this.swiping = false;
            this.swipeStartY = 0;
            this.swipeStarted = false;
            this.swipeHeader = false;
        }

    },

    mounted() {

        window.addEventListener("mousemove", this.handleSwipe)
        window.addEventListener("touchmove", this.handleSwipe, { passive: false })

        window.addEventListener("mouseup", this.handleSwipeEnd)
        window.addEventListener("touchend", this.handleSwipeEnd)
        window.addEventListener("touchcancel", this.handleSwipeEnd)

    },

    unmounted() {
        window.removeEventListener("mousemove", this.handleSwipe)
        window.removeEventListener("touchmove", this.handleSwipe)

        window.removeEventListener("mouseup", this.handleSwipeEnd)
        window.removeEventListener("touchend", this.handleSwipeEnd)
        window.removeEventListener("touchcancel", this.handleSwipeEnd)
    }


}

</script>

<style lang="scss">
.sheet-backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    touch-action: pan-y;
    pointer-events: auto;
    align-items: flex-end;
    justify-content: center;
    transition: background-color 300ms ease;
    overflow: hidden;
    background-color: var(--sheet-backdrop-background-color, rgba(2, 6, 23, 0.75));
    z-index: 1000;

    .sheet {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        background-color: var(--bg);
        max-width: var(--sheet-max-width, 680px);
        min-height: calc(var(--sheet-min-height, 40%) - var(--safe-area-top, 0px));
        max-height: calc(var(--sheet-max-height, 60%) - var(--safe-area-top, 0px));
        border-radius: var(--sheet-border-radius, 30px 30px 0px 0px);
        transition-property: transform;
        transition-duration: 200ms;
        transition-timing-function: ease;

        &.swiping {
            transition-property: none !important;
        }
    }

    .sheet-header {
        flex-shrink: 0;
        flex-grow: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &-bar {
            margin: 10px;
            border-radius: 5px;
            height: 5px;
            width: 25%;
            background-color: var(--news-card-news-card-line-color);
        }
    }

    .sheet-body {
        overflow: auto;
        /**отключаем pull для safari */
        overscroll-behavior: none;

        color: var(--color-sheet-text);

        padding-bottom: calc(var(--keyboard-height, 0px) + var(--safe-area-bottom, 0px) + 25px);

        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;

        &.nopadding {
            padding-top: 0px;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .sheet-title {
        color: var(--top-navigation-top-navigation-title-color);
        font-family: Nunito;
        font-size: 18px;

        font-weight: 700;
        line-height: 110%;
        /* 19.8px */
        letter-spacing: -0.04px;
        margin-bottom: 20px
    }
}
</style>