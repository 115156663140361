<template>
    <div class="sbs-edu-ppb">

        <div class="post-block" v-for="block in blocks" :key="block.id">

            <template v-if="block.type == 'image'">

                <div class="post-picture preloaded" v-lazy:background-image="block.image.preview"
                    @click="openPhoto(block.image.id)"
                    :style="{ paddingTop: block.image.height / block.image.width * 100 + '%' }">

                    <div class="preload" v-if="block.image.preload"
                        :style="{ backgroundImage: 'url(\'' + block.image.preload + '\')' }">
                    </div>

                    <div class="loader">
                        <sbs-loader-inline />
                    </div>
                </div>
                <div class="post-description nunito-2-regular" v-if="block.title" v-html="block.title"></div>

            </template>

            <template v-if="block.type == 'video'">

                <div class="post-video-wrap">
                    <video :poster="block.video.preview" controls>
                        <source :src="block.video.src" type="video/mp4" />
                    </video>
                </div>

                <div class="post-description nunito-2-regular" v-if="block.title" v-html="block.title"></div>

            </template>

            <template v-else-if="block.type == 'text'">
                <div class="post-text roboto-3-regular" v-html="prepareText(block.text)" @click="onTextClick"></div>
            </template>

            <template v-else-if="block.type == 'video.link'">

                <div class="post-video-wrap" v-if="block.video && block.video.url">
                    <iframe :src="getVideoSrc(block.video)" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="post-description nunito-2-regular" v-if="block.title" v-html="block.title"></div>

            </template>

        </div>

    </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";

import utils from "@/plugins/utils";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import { mapGetters } from "vuex";

export default {
    name: "sbs-education-post-post-blocks",
    components: {
        sbsLoaderInline
    },
    data() {
        return {

            videoTypes: [
                {
                    code: "youtube",
                    src: "https://www.youtube.com/embed/#VIDEO_ID#?feature=oembed",
                    getSrc: function (id) {
                        return this.src.replace("#VIDEO_ID#", id)
                    }
                },
                {
                    code: "rutube",
                    src: "https://rutube.ru/play/embed/#VIDEO_ID#",
                    getSrc: function (id) {
                        let parts = id.split("_");
                        let vid = parts[0];
                        parts.shift();

                        return this.src.replace("#VIDEO_ID#", vid) + (parts.length > 0 ? "?p=" + parts.join("_") : "")
                    }
                },
                {
                    code: "vkvideo",
                    src: "https://vk.com/video_ext.php?oid=-#OID#&id=#ID#&hd=2",
                    getSrc: function (id) {
                        let parts = id.split("_");
                        return this.src.replace("#OID#", parts[0]).replace("#ID#", parts[1]);
                    }
                }

            ],
        }
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },

    },
    computed: {

        ...mapGetters({
            user: "getUserProfile",
        }),

        blocks() {
            if (!this.item.data || !this.item.data.blocks) {
                return [];
            }

            return this.item.data.blocks;
        },

        gallery() {
            let images = [];

            this.blocks.forEach((block) => {
                if (block.type == 'image') {
                    if (block.image) {
                        images.push({
                            src: block.image.full ? block.image.full : block.image.src,
                            type: "image",
                            width: block.image.width,
                            height: block.image.height,
                            thumb: block.image.preview ? block.image.preview : block.image.src,
                            caption: block.title,
                            id: block.image.id,
                        });
                    }
                } else if (block.type == 'video.link') {
                    if (block.video) {
                        images.push({
                            src: this.getVideoSrc(block.video),
                            type: "video",
                            caption: block.title,
                            id: block.id,
                        });
                    }
                }
            });


            return images;
        },
    },

    methods: {
        /**
         * Клик по тексту
         */
        onTextClick(e) {
            //если клик по ссылке
            if (e.target.tagName == "A") {

                let url = e.target.getAttribute('href');
                //получим относительную ссылку
                url = url.replace("http://" + this.server, "").replace("https://" + this.server, "");
                this.routeClick(e, url);
            }

        },

        prepareText(text) {

            return text.replace("<span class=\"username\">﻿<span contenteditable=\"false\"></span>﻿</span>", this.user.name + ' ' + this.user.last_name);
        },

        /**
         * Маршрутизация клика по ссылке
         */
        routeClick(e, url) {

            let query = this.getUrlQuery(url);

            //куда-то в обучение
            if (url.startsWith("/education")) {
                this.$router.push({ path: url, query: query });
                e.preventDefault();
            }

        },

        /**
         * Получает параметры из URL
         */
        getUrlQuery(url) {
            //парсим параметры (например переход к странице поиска)
            const [, paramString] = url.split('?');
            let urlParams = new URLSearchParams(paramString);
            let entries = urlParams.entries();
            let query = {};
            for (let [key, value] of entries) {
                query[key] = value;
            }

            return query;
        },

        /**
         * Получает ссылку для отображения видео
         */
        getVideoSrc(video) {
            let type = this.videoTypes.find(t => t.code == video.type);
            if (type) {
                return type.getSrc(video.id);
            }

            return "";
        },

        /**
        * Открыть фото на просмотр
        */
        openPhoto(id) {
            let start = this.gallery.findIndex((i) => i.id == id);

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 0, //иначе видео mp4 слева/справа начинает сразу воспроизводиться
                infinite: false,
                Toolbar: {
                    display: [
                        { id: "prev", position: "center" },
                        { id: "counter", position: "center" },
                        { id: "next", position: "center" },
                        "zoom",
                        "slideshow",
                        "thumbs",
                        "close",
                    ],
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        utils.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        utils.fancyBoxOnClose();
                    },
                },
            });
        },
    }
}
</script>

<style lang="scss">
.sbs-edu-ppb {

    .post-block {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0px;
        }
    }

    .post-picture {
        height: 0px;
        padding-top: 60%;
        background-size: cover;
        background-position: center center;
        margin-bottom: 15px;
        border-radius: 12px;
    }

    .post-description {
        color: var(--news-card-news-card-secondary-text-color);
        margin-bottom: 10px;
    }

    .post-text {
        color: var(--news-card-news-card-primary-text-color);
    }

    .post-video-wrap {

        position: relative;
        height: 0px;
        padding-top: 56.25%;

        background-color: var(--color-block-background);

        margin-bottom: 10px;

        iframe {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }

        video {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }



    }
}
</style>