<template>
    <div class="sbs-view" :class="[this.class, noSafeAreaTop ? 'safe-area-top-disble' : '']">
        <div class="view-status-back" :style="{ opacity: statusBackOpacity }"></div>

        <div class="view-inner" :class="[!this.isMenuHidden ? 'bottom-padding' : '']">

            <div class="view-header" v-if="this.$slots.header" ref="header" :style="{ right: scrollBarWidth + 'px' }">
                <div class="header-bg" :style="{ opacity: headerBgOpacity }"></div>
                <div class="header-inner">
                    <slot name="header"></slot>
                </div>
            </div>

            <div class="view-content-scroll" ref="contentScroll" v-pull-to-refresh:disabled="refreshDisabled"
                @refresh="refresh" @scroll="onScroll()" @resize="onResize()">

                <div ref="contentScrollInnerWidth"></div>

                <div class="view-content view-horizontal-padding"
                    :class="{ 'no-padding-top': noPaddingTop, 'no-padding-horizontal': noPaddingHorizontal, 'container': !noContainerWrap }">
                    <div v-if="!noSafeAreaTop" :style="{ height: headerHeight + 'px' }"></div>
                    <slot name="content"></slot>

                </div>


            </div>

            <div class="view-footer">
                <slot name="footer"></slot>
            </div>
        </div>
        <slot name="outer"></slot>

    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: "sbs-view",
    components: {},
    emits: ["refresh"],
    data() {
        return {
            savedScrollTop: 0,
            scroll: 0,
            scrollBarWidth: 0,
            headerHeight: 0
        };
    },
    props: {
        class: {
            type: String,
            default() {
                return "refresh";
            },
        },
        refreshDisabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
        isMenuHidden: {
            type: Boolean,
            default() {
                return false;
            },
        },
        noSafeAreaTop: {
            type: Boolean,
            default() {
                return false;
            },
        },
        noPaddingTop: {
            type: Boolean,
            default() {
                return false;
            },
        },
        noPaddingHorizontal: {
            type: Boolean,
            default() {
                return false;
            },
        },
        noContainerWrap: {
            type: Boolean,
            default() {
                return false;
            },
        }
    },
    computed: {

        ...mapGetters({
            safeArea: "getSafeArea",
        }),

        /**
         * Прозрачность плашки для МП, у которых статус бар поверх экрана
         */
        statusBackOpacity() {

            //если контент экрана не залазит на шторку статус бара телефона
            if (!this.noSafeAreaTop) {
                return 1;
            }

            if (this.safeArea && this.safeArea.top > 0 && this.scroll < this.safeArea.top + 30) {
                return 1 / (this.safeArea.top + 30) * this.scroll;
            }

            return 1;
        },

        /**
         * Прозрачность фона шапки
         */
        headerBgOpacity() {

            //если контент экрана не залазит на шторку статус бара телефона
            if (!this.noSafeAreaTop) {
                return 1;
            }

            if (this.safeArea && this.safeArea.top > 0) {
                return this.statusBackOpacity;
            } else {

                if (this.scroll < this.headerHeight + 30) {
                    return 1 / (this.headerHeight + 30) * this.scroll;
                }
            }

            return 1;

        }
    },
    methods: {

        onScroll() {
            this.scroll = this.$refs.contentScroll.scrollTop;
            this.refreshScrollBarWidth();
        },

        /**
         * Перезагрузка экрана
         */
        refresh(e) {
            this.$emit("refresh", e.detail.resolve);
        },

        /**
         * Пересчитывает отступ контента сверху для учата шапки
         */
        refreshHeaderHeight() {

            this.headerHeight = this.$refs.header ? this.$refs.header.clientHeight : 0;
        },

        /**
         * Вызывается перед переходом из этого экрана в другой
         */
        onBeforeRouteLeave() {
            this.savedScrollTop = this.$refs.contentScroll.scrollTop;
        },

        /**
         * Обраотка переход входом на экран
         */
        onBeforeRouteEnter() {
            this.$refs.contentScroll.scrollTop = this.savedScrollTop;
            this.refreshHeaderHeight();
            this.onScroll();
        },

        /**
         * Сбарсывает сохранённый скрол
         */
        resetSavedScroll() {
            this.savedScrollTop = 0;
        },

        onResize() {
            this.refreshScrollBarWidth();

        },

        refreshScrollBarWidth() {
            this.scrollBarWidth = this.$refs.contentScroll.offsetWidth - this.$refs.contentScrollInnerWidth.offsetWidth;
        }


    },

    /**
    * Событие создания экземпляра
    */
    created() {
        this.contentResizeObsover = new ResizeObserver(this.onResize);
    },

    mounted() {
        this.refreshHeaderHeight();
        this.refreshScrollBarWidth();
        window.addEventListener("resize", this.onResize);
        //включаем слежение за изменением высоты контента
        this.contentResizeObsover.observe(this.$refs.contentScroll);
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.onResize);
        //откючаем слежение за изменением высоты контента
        this.contentResizeObsover.unobserve(this.$refs.contentScroll);
    }

}

</script>

<style lang="scss">
.sbs-view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;

    padding-top: var(--safe-area-top);
    padding-bottom: var(--safe-area-bottom);
    transition: background-color ease-in-out 0.15s;

    box-shadow: rgba(1, 0, 61, 0.15) 0px 0px 15px;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    --view-content-padding-horizontal: 15px;
    --view-background-color: var(--bg);

    background-color: var(--view-background-color);

    &.safe-area-top-disble {
        padding-top: 0px;
    }

    .view-status-back {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: var(--safe-area-top);
        background-color: var(--bg);
        opacity: 0;
        z-index: 10;
        transition: opacity 0.3s cubic-bezier(0.25, 1.07, 0.54, 0.92);

    }

    .view-inner {
        position: relative;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        overflow: hidden;

        &.bottom-padding {
            padding-bottom: 55px;
        }
    }

    .view-header {
        position: fixed;

        top: var(--safe-area-top);
        left: 0px;
        right: 0px;

        z-index: 200;

        .header-bg {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-color: var(--bg);


            margin-right: auto;
            margin-left: auto;

            transition: opacity 0.3s cubic-bezier(0.25, 1.07, 0.54, 0.92);
        }

        .header-inner {
            position: relative;
            z-index: 2;

            max-width: 960px;
            margin-right: auto;
            margin-left: auto;
            padding: 0px 20px;
        }
    }

    .view-content-scroll {
        position: relative;
        overflow: auto;
        padding-bottom: var(--keyboard-height, 0px);
        /**отключаем pull для safari */
        overscroll-behavior: none;
    }

    .view-horizontal-padding {
        padding-left: var(--view-content-padding-horizontal);
        padding-right: var(--view-content-padding-horizontal);
    }

    .view-content {
        position: relative;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 100%;

        >.container {
            padding: 0px;
        }

        &.no-padding-top {
            padding-top: 0px;
        }

        &.no-padding-horizontal {
            padding-left: 0px;
            padding-right: 0px;
        }
    }



    /*
        Анимация перехода: слайд слева
    */

    &.slide-left-enter-active {
        transition: transform 0.5s cubic-bezier(0.25, 1.07, 0.54, 0.92);
        z-index: 999;
    }

    &.slide-left-enter-from {
        transform: translateX(100%);
    }

    &.slide-left-enter-from,
    &.slide-left-enter-to {
        z-index: 999;
    }

    &.slide-left-leave-active {
        transition: transform 0.5s cubic-bezier(0.25, 1.07, 0.54, 0.92);
    }

    &.slide-left-leave-active:after {
        content: "";
        position: absolute;
        background-color: #000;
        opacity: 0;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 501;

        transition: opacity 0.5s cubic-bezier(.25, 1.07, .54, .92);
    }

    &.slide-left-leave-to {
        transform: translateX(-30%);
    }

    &.slide-left-leave-to:after {
        opacity: 0.5;
    }


    /*
        Анимация перехода: слайд с права
    */

    &.slide-right-enter-active {
        transition: transform 0.5s cubic-bezier(0.25, 1.07, 0.54, 0.92);
    }

    &.slide-right-enter-active:after {
        content: "";
        position: absolute;
        background-color: #000;

        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 500;

        transition: opacity 0.5s cubic-bezier(.25, 1.07, .54, .92);
    }

    &.slide-right-enter-from {
        transform: translateX(-30%);
    }

    &.slide-right-enter-from:after {
        opacity: 0.5;
    }

    &.slide-right-enter-to:after {
        opacity: 0;
    }

    &.slide-right-leave-from {
        z-index: 999;
    }

    &.slide-right-leave-active {
        transition: transform 0.5s cubic-bezier(0.25, 1.07, 0.54, 0.92);
        z-index: 999;
    }

    &.slide-right-leave-to {
        transform: translateX(100%);
        z-index: 999;
    }
}
</style>