<template>
  <sbs-sheet v-model:visible="visible" :minHeight="10" :noPadding="false" :history="false">

    <template v-slot:body>
      <div class="sbs-update">
        <div class="message">
          {{ $t('system.update.new_version') }}
          <br />
          {{ $t('system.update.downloded') }}
        </div>
        <div class="btns">
          <div class="btn btn-block btn2 slim" @click="apply">{{ $t('system.update.close') }}</div>
          <div class="btn btn-block btn1 slim" @click="apply">{{ $t('system.update.apply_now') }}</div>
        </div>

      </div>
    </template>

  </sbs-sheet>

</template>

<script>
import bus from "@/plugins/bus";
import sbsSheet from '@/components/sheet.vue'

import { mapGetters } from "vuex";

export default {
  name: "sbs-update",
  components: {
    sbsSheet,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapGetters({
      safeArea: "getSafeArea",
      platform: "getPlatform"
    }),
  },
  methods: {
    show() {
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },

    apply() {
      bus.emit("SBS_UPDATE_E_APPLY");
    },
  },
  mounted() {
    //подписваемся на событие показа сообщения
    bus.on("SBS_UPDATE_E_SHOW", this.show);
  },
  unmounted() {
    //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
    bus.off("SBS_UPDATE_E_SHOW", this.show);
  },
};
</script>

<style lang="scss">
.sbs-update {

  .message {
    margin-bottom: 10px;
  }

  .btns {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    column-gap: 10px;
  }

}
</style>