import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage} from "firebase/messaging";
import { Capacitor } from '@capacitor/core';

import bus from '@/plugins/bus'
import store from '@/store'
import rest from "@/plugins/rest"
import errorListiner from '@/plugins/error.listiner'
import pushGoogle from '@/plugins/capacitor/google.push'

export default {

    init() {
        //функционал работает только на HTTPS
        if (window.location.protocol != "https:") {
            return;
        }

        //
        // конфиг ещё дублируется в public/firebase-messaging-sw.js
        //
        
        // Your web app's Firebase configuration
        // For Firebase JS SDK v7.20.0 and later, measurementId is optional
        const firebaseConfig = {
            apiKey: "AIzaSyD7f3H5hcON3R_JLrQtOYLgCTgnucifnTY",
            authDomain: "okraina-sbs.firebaseapp.com",
            projectId: "okraina-sbs",
            storageBucket: "okraina-sbs.firebasestorage.app",
            messagingSenderId: "781822306049",
            appId: "1:781822306049:web:e783253e7176b019719d00",
            measurementId: "G-3F4ZXB3R5H"
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);

        // Initialize Firebase Cloud Messaging and get a reference to the service
        const messaging = getMessaging(app);

        getToken(messaging, {vapidKey: "BIdhLwGCVJRo574_EwSsZP9YmhtmPkEsEByxS580VgZ6y-1v9VR1l0FIvYCTeMf3Vqqjh3lVAO380BPD2x_m35c"})
            .then((token) => {
                if (token) {
                    //обновляем токен в приложении
                    this.onTokenRefresh(token);

                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });

        //обработка получения PUSH, когда приложение активно (т.е. вкладка с сайтом сейчас выбрана)
        //в этом случае никакое сообщение в области уведомлений не отображается
        onMessage(messaging, (payload) => {
            //создаём и показываем уведомление
            let notification = new Notification(payload.notification.title,{
                body: payload.notification.body,
                data: payload.data
            });
            //и реагируем на клик по нему
            notification.onclick = () => {
                window.focus();
                pushGoogle.onPushClick(payload.data);
            };
        });

        if(navigator.serviceWorker) {
            //обработка клика по PUSH, когда приложение не активно (т.е. фоновое сообщение)
            //в service worker перехватывается клик, открывается вкладка с приложением
            //и передаётся сообщение с данными Push
            navigator.serviceWorker.addEventListener("message", (event) => {
                if(event.data.action == "push_click") {
                    pushGoogle.onPushClick(event.data.data);
                }
            });
        }

   
        //подписываемся на запрос удаления токена с сервера
        bus.on("SBS_PUSH_E_DELETE_TOKEN_FROM_SERVER", this.deleteTokenFromServer);
        //подписываемся на запрос сохранения токена на сервере
        bus.on("SBS_PUSH_E_SAVE_TOKEN_TO_SERVER", this.saveTokenToServer);     
    },



    /**
    * Обработка изменения токена
    */
    onTokenRefresh(token) {

        let oldToken = store.getters.getPushToken;

        //сохраним токен в текущем состоянии приложения
        store.commit('setPushToken', token);

        //сохраним токен на сервере
        this.saveTokenToServer(oldToken);

    },

    /**
    * Сохраняет токен на сервере
    */
    saveTokenToServer(oldToken = null) {

        //сохраняем только в случае если пользователь авторизован и имеется FCM токен
        if (store.getters.getPushToken && store.getters.isAuthorized) {

            rest.call("push.token.add", {
                method: 'post',
                data: {
                    token: store.getters.getPushToken,
                    oldToken: oldToken,
                    platform: Capacitor.getPlatform(),
                    type: "FCM"
                }
            }, true) //тихий режим
                .then(data => {

                    if (!data.success) {
                        errorListiner.onError({
                            message: "Ошибка сохранения push токена: " +data.errorText
                        });
                    }

                })
                .catch(() => {
                    //в тихом режиме не обрабатываем ошибку
                });
        }

    },

    /**
    * Удаление
    */
    deleteTokenFromServer() {

        //только если токен имеется и пользователь авторизован
        if (store.getters.getPushToken && store.getters.isAuthorized) {

            rest.call("push.token.delete", {
                method: 'post',
                data: {
                    token: store.getters.getPushToken
                }
            }, true) //тихий режим
                .then(data => {

                    if (!data.success) {
                        errorListiner.onError({
                            message: "Ошибка удаления push токена: " +data.errorText
                        });
                    }

                })
        }

    },

}