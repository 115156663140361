<template>
  <div class="sbs-department-item" @click="onItemClick(item)">
    <div class="name nunito-3-bold">{{ item.name }}</div>
    <span class="icon fi fi-arrow-right"></span>
  </div>
</template>

<script>
export default {
  name: "sbs-department-item",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods: {
    /**
     * Переход к подразделению
     */
    onItemClick(item) {
      this.$router.push({
        name: "department-view",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss">
.sbs-department-item {
  position: relative;

  padding: 15px 40px 15px 15px;
  border-radius: 15px;

  background: var(--audit-cards-audit-cards-bg-color);
  box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
  cursor: pointer;
  margin-bottom: 10px;

  .name {
    color: var(--audit-cards-audit-cards-title-text-color);

    margin-bottom: 5px;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: var(--service-color-1);
  }
}
</style>