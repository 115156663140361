<template>
  <sbs-view :class="'sbs-v-equipment-view'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
    <template v-slot:header>
      <sbs-header>
        <template v-if="equipment" v-slot:center>
          <div class="title title-small">{{ equipment.name }}</div>
        </template>

        <template v-slot:right>
          <!-- Действия в шапке-->
          <template v-if="!loading && equipment && equipment.permissions.length > 0">
            <div class="action" v-ripple.300 @click="openActions">
              <span class="icon fi fi-actions"></span>
            </div>
          </template>
        </template>
      </sbs-header>
    </template>
    <template v-slot:content>
      <template v-if="loading">
        <div class="text-center">
          <sbs-loader-inline />
        </div>
      </template>

      <template v-if="!loading">
        <!-- Не найдена-->
        <div v-if="equipment == null">{{ $t("equipment.view.not_found") }}</div>
        <!-- Оборудование -->
        <div v-else>

          <div class="description" v-if="equipment.detail_text && equipment.detail_text.trim() != ''"
            v-html="equipment.detail_text"></div>
          <div class="description" v-else-if="equipment.description.trim() != ''" v-html="equipment.description"></div>

          <template v-if="equipment.props">
            <sbs-props :props="equipment.props" />
            <br />
          </template>

          <sbs-fields-view :fields="fields" />

          <sbs-requests-statuses :items="requests_statuses" :prefilter="prefilter"
            :title="$t('equipment.view.requests_by_statuses')" />

          <sbs-requests-types :items="requests_types" :prefilter="prefilter"
            :title="$t('equipment.view.requests_by_types')" />

          <div class="btn btn1 btn-block" @click="goToRequestMaster">{{ $t('equipment.view.request_add') }}
          </div>

          <sbs-equipment-view-actions v-model:show="showActions" :permissions="equipment.permissions"
            :id="equipment.id" />

        </div>
      </template>
    </template>
  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsProps from "@/components/props.vue";
import sbsFieldsView from '@/components/fields.view.vue';
import sbsRequestsStatuses from "@/components/requests/statuses.vue";
import sbsRequestsTypes from "@/components/requests/statistics/types.vue";
import sbsEquipmentViewActions from "@/components/equipment/view.actions.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import store from "@/store";

import { mapGetters } from "vuex";

export default {
  name: "sbs-v-equipment-view",
  components: {
    sbsView,
    sbsHeader,
    sbsLoaderInline,
    sbsProps,
    sbsRequestsStatuses,
    sbsRequestsTypes,
    sbsFieldsView,
    sbsEquipmentViewActions
  },
  data() {
    return {
      loading: true,
      equipment: null,
      fields: [],
      requests_statuses: [],
      requests_types: [],

      hideMenu: false,
      showActions: false
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters({
      isNativePlatform: "isNativePlatform",
    }),

    prefilter() {
      if (this.equipment == null) {
        return {};
      }
      return {
        equipment: [
          {
            id: this.equipment.id,
            name: this.equipment.name,
          },
        ],
      };
    },
  },
  watch: {
    id: function () {
      this.loadData();
    }
  },

  methods: {

    /**
     * Подготовка запросов
     */
    getBatchRequests() {
      return {
        equipment: {
          ENTITY: "equipment",
          METHOD: "get",
          PARAMS: {
            id: this.id,
            select: ["fields", "permissions"],
          },
        },
        requests_statuses: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: ["status_id", "cnt"],
            filter: {
              equipment_id: this.id,
            },
            order: { "status.sort": "asc" },
            group: ["status_id"],
          },
        },
        requests_types: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: { 0: "type_id", 1: "cnt", "type_name": "type.name" },
            filter: {
              equipment_id: this.id,
            },
            order: { "type.name": "asc" },
            group: ["type_id", "type.name"],
          },
        },
      };
    },

    /**
     * Загружает данные
     */
    loadData(resolve = false) {
      //покажем экран загрузки
      this.loading = true;
      this.request = null;

      //запрашиваем данные
      rest
        .batch(this.getBatchRequests())
        .then((arResults) => {
          //обработка результатов
          this.processResponse(arResults);
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
          if (resolve) {
            resolve();
          }
        });
    },

    /**
     * Обработка результата
     */
    processResponse(results) {

      for (var key in results) {
        let data = results[key];

        if (data.success) {
          if (key == "equipment") {
            this.equipment = data.equipment;
            this.fields = data.fields;
          } else if (key == "requests_statuses") {
            this.requests_statuses = data.items;
          } else if (key == "requests_types") {
            this.requests_types = data.items;
          }

        } else {
          //показываем сообщение об ошибке
          bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          return;
        }
      }

    },

    /**
     * Переход к мастеру создания заявки
     */
    goToRequestMaster() {

      //переход на экран добавления заявки
      this.$router.push({ name: "equipment-request-master", params: { id: this.id } });
    },

    refresh(resolve) {
      this.loadData(resolve);
      this.showActions = false;
    },

    /**
     * Показать действия
     */
    openActions() {
      this.showActions = true;
    },

    /**
     * Обработка сохранения заявки
     */
    onEquipmentSave(params) {
      if (params.id == this.id) {
        this.loadData();
      }
    }
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  mounted() {
    //подписваемся на события
    bus.on("SBS_EQUIPMENT_E_SAVE", this.onEquipmentSave);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_EQUIPMENT_E_SAVE", this.onEquipmentSave);
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Вызывается перед переходом в этот экран
   */
  beforeRouteEnter(to, from, next) {
    let isGoBack = store.getters.getIsNowGoBack;

    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
      //елси НЕ возвращаемся на этот экран
      if (!isGoBack) {
        //то перезагрузим его
        vm.loadData();
      }

    });
  },



};
</script>

<style lang="scss">
.sbs-v-equipment-view {
  .description {
    margin-bottom: 15px;
  }
}
</style>
