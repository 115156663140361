<template>
  <sbs-view :class="'sbs-v-requests-change-responsible'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:center>
          <div class="title title-small">{{ $t('requests.delegate.title') }}</div>
        </template>
      </sbs-header>

    </template>
    <template v-slot:content>
      <br />
      <sbs-request-change-responsible :id="id" />
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsRequestChangeResponsible from "@/components/requests/change.responsible.vue";

export default {
  name: "sbs-v-requests-change-responsible",
  components: {
    sbsView,
    sbsHeader,
    sbsRequestChangeResponsible,
  },
  data() {
    return {
      hideMenu: false
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },

  methods: {},

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },
};
</script>