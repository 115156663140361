<template>
    <sbs-sheet v-model:visible="visibleInternal" :minHeight="60">
        <template v-slot:body>
            <div class="sbs-edu-pcrl">
                <div class="tabs">
                    <sbs-tabs :items="tabs" v-model:active="tab" />
                </div>

                <template v-if="loading">
                    <div class="text-center">
                        <sbs-loader-inline />
                    </div>
                </template>

                <div v-else class="items">
                    <div class="item" v-for="item in items" :key="item.id" @click="onItemClick(item)">
                        <div class="photo">
                            <div v-if="item.user_photo" class="picture" :style="{
        backgroundImage: 'url(' + item.user_photo.preview + ')',
    }"></div>
                            <span v-else class="icon fi fi-user"></span>
                        </div>

                        <div>
                            <div v-if="item.user_last_name || item.user_name" class="fio nunito-3-bold">
                                {{ item.user_last_name }} {{ item.user_name }}
                            </div>

                            <div v-if="item.user_work_position" class="position nunito-1-bold">
                                {{ item.user_work_position }}
                            </div>
                        </div>


                        <span class="arrow fi fi-arrow-right"></span>
                    </div>
                </div>

            </div>
        </template>
    </sbs-sheet>
</template>

<script>
import sbsSheet from '@/components/sheet.vue'
import sbsTabs from "@/components/tabs.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "sbs-education-post-comment-reaction-list",
    components: {
        sbsSheet,
        sbsTabs,
        sbsLoaderInline
    },
    emits: ["update:visible"],
    data() {
        return {
            visibleInternal: this.visible,
            loading: true,
            loaded: false,
            tab: "",
            items: true
        };
    },

    props: {
        id: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        stat: {
            type: Array,
            default() {
                return []
            }
        }
    },

    computed: {
        ...mapGetters({
            service: "getService"
        }),

        reactionsCount() {
            if (!this.stat) {
                return 0;
            }

            return this.stat.reduce(function (a, b) {
                return a + parseInt(b.count);
            }, 0)

        },

        tabs() {
            let tabs = [{
                code: "",
                name: "Все",
                count: this.reactionsCount
            }];
            this.stat.forEach(v => {
                if (v.count > 0) {
                    tabs.push({
                        code: v.value,
                        name: v.value,
                        count: v.count
                    });
                }
            });

            return tabs;
        },

        filter() {
            let filter = {
                comment_id: this.id
            };

            if (this.tab) {
                filter.symbol = this.tab;
            }

            return filter;
        }
    },

    watch: {


        visibleInternal: function (newVal) {
            if (newVal != this.visible) {
                this.$emit("update:visible", newVal);
            }
            //если открывается и данные ещё не загружены
            if (newVal && !this.loaded) {
                //делаем задержку чтобы не мелькало колесо прокрутки
                setTimeout(() => {
                    this.loadData();
                }, 500);
            }
        },
        visible: function (newVal) {
            if (newVal != this.visibleInternal) {
                this.visibleInternal = newVal;
            }
        },

        tab: function () {
            this.loadData();
        }
    },

    methods: {

        /**
         * Загрузака данных
         */
        loadData() {
            this.loading = true;
            this.items = [];

            //запрашиваем данные
            rest
                .call("education.post.comment.reaction.list", {
                    method: "post",
                    data: {
                        filter: this.filter,
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.items = data.items;
                        this.loaded = true;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    //скроем экран загрузки
                    this.loading = false;
                });
        },

        /**
        * Переход к пользователю
        */
        onItemClick(item) {
            this.visibleInternal = false;

            let vm = this;
            setTimeout(() => {

                vm.$router.push({
                    name: "user-view",
                    params: { id: item.user_id, service: vm.service.toLowerCase() },
                });
            }, 300);


        },

    }
}

</script>
<style lang="scss">
.sbs-edu-pcrl {
    .tabs {
        overflow: hidden;
    }

    .items {

        .item {
            position: relative;
            padding: 10px 50px 10px 10px;

            border-bottom: 1px solid var(--news-card-news-card-line-color);

            display: grid;
            align-items: center;
            grid-template-columns: 40px 1fr;
            grid-gap: 0px 10px;
            cursor: pointer;

            .photo {
                position: relative;
                width: 40px;
                height: 40px;

                border-radius: 50%;

                .picture {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    background-size: cover;
                    border-radius: 50%;
                }

                .icon {
                    font-size: 20px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: var(--news-card-news-card-secondary-text-color);
                }
            }

            .fio {
                color: var(--audit-cards-audit-cards-title-text-color);
                margin-bottom: 3px;
            }

            .position {
                color: var(--news-card-news-card-secondary-text-color);
            }

            .arrow {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                color: var(--service-color-1);
            }
        }

    }
}
</style>
