<template>
    <div class="sbs-control-stars form-group form-group-label" :class="{
        'form-group-error': error,
        'readonly': readonly,
        'no-margin': noMargin,
        'no-label': !label
    }">

        <template v-if="label != ''">
            <div class="floating-label">
                {{ label }}
                <span v-if="required && needShowRequired" class="required">*</span>
            </div>
        </template>

        <div class="stars">
            <div class="normal">
                <div class="star" :class="{ selected: isSelected(i) }" v-for="i in Object.keys(values)" :key="i"
                    @click="setValue(i)">
                    <span class="fi fi-star-filled"></span>
                </div>
            </div>

            <div class="active" :style="{ width: (value / 5 * 100) + '%' }">
                <div class="star" v-for="i in Object.keys(values)" :key="i" @click="setValue(i)">
                    <span class="fi fi-star-filled"></span>
                </div>
            </div>
        </div>

        <input type="hidden" :class="required ? 'required' : ''" :value="value" />

    </div>
</template>

<script>
export default {
    name: "sbs-control-stars",
    emits: ["update:modelValue", "change", "update:valid", "update:error"],
    data() {
        return {
            value: parseFloat(this.modelValue),
        };
    },
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        modelValue: {
            type: [String, Number],
            default() {
                return "";
            },
        },

        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showRequired: {
            type: Boolean,
            default() {
                return true;
            },
        },
        readonly: {
            type: Boolean,
            default() {
                return false;
            },
        },
        noMargin: {
            type: Boolean,
            default() {
                return false;
            },
        },

        error: {
            type: Boolean,
            default() {
                return false;
            },
        },
        valid: {
            type: Boolean,
            default() {
                return true
            }
        },

        values: {
            type: Object,
            default() {
                return {
                    1: 1,
                    2: 2,
                    3: 3,
                    4: 4,
                    5: 5
                };
            },
        }

    },
    computed: {
        needShowRequired() {
            return this.required && this.showRequired;
        },
    },
    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
        value: function () {
            this.checkValid();
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        }
    },

    methods: {
        onChange() {
            this.$emit("change", this.value);
        },

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {
            if (this.required && !(this.value)) {
                this.$emit("update:valid", false);
                return;
            }

            this.$emit("update:valid", true);
        },

        setValue(v) {
            if (this.readonly) {
                return;
            }
            this.value = v;
            this.$emit("update:modelValue", this.value);
        },

        isSelected(v) {
            if (this.value == v) {
                return true;
            }

            let keys = Object.keys(this.values);
            let index = keys.indexOf(v)
            for (let i = index + 1; i < keys.length; i++) {
                let k = keys[i];
                if (k == this.value) {
                    return true;
                }
            }


            return false
        }
    },

    mounted() {
        this.checkValid();
    },

}
</script>

<style lang="scss">
.sbs-control-stars {
    padding-top: 20px;

    &.no-label {
        padding-top: 0px;
    }

    .floating-label {
        top: 0px;
        left: 0px;
    }

    .stars {
        position: relative;
        display: inline-block;

        &::after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .normal {
        color: var(--news-card-news-card-secondary-text-color);

    }

    .star {
        z-index: 10;
        display: inline-block;
        margin-right: 8px;
        font-size: 27px;
        line-height: 1;
        cursor: pointer;

        &:last-of-type {
            margin-right: 0px;
        }
    }

    .active {
        position: absolute;
        z-index: 2;
        top: 0px;
        left: 0px;
        right: 0px;
        color: var(--service-color-1);
        overflow: hidden;
        white-space: nowrap;
    }

    &.form-group-error {
        .stars {
            padding: 5px 10px;

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                border: 1px solid var(--color-control-border-error);
                border-radius: 20px;
            }
        }
    }

    &.readonly {
        .star {
            cursor: default;
        }
    }

    &.no-margin {
        margin-bottom: 0px;
    }

    @media (max-width: 359px) {
        .star {
            font-size: 23px;
        }
    }
}
</style>