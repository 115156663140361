<template>
    <div class="sbs-fields" v-if="fields.length > 0">
        <template v-for="field in fields" :key="field.code">
            <div class="field" v-if="!isEmpty(displayValues[field.code])">
                <div class="label nunito-2-bold">{{ field.name }}</div>

                <div class="value nunito-3-regular" v-if="Array.isArray(displayValues[field.code])">
                    {{ displayValues[field.code].join(', ') }}
                </div>
                <div class="value nunito-3-regular" v-else>
                    {{ displayValues[field.code] }}
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "sbs-fields-view",
    components: {
    },
    props: {
        fields: {
            type: Array,
            default() {
                return [];
            },
        },

        values: {
            type: Object,
            default() {
                return {};
            },
        }
    },

    computed: {
        displayValues() {
            let list = {};

            this.fields.forEach(f => {
                list[f.code] = this.getDisplayValue(f)
            })

            return list;
        }
    },

    methods: {

        /**
         * Получает значение для отображения
         */
        getDisplayValue(field) {
            //если у поля уже задано значение для отображения (с сервера например вернулось)
            if (field.display_value) {
                return field.display_value;
            }

            //иначе смотрим может задано значение у поля или передано параллельно
            let value = field.value;
            if (!value) {
                value = this.values[field.code];
            }
            if (!value) {
                return false;
            }

            if (Array.isArray(value)) {
                let displayValue = [];
                value.forEach(v => {
                    let dv = this.getDisplayValueByField(field, v);
                    if (dv) {
                        displayValue.push(dv);
                    }
                });
                return displayValue;
            } else {
                let displayValue = this.getDisplayValueByField(field, value);
                return displayValue;
            }

        },

        /**
         * Получает значение для отображения по типу поля
         */
        getDisplayValueByField(field, value) {
            if (field.type == "STRING" || field.TYPE == "NUMBER") {
                return value;
            } else if (field.type == "DATE") {
                if (value) {
                    return moment(value, "DD.MM.YYYY").format("DD.MM.YYYY")
                }
            } else if (field.type == "LIST") {
                if (value) {
                    let item = field.list.find(i => i.id == value);
                    if (item) {
                        return item.name
                    }
                    return false;
                }
            }

            return value;

        },

        isEmpty(value) {
            if (!value) {
                return true;
            }
            if (Array.isArray(value) && value.length == 0) {
                return true;
            }

            return false

        }


    }
};
</script>

<style lang="scss">
.sbs-fields {

    .field {
        margin-bottom: 13px;

        .label {
            color: var(--news-card-news-card-secondary-text-color);
            text-transform: uppercase;
            margin-bottom: 5px;
        }

        .value {
            color: var(--audit-cards-audit-cards-title-text-color);
        }
    }
}
</style>