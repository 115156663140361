<template>
  <sbs-view :class="'sbs-v-picture-editor'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:center>
          <div class="title title-small">{{ $t('picture_editor.title') }}</div>
        </template>

        <template v-slot:right>
          <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="save">
            <span class="icon fi fi-save"></span>
          </div>
        </template>
      </sbs-header>
    </template>
    <template v-slot:content>

      <vue-cropper v-if="this.params.image" ref="cropper" :src="this.params.image" :aspectRatio="this.params.aspectRatio ? this.params.aspectRatio : NaN">
      </vue-cropper>
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";

import { mapGetters } from "vuex";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "sbs-v-picture-editor",
  components: {
    sbsView,
    sbsHeader,
    VueCropper,
  },
  data() {
    return {
      hideMenu: false
    };
  },
  computed: {
    ...mapGetters({
      params: "getPictureEditorParams",
    }),
  },

  watch: {
    params: function () {
      if (this.$refs.cropper) {
        this.$refs.cropper.replace(this.params.image);
        this.$refs.cropper.reset();
      }
    },
  },

  methods: {
    save() {
      if (this.params.save) {
        this.params.save(
          this.$refs.cropper.getCroppedCanvas().toDataURL("image/jpeg")
        );
        this.$router.go(-1);
      }
    },
  },

  mounted() { },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },
};
</script>

<style lang="scss">
.sbs-v-picture-editor {
  display: block;
}
</style>