<template>
  <div class="sbs-props">
    <div class="prop" :class="[prop.class ? prop.class : '']" v-for="prop in props" :key="prop.code"
      @click="onPropClick(prop)">
      <div class="label nunito-2-bold" v-if="prop.name">{{ prop.name }}</div>

      <template v-if="prop.type == 'string'">
        <div class="value roboto-3-regular">{{ prop.value }}</div>
      </template>
      <template v-else-if="prop.type == 'phone'">
        <div class="value roboto-3-regular"><a :href="'tel:' + prop.value">{{ prop.value }}</a></div>
      </template>
      <template v-else-if="prop.type == 'email'">
        <div class="value roboto-3-regular"><a :href="'mailto:' + prop.value">{{ prop.value }}</a></div>
      </template>
      <template v-else-if="prop.type == 'files' &&
      (prop.code == 'PHOTO' || prop.code == 'UF_PHOTO')
      ">
        <sbs-photo-swiper :photos="prop.value" />
      </template>
    </div>
  </div>
</template>

<script>
import sbsPhotoSwiper from "@/components/photo.swiper.vue";

export default {
  name: "sbs-props",
  components: {
    sbsPhotoSwiper,
  },
  props: {
    props: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  methods: {

    onPropClick(prop) {
      if (prop.action) {
        prop.action();
      }
    }
  }
};
</script>

<style lang="scss">
.sbs-props {

  .prop {
    margin-bottom: 13px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .label {
      color: var(--news-card-news-card-secondary-text-color);

      text-transform: uppercase;
      margin-bottom: 5px;
    }

    .value {
      color: var(--audit-cards-audit-cards-title-text-color);

      a {
        color: currentColor;
        font-size: inherit;
        text-decoration: none;
      }
    }
  }
}
</style>