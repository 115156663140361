<template>
    <div class="sbs-rt-fc">

        <template v-for="(item, index) in checkList" :key="item.id">
            <div class="item">
                <sbs-control-input v-model="item.name" type="text"
                    :label="$t('requests.form.checklist.item') + (index + 1)">
                    <template v-slot:icon>
                        <div class="icon" @click="showCheckListItemOptions(item)">
                            <span class="fi fi-actions"></span>
                        </div>
                    </template>
                </sbs-control-input>
                <div class="delete" @click="deleteCheckListItem(item.id)">
                    <span class="fi fi-close"></span>
                </div>
            </div>

            <sbs-control-select-sheet v-model:visible="item.showOptions" :title="$t('requests.form.checklist.type')"
                v-model="item.type" :list="checklistTypes" :showButtons="false" />


        </template>

        <sbs-control-input v-model="input" type="text" label="Добавить пункт">

            <template v-slot:icon>
                <div class="icon" @click="addCheckListItem">
                    <span class="fi fi-add"></span>
                </div>
            </template>
        </sbs-control-input>

    </div>
</template>

<script>
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";

import moment from "moment";
import { mapGetters } from "vuex";

export default {
    name: "sbs-request-template-form-checklist",
    components: {
        sbsControlInput,
        sbsControlSelectSheet
    },
    emits: ["update:modelValue"],
    data() {
        return {
            checkList: this.modelValue,
            input: "",

            checklistTypes: [
                {
                    id: "TEXT",
                    name: this.$t('requests.form.checklist.text')
                },
                {
                    id: "PHOTO",
                    name: this.$t('requests.form.checklist.photo')
                },
                {
                    id: "NUMBER",
                    name: this.$t('requests.form.checklist.number')
                },
                {
                    id: "CHECKBOX",
                    name: this.$t('requests.form.checklist.checkbox')
                },
            ]
        };
    },
    props: {
        modelValue: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        ...mapGetters({
            isNativePlatform: "isNativePlatform",
        }),
    },
    watch: {
        modelValue: function () {
            this.checkList = this.modelValue;
        },
        checkList: {
            handler() {
                this.$emit("update:modelValue", this.checkList);
            },
            deep: true,
        },
    },

    methods: {

        /**
         * Добавление пункта чеклиста
         */
        addCheckListItem() {
            //если название не ввели
            if (this.input.trim() == "") {
                return;
            }

            this.checkList.push({
                id: "new-" + moment().format("x"),
                name: this.input,
                type: "TEXT",
                showOptions: false,
            });

            this.input = "";
        },

        /**
         * Отображает опции пункта чек листа
         */
        showCheckListItemOptions(item) {
            item.showOptions = true;
        },

        /**
         * Удаляет пункт чек листа
         */
        deleteCheckListItem(id) {
            this.checkList = this.checkList.filter((i) => i.id != id);
        },

        /**
         * Перед сохранением
         */
        onBeforeSave() {

            //если в поле добавления пункта чеклиста что-то написано
            if (this.input.trim() != "") {
                //то добавляем в список перед сохранением
                this.addCheckListItem();
            }

        }
    }
}

</script>

<style lang="scss">
.sbs-rt-fc {

    .item {
        position: relative;
        padding-right: 40px;

        .delete {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            padding: 10px;
            line-height: 1;
        }
    }

    .options {
        .option {
            padding: 10px 0px 10px;
        }
    }

}
</style>