<template>
  <div class="sbs-request-form">
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>

    <template v-else>
      <sbs-tabs :items="tabs.filter(t => t.code != 'photo')" v-model:active="tab" />

      <div v-show="tab == 'fields'" class="tab">

        <div ref="form">

          <sbs-control-input v-model="fields.name" type="text" :label="$t('requests.form.fields.name')" :required="true"
            :noLabelAnimation="setFieldsFilled">
            <template v-if="isNativePlatform" v-slot:icon>
              <div class="icon" @click="speechName">
                <span class="fi fi-mic"></span>
              </div>
            </template>
          </sbs-control-input>

          <sbs-control-textarea v-model="fields.description" :label="$t('requests.form.fields.description')"
            :required="false" :rows="5" :noLabelAnimation="setFieldsFilled">

            <template v-if="isNativePlatform" v-slot:icon>
              <div class="icon" @click="speechDescription">
                <span class="fi fi-mic"></span>
              </div>
            </template>
          </sbs-control-textarea>

          <sbs-control-select v-model="fields.equipment_id" v-model:modelName="info.equipment_name"
            :label="$t('requests.form.fields.equipment_id')" :required="false" name="equipment_id"
            method="equipment.list" :noLabelAnimation="setFieldsFilled" />

          <div class="equipment-buttons" v-if="isNativePlatform">

            <div class="btn btn-block btn1 slim" @click="onQrClick">
              <div class="text-wrap">
                <span class="icon fi fi-qr-scanner"></span>
                {{ $t('requests.form.scan-qr') }}
              </div>
            </div>

            <div class="btn btn-block btn1 slim" @click="onNfcClick">
              <div class="text-wrap">
                <span class="icon fi fi-nfc"></span>
                {{ $t('requests.form.scan-nfc') }}
              </div>
            </div>

          </div>


          <sbs-control-select v-model="fields.responsible_department_id"
            v-model:modelName="info.responsible_department_name"
            :label="$t('requests.form.fields.responsible_department_id')" :required="true"
            name="responsible_department_id" method="department.list" :params="{
      filter: [
        {
          LOGIC: 'OR',
          active: 'Y',
          id: this.fields.responsible_department_id,
        },
      ],
      forResponsible: 'Y',
    }" :multiple="false" :noLabelAnimation="setFieldsFilled" />

          <sbs-control-select v-model="fields.type_id" v-model:modelName="info.type_name"
            :label="$t('requests.form.fields.type_id')" :required="true" name="type_id"
            method="dictionary.request.type.list" :noLabelAnimation="setFieldsFilled" :params="{
      filter: [
        {
          LOGIC: 'OR',
          ACTIVE: 'Y',
          ID: this.fields.type_id,
        },
      ],
      forRequestCreate: 'Y',
    }" />

          <sbs-control-select v-model="fields.priority_id" v-model:modelName="info.priority_name"
            :label="$t('requests.form.fields.priority_id')" :required="true" name="type_id"
            method="dictionary.request.priorities.list" :noLabelAnimation="setFieldsFilled" />

          <sbs-control-datetime v-model="fields.start_date_plan" :label="$t('requests.form.fields.start_date_plan')" />

          <sbs-control-datetime v-model="fields.end_date_plan" :label="$t('requests.form.fields.end_date_plan')" />

          <sbs-control-time v-model="fields.duration_plan" :label="$t('requests.form.fields.duration_plan')" />

          <sbs-control-checkbox v-model="fields.report_required" value="Y"
            :label="$t('requests.form.fields.report_required')" />

        </div>

        <template v-if="this.mode == 'ADD'">
          <sbs-tabs :items="tabs.filter(t => t.code == 'photo')" :active="'photo'" />

          <sbs-control-photos v-model="photos" />

        </template>

        <div ref="entity">
          <sbs-fields-edit v-model="entityFields" entity="REQUEST" :settingsKey="fields.type_id" :id="id" />
        </div>

        <!-- Кнопка добавления обычное позиционирование -->
        <div class="btn btn1 btn-block btn-save" :class="saving ? 'wait' : ''" v-ripple.300 @click="save">

          <template v-if="mode == 'ADD'">{{ $t('common.add') }}</template>
          <template v-else>{{ $t('common.save') }}</template>
          <div v-if="saving" class="btn-loader"></div>
        </div>

      </div>

      <!-- Чеклист -->
      <div v-show="tab == 'checklist'" class="checklist tab">

        <template v-for="(item, index) in checkList" :key="item.id">
          <div class="item">
            <sbs-control-input v-model="item.name" type="text"
              :label="$t('requests.form.checklist.item') + (index + 1)">
              <template v-slot:icon>
                <div class="icon" @click="showCheckListItemOptions(item)">
                  <span class="fi fi-actions"></span>
                </div>
              </template>
            </sbs-control-input>
            <div class="delete" @click="deleteCheckListItem(item.id)">
              <span class="fi fi-close"></span>
            </div>
          </div>

          <sbs-control-select-sheet v-model:visible="item.showOptions" :title="$t('requests.form.checklist.type')"
            v-model="item.type" :list="checklistTypes" :showButtons="false" />

        </template>

        <sbs-control-input v-model="checkListNew" type="text" :label="$t('requests.form.checklist.add')">

          <template v-slot:icon>
            <div class="icon" @click="addCheckListItem">
              <span class="fi fi-add"></span>
            </div>
          </template>
        </sbs-control-input>
      </div>

      <!-- Кнопка добавления абсолютное позиционирование (для вкладки Чек лист и Фото) -->
      <sbs-teleport-wrapper to="#sbs-rfvb">
        <div class="sbs-request-form-add-btn" v-show="tab == 'checklist' || tab == 'photo'">
          <div class="container">
            <div class="page-padding">
              <div class="btn btn1 btn-block btn-save" :class="saving ? 'wait' : ''" v-ripple.300 @click="save">

                <template v-if="mode == 'ADD'">{{ $t('common.add') }}</template>
                <template v-else>{{ $t('common.save') }}</template>

                <div v-if="saving" class="btn-loader"></div>
              </div>
            </div>
          </div>
        </div>
      </sbs-teleport-wrapper>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlTextarea from "@/components/controls/textarea.vue";
import sbsControlSelect from "@/components/controls/select.vue";
import sbsControlDatetime from "@/components/controls/datetime.vue";
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import sbsControlPhotos from "@/components/controls/photos.vue";
import sbsControlTime from "@/components/controls/time.vue";
import sbsTabs from "@/components/tabs.vue";
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";
import sbsTeleportWrapper from "@/components/teleport-wrapper.vue";
import sbsFieldsEdit from "@/components/fields.edit.vue";

import validator from "@/plugins/validator";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "sbs-request-form",
  components: {
    sbsLoaderInline,
    sbsControlInput,
    sbsControlTextarea,
    sbsControlSelect,
    sbsControlDatetime,
    sbsControlCheckbox,
    sbsControlPhotos,
    sbsControlTime,
    sbsTabs,
    sbsControlSelectSheet,
    sbsTeleportWrapper,
    sbsFieldsEdit
  },
  emits: ["update:checkLeave"],
  data() {
    return {
      loading: true,
      fields: {},
      info: {},
      saving: false,
      setFieldsFilled: false,
      nfcId: "",
      tab: "fields",
      checkListNew: "",
      checkList: [],
      photos: [],
      entityFields: {},

      checklistTypes: [
        {
          id: "TEXT",
          name: this.$t('requests.form.checklist.text')
        },
        {
          id: "PHOTO",
          name: this.$t('requests.form.checklist.photo')
        },
        {
          id: "NUMBER",
          name: this.$t('requests.form.checklist.number')
        },
        {
          id: "CHECKBOX",
          name: this.$t('requests.form.checklist.checkbox')
        },
      ]
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
    reset: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters({
      isNativePlatform: "isNativePlatform",
    }),
    mode() {
      return this.id > 0 ? "EDIT" : "ADD";
    },
    tabs() {
      var arrTabs = [
        {
          code: "fields",
          name: "Заявка",
        },
        {
          code: "checklist",
          name: "Чек лист",
        },
      ];
      if (this.mode == 'ADD') {
        arrTabs.push({
          code: "photo",
          name: "Фото",
        });
      }
      return arrTabs;
    },

  },
  watch: {
    id: function () {
      this.loadData();
    },
    reset: function () {
      this.loadData();
    },
  },

  methods: {
    /**
     * Очищает форму
     */
    clear() {
      this.fields = {
        name: "",
        description: "",
        equipment_id: "",
        responsible_department_id: "",
        type_id: "",
        priority_id: "",
        start_date_plan: "",
        end_date_plan: "",
        report_required: "N",
      };
      this.info = {
        equipment_name: "",
        responsible_department_name: "",
        type_name: "",
        priority_name: "",
      };
      this.checkListNew = "";
      this.checkList = [];
      this.tab = "fields";
      this.photos = [];
    },

    /**
     * Сохранение
     */
    save() {
      //проверка введённых данных
      let validFields = validator.check({ container: this.$refs.form });
      let validEntity = validator.check({ container: this.$refs.entity });
      if (!validFields || !validEntity) {
        this.tab = "fields";
        return;
      }

      if (this.saving) {
        return;
      }
      this.saving = true;

      //если в поле добавления пункта чеклиста что-то написано
      if (this.checkListNew.trim() != "") {
        //то добавляем в список перед сохранением
        this.addCheckListItem();
      }

      let dataRequest = {
        id: this.id,
        fields: this.fields,
        checklist: this.checkList,
        entityFields: this.entityFields
      };

      if (this.mode == "ADD") {
        dataRequest.photos = this.photos.map((p) => p.data);
      }

      //выполняем сохранение
      rest
        .call("request." + (this.mode == "ADD" ? "add" : "update"), {
          method: "post",
          data: dataRequest,
        })
        .then((data) => {
          if (data.success) {
            this.onSave(data.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          this.saving = false;
        });
    },

    /**
     * Обработка успешного сохранения
     */
    onSave(id) {
      //показываем сообщение об Успехе
      bus.emit("SBS_MESSAGE_E_SHOW", {
        message:
          this.mode == "ADD"
            ? this.$t('requests.form.success.add')
            : this.$t('requests.form.success.edit'),
        type: "success",
      });

      //запрещает экрану запрашивать у пользователя подтверждение выхода с экрана
      this.$emit("update:checkLeave", false);

      //генерим событие успешного сохранения заявки
      bus.emit("SBS_REQUEST_FORM_E_SAVE", id);

      //переход обратно
      this.$router.go(-1);

      //если режим создания
      if (this.mode == "ADD") {
        //очищаем форму с задержкой (пока переход анимируется ждем)
        let vm = this;
        setTimeout(function () {
          vm.clear();
        }, 500);
      }
    },

    /**
     * Загрузка данных
     */
    loadData() {
      if (this.mode == "ADD") {
        this.clear();
        this.loading = false;
        return;
      }

      //покажем экран загрузки
      this.loading = true;
      this.clear();

      //запрашиваем данные
      rest
        .call("request.get", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            this.setFields(data.request);
            this.checkList = data.checklist;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Устанавливает поля формы по данным заявки
     * @param Object request данные заявки
     */
    setFields(request) {
      //установим у полей флаг как буд-то бы поля заполнены
      //чтобы метки поднялись без анимации
      this.setFieldsFilled = true;

      for (let code in this.fields) {
        this.fields[code] = request[code];
      }

      for (let code in this.info) {
        this.info[code] = request[code];
      }

      //снимаем флаг
      let vm = this;
      setTimeout(() => {
        vm.setFieldsFilled = false;
      }, 100);
    },

    /**
     * Клик по иконке NFC
     */
    onNfcClick() {
      let vm = this;
      bus.emit("SBS_NFC_SCANNER_E_OPEN", {
        resolve: function (item) {
          vm.fields.equipment_id = item.id;
          vm.info.equipment_name = item.name;
        },
      });
    },

    /**
     * Клик по иконке QR
     */
    onQrClick() {
      let vm = this;
      bus.emit("SBS_QR_SCANNER_E_OPEN", {
        resolve: function (item) {
          vm.fields.equipment_id = item.id;
          vm.info.equipment_name = item.name;
        },
      });
    },

    /**
     * Добавление пункта чеклиста
     */
    addCheckListItem() {
      //если название не ввели
      if (this.checkListNew.trim() == "") {
        return;
      }

      this.checkList.push({
        id: "new-" + moment().format("x"),
        name: this.checkListNew,
        type: "TEXT",
        showOptions: false,
      });

      this.checkListNew = "";
    },

    /**
     * Отображает опции пункта чек листа
     */
    showCheckListItemOptions(item) {
      item.showOptions = true;
    },

    /**
     * Удаляет пункт чек листа
     */
    deleteCheckListItem(id) {
      this.checkList = this.checkList.filter((i) => i.id != id);
    },

    /**
     * Распознование речи для навания
     */
    speechName() {
      bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
        resolve: (text) => {
          this.fields.name = text;
        },
      });
    },

    /**
     * Распознование речи для описания
     */
    speechDescription() {
      bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
        resolve: (text) => {
          this.fields.description = text;
        },
      });
    },
  },

  /**
   * Обработка создания экземпляра
   */
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss">
.sbs-request-form {
  padding-bottom: 40px;

  .equipment-buttons {
    margin-top: -10px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 20px;
  }

  .checklist {
    .item {
      position: relative;
      padding-right: 40px;

      .delete {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px;
        line-height: 1;
      }
    }

    .options {
      .option {
        padding: 10px 0px 10px;
      }
    }
  }
}

.sbs-request-form-add-btn {

  padding: 15px 0px;
}
</style>