<template>
    <sbs-view :class="'sbs-v-requests-summary'" @refresh="refresh" :isMenuHidden="hideMenu" :noSafeAreaTop="true"
        :noPaddingTop="true" :noPaddingHorizontal="true" ref="view">

        <template v-slot:header>
            <sbs-header :showBackBtn="false" :showLogo="true">
                <template v-slot:center>
                    <div class="title">{{ $t("requests.summary.title") }}</div>
                </template>

            </sbs-header>
        </template>

        <template v-slot:content>

            <template v-if="loading">
                <sbs-loader-page />
            </template>
            <template v-if="loaded">

                <div class="main-panel">

                    <div class="period">
                        <div class="title nunito-4-bold">{{ $t('requests.summary.period') }}</div>
                        <div>
                            <sbs-control-select-simple v-model="periodId" :list="periods" :showButtons="false"
                                :label="$t('requests.summary.period')" />

                        </div>
                    </div>
                    <sbs-requests-statuses :items="statusesStatCombined" :showEmpty="true"
                        :emptyMessage="$t('requests.summary.no_statistics')" :showTitle="false" />

                    <sbs-department-rating
                        :rating="statistics.myDepartmentRating ? statistics.myDepartmentRating.rating_avg : 0"
                        :filter="filterForView" />
                </diV>

                <div class="view-horizontal-padding dashboard">

                    <div class="row">
                        <div class="col-sm-12">

                            <sbs-accordion :title="$t('requests.summary.new_dynamics')"
                                :code="'requests.summary.new_dynamics'">
                                <sbs-requests-statistics-new-dynamics :items="statistics.newByDates" />
                            </sbs-accordion>

                        </div>
                        <div class="col-sm-12">

                            <sbs-accordion :title="$t('requests.summary.by_responsible_users')"
                                :code="'requests.summary.by_responsible_users'">
                                <sbs-requests-statistics-count-by-responsible-users
                                    :items="statistics.countByResponsibleUsers" />
                            </sbs-accordion>

                        </div>
                        <div class="col-sm-12">

                            <sbs-accordion :title="$t('requests.summary.closetime_by_responsible_users')"
                                :code="'requests.summary.closetime_by_responsible_users'">
                                <sbs-requests-statistics-closetime-by-responsible-users
                                    :items="statistics.closeTimeByResponsibleUsers" />
                            </sbs-accordion>

                        </div>
                        <div class="col-sm-12">

                            <sbs-accordion :title="$t('requests.summary.closed_by_created_departments')"
                                :code="'requests.summary.closed_by_created_departments'">
                                <sbs-requests-statistics-closed-by-created-departments
                                    :items="statistics.closedByCreatedDepartments" />
                            </sbs-accordion>

                        </div>
                        <div class="col-sm-12">

                            <sbs-accordion :title="$t('requests.summary.closetime_by_dates')"
                                :code="'requests.summary.closetime_by_dates'">
                                <sbs-requests-statistics-closetime-by-dates :items="statistics.closeTimeByDates" />
                            </sbs-accordion>

                        </div>
                        <div class="col-sm-12">

                            <sbs-accordion :title="$t('requests.summary.worktime_by_responsible_users')"
                                :code="'requests.summary.worktime_by_responsible_users'">
                                <sbs-requests-statistics-worktime-by-responsible-users
                                    :items="statistics.workTimeByResponsiblesUsers" />
                            </sbs-accordion>

                        </div>
                        <div class="col-sm-12">

                            <sbs-accordion :title="$t('requests.summary.worktime_by_dates')"
                                :code="'requests.summary.worktime_by_dates'">
                                <sbs-requests-statistics-worktime-by-dates :items="statistics.workTimeByDates" />
                            </sbs-accordion>

                        </div>
                        <div class="col-sm-12">

                            <sbs-accordion :title="$t('requests.summary.count_by_equipment')"
                                :code="'requests.summary.count_by_equipment'">
                                <sbs-requests-statistics-count-by-equipment :items="statistics.countByEquipment" />
                            </sbs-accordion>

                        </div>
                        <div class="col-sm-12">

                            <sbs-accordion :title="$t('requests.summary.rating_by_responsible_users')"
                                :code="'requests.summary.rating_by_responsible_users'">
                                <sbs-requests-statistics-rating-by-responsible-users
                                    :items="statistics.responsibleUsersRating" />
                            </sbs-accordion>

                        </div>

                    </div>

                </div>
            </template>
        </template>

        <template v-slot:footer>
            <!-- Кнопка -->
            <sbs-requests-widget />
        </template>
    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderPage from "@/components/loader.page.vue";
import sbsControlSelectSimple from "@/components/controls/select.simple.vue";
import sbsRequestsStatuses from "@/components/requests/statuses.vue";
import sbsAccordion from "@/components/accordion.vue";
import sbsRequestsStatisticsNewDynamics from "@/components/requests/statistics/new.dynamics.vue";
import sbsRequestsStatisticsCountByResponsibleUsers from "@/components/requests/statistics/count.by.responsible.users.vue";
import sbsRequestsStatisticsClosetimeByResponsibleUsers from "@/components/requests/statistics/closetime.by.responsible.users.vue";
import sbsRequestsStatisticsClosedByCreatedDepartments from "@/components/requests/statistics/closed.by.created.departments.vue";
import sbsRequestsStatisticsClosetimeByDates from "@/components/requests/statistics/closetime.by.dates.vue";
import sbsRequestsStatisticsWorktimeByResponsibleUsers from "@/components/requests/statistics/worktime.by.responsible.users.vue";
import sbsRequestsStatisticsWorktimeByDates from "@/components/requests/statistics/worktime.by.dates.vue";
import sbsRequestsStatisticsCountByEquipment from "@/components/requests/statistics/count.by.equipment.vue";
import sbsRequestsStatisticsRatingByResponsibleUsers from "@/components/requests/statistics/rating.by.responsible.users.vue";
import sbsDepartmentRating from "@/components/departments/rating.vue";
import sbsRequestsWidget from "@/components/requests/widget.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";
import localStorage from "@/plugins/localStorage";

export default {
    name: "sbs-v-requests-summary",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderPage,
        sbsControlSelectSimple,
        sbsRequestsStatuses,
        sbsAccordion,
        sbsRequestsStatisticsNewDynamics,
        sbsRequestsStatisticsCountByResponsibleUsers,
        sbsRequestsStatisticsClosetimeByResponsibleUsers,
        sbsRequestsStatisticsClosedByCreatedDepartments,
        sbsRequestsStatisticsClosetimeByDates,
        sbsRequestsStatisticsWorktimeByResponsibleUsers,
        sbsRequestsStatisticsWorktimeByDates,
        sbsRequestsStatisticsCountByEquipment,
        sbsRequestsStatisticsRatingByResponsibleUsers,
        sbsDepartmentRating,
        sbsRequestsWidget
    },
    data() {
        return {
            hideMenu: false,

            loading: true,
            loaded: false,
            requestsStatuses: [],
            statistics: {
                statuses: [],
                newByDates: [],
                countByResponsibleUsers: [],
                closeTimeByResponsibleUsers: [],
                closedByCreatedDepartments: [],
                closeTimeByDates: [],
                workTimeByResponsiblesUsers: [],
                workTimeByDates: [],
                countByEquipment: [],
                myDepartmentRating: false,
                responsibleUsersRating: []
            },
            periodId: "30",
            periods: [
                {
                    id: "10",
                    name: this.$t("requests.summary.period_days", { days: 10 }),
                    from: moment().add(-10, "d").format("DD.MM.YYYY"),
                    filter: {
                        ">=date_create": moment().add(-10, "d").format("DD.MM.YYYY"),
                    },
                    filterClose: {
                        ">=date_create": moment().add(-10, "d").format("DD.MM.YYYY"),
                    },
                },
                {
                    id: "30",
                    name: this.$t("requests.summary.period_days", { days: 30 }),
                    from: moment().add(-30, "d").format("DD.MM.YYYY"),
                    filter: {
                        ">=date_create": moment().add(-30, "d").format("DD.MM.YYYY"),
                    },
                    filterClose: {
                        ">=date_create": moment().add(-30, "d").format("DD.MM.YYYY"),
                    },
                },
                {
                    id: "60",
                    name: this.$t("requests.summary.period_days", { days: 60 }),
                    from: moment().add(-60, "d").format("DD.MM.YYYY"),
                    filter: {
                        ">=date_create": moment().add(-60, "d").format("DD.MM.YYYY"),
                    },
                    filterClose: {
                        ">=date_create": moment().add(-60, "d").format("DD.MM.YYYY"),
                    },
                },
            ],
        };
    },

    computed: {
        selectedPeriod() {
            let p = this.periods.find((p) => p.id == this.periodId);
            return p;
        },

        filterForView() {
            if (this.selectedPeriod) {
                return {
                    created: [
                        {
                            id: moment().format("x"),
                            from: this.selectedPeriod.from,
                            to: ""
                        }
                    ]
                }
            }

            return false;
        },

        /**
         * Комбинированный список статистики по статусам - включая те статусы, по которым нет статистики
         */
        statusesStatCombined() {
            let list = JSON.parse(JSON.stringify(this.requestsStatuses));

            list.forEach(item => {
                item.status_id = item.id;
                item.status_name = item.name;
                item.status_icon = item.icon;
                item.cnt = 0;
                let stat = this.statistics.statuses.find(i => i.status_id == item.id);
                if (stat) {
                    item.cnt = stat.cnt;
                }
            });

            return list;
        }
    },

    watch: {
        reload: function () {
            this.refresh();
        },
        periodId: function (newVal) {
            if (!this.loading) {
                this.refresh();
                localStorage.set("requests_summary_period", newVal);
            }
        },
    },

    methods: {

        /**
         * Подготовка запросов
         */
        getBatchRequests() {
            return {
                //просто список статусов
                statuses: {
                    ENTITY: "dictionary",
                    METHOD: "request.statuses.list",
                    PARAMS: {

                    },
                },

                //кол-во заявок по статусам
                statuses_stat: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: ["status_id", "cnt"],
                        filter: this.selectedPeriod.filter,
                        group: ["status_id"],
                        order: { "status.sort": "asc" },
                    },
                },
                new: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: ["date_create_date", "status_id", "cnt"],
                        filter: this.selectedPeriod.filter,
                        group: ["date_create_date"],
                        order: { date_create_date: "asc", "status.sort": "asc" },
                    },
                },
                //кол-во заявок по исполнителям
                countByResponsibleUsers: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "responsible_user_id",
                            1: "cnt",
                            responsible_user_name: "responsible_user.name",
                            responsible_user_last_name: "responsible_user.last_name",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!responsible_user_id": false,
                        },
                        group: ["responsible_user_id"],
                    },
                },
                //среднее время закрытия заявок по исполнителям
                closeTimeByResponsibleUsers: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "responsible_user_id",
                            1: "close_period_avg",
                            responsible_user_name: "responsible_user.name",
                            responsible_user_last_name: "responsible_user.last_name",
                        },
                        filter: {
                            0: this.selectedPeriod.filterClose,
                            "!date_close": false,
                            "!responsible_user_id": false,
                        },
                        group: ["responsible_user_id"],
                    },
                },
                //закрытые заявки по заказчикам
                closedByCreatedDepartments: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "created_department_id",
                            1: "cnt",
                            created_department_name: "created_department.name",
                        },
                        filter: {
                            0: this.selectedPeriod.filterClose,
                            "!date_close": false,
                        },
                        group: ["created_department_id"],
                    },
                },

                //динамика среднего времени закрытия по датам
                closeTimeByDates: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "cnt",
                            1: "close_period_avg",
                            2: "date_close_date",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!date_close": false,
                        },
                        group: ["date_close_date"],
                        order: {
                            date_close_date: "asc",
                        },
                    },
                },

                //отработанные часы по исполнителям
                workTimeByResponsiblesUsers: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "responsible_user_id",
                            1: "work_time_sum",
                            responsible_user_name: "responsible_user.name",
                            responsible_user_last_name: "responsible_user.last_name",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!responsible_user_id": false,
                            "!work_time.id": false,
                        },
                        group: ["responsible_user_id"],
                    },
                },

                //динамика рабочего времени по датам
                workTimeByDates: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "work_time_sum",
                            2: "work_time_date",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!work_time_date": false,
                        },
                        group: ["work_time_date"],
                        order: {
                            work_time_date: "asc",
                        },
                    },
                },

                //кол-во заявок по исполнителям
                countByEquipment: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "equipment_id",
                            1: "cnt",
                            equipment_name: "equipment.name",
                            equipment_short_name: "equipment.short_name.value",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!equipment_id": false,
                        },
                        limit: 10,
                        group: ["equipment_id", "equipment.name", "equipment.short_name.value"],
                        order: {
                            CNT: "desc",
                        },
                    },
                },

                //средняя оценка своего подразделения
                myDepartmentRating: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "responsible_department_id",
                            1: "rating_avg",
                            responsible_department_name: "responsible_department.name",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "responsible_department_id": "MY_DEPARTMENT",
                            ">rating": 0
                        },
                        limit: 10,
                        group: ["responsible_department_id"],
                    },
                },

                //средняя оценка по исполнителям
                responsibleUsersRating: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "responsible_user_id",
                            1: "rating_avg",
                            2: "cnt",
                            responsible_user_name: "responsible_user.name",
                            responsible_user_last_name: "responsible_user.last_name",
                            responsible_user_photo: "responsible_user.personal_photo"
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!responsible_user_id": false,
                            ">rating": 0
                        },
                        limit: 10,
                        group: ["responsible_user_id"],
                        order: { "rating_avg": "desc" }
                    },
                },
            };
        },

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            //покажем экран загрузки
            this.loading = !resolve ? true : false;

            //запрашиваем данные
            rest
                .batch(this.getBatchRequests())
                .then((arResults) => {
                    //обработка результатов
                    this.processResponse(arResults);
                })
                .catch(() => {
                    //в тихом режиме не обрабатваем ошибку
                })
                .finally(() => {
                    //скроем экран загрузки
                    this.loading = false;
                    this.loaded = true;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Обработка результата
         */
        processResponse(results) {
            for (var key in results) {
                let data = results[key];

                if (data.success) {
                    if (key == "statuses") {
                        this.requestsStatuses = data.items;
                    } else if (key == "statuses_stat") {
                        this.statistics.statuses = data.items;
                    } else if (key == "new") {
                        this.statistics.newByDates = data.items;
                    } else if (key == "countByResponsibleUsers") {
                        this.statistics.countByResponsibleUsers = data.items;
                    } else if (key == "closeTimeByResponsibleUsers") {
                        this.statistics.closeTimeByResponsibleUsers = data.items;
                    } else if (key == "closedByCreatedDepartments") {
                        this.statistics.closedByCreatedDepartments = data.items;
                    } else if (key == "closeTimeByDates") {
                        this.statistics.closeTimeByDates = data.items;
                    } else if (key == "workTimeByResponsiblesUsers") {
                        this.statistics.workTimeByResponsiblesUsers = data.items;
                    } else if (key == "workTimeByDates") {
                        this.statistics.workTimeByDates = data.items;
                    } else if (key == "countByEquipment") {
                        this.statistics.countByEquipment = data.items;
                    } else if (key == "myDepartmentRating") {
                        this.statistics.myDepartmentRating = data.items.length > 0 ? data.items[0] : false
                    } else if (key == "responsibleUsersRating") {
                        this.statistics.responsibleUsersRating = data.items;
                    }

                } else {
                    //показываем сообщение об ошибке
                    bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    return;
                }
            }
        },

        refresh(resolve) {
            this.loadData(resolve);
        },


        /**
         * Обработка обновления заявок
         */
        onRequestsChange() {
            this.loadData();
        }
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;

        //пытаемся восстановить кол-во дней
        localStorage.get("requests_summary_period").then((value) => {
            let p = this.periods.find((p) => p.id == value);
            if (p) {
                this.periodId = p.id;
            }
            //загружаем данные
            this.loadData();
        });
    },

    mounted() {
        //подписваемся на события
        bus.on("SBS_REQUEST_FORM_E_SAVE", this.onRequestsChange);
        bus.on("SBS_REQUEST_E_UPDATED", this.onRequestsChange);
    },

    unmounted() {
        //отписываемся от событий
        bus.off("SBS_REQUEST_FORM_E_SAVE", this.onRequestsChange);
        bus.off("SBS_REQUEST_E_UPDATED", this.onRequestsChange);
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },
};
</script>


<style lang="scss">
.sbs-v-requests-summary {

    .main-panel {
        padding: 20px;
        padding-top: calc(var(--safe-area-top) + 75px);

        background: linear-gradient(180deg, var(--requests-requests-gradient-color-4) 0%, var(--requests-requests-gradient-color-5) 100%);
        border-radius: 0px 0px 20px 20px;
        margin-bottom: 10px;

        .period {

            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            column-gap: 10px;
            margin-bottom: 15px;

            .title {
                color: var(--audit-cards-audit-cards-title-text-color, #FFF);
            }
        }
    }

    .dashboard {
        padding-bottom: 80px;
    }


}
</style>
