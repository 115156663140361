<template>
  <sbs-view :class="'sbs-v-user-view'" :isMenuHidden="hideMenu" @refresh="refresh">

    <template v-slot:header>
      <sbs-header>

      </sbs-header>
    </template>

    <template v-slot:content>

      <template v-if="loading">
        <div class="text-center">
          <sbs-loader-inline />
        </div>
      </template>

      <template v-if="!loading">

        <!-- Не найден-->
        <template v-if="user == null">
          {{ $t("users.view.not_found") }}
        </template>

        <!-- Иначе -->
        <template v-else>

          <div class="user">
            <div>
              <div class="photo">
                <div v-if="user.photo" class="picture" :style="{ backgroundImage: 'url(' + user.photo.preview + ')' }"
                  @click="openPhoto"></div>
                <span v-else class="icon fi fi-user"></span>
              </div>
            </div>
            <div>
              <div class="fio nunito-5-bold">{{ user.last_name }} {{ user.name }}</div>
              <div v-if="user.work_position" class="position nunito-3-regular">
                {{ user.work_position }}
              </div>
              <div v-if="user.department_name" class="department nunito-2-regular">
                {{ user.department_name }}
              </div>

            </div>
          </div>

          <template v-if="props">
            <sbs-props :props="props" />
            <br />
          </template>

          <sbs-requests-statuses :items="requests_created" :prefilter="prefilterOwn"
            :title="$t('users.view.requests_created')" titleClass="h2" />

          <sbs-requests-statuses :items="requests_reponsible" :prefilter="prefilterResponsible"
            :title="$t('users.view.requests_reponsible')" titleClass="h2" />

        </template>

      </template>

    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsRequestsStatuses from "@/components/requests/statuses.vue";
import sbsProps from "@/components/props.vue";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import utils from "@/plugins/utils";

export default {
  name: "sbs-v-user-view",
  components: {
    sbsView,
    sbsHeader,
    sbsLoaderInline,
    sbsRequestsStatuses,
    sbsProps
  },
  data() {
    return {
      reload: 0,
      hideMenu: false,

      loading: true,
      user: null,
      requests_created: [],
      requests_reponsible: [],
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {

    props() {
      let list = [];

      //рабочий телефон
      if (this.user.work_phone) {
        list.push({
          type: "phone",
          name: this.$t('users.view.work_phone'),
          value: this.user.work_phone
        });
      }

      //мобильный телефон
      if (this.user.personal_mobile) {
        list.push({
          type: "phone",
          name: this.$t('users.view.personal_mobile'),
          value: this.user.personal_mobile
        });
      }

      //email
      if (this.user.email) {
        list.push({
          type: "email",
          name: this.$t('users.view.email'),
          value: this.user.email
        });
      }



      return list;
    },


    gallery() {
      let images = [];
      if (this.user.photo) {
        images.push({
          src: this.user.photo.full
            ? this.user.photo.full
            : this.user.photo.src,
          type: this.user.photo.image ? "image" : "video",
          width: this.user.photo.width,
          height: this.user.photo.height,
          thumb: this.user.photo.preview
            ? this.user.photo.preview
            : this.user.photo.src,
          id: this.user.photo.id,
        });
      }

      return images;
    },

    prefilterOwn() {
      if (this.user == null) {
        return {};
      }
      return {
        createdBy: [
          {
            id: this.user.id,
            name: this.user.name,
            last_name: this.user.last_name,
          },
        ],
      };
    },
    prefilterResponsible() {
      if (this.user == null) {
        return {};
      }
      return {
        responsibleUser: [
          {
            id: this.user.id,
            name: this.user.name,
            last_name: this.user.last_name,
          },
        ],
      };
    },
  },

  watch: {
    id: function () {
      this.loadData();
    },
  },

  methods: {
    refresh(resolve) {
      this.loadData(resolve);
    },

    /**
     * Подготовка запросов
     */
    getBatchRequests() {
      return {
        user: {
          ENTITY: "users",
          METHOD: "get",
          PARAMS: {
            id: this.id,
          },
        },
        requests_created: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: ["status_id", "cnt"],
            group: ["status_id"],
            order: { "status.sort": "asc" },
            filter: {
              created_by: this.id,
            },
          },
        },
        requests_reponsible: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: ["status_id", "cnt"],
            group: ["status_id"],
            order: { "status.sort": "asc" },
            filter: {
              responsible_user_id: this.id,
            },
          },
        },
      };
    },

    /**
     * Загружает данные
     */
    loadData(resolve = false) {
      //покажем экран загрузки
      this.loading = true;
      this.user = null;

      //запрашиваем данные
      rest
        .batch(this.getBatchRequests())
        .then((arResults) => {
          //обработка результатов
          this.processResponse(arResults);
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
          if (resolve) {
            resolve();
          }
        });
    },

    /**
     * Обработка результата
     */
    processResponse(results) {
      for (var key in results) {
        let data = results[key];

        if (data.success) {
          if (key == "user") {
            this.user = data.user;
          } else if (key == "requests_created") {
            this.requests_created = data.items;
          } else if (key == "requests_reponsible") {
            this.requests_reponsible = data.items;
          }
        } else {
          //показываем сообщение об ошибке
          bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          return;
        }
      }
    },

    /**
     * Открыть фото на просмотр
     */
    openPhoto() {
      Fancybox.show(this.gallery, {
        startIndex: 0,
        infinite: false,
        Toolbar: {
          display: [
            { id: "prev", position: "center" },
            { id: "counter", position: "center" },
            { id: "next", position: "center" },
            "zoom",
            "slideshow",
            "thumbs",
            "close",
          ],
        },
        Hash: false,
        on: {
          ready: (fancybox) => {
            utils.fancyBoxOnReady(fancybox);
          },
          closing: () => {
            utils.fancyBoxOnClose();
          },
        },
      });
    },
  },
  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
    //загружаем данные
    this.loadData();
  },
};
</script>

<style lang="scss">
.sbs-v-user-view {

  .user {
    padding: 10px 10px;
    display: grid;
    grid-template-columns: 70px auto;
    column-gap: 15px;
    align-items: center;

    font-family: Nunito;

    margin-bottom: 30px;

    .photo {
      position: relative;
      width: 70px;
      height: 70px;
      border-radius: 50%;

      .picture {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-size: cover;
        border-radius: 50%;
      }

      .icon {
        font-size: 29px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--news-card-news-card-secondary-text-color);
      }
    }

    .fio {
      color: var(--audit-cards-audit-cards-title-text-color);
    }

    .position {
      color: var(--audit-cards-audit-cards-title-text-color);
    }

    .department {
      margin-top: 5px;
      color: var(--news-card-news-card-secondary-text-color);
    }
  }
}
</style>