<template>
    <div class="sbs-equipment-widget" :class="{ open: open }">
        <div class="wrap">
            <div class="container">
                <div class="outer">
                    <div class="inner">
                        <div class="btn-widget" @click="onBtnClick">
                            <span class="icon icon-add fi fi-search"></span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <sbs-control-select-sheet v-model:visible="open" :title="$t('equipment.widget.title')" v-model="value" :list="list"
        :showCheck="false" :showButtons="false" />
</template>


<script>
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";
import { NotFoundError } from '@/plugins/errors.js'

import { mapGetters } from "vuex";

export default {
    name: "sbs-equipment-widget",
    components: {
        sbsControlSelectSheet
    },
    data() {
        return {
            open: false,
            value: false,

            list: [
                {
                    id: "qr",
                    name: this.$t('equipment.widget.qr'),
                    icon: "fi-qr-scanner",
                    action: this.openQrScanner
                },
                {
                    id: "nfc",
                    name: this.$t('equipment.widget.nfc'),
                    icon: "fi-nfc",
                    action: this.openNfcScanner
                }
            ]
        };
    },

    computed: {
        ...mapGetters({
            access: "getAccess"
        }),

        permissions() {
            if (!this.access || !this.access.permissions.equipment) {
                return [];
            }

            return this.access.permissions.equipment;
        },


        /**
         * Доступность кнопки добавления
         */
        canAdd() {
            if (!this.access) {
                return false;
            }

            if (this.permissions.includes('MANAGE_ALL') ||
                this.permissions.includes('MANAGE_OWN_DEPARTMENT') ||
                this.permissions.includes('MANAGE_SELECTED_DEPARTMENTS')) {
                return true;
            }

            return false;

        }
    },

    watch: {

        value: function () {
            if (this.value && !!this.value.id) {

                setTimeout(() => {
                    this.value.action();
                    this.value = false;
                }, 400);

            }
        },
    },

    methods: {
        /**
         * Клик по кнопке
         */
        onBtnClick() {
            this.open = !this.open;
        },

        /**
         * Переход к Сканеру QR
         */
        openQrScanner() {
            this.open = false;

            let vm = this;
            bus.emit("SBS_QR_SCANNER_E_OPEN", {
                mode: "SIMPLE",
                resolve: function (code) {

                    //пробуем найти оборудование
                    vm.loadEquipment({
                        PROPERTY_QR_CODE: code,
                    }).then((item) => {
                        //переход к просмотру
                        vm.goToEquipmentView(item);
                    }).catch(error => {
                        //если не найдено
                        if (error instanceof NotFoundError) {
                            //если есть право добавления
                            if (vm.canAdd) {
                                vm.confirmCreateIfNotFound({ qr_code: code });
                            } else {
                                bus.emit("SBS_MESSAGE_E_SHOW", {
                                    message: this.$t('equipment.widget.qr_not_found'),
                                });
                            }

                        }
                    })

                },
            });
        },

        /**
         * Переход к сканеру NFC
         */
        openNfcScanner() {

            this.open = false;

            let vm = this;
            bus.emit("SBS_NFC_SCANNER_E_OPEN", {
                mode: "SIMPLE",
                resolve: function (code) {

                    //пробуем найти оборудование
                    vm.loadEquipment({
                        PROPERTY_NFC_ID: code,
                    }).then((item) => {
                        //переход к просмотру
                        vm.goToEquipmentView(item);
                    }).catch(error => {
                        //если не найдено
                        if (error instanceof NotFoundError) {
                            //если есть право добавления
                            if (vm.canAdd) {
                                vm.confirmCreateIfNotFound({ nfc_id: code });
                            } else {
                                bus.emit("SBS_MESSAGE_E_SHOW", {
                                    message: this.$t('equipment.widget.nfc_not_found'),
                                });
                            }

                        }
                    })

                },
            });
        },

        /**
         * Переход к просмотру оборудования
         */
        goToEquipmentView(item) {

            //даём время чтобы возврат назад по  истории брузера прошёл
            setTimeout(() => {
                this.$router.push({
                    name: "equipment-view",
                    params: { id: item.id },
                });

            }, 800);
        },

        /**
         * Загрузка оборудования по фильтру
         */
        loadEquipment(filter) {

            return new Promise((resolve, reject) => {

                rest
                    .call("equipment.list", {
                        method: "post",
                        data: {
                            filter: filter,
                        },
                    })
                    .then((data) => {
                        if (data.success) {
                            if (data.items.length > 0) {
                                let item = data.items[0];
                                resolve(item);
                            } else {
                                reject(new NotFoundError("Not found"));
                            }
                        } else {
                            //показываем сообщение об ошибке
                            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                            reject(false);
                        }

                    });

            });
        },

        /**
         * Запрос добавления оборудования если не найдено
         */
        confirmCreateIfNotFound(data) {

            bus.emit("SBS_CONFIRM_E_SHOW", {
                message: this.$t('equipment.widget.add_message'),
                resolveText: this.$t('common.add'),
                rejectText: this.$t('common.cancel'),
                resolve: function (value) {

                    if (value) {
                        this.$store.commit("setTempData", { name: "equipment-form-data", value: data });
                        //переход на экран добавления заявки
                        this.$router.push({ name: "equipment-form", params: { id: 0 } });
                    }

                },
            });

        }


    },
};
</script>

<style lang="scss">
.sbs-equipment-widget {
    .wrap {
        position: absolute;
        bottom: 80px;
        right: 0px;
        left: 0px;
        z-index: 150;

        .outer {
            position: relative;
        }

        .inner {
            position: absolute;
            right: 0px;
            bottom: 0px;
        }
    }

    .btn-widget {
        position: relative;

        width: 48px;
        height: 48px;
        border-radius: 15px;
        background: var(--service-color-1);
        box-shadow: 0px 8px 8px 0px var(--shadow-secondary);

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
        }

    }
}
</style>