<template>
    <sbs-sheet v-model:visible="visibleInternal">
        <template v-slot:body>

            <div class="sbs-equipment-filter">

                <div class="sheet-title">{{ $t('common.filter') }}</div>

                <template v-if="loading">
                    <sbs-loader-inline />
                </template>

                <template v-else>

                    <div class="filters">
                        <sbs-control-filter :label="$t('equipment.filter.department')"
                            method="dictionary.departments.list" v-model="filter.department_id"
                            :params="{ filter: [{ active: 'Y' }] }" />

                        <sbs-control-filter :label="$t('equipment.filter.type')"
                            method="dictionary.equipment.types.list" v-model="filter.type_id" />

                        <template v-for="field in fields" :key="field.id">
                            <sbs-control-filter v-if="field.stat_items.length > 0" :label="field.name"
                                :items="field.stat_items" v-model="filter['entity.' + field.code]" />
                        </template>

                    </div>

                    <div class="buttons">
                        <div class="btn btn-block btn2" @click="clear">{{ $t('common.clear') }}</div>
                        <div class="btn btn-block btn1" @click="apply">{{ $t('common.apply') }}</div>
                    </div>

                </template>

            </div>
        </template>
    </sbs-sheet>
</template>
<script>
import sbsSheet from '@/components/sheet.vue'
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlFilter from "@/components/controls/filter";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-equipment-filter",
    components: {
        sbsSheet,
        sbsLoaderInline,
        sbsControlFilter,
    },
    emits: ["update:visible"],
    data() {
        return {
            visibleInternal: this.visible,

            loading: false,
            loaded: false,
            fields: [],
            filter: {},
            reset: 0
        };
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    computed: {
        globalFilter() {
            return this.$store.getters.getFilter("EQUIPMENT");
        },
    },

    watch: {
        visibleInternal: function (newVal) {
            if (newVal != this.visible) {
                this.$emit("update:visible", newVal);
            }

        },
        visible: function (newVal) {
            if (newVal != this.visibleInternal) {
                this.visibleInternal = newVal;
            }
            if (newVal) {
                if (!this.loaded) {
                    this.loadData();
                }
                this.setDefaults();
                this.setFromGlobalFilter();
            }
        },

        fields: function () {
            this.setDefaultsFields();
        }
    },

    methods: {

        getBatchRequests() {
            return {
                fields: {
                    ENTITY: "equipment",
                    METHOD: "field.statistics",
                    PARAMS: {
                    },
                }
            };

        },

        /**
         * Загрузка данных
         */
        loadData() {

            this.loading = true;

            rest.batch(this.getBatchRequests())
                .then((data) => {
                    this.processResponse(data);
                })
                .finally(() => {
                    this.loading = false;
                    this.loaded = true;
                });

        },

        /**
         * Обработка результата загрузки
         */
        processResponse(results) {

            for (var key in results) {
                let data = results[key];

                if (data.success) {
                    if (key == "fields") {
                        this.fields = data.fields;
                    }
                } else {
                    //показываем сообщение об ошибке
                    bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    return;
                }
            }
        },

        setDefaults() {
            this.filter = {
                department_id: [],
                type_id: [],
            };
            this.setDefaultsFields();
        },

        setDefaultsFields() {

            this.fields.forEach(field => {
                this.filter["entity." + field.code] = []
            });
        },

        apply() {
            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", {
                name: "EQUIPMENT",
                value: this.filter
            });

            this.visibleInternal = false;
        },

        /**
         * Сбросить фильтр
         */
        clear() {
            //сбрасываем фильтр
            this.setDefaults();
            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", {
                name: "EQUIPMENT",
                value: this.filter
            });

            this.visibleInternal = false;
        },

        setFromGlobalFilter() {
            for (let code in this.filter) {
                if (this.globalFilter[code]) {
                    this.filter[code] = this.globalFilter[code];
                } else {
                    this.filter[code] = this.filter[code] instanceof Array ? [] : "";
                }
            }
        },
    },

};
</script>

<style lang="scss">
.sbs-equipment-filter {
    position: relative;
    padding-bottom: 80px;

    .buttons {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 15px 20px calc(var(--keyboard-height, 0px) + var(--safe-area-bottom, 0px) + 20px);

        z-index: 10;
        background-color: var(--bg);

        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 10px;
    }
}
</style>