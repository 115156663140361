<template>
  <div class="sbs-user-view-menu" v-if="user" @click="openProfile">
    <div class="picture">
      <template v-if="this.user.photo && this.user.photo.src">
        <div class="picture" :style="{
    backgroundImage: 'url(' + this.user.photo.preview + ')',
  }"></div>
      </template>
      <template v-else>
        <span class="icon fi fi-user-circle"></span>
      </template>
    </div>
    <div class="fio nunito-6-bold">
      {{ user.name }} {{ user.last_name }}

      <div v-if="rating > 0" class="rating-wrap">
        <div class="rating" @click.stop="goToRatingRequests">

          <div>
            <span class="icon fi fi-star-filled"></span>
          </div>
          <div class="value">{{ displayRating }}</div>
        </div>
      </div>
    </div>
    <div class="server nunito-2-bold">{{ server }}</div>
  </div>
</template>

<script>
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
  name: "sbs-user-view-menu",
  components: {},
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      server: "getServer",
      user: "getUserProfile",
      rating: "getRequestRating"
    }),

    displayRating() {
      return Math.round(this.rating * 10) / 10;
    }
  },

  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;

      //запрашиваем данные
      rest
        .call("user.profile.get", {}, false)
        .then((data) => {
          if (data.success) {
            this.user = data.fields;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Переход к профилю
     */
    openProfile() {
      this.$router.push({ name: "profile" });
    },

    goToRatingRequests() {

      //сохраняем Фильтр в текущем состоянии
      this.$store.commit("setFilter", {
        name: "REQUEST-common",
        value: {
          rating: [
            {
              id: 1,
              name: "1"
            },
            {
              id: 2,
              name: "2"
            },
            {
              id: 3,
              name: "3"
            },
            {
              id: 4,
              name: "4"
            },
            {
              id: 5,
              name: "5"
            }
          ],
          responsibleUser: [
            {
              id: this.user.id,
              last_name: this.user.last_name,
              name: this.user.name
            }
          ]
        }
      });

      //меняем вкладку на экране заявок
      this.$store.commit("setRequestsTab", "all");

      this.$router.push({ name: "requests" });

    }
  },
};
</script>

<style lang="scss">
.sbs-user-view-menu {
  position: relative;
  padding: 40px 0px 40px 90px;

  &.stub {
    -webkit-filter: blur(3px);
    filter: blur(4px);
  }

  .picture {
    position: absolute;
    top: 50%;
    left: 0px;
    width: 60px;
    height: 60px;
    transform: translateY(-50%);

    .icon {
      font-size: 60px;
      color: var(--service-color-1);
    }

    .picture {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-size: cover;
    }
  }

  .fio {
    display: inline-block;
    position: relative;

    color: var(--audit-cards-audit-cards-title-text-color);
  }

  .rating-wrap {
    position: relative;
    top: -10px;
    display: inline-block;
  }

  .rating {


    display: flex;
    gap: 5px;
    font-size: 12px;
    padding: 5px;

    .icon {
      color: var(--service-color-1);
    }
  }

  .server {
    color: var(--news-card-news-card-secondary-text-color);
  }
}
</style>