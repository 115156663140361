<template>
  <div class="sbs-control-filter" v-ripple.300 @click="onClick">
    <div class="label nunito-4-regular">
      {{ this.label }}
      <div class="icon">
        <span class="fi fi-arrow-right"></span>
      </div>
    </div>
    <TransitionGroup v-if="values.length > 0" class="values" name="values" tag="div">
      <div v-for="value in values" :key="value.id" class="value nunito-4-regular" @touchstart.stop="void (0)"
        @click.stop="removeValue(value.id)">
        <div>
          {{ formatName(value) }}
        </div>
        <div>
          <span class="icon fi fi-close"></span>
        </div>
      </div>
    </TransitionGroup>

    <sbs-control-select-sheet v-model:visible="showDialog" :title="label" :multiple="true" v-model:modelValues="values"
      :list="items" :method="method" :params="params" />

  </div>
</template>


<script>
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";

export default {
  name: "sbs-control-filter",
  components: {
    sbsControlSelectSheet,
  },
  data() {
    return {
      values: this.modelValue,
      showDialog: false,
      blocking: false
    };
  },
  emits: ["update:modelValue"],
  props: {
    label: {
      type: String,
      default() {
        return "Метка";
      },
    },

    name: {
      type: String,
      default() {
        return "";
      },
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },

    method: {
      type: String,
      default() {
        return "";
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  watch: {
    modelValue: function (newVal) {
      this.values = newVal;
    },

    values: function () {
      this.$emit("update:modelValue", this.values);
    },
  },
  methods: {
    /**
     * Клик по фильтру
     */
    onClick() {
      if (this.blocking) {
        return;
      }
      this.blocking = true;

      setTimeout(() => {

        this.showDialog = true;
        this.blocking = false;

      }, 500);

    },

    removeValue(id) {
      this.values = this.values.filter((item) => item.id != id);
    },

    formatName(item) {
      if (this.method == "users.list") {
        return item.last_name + " " + item.name;
      }
      return item.name;
    },
  },
};
</script>

<style lang="scss">
.sbs-control-filter {

  padding: 20px 20px;

  border-radius: 15px;
  background: var(--audit-cards-audit-cards-bg-color);
  box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
  margin-bottom: 10px;
  cursor: pointer;

  .label {
    position: relative;

    color: var(--text-field-text-field-body-color-text);

    padding-right: 50px;

    .icon {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      color: var(--service-color-1);
    }
  }

  .values-enter-active,
  .values-leave-active {
    transition: all 0.5s ease;
  }

  .values-enter-from,
  .values-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .values {
    margin-top: 10px;

    display: flex;
    gap: 5px 5px;
    flex-wrap: wrap;
    align-items: center;

    .value {
      display: flex;
      align-items: center;
      column-gap: 4px;
      text-transform: none;
      padding: 8px 15px 8px 15px;
      border-radius: 20px;
      background: var(--tag-tag-bg-orange-color);
      color: var(--tag-tag-text-orange-color);

      text-align: left;

      .icon {
        font-size: 10px;
      }
    }
  }
}
</style>
