<template>
  <div class="sbs-request-view-actions">

    <sbs-control-select-sheet v-model:visible="display" :title="this.$t('requests.view.actions.title')" v-model="action"
      :list="actions" :showCheck="false" :showButtons="false" />

    <sbs-sheet v-model:visible="displayNfc">
      <template v-slot:body>

        {{ $t('requests.view.actions.start_need_nfc') }}
        <br /><br />
        <div class="btn btn-block btn1" @click="scanNfc"> {{ $t('requests.view.actions.scan_nfc') }}
        </div>
      </template>

    </sbs-sheet>

    <sbs-request-rate v-model:visible="displayRate" :id="id" />
    <sbs-request-stop v-model:visible="displayStop" :id="id" />
  </div>
</template>

<script>
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";
import sbsSheet from '@/components/sheet.vue'
import sbsRequestRate from "@/components/requests/rate.vue";
import sbsRequestStop from "@/components/requests/stop.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-request-view-actions",
  components: {
    sbsControlSelectSheet,
    sbsSheet,
    sbsRequestRate,
    sbsRequestStop
  },
  emits: ["update:show"],
  data() {
    return {
      loading: false,
      action: false,
      display: this.show,
      displayNfc: false,
      displayRate: false,
      displayStop: false,
      subscribedInternal: this.subscribed
    };
  },
  props: {
    id: {
      type: [Number, String],
      default() {
        return 0;
      },
    },
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    needNfcStart: {
      type: Boolean,
      default() {
        return false;
      },
    },
    permissions: {
      type: Array,
      default() {
        return [];
      },
    },
    checklist: {
      type: Array,
      default() {
        return [];
      },
    },
    equipmentId: {
      type: String,
      default() {
        return "";
      },
    },
    reportRequired: {
      type: String,
      default() {
        return "N";
      },
    },
    subscribed: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    checklistComplited() {
      return !(this.checklist.filter((i) => i.status == "N").length > 0);
    },

    actions() {
      let list = [];

      //Редактировать
      if (this.permissions.includes('edit')) {

        list.push({
          id: "edit",
          name: this.$t('requests.view.actions.edit'),
          icon: "fi-edit",
          action: this.edit
        });
      }

      //Назначить себе
      if (this.permissions.includes('take')) {

        list.push({
          id: "take",
          name: this.$t('requests.view.actions.take'),
          icon: "fi-add-outline",
          action: this.take
        });
      }

      //Делегировать
      if (this.permissions.includes('delegate')) {

        list.push({
          id: "delegate",
          name: this.$t('requests.view.actions.delegate'),
          icon: "fi-sound-outline",
          action: this.delegate
        });
      }

      //Начать выполнение
      if (this.permissions.includes('start')) {

        list.push({
          id: "start",
          name: this.$t('requests.view.actions.start'),
          icon: "fi-play-outline",
          action: this.start
        });
      }

      //Приостановить
      if (this.permissions.includes('stop')) {

        list.push({
          id: "stop",
          name: this.$t('requests.view.actions.stop'),
          icon: "fi-pause-outline",
          action: this.openStop
        });
      }

      //Завершить
      if (this.permissions.includes('complete')) {

        list.push({
          id: "complete",
          name: this.$t('requests.view.actions.complete'),
          icon: "fi-stop-outline",
          action: this.complete
        });
      }

      //Вернуть в работу
      if (this.permissions.includes('reopen')) {

        list.push({
          id: "reopen",
          name: this.$t('requests.view.actions.reopen'),
          icon: "fi-refresh",
          action: this.reopen
        });
      }

      //Оценить
      if (this.permissions.includes('rate')) {

        list.push({
          id: "rate",
          name: this.$t('requests.view.actions.rate'),
          icon: "fi-star",
          action: this.openRate
        });
      }

      //Подписаться
      if (!this.subscribedInternal) {

        list.push({
          id: "subscribe",
          name: this.$t('requests.view.actions.subscribe'),
          icon: "fi-notifications",
          action: this.subscribe
        });
      }

      //Отписаться
      if (this.subscribedInternal) {

        list.push({
          id: "unsubscribe",
          name: this.$t('requests.view.actions.unsubscribe'),
          icon: "fi-notifications-off",
          action: this.unSubscribe
        });
      }


      return list;
    }
  },
  watch: {
    show: function (newVal) {
      this.display = newVal;
    },
    display: function (newVal) {
      this.$emit("update:show", newVal);
    },

    action: function () {
      if (this.action && !!this.action.id) {

        setTimeout(() => {
          this.action.action();
          this.action = false;
        }, 400);

      }
    },
  },

  methods: {
    close() {
      this.displayNfc = false;
      this.$emit("update:show", false);
    },

    /**
     * Редактировать
     */
    edit() {
      this.close();
      this.$router.push({ name: "request-form", params: { id: this.id } });
    },

    /**
     * Назначить себе
     */
    take() {
      this.loading = true;

      //выполняем запрос
      rest
        .call("request.take", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Начать выполнение
     */
    start() {
      if (!this.needNfcStart) {
        this.startImmediatly();
      } else {
        this.$emit("update:show", false);
        this.displayNfc = true;
      }
    },

    /**
     * Сканировать NFC метку
     */
    scanNfc() {
      let vm = this;
      this.displayNfc = false;

      setTimeout(() => {
        bus.emit("SBS_NFC_SCANNER_E_OPEN", {
          resolve: function (item) {
            if (item.id == vm.equipmentId) {
              vm.startImmediatly();
            } else {
              bus.emit("SBS_MESSAGE_E_SHOW", {
                message:
                  "Метка не закрепленна за оборудованием по данной заявке.",
              });
            }
          },
        });

      }, 400);

    },

    /**
     * Начать выполнение
     */
    startImmediatly() {
      this.loading = true;

      //выполняем запрос
      rest
        .call("request.start", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Открывает попап остановки задачи
     */
    openStop() {
      this.close();
      this.displayStop = true;
    },

    /**
     * Завершить
     */
    complete() {

      //если чек-лист не пройден
      if (!this.checklistComplited) {
        this.close();

        this.$router.push({
          name: "request-checklist-execute",
          params: { id: this.id },
        });
        return;
      }

      //если требуется отчёт по завершении
      if (this.reportRequired == "Y") {
        this.close();

        this.$router.push({
          name: "request-report-execute",
          params: { id: this.id },
        });

        return;
      }
      this.loading = true;

      //выполняем запрос
      rest
        .call("request.complete", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Делегировать
     */
    delegate() {
      this.close();

      this.$router.push({
        name: "request-change-responsible",
        params: { id: this.id },
      });
    },

    /**
     * Подписаться
     */
    subscribe() {

      this.loading = true;

      //выполняем запрос
      rest
        .call("request.subscribe", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            this.subscribedInternal = true;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Отписаться
     */
    unSubscribe() {

      this.loading = true;

      //выполняем запрос
      rest
        .call("request.unsubscribe", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            this.subscribedInternal = false;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Возобновить
     */
    reopen() {

      this.loading = true;

      //выполняем запрос
      rest
        .call("request.reopen", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Открыть форму оценки заявки
     */
    openRate() {

      this.close();
      this.displayRate = true;
    },

  },
};
</script>

<style lang="scss">
.sbs-request-action-rate {
  padding: 50px 25px;

}
</style>