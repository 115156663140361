<template>
    <div class="sbs-edu-training-statuses">



        <template v-for="item in list" :key="item.result_status">
            <div class="item" v-ripple.300 @click="onItemClick(item)">
                <div class="name nunito-2-bold">{{ item.name }}</div>

                <div class="cnti">
                    <div>
                        <span class="icon fi" :class="item.icon"></span>
                    </div>
                    <div>
                        <div class="cnt nunito-6-bold">
                            {{ item.cnt >= 10000 ? "+9999" : item.cnt }}
                        </div>
                    </div>
                </div>

            </div>
        </template>



    </div>
</template>

<script>
export default {
    name: "sbs-edu-training-statuses",
    components: {},
    data() {
        return {
            blocked: false
        };
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        prefilter: {
            type: Object,
            default() {
                return {};
            },
        },

    },
    computed: {
        list() {
            let list = [];

            ["N", "P", "S"].forEach(s => {
                let item = this.items.find(i => i.result_status == s);
                if (!item) {
                    item = {
                        result_status: s,
                        cnt: 0
                    };
                }

                let icon = "fi-new";
                if (item.result_status == 'P') {
                    icon = "fi-process";
                } else if (item.result_status == 'S') {
                    icon = "fi-done";
                }



                list.push({
                    result_status: item.result_status,
                    cnt: item.cnt,
                    icon: icon,
                    name: this.$t("education.trainings.statuses." + item.result_status)
                });
            })

            return list;
        }
    },
    methods: {
        onItemClick(item) {
            if (this.blocked) {
                return;
            }

            this.blocked = true;

            setTimeout(() => {
                this.blocked = false;
                this.$router.push({ name: "education-training-my", params: { status: item.result_status } });
            }, 400);

        },
    },
};
</script>


<style lang="scss">
.sbs-edu-training-statuses {
    display: grid;
    grid-template-columns: 0.33fr 0.33fr 0.33fr;
    column-gap: 5px;
    margin-bottom: 15px;

    .item {
        padding: 12px 10px;
        margin-bottom: 5px;
        cursor: pointer;

        border-radius: 10px;
        background: var(--training-item-training-item-standard-color);
        box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
        color: var(--audit-cards-audit-cards-title-text-color);

        .name {
            text-align: right;
        }

        .cnti {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 5px;
        }

        .icon {

            font-size: 20px;
            color: var(--service-color-1);
        }

        .cnt {
            text-align: right;
        }
    }
}
</style>
