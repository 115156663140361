<template>
  <sbs-view :class="'sbs-v-request-report-exec'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:center>
          <div class="title title-small">{{ $t('requests.report_exec.title') }}</div>
        </template>

        <template v-slot:right>
          <div class="action" v-ripple.300 @click="close">
            <span class="icon fi fi-close"></span>
          </div>
        </template>
      </sbs-header>
    </template>
    <template v-slot:content>

      <br /><br />
      <sbs-request-report-execute :id="id" :reload="reload" />
    </template>
    <template v-slot:footer>
      <div id="sbs-rrevb"></div>
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsRequestReportExecute from "@/components/requests/report.exec.vue";

export default {
  name: "sbs-v-request-report-exec",
  components: {
    sbsView,
    sbsHeader,
    sbsRequestReportExecute,
  },
  data() {
    return {
      reload: 0,
      hideMenu: false
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },

  methods: {
    /**
     * Закрывает экран
     */
    close() {
      //переход обратно
      this.$router.go(-1);
    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },
};
</script>
