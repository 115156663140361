<template>
  <div class="sbs-equipment-list" ref="container">
    <template v-if="items != null">

      <!-- Если список пуст-->
      <template v-if="items.length == 0">

        <!-- Если не применён фильтр -->
        <template v-if="!filtered">
          <sbs-empty :title="$t('equipment.list.empty.title')" :text="$t('equipment.list.empty.text')" />
        </template>

        <!-- Иначе применён фильтр -->
        <template v-else>
          <sbs-empty :title="$t('equipment.list.empty.search_title')" :text="$t('equipment.list.empty.search_text')" />
        </template>

      </template>

      <!-- Список -->
      <div v-else class="items">
        <sbs-equipment-item v-for="item in items" :key="item.id" :item="item" />
      </div>
    </template>
    <div ref="pager"></div>

    <!-- Загрузка -->
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEquipmentItem from "@/components/equipment/item.vue";
import sbsEmpty from "@/components/empty.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-equipment-list",
  components: {
    sbsLoaderInline,
    sbsEquipmentItem,
    sbsEmpty
  },
  data() {
    return {
      loading: true,
      items: null,
      pager: {
        size: 10,
        page: 1,
        cnt: 1,
      },
    };
  },
  props: {
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {

    filter() {
      return this.$store.getters.getFilter("EQUIPMENT");
    },

    filterPrepared() {
      let f = {};

      if (!this.filter || Object.keys(this.filter).length == 0) {
        return f;
      }

      for (let key in this.filter) {
        let value = this.filter[key];
        if (value instanceof Array) {
          if (value.length > 0) {
            f[key] = value.map((v) => v.id);
          }
        }

      }

      return f;
    },

    filtered() {
      return Object.keys(this.filterPrepared).length > 0;
    },
  },
  watch: {
    reload: function () {
      this.refresh();
    },
    filter: {
      handler() {
        if (this.$store.getters.isAuthorized) {
          this.refresh();
        }
      },
      deep: true,
    },
  },

  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;

      //запрашиваем данные
      rest
        .call("equipment.list", {
          method: "post",
          data: {
            select: ["request_cnt"],
            paging: this.pager,
            filter: this.filterPrepared,
          },
        })
        .then((data) => {
          if (data.success) {
            if (this.pager.page == 1) {
              this.items = data.items;
            } else {
              this.items = this.items.concat(data.items);
            }
            this.pager = data.pager;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Перезагружает список
     */
    refresh() {
      this.items = null;
      this.pager.page = 1;
      this.loadData();
    },

    /**
     * Переход к оборудованию
     */
    goToItem(item) {
      this.$router.push({
        name: "equipment-view",
        params: { id: item.id },
      });
    },

    onScroll(e) {
      let scroll = e.target.scrollTop;
      let height = e.target.offsetHeight;
      let offsetTop = this.$refs.pager.offsetTop;
      //если экран прокрутился до пейджера (высоту экрана за вычетом 70px под меню которые заререзрвированы)
      if (scroll + height > offsetTop) {
        //если экран не в стадии загрузки и есть следующие страницы
        if (!this.loading && this.pager.page < this.pager.cnt) {
          this.pager.page++;
          this.loadData();
        }
      }
    },


    /**
     * Обработка события сохранения оборудования
     */
    onSave(data) {

      let item = this.items.find((i) => i.id == data.id);

      //если в списке отображения уже есть
      if (item) {
        this.syncItem(item);
      } else {
        this.refresh();
      }
    },

    /**
     * Синхронизация
     */
    syncItem(item) {
      //запрашиваем данные
      rest
        .call("equipment.list", {
          method: "post",
          data: {
            filter: {
              id: item.id,
            },
          },
        })
        .then((data) => {
          let index = this.items.indexOf(item);
          if (data.success) {
            this.items[index] = data.items[0];
          }
        });
    },
  },

  created() {
    //загружаем данные
    this.loadData();
  },

  mounted() {
    //подписываемся на скролинг экрана
    this.$refs.container
      .closest(".view-content-scroll")
      .addEventListener("scroll", this.onScroll);

    bus.on("SBS_EQUIPMENT_E_SAVE", this.onSave);
  },

  beforeUnmount() {
    this.$refs.container
      .closest(".view-content-scroll")
      .removeEventListener("scroll", this.onScroll);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_EQUIPMENT_E_SAVE", this.onSave);
  },
};
</script>
