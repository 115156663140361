<template>
  <div class="sbs-user-profile">
    <!-- Загрузка -->
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>
    <template v-else>
      <!-- Не найдена-->
      <div v-if="user == null">{{ $t('user.profile.not_found') }}</div>
      <div v-else>
        <!-- Действия в шапке-->
        <sbs-teleport-wrapper v-if="changed" to="#profile-view-header-right">
          <div class="action" v-ripple.300 @click="save">
            <span class="icon fi fi-save"></span>
          </div>
        </sbs-teleport-wrapper>

        <div class="photo-block">
          <div class="photo">
            <template v-if="this.user.photo && (this.user.photo.src || this.user.photo.data)
      ">
              <div v-if="this.user.photo.data" class="picture" :style="{
      backgroundImage:
        'url(data:image/jpeg;base64,' + this.user.photo.data + ')',
    }"></div>
              <div v-else class="picture" :style="{
      backgroundImage: 'url(' + this.user.photo.preview + ')',
    }"></div>
            </template>
            <template v-else>
              <span class="icon fi fi-user-circle"></span>
            </template>
          </div>
          <div class="change-wrap">
            <div class="change" @click="phoneActions">{{ $t('user.profile.change_photo') }}</div>
          </div>

          <sbs-control-select-sheet v-model:visible="photoActionsOpen" :title="this.$t('user.profile.actions.title')"
            v-model="photoAction" :list="photoActions" :showCheck="false" :showButtons="false" />

        </div>

        <h2>{{ $t('user.profile.personal') }}</h2>
        <div class="block">
          <sbs-props :props="propsPersonal" />
        </div>

        <h2>{{ $t('user.profile.work') }}</h2>
        <div class="block">
          <sbs-props :props="propsWork" />
        </div>

        <h2>{{ $t('user.profile.account') }}</h2>
        <div class="block">
          <sbs-props :props="propsAccount" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsTeleportWrapper from "@/components/teleport-wrapper.vue";
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";
import sbsProps from "@/components/props.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";


export default {
  name: "sbs-user-profile",
  components: {
    sbsControlSelectSheet,
    sbsLoaderInline,
    sbsTeleportWrapper,
    sbsProps
  },
  data() {
    return {
      loading: true,
      user: null,
      photoActionsOpen: false,
      photoAction: false,
      changed: false,
    };
  },

  computed: {

    photoActions() {
      let list = [];

      list.push({
        id: "camera",
        name: this.$t('user.profile.actions.camera'),
        icon: "fi-camera",
        action: this.getPictureFromCamera
      });

      list.push({
        id: "media",
        name: this.$t('user.profile.actions.media'),
        icon: "fi-photo-library",
        action: this.getPictureFromMedia
      });

      list.push({
        id: "delete",
        name: this.$t('user.profile.actions.delete'),
        icon: "fi-close",
        action: this.deletePhoto
      });

      return list;
    },

    propsPersonal() {
      let list = [];

      //Фамилия
      if (this.user.last_name) {
        list.push({
          type: "string",
          name: this.$t('user.profile.last_name'),
          value: this.user.last_name
        });
      }

      //Имя
      if (this.user.name) {
        list.push({
          type: "string",
          name: this.$t('user.profile.name'),
          value: this.user.name
        });
      }

      //Отчество
      if (this.user.second_name) {
        list.push({
          type: "string",
          name: this.$t('user.profile.second_name'),
          value: this.user.second_name
        });
      }

      //email
      if (this.user.email) {
        list.push({
          type: "email",
          name: this.$t('user.profile.email'),
          value: this.user.email
        });
      }

      return list;
    },

    propsWork() {
      let list = [];

      //Подразделение
      if (this.user.department_name) {
        list.push({
          type: "string",
          name: this.$t('user.profile.department_name'),
          value: this.user.department_name
        });
      }

      //Должность
      if (this.user.work_position) {
        list.push({
          type: "string",
          name: this.$t('user.profile.work_position'),
          value: this.user.work_position
        });
      }

      return list;
    },

    propsAccount() {

      let list = [];

      //Логин
      if (this.user.login) {
        list.push({
          type: "string",
          name: this.$t('user.profile.login'),
          value: this.user.login
        });
      }

      //Сменить пароль
      list.push({
        type: "string",
        name: "",
        value: this.$t('user.profile.change_password'),
        class: "change-password",
        action: this.changePassword
      });


      return list;

    }
  },

  watch: {

    photoAction: function () {
      if (this.photoAction && !!this.photoAction.id) {

        setTimeout(() => {
          this.photoAction.action();
          this.photoAction = false;
        }, 400);

      }
    },
  },

  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;

      //запрашиваем данные
      rest
        .call("user.profile.get", {}, false)
        .then((data) => {
          if (data.success) {
            this.user = data.fields;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Открывает popup действий с фото
     */
    phoneActions() {
      this.photoActionsOpen = true;
    },

    /**
     * Снять фото на камеру
     */
    getPictureFromCamera() {
      this.photoActionsOpen = false;
      bus.emit("SBS_CAMERA_E_GET_PICTURE", {
        resolve: this.onGetPictureFromCamera,
      });
    },

    /**
     * Получение фото из фото библиотеки
     */
    getPictureFromMedia() {
      this.photoActionsOpen = false;
      bus.emit("SBS_CAMERA_E_GET_PICTURE", {
        resolve: this.onGetPictureFromCamera,
        photoLibrary: true,
      });
    },

    /**
     * Обработка получения фото с камеры
     */
    onGetPictureFromCamera(imageData) {
      this.$store.commit("setPictureEditorParams", {
        image: "data:image/jpeg;base64," + imageData,
        save: this.setCroippedPicture,
      });
      this.$router.push({ name: "picture-editor" });
    },

    /**
     * Устанавливает обрезанное фото
     */
    setCroippedPicture(data) {
      if (!this.user.photo) {
        this.user.photo = {};
      }

      this.user.photo.data = data.replace("data:image/jpeg;base64,", "");
      this.changed = true;
    },

    /**
     * Удалить фото
     */
    deletePhoto() {
      this.photoActionsOpen = false;
      this.user.photo = false;
      this.changed = true;
    },

    /**
     * Сохранение
     */
    save() {
      let fields = {};
      if (this.user.photo.data) {
        fields.photo = this.user.photo.data;
      } else if (this.user.photo == false) {
        fields.photo = false;
      }

      //запрашиваем данные
      rest
        .call("user.profile.update", {
          method: "post",
          data: {
            fields: fields,
          },
        })
        .then((data) => {
          if (data.success) {
            this.changed = false;
            this.loadData();
            //генерим событие успешного обнолвения профиля
            bus.emit("SBS_USER_PROFILE_E_UPDATE");
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },

    /**
     * Переход к смене пароля
     */
    changePassword() {
      this.$router.push({ name: "password-change" });
    },
  },

  /**
   * Создание эксземпляра компонента
   */
  created() {
    //загружаем данные
    this.loadData();
  },
};
</script>

<style lang="scss">
.sbs-user-profile {
  .photo-block {
    margin-bottom: 30px;

    .photo {
      width: 140px;
      height: 140px;
      margin: 0px auto 20px auto;
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 120px;
        color: var(--service-color-1);
      }

      .picture {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-size: cover;
      }
    }

    .change-wrap {
      text-align: center;
    }

    .change {
      display: inline-block;
    }
  }

  .block {
    padding: 15px;

    border-radius: 15px;
    background: var(--audit-cards-audit-cards-bg-color);

    box-shadow: 0px 2px 4px 0px var(--shadow-secondary);

    margin-bottom: 20px;



    .change-password {
      .value {
        display: inline-block;
        border-bottom: 1px dashed currentColor;
      }
    }
  }
}
</style>