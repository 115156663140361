<template>
    <div class="sbs-equipment-view-actions">
        <sbs-control-select-sheet v-model:visible="display" :title="this.$t('equipment.view.actions.title')"
            v-model="action" :list="actions" :showCheck="false" :showButtons="false" />
    </div>
</template>

<script>
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "sbs-equipment-view-actions",
    components: {
        sbsControlSelectSheet,
    },
    emits: ["update:show"],
    data() {
        return {
            loading: false,
            display: this.show,
            action: false,
        };
    },
    props: {
        id: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        show: {
            type: Boolean,
            default() {
                return false;
            },
        },
        permissions: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        ...mapGetters({
            isNativePlatform: "isNativePlatform",
        }),

        actions() {
            let list = [];

            //Редактировать
            if (this.permissions.includes('manage')) {

                list.push({
                    id: "edit",
                    name: this.$t('equipment.view.actions.edit'),
                    icon: "fi-edit",
                    action: this.editAction
                });
            }

            if (this.isNativePlatform) {

                //привязать Qr-код
                if (this.permissions.includes('manage')) {

                    list.push({
                        id: "attachQrCode",
                        name: this.$t('equipment.view.actions.attach_qr'),
                        icon: "fi-qr-scanner",
                        action: this.attachQrCodeAction
                    });
                }

                //привязать NFC-метку
                if (this.permissions.includes('manage')) {

                    list.push({
                        id: "attachNfc",
                        name: this.$t('equipment.view.actions.attach_nfc'),
                        icon: "fi-nfc",
                        action: this.attachNfcAction
                    });
                }
            }

            return list;
        }
    },
    watch: {
        show: function (newVal) {
            this.display = newVal;
        },
        display: function (newVal) {
            this.$emit("update:show", newVal);
        },

        action: function () {
            if (this.action && !!this.action.id) {
                setTimeout(() => {
                    this.action.action();
                    this.action = false;
                }, 400);

            }
        },
    },

    methods: {
        close() {
            this.$emit("update:show", false);
        },

        /**
         * Редактировать
         */
        editAction() {
            this.close();
            this.$router.push({ name: "equipment-form", params: { id: this.id } });
        },

        /**
         * Привязать QR-код
         */
        attachQrCodeAction() {

            let vm = this;
            bus.emit("SBS_QR_SCANNER_E_OPEN", {
                mode: "SIMPLE",
                resolve: function (code) {
                    vm.attachQrCode(code);
                },
            });
        },

        /**
         * Выполняет непосредственную привязку qr-кода
         */
        attachQrCode(code) {

            //выполняем запрос
            rest
                .call("equipment.update", {
                    method: "post",
                    data: {
                        id: this.id,
                        props: {
                            qr_code: code
                        }
                    },
                })
                .then((data) => {
                    if (data.success) {
                        //показываем сообщение
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: this.$t('equipment.view.actions.attach_qr_success'), type: "success" });
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .catch(() => {
                    //в тихом режиме не обрабатваем ошибку
                })
                .finally(() => {
                    this.loading = false;
                    this.close();
                });

        },


        /**
         * Привязать NFC-метку
         */
        attachNfcAction() {

            let vm = this;
            bus.emit("SBS_NFC_SCANNER_E_OPEN", {
                mode: "SIMPLE",
                resolve: function (code) {
                    vm.attachNfcCode(code);

                },
            });
        },

        /**
         * Выполняет непосредственную привязку nfc-метки
         */
        attachNfcCode(code) {

            rest
                .call("equipment.update", {
                    method: "post",
                    data: {
                        id: this.id,
                        props: {
                            nfc_id: code
                        }
                    },
                })
                .then((data) => {
                    if (data.success) {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: this.$t('equipment.view.actions.attach_nfc_success'), type: "success" });
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .catch(() => {
                    //в тихом режиме не обрабатваем ошибку
                })
                .finally(() => {
                    this.loading = false;
                    this.close();
                });

        }
    }
}
</script>
