<template>
    <sbs-view :class="'sbs-v-edu-trainings'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">

        <template v-slot:header>
            <sbs-header :showBackBtn="false" :showLogo="true">
                <template v-slot:center>
                    <div class="title">{{ $t('education.trainings.title') }}</div>
                </template>
                <template v-slot:right>

                    <div class="action" @click="onSearchClick">
                        <span class="icon fi fi-search"></span>
                    </div>
                </template>
            </sbs-header>
        </template>

        <template v-slot:content>

            <!-- Загрузка -->
            <template v-if="loading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>

            <template v-else>

                <sbs-education-training-statuses :items="statuses" />

                <div class="items">
                    <template v-for="item in items" :key="item.id">
                        <div v-if="item.trainings_cnt > 0" class="category" @click="onItemClick(item)">
                            <div class="name nunito-3-bold">{{ item.name }}</div>
                            <div class="cnt">{{ item.trainings_cnt }}</div>
                            <span class="icon fi fi-arrow-right"></span>
                        </div>
                    </template>


                </div>
            </template>


        </template>

    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationTrainingStatuses from "@/components/education/training.statuses.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-v-education-trainings",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsEducationTrainingStatuses
    },
    data() {
        return {
            hideMenu: false,

            loading: true,
            items: [],
            statuses: []
        };
    },

    computed: {
    },

    methods: {

        getBatchRequests() {
            let requests = {
                categories: {
                    ENTITY: "education",
                    METHOD: "category.list",
                    PARAMS: {
                        mode: "public"
                    },
                },
                statuses: {
                    ENTITY: "education",
                    METHOD: "training.statistics",
                    PARAMS: {
                        mode: "public",
                        select: { 0: "result_status", 1: "cnt" },
                        filter: {
                            "result.user_id": "MY_SELF"
                        },
                        group: ["result_status"],
                        order: { "result_status": "ASC" }
                    },
                }
            };

            return requests;
        },

        /**
         * Загружает данные
         */
        loadData(resolve = false) {

            let requests = this.getBatchRequests();

            //покажем экран загрузки
            this.loading = resolve ? false : true;

            rest.batch(requests)
                .then((results) => {
                    for (var key in results) {
                        let data = results[key];
                        if (data.success) {
                            if (key == "categories") {
                                this.items = data.items;
                            } else if (key == "statuses") {
                                this.statuses = data.items;
                            }
                        } else {
                            //показываем сообщение об ошибке
                            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                            return;
                        }

                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    //скроем экран загрузки
                    this.loading = false;
                });
        },

        /**
         * Перезагрузка данных
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Переход к категории
         */
        onItemClick(item) {
            this.$router.push({
                name: "education-training-category",
                params: { id: item.id },
            });
        },

        /**
        * Клик по иконке "Поиск"
        */
        onSearchClick() {
            this.$router.push({ name: "education-trainings-search", });
        },

        /**
         * Обновление счётчиков статусов 
         */
        refreshStatuses() {

            //запрашиваем данные
            rest
                .call("education.training.statistics", {
                    method: "post",
                    data: {
                        mode: "public",
                        select: { 0: "result_status", 1: "cnt" },
                        filter: {
                            "result.user_id": "MY_SELF"
                        },
                        group: ["result_status"],
                        order: { "result_status": "ASC" }
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.statuses = data.items;
                    }
                });
        }
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.loadData();
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },

    mounted() {
        //подписваемся на события
        bus.on("SBS_EDU_TRAINING_RESULT_E_SAVE", this.refreshStatuses);
    },

    unmounted() {
        //отписываемся от событий
        bus.off("SBS_EDU_TRAINING_RESULT_E_SAVE", this.refreshStatuses);
    },

}
</script>

<style lang="scss">
.sbs-v-edu-trainings {

    .category {
        position: relative;
        padding: 15px 45px 15px 15px;
        border-radius: 15px;
        background: var(--audit-cards-audit-cards-bg-color);
        box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
        cursor: pointer;
        margin-bottom: 10px;

        .name {
            color: var(--audit-cards-audit-cards-title-text-color);
        }

        .cnt {
            position: absolute;
            top: 50%;
            right: 35px;
            transform: translateY(-50%);
            color: var(--news-card-news-card-secondary-text-color);
            line-height: 1;
        }

        .icon {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            color: var(--service-color-1);
            line-height: 1;
        }
    }
}
</style>