<template>
    <div class="sbs-fe" v-if="!loading && fields.length > 0">

        <div class="title" v-if="showTitle">{{ $t('fields.edit.title') }}</div>

        <template v-for="field in fields" :key="field.code">
            <div class="field" v-if="field.active == 'Y' || values[field.code]">

                <!-- Строка -->
                <template v-if="field.type == 'STRING'">

                    <template v-if="field.multiple == 'N'">
                        <sbs-control-input v-model="values[field.code]" type="text" :label="field.name"
                            :required="field.required == 'Y'" />
                    </template>
                    <template v-else>
                        <template v-for="(val, index) in values[field.code]" :key="field.code + '_'+index">
                            <div class="item" :class="{ padding: values[field.code].length > 1 }">
                                <sbs-control-input v-model="values[field.code][index]" type="text"
                                    :label="field.name + ' #' + (index + 1)" :required="field.required == 'Y'" />

                                <div class="delete" v-if="values[field.code].length > 1"
                                    @click="deleteValue(field.code, index)">
                                    <span class="fi fi-close"></span>
                                </div>
                            </div>
                        </template>
                        <div class="add" @click="addValue(field.code)">
                            + {{ $t('common.add') }}
                        </div>

                    </template>

                </template>

                <!-- Число -->
                <template v-else-if="field.type == 'NUMBER'">

                    <template v-if="field.multiple == 'N'">
                        <sbs-control-input v-model="values[field.code]" type="text" :label="field.name"
                            :mask="{ mask: 'U', tokens: { U: { pattern: /[0-9]/, repeated: true } } }"
                            :required="field.required == 'Y'" />
                    </template>
                    <template v-else>
                        <template v-for="(val, index) in values[field.code]" :key="field.code + '_'+index">
                            <div class="item" :class="{ padding: values[field.code].length > 1 }">
                                <sbs-control-input v-model="values[field.code][index]" type="text"
                                    :label="field.name + ' #' + (index + 1)"
                                    :mask="{ mask: 'U', tokens: { U: { pattern: /[0-9]/, repeated: true } } }" />

                                <div class="delete" v-if="values[field.code].length > 1"
                                    @click="deleteValue(field.code, index)">
                                    <span class="fi fi-close"></span>
                                </div>
                            </div>
                        </template>
                        <div class="add" @click="addValue(field.code)">
                            + {{ $t('common.add') }}
                        </div>

                    </template>

                </template>

                <!-- Список -->
                <template v-else-if="field.type == 'LIST'">

                    <template v-if="field.multiple == 'N'">
                        <sbs-control-select v-model="values[field.code]" :label="field.name" :list="field.list"
                            :required="field.required == 'Y'" />
                    </template>
                    <template v-else>
                        <sbs-control-select-multiple v-model="values[field.code]" :label="field.name" :list="field.list"
                            :required="field.required == 'Y'" />
                    </template>

                </template>

                <!-- Дата -->
                <template v-else-if="field.type == 'DATE'">

                    <template v-if="field.multiple == 'N'">
                        <sbs-control-date v-model="values[field.code]" :label="field.name"
                            :required="field.required == 'Y'" />
                    </template>
                    <template v-else>
                        <template v-for="(val, index) in values[field.code]" :key="field.code + '_'+index">
                            <div class="item" :class="{ padding: values[field.code].length > 1 }">
                                <sbs-control-date v-model="values[field.code][index]"
                                    :label="field.name + ' #' + (index + 1)" :required="field.required == 'Y'" />

                                <div class="delete" v-if="values[field.code].length > 1"
                                    @click="deleteValue(field.code, index)">
                                    <span class="fi fi-close"></span>
                                </div>
                            </div>
                        </template>
                        <div class="add" @click="addValue(field.code)">
                            + {{ $t('common.add') }}
                        </div>

                    </template>

                </template>

            </div>

        </template>

    </div>
</template>

<script>
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlSelect from "@/components/controls/select.vue";
import sbsControlSelectMultiple from "@/components/controls/select.multiple.vue";
import sbsControlDate from "@/components/controls/date.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import enums from "@/plugins/enums"

export default {
    name: "sbs-fields-edit",
    components: {
        sbsControlInput,
        sbsControlSelect,
        sbsControlSelectMultiple,
        sbsControlDate
    },
    emits: ["update:modelValue"],
    data() {
        return {
            loading: true,
            values: this.modelValue,
            fields: [],
            types: enums.fieldTypes
        }
    },
    props: {
        modelValue: {
            type: Object,
            default() {
                return {};
            },
        },

        entity: {
            type: String,
            default() {
                return "";
            },
        },

        settingsKey: {
            type: [String, Number],
            default() {
                return "";
            },
        },

        id: {
            type: [String, Number],
            default() {
                return "";
            },
        },

        showTitle: {
            type: Boolean,
            default() {
                return true;
            }
        }


    },
    watch: {
        settingsKey: function () {
            this.loadData();
        },

        values: {
            handler() {
                this.$emit("update:modelValue", this.values);
            },
            deep: true
        }
    },

    methods: {

        loadData() {
            this.loading = true;

            rest.call("field.entity.fields", {
                method: "post",
                data: {
                    entity: this.entity,
                    key: this.settingsKey,
                    id: this.id,

                },
            })
                .then((data) => {
                    if (data.success) {
                        this.fields = data.fields;
                        this.setValues();
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }

                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Устанавливает значения полей
         */
        setValues() {
            this.fields.forEach(field => {
                if (this.values[field.code] === undefined) {
                    if (field.multiple == "Y") {
                        if (!field.value) {
                            this.values[field.code] = field.type == 'LIST' ? [] : [""]
                        } else {
                            this.values[field.code] = field.type == 'DATE' ? field.display_value : field.value
                        }
                    } else {
                        if (!field.value) {
                            this.values[field.code] = "";
                        } else {
                            this.values[field.code] = field.type == 'DATE' ? field.display_value : field.value;
                        }

                    }

                }
            });

            //удалим значения полей, которых нет в текущем списке
            for (let code in this.values) {
                let field = this.fields.find(field => field.code == code);
                if (!field) {
                    delete this.values[code];
                }
            }

        },

        /**
         * Добавить значение множественное
         */
        addValue(code) {
            this.values[code].push("");
        },

        /**
         * Удаление значени по индексу
         */
        deleteValue(code, index) {
            this.values[code].splice(index, 1);
        }


    },

    created() {

        this.loadData();

    }

}

</script>

<style lang="scss">
.sbs-fe {
    .title {
        font-size: 18px;
        margin-bottom: 35px;
    }

    .item {
        position: relative;

        &.padding {
            padding-right: 40px;
        }

        .delete {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            padding: 10px;
            line-height: 1;
            cursor: pointer;
        }
    }

    .add {
        position: relative;
        top: -10px;
        cursor: pointer;
        margin-bottom: 10px;
    }

}
</style>
