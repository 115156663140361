<template>
  <div class="sbs-users-list" ref="container">
    <template v-if="items != null">
      <!-- Если список пуст-->
      <template v-if="items.length == 0">
        <sbs-empty :title="$t('users.list.empty.title')" :text="$t('equipment.users.empty.text')" />
      </template>

      <!-- Сотрудники -->
      <div v-else class="items">
        <sbs-user-item v-for="item in items" :key="item.id" :item="item" />
      </div>
    </template>
    <div ref="pager"></div>

    <!-- Загрузка -->
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsUserItem from "@/components/users/item.vue";
import sbsEmpty from "@/components/empty.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-users-list",
  components: {
    sbsLoaderInline,
    sbsUserItem,
    sbsEmpty
  },
  data() {
    return {
      loading: true,
      items: null,
      pager: {
        size: 6,
        page: 1,
        cnt: 1,
      },
    };
  },
  props: {
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
    departmentId: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {},
  watch: {
    reload: function () {
      this.refresh();
    },
  },

  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;

      //запрашиваем данные
      rest
        .call("users.list", {
          method: "post",
          data: {
            paging: this.pager,
          },
        })
        .then((data) => {
          if (data.success) {
            if (this.pager.page == 1) {
              this.items = data.items;
            } else {
              this.items = this.items.concat(data.items);
            }
            this.pager = data.pager;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Перезагружает список
     */
    refresh() {
      this.items = null;
      this.pager.page = 1;
      this.loadData();
    },

    onScroll(e) {
      let scroll = e.target.scrollTop;
      let height = e.target.offsetHeight;
      let offsetTop = this.$refs.pager.offsetTop;
      //если экран прокрутился до пейджера (высоту экрана за вычетом 70px под меню которые заререзрвированы)
      if (scroll + height - 70 > offsetTop) {
        //если экран не в стадии загрузки и есть следующие страницы
        if (!this.loading && this.pager.page < this.pager.cnt) {
          this.pager.page++;
          this.loadData();
        }
      }
    },
  },

  created() {
    //загружаем данные
    this.loadData();
  },

  mounted() {
    //подписываемся на скролинг экрана
    this.$refs.container
      .closest(".view-content-scroll")
      .addEventListener("scroll", this.onScroll);
  },

  beforeUnmount() {
    this.$refs.container
      .closest(".view-content-scroll")
      .removeEventListener("scroll", this.onScroll);
  },
};
</script>
