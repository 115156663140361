<template>
  <div class="sbs-requests-statistics-wtbd">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend
);

import moment from "moment";
import bus from "@/plugins/bus";
import { shallowRef } from "vue";

export default {
  name: "sbs-requests-statistics-work-time-by-dates",
  components: {},
  data() {
    return {
      chart: false,
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    draw() {
      let ctx = this.$refs.canvas;

      Chart.defaults.font.family = 'Nunito';
      this.chart = shallowRef(
        new Chart(ctx, {
          type: "line",
          data: this.data(),
          options: this.options(),
        })
      );
    },

    refresh() {
      this.chart.options = this.options();
      this.chart.update();
    },

    data() {
      let data = this.items.map(
        (item) =>
          Math.round((parseInt(item.work_time_sum) / 60 / 60) * 100) / 100
      );

      let color = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("--orange-400");

      return {
        labels: this.items.map((item) =>
          moment(item.work_time_date, "DD.MM.YYYY hh:mm:ss").format("DD.MM")
        ),
        datasets: [
          {
            data: data,
            borderColor: color,
            backgroundColor: color,
          },
        ],
      };
    },

    /**
     * Опции графика
     */
    options() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },

        aspectRatio: this.windowWidth <= 767 ? 1.1 : 4,

        scales: {
          x: {
            ticks: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-secondary-text-color"),
            },
            grid: {
              borderColor: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-line-color"),
              display: false,
            },
          },
          y: {
            ticks: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-secondary-text-color"),
            },
            grid: {
              borderColor: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-line-color"),
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-line-color"),
            },
            beginAtZero: true,
          },
        },
      };
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.refresh();
    }
  },

  created() {
    this.windowWidth = window.innerWidth;
  },

  mounted() {
    this.draw();

    //подписываемся на событие завершения смены темы
    bus.on("SBS_APP_E_THEME_CHANGED", this.refresh);
    window.addEventListener("resize", this.onResize);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_APP_E_THEME_CHANGED", this.refresh);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
