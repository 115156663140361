<template>
  <sbs-view :class="'sbs-v-equipment'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
    <template v-slot:header>
      <sbs-header :showBackBtn="false" :showLogo="true">
        <template v-slot:center>
          <div class="title">{{ $t('equipment.list.title') }}</div>
        </template>

        <template v-slot:right>
          <div class="action" v-ripple.300 @click="onAddClick" v-if="canAdd">
            <span class="icon fi fi-add"></span>
          </div>
          <div class="action" v-ripple.300 @click="onSearchClick">
            <span class="icon fi fi-search"></span>
          </div>
          <div class="action" v-ripple.300 @click="onFilterClick">
            <span class="icon fi fi-filter"></span>
            <div class="counter nunito-2-medium" v-if="filterCounter > 0">{{ filterCounter }}</div>
          </div>
        </template>
      </sbs-header>
    </template>

    <template v-slot:content>
      <sbs-equipment-list :reload="reload" />

      <sbs-equipment-filter v-model:visible="showFilter" />
    </template>

    <template v-slot:footer>
      <!-- Кнопка поиска по Qr-коду или NFC-метке -->
      <sbs-equipment-widget v-if="isNativePlatform" />
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsEquipmentList from "@/components/equipment/list.vue";
import sbsEquipmentWidget from "@/components/equipment/widget.vue";
import sbsEquipmentFilter from "@/components/equipment/filter.vue"

import { mapGetters } from "vuex";

export default {
  name: "sbs-v-equipment",
  components: {
    sbsView,
    sbsHeader,
    sbsEquipmentList,
    sbsEquipmentWidget,
    sbsEquipmentFilter
  },
  data() {
    return {
      reload: 0,
      hideMenu: false,

      showFilter: false
    };
  },
  computed: {

    ...mapGetters({
      isNativePlatform: "isNativePlatform",
      access: "getAccess"
    }),

    filter() {
      return this.$store.getters.getFilter("EQUIPMENT");
    },

    filterCounter() {
      if (!this.filter || Object.keys(this.filter).length == 0) {
        return 0;
      }
      let c = 0;
      for (let key in this.filter) {
        let value = this.filter[key];
        if (value instanceof Array) {
          if (value.length > 0) {
            c++;
          }
        } else if (value) {
          c++;
        }
      }
      return c;
    },


    permissions() {
      if (!this.access || !this.access.permissions.equipment) {
        return [];
      }

      return this.access.permissions.equipment;
    },


    /**
     * Доступность кнопки добавления
     */
    canAdd() {
      if (!this.access) {
        return false;
      }

      if (this.permissions.includes('MANAGE_ALL') ||
        this.permissions.includes('MANAGE_OWN_DEPARTMENT') ||
        this.permissions.includes('MANAGE_SELECTED_DEPARTMENTS')) {
        return true;
      }

      return false;

    }
  },

  methods: {
    refresh(resolve) {
      this.reload++;
      setTimeout(() => {
        resolve();
      }, 300);
    },

    /**
     * Клик по иконке "Фильтр"
     */
    onFilterClick() {
      setTimeout(() => {
        this.showFilter = true;
      }, 300);
    },

    /**
     * Клик по иконке "Поиск"
     */
    onSearchClick() {
      setTimeout(() => {
        this.$router.push({ name: "equipment-search" });
      }, 300);

    },

    /**
     * Клик по иконке "Добавить"
     */
    onAddClick() {
      setTimeout(() => {
        this.$router.push({ name: "equipment-form", params: { id: 0 } });
      }, 300);

    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
    });
  },
};
</script>

<style>
.sbs-v-equipment {

  .view-content {
    padding-bottom: 80px;
  }
}
</style>
