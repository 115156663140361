<template>
    <div class="sbs-rt-i" @click="onItemClick">


        <div class="en">
            <div class="equipment nunito-4-bold" v-if="item.equipment_id > 0">
                {{
        item.equipment_short_name
            ? item.equipment_short_name
            : item.equipment_name
    }}
            </div>
            <div class="name roboto-4-regular">
                {{ item.name }}
            </div>
        </div>
        <div class="repeat nunito-2-bold" v-if="item.repeat == 'Y'">
            <span class="icon fi fi-repeat"></span>
            {{ $t('requests.templates.list.repeat') }}
        </div>

        <div class="props">
            <div class="prop" v-if="item.repeat == 'Y'">
                <div class="label nunito-1-bold">{{ $t('requests.templates.list.repeat_next_date') }}:</div>
                <div class="value roboto-3-regular">{{ item.repeat_next_date }}</div>
            </div>
        </div>


    </div>
</template>

<script>

export default {
    name: "sbs-v-request-templates-item",
    components: {

    },
    data() {
        return {

        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
    },


    methods: {

        /**
         * Переход к шаблону
         */
        onItemClick() {
            this.$router.push({
                name: "request-template-form",
                params: { id: this.item.id },
            });
        },

    },
}

</script>

<style lang="scss">
.sbs-rt-i {
    position: relative;

    padding: 15px;
    border-radius: 15px;
    background: var(--audit-cards-audit-cards-bg-color);
    box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
    cursor: pointer;
    margin-bottom: 10px;

    .en {
        padding-right: 85px;
    }

    .equipment {
        color: var(--audit-cards-audit-cards-title-text-color);
    }

    .name {
        color: var(--audit-cards-audit-cards-title-text-color);

    }

    .repeat {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 6px 8px 6px 26px;

        border-radius: 10px;
        line-height: 1;

        background: var(--audit-status-audit-status-repeat-background-color);
        color: var(--audit-status-audit-status-repeat-text-color);

        .icon {
            position: absolute;
            top: 50%;
            left: 8px;
            transform: translateY(-50%);
        }

    }

    .prop {
        margin-top: 10px;

        .label {
            color: var(--news-card-news-card-secondary-text-color);

            text-transform: uppercase;
            margin-bottom: 5px;
        }

        .value {
            color: var(--audit-cards-audit-cards-title-text-color);

            a {
                color: currentColor;
                font-size: inherit;
                text-decoration: none;
            }
        }

    }

}
</style>
