<template>
    <sbs-sheet v-model:visible="visibleInternal">
        <template v-slot:body>
            <div class="sbs-edu-posts-filter">

                <div class="sheet-title">{{ $t('common.filter') }}</div>

                <template v-if="loading">
                    <sbs-loader-inline />
                </template>

                <template v-else>

                    <div class="filters">

                        <sbs-control-filter :label="$t('education.posts.filter.type')" :items="types"
                            v-model="filter.type" />

                        <sbs-control-filter :label="$t('education.posts.filter.author')" :items="authors"
                            v-model="filter.created_by" />

                        <sbs-control-filter :label="$t('education.posts.filter.created_department')"
                            :items="createdDepartments" v-model="filter.created_department_id" />

                        <sbs-control-filter :label="$t('education.posts.filter.tags')" :items="tags"
                            v-model="filter.tag_id" />

                        <sbs-control-filter-dates :label="$t('education.posts.filter.publication_date')"
                            v-model="filter.publication_date" />

                    </div>

                    <div class="buttons">
                        <div class="btn btn-block btn2" @click="clear">{{ $t('common.clear') }}</div>
                        <div class="btn btn-block btn1" @click="apply">{{ $t('common.apply') }}</div>
                    </div>
                </template>

            </div>
        </template>
    </sbs-sheet>
</template>

<script>
import sbsSheet from '@/components/sheet.vue'
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlFilter from "@/components/controls/filter";
import sbsControlFilterDates from "@/components/controls/filter.dates";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-education-posts-filter",
    components: {
        sbsSheet,
        sbsLoaderInline,
        sbsControlFilter,
        sbsControlFilterDates

    },
    emits: ["update:visible"],
    data() {
        return {
            visibleInternal: this.visible,

            loading: false,
            loaded: false,
            types: [],
            authors: [],
            createdDepartments: [],
            tags: [],

            filter: {},
            key: "EDUCATION_POSTS"
        };
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    computed: {
        globalFilter() {
            return this.$store.getters.getFilter(this.key);
        },
    },

    watch: {
        visibleInternal: function (newVal) {
            if (newVal != this.visible) {
                this.$emit("update:visible", newVal);
            }

        },
        visible: function (newVal) {
            if (newVal != this.visibleInternal) {
                this.visibleInternal = newVal;
            }
            if (newVal) {
                if (!this.loaded) {
                    this.loadData();
                }
                this.setDefaults();
                this.setFromGlobalFilter();
            }
        },
    },

    methods: {

        getBatchRequests() {
            return {
                types: {
                    ENTITY: "education",
                    METHOD: "post.statistics",
                    PARAMS: {
                        select: ["type", "cnt"],
                        group: ["type"],
                        order: { "type": "asc" }

                    },
                },
                authors: {
                    ENTITY: "education",
                    METHOD: "post.statistics",
                    PARAMS: {
                        select: { 0: "cnt", 1: "created_by", 2: "user_name", 3: "user_last_name" },
                        group: ["created_by"],
                        order: { "CREATED_BY_USER.LAST_NAME": "asc" }
                    },
                },
                created_departments: {
                    ENTITY: "education",
                    METHOD: "post.statistics",
                    PARAMS: {
                        select: { "department_name": "created_department.name", 0: "cnt", 1: "created_department_id" },
                        group: ["created_department_id"],
                        order: { "created_department.name": "asc" }
                    },
                },
                tags: {
                    ENTITY: "education",
                    METHOD: "post.statistics",
                    PARAMS: {
                        select: { "tag_name": "tag.name", 0: "tag_id", 1: "cnt", },
                        group: ["tag_id"],
                        order: { "tag.name": "asc" },
                        filter: {
                            ">tag_id": 0
                        }
                    },
                },
            };

        },

        /**
         * Загрузка данных
         */
        loadData() {

            this.loading = true;

            rest.batch(this.getBatchRequests())
                .then((data) => {
                    this.processResponse(data);
                })
                .finally(() => {
                    this.loading = false;
                    this.loaded = true;
                });

        },

        /**
         * Обработка результата загрузки
         */
        processResponse(results) {

            for (var key in results) {
                let data = results[key];

                if (data.success) {
                    if (key == "types") {
                        this.setTypes(data.items);
                    } else if (key == "authors") {
                        this.setAuthors(data.items);
                    } else if (key == "created_departments") {
                        this.setCreatedDepartments(data.items);
                    } else if (key == "tags") {
                        this.setTags(data.items);
                    }

                } else {
                    //показываем сообщение об ошибке
                    bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    return;
                }
            }
        },

        /**
         * Устанавилвает список типов по статистике использования
         */
        setTypes(items) {
            this.types = items.map(item => {
                return { id: item.type, name: this.$t('education.posts.filter.types.' + item.type), count: item.cnt }
            });
        },

        /**
         * Устанавилвает список пользователей-авторов
         */
        setAuthors(items) {
            this.authors = items.map(item => {
                return { id: item.created_by, name: item.user_last_name + " " + item.user_name, count: item.cnt }
            });
        },

        /**
         * Устанавилвает список подраздлелений, создававших посты
         */
        setCreatedDepartments(items) {
            this.createdDepartments = items.map(item => {
                return { id: item.created_department_id, name: item.department_name, count: item.cnt }
            });
        },

        /**
         * Устанавилвает список тегов
         */
        setTags(items) {
            this.tags = items.map(item => {
                return { id: item.tag_id, name: item.tag_name, count: item.cnt }
            });
        },

        setDefaults() {
            this.filter = {
                type: [],
                created_by: [],
                created_department_id: [],
                publication_department_id: [],
                publication_date: [],
                tag_id: []
            };
        },

        apply() {
            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", {
                name: this.key,
                value: this.filter
            });

            this.visibleInternal = false;
        },

        /**
         * Сбросить фильтр
         */
        clear() {
            //сбрасываем фильтр
            this.setDefaults();
            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", { name: this.key, value: this.filter });


            this.visibleInternal = false;
        },

        /**
         * Устанавливает значения из глобального фильтра
         */
        setFromGlobalFilter() {
            for (let code in this.filter) {
                if (this.globalFilter[code]) {
                    this.filter[code] = this.globalFilter[code];
                } else {
                    this.filter[code] = this.filter[code] instanceof Array ? [] : "";
                }
            }
        },
    },

};

</script>

<style lang="scss">
.sbs-edu-posts-filter {
    position: relative;
    padding-bottom: 80px;

    .buttons {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 15px 20px calc(var(--keyboard-height, 0px) + var(--safe-area-bottom, 0px) + 20px);

        z-index: 10;
        background-color: var(--bg);

        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 10px;
    }
}
</style>
