<template>
  <div class="sbs-tabs pull-to-refresh--consume">
    <div v-for="item in items" class="item nunito-4-regular" :class="{ active: item.code == active }" :key="item.code"
      @click="onClick(item)">
      {{ item.name }}
      <div class="count nunito-2-medium" v-if="item.count > 0">
        {{ item.count <= 99 ? item.count : "99+" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sbs-tabs",
  emits: ["update:active"],
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    active: {
      type: String,
      default() {
        return "";
      },
    },
  },

  methods: {
    onClick(item) {
      this.$emit("update:active", item.code);
    },
  },
};
</script>

<style lang="scss">
.sbs-tabs {
  padding-top: 12px;
  margin-bottom: 20px;

  border-bottom: 1px solid var(--news-card-news-card-line-color);

  &::-webkit-scrollbar {
    display: none;
  }

  .item {
    position: relative;
    display: inline-block;
  
    padding: 0px 0px 10px;
    color: var(--news-card-news-card-secondary-text-color);
    margin: 5px 15px 0px 0px;
    cursor: pointer;

    .count {
      position: absolute;
      top: -12px;
      right: -12px;

      height: 22px;
      width: 22px;
      border-radius: 50%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height:1;

      color: var(--bottom-navigation-tab-bottom-navigation-tab-notification-text-color);

      border: 2px solid var(--bottom-navigation-tab-bottom-navigation-tab-standard-bg-color);
      background: var(--bottom-navigation-tab-bottom-navigation-tab-icon2-color);
    }

    &:last-child {
      margin-right: var(--view-content-padding-horizontal);
    }

    &.active {
      font-weight: 700;
      color: var(--service-color-1);

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;

        height: 3px;
        border-radius: 5px 5px 0px 0px;
        background: var(--service-color-1);
      }
    }
  }
}

@media (max-width:1199px) {
  .sbs-tabs {
    white-space: nowrap;
    overflow-x: scroll;
    margin-left: calc(-1 * var(--view-content-padding-horizontal));
    margin-right: calc(-1 * var(--view-content-padding-horizontal));

    .item {
      margin: 0px 15px 0px 0px;

      &:first-child {
        margin-left: var(--view-content-padding-horizontal);
      }

      &:last-child {
        margin-right: var(--view-content-padding-horizontal);
      }
    }


  }
}
</style>