<template>
    <svg class="sbs-logo-mini" width="35" height="20" viewBox="0 0 35 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.3263 4.49714C31.2047 4.49714 32.9171 5.21507 34.2054 6.39212L32.6719 8.08502C31.7888 7.27807 30.6146 6.7861 29.3262 6.7861C27.9531 6.7861 26.7099 7.3449 25.8103 8.2482C24.9109 9.15132 24.3543 10.3996 24.3543 11.7785C24.3543 13.1573 24.9109 14.4056 25.8103 15.3087C26.7099 16.212 27.9531 16.7708 29.3262 16.7708C30.6146 16.7708 31.7888 16.2789 32.6719 15.4719L34.2054 17.1648C32.9171 18.3419 31.2047 19.0598 29.3263 19.0598C27.3242 19.0598 25.5112 18.2446 24.1989 16.9269C23.8286 16.555 23.4981 16.1431 23.2145 15.6982C22.9309 16.1431 22.6004 16.555 22.2301 16.9269C20.9178 18.2446 19.1046 19.0598 17.1026 19.0598C15.1006 19.0598 13.2875 18.2446 11.9753 16.9269C11.6049 16.5551 11.2745 16.1432 10.9908 15.6983C10.7072 16.1432 10.3768 16.5551 10.0064 16.9269C8.69414 18.2446 6.88109 19.0598 4.87907 19.0598C3.00063 19.0598 1.28821 18.3419 0 17.1648L1.53344 15.4719C2.41653 16.2789 3.59071 16.7708 4.8791 16.7708C6.25231 16.7708 7.49546 16.2121 8.39502 15.3087C9.29443 14.4056 9.85111 13.1573 9.85111 11.7785C9.85111 10.3996 9.29443 9.15132 8.39502 8.2482C7.49546 7.34493 6.25231 6.7861 4.8791 6.7861C3.59071 6.7861 2.41653 7.27804 1.53344 8.08502L0 6.39212C1.28821 5.21507 3.00063 4.49714 4.87907 4.49714C6.80329 4.49714 8.55283 5.25028 9.85108 6.47878V0C10.9516 0.0483371 12.1306 1.23854 12.1306 3.1945V6.4789V11.7633V11.7659V11.7684V11.7709V11.7734V11.7759V11.7784C12.1306 13.1573 12.6872 14.4056 13.5867 15.3087C14.4863 16.212 15.7294 16.7708 17.1026 16.7708C18.4758 16.7708 19.7191 16.212 20.6187 15.3087C21.5182 14.4054 22.0748 13.1573 22.0748 11.7784C22.0748 10.3995 21.5183 9.15141 20.6187 8.24814C19.8461 7.47235 18.8199 6.95068 17.6756 6.81882V4.51965C19.4492 4.65897 21.0443 5.43931 22.2301 6.63003C22.6004 7.00186 22.9309 7.41376 23.2145 7.85865C23.4981 7.41376 23.8286 7.00186 24.1989 6.63003C25.5112 5.31231 27.3242 4.49714 29.3263 4.49714Z" />
    </svg>
</template>

<script>
export default {
    name: "sbs-logo-mini"
};
</script>

<style lang="scss">
.sbs-logo-mini {

    path {
        fill: var(--service-color-1);
    }

}
</style>
