<template>
  <div class="sbs-user-password-change">
    <sbs-control-input v-model="password" :type="showPassword ? 'text' : 'password'"
      :label="$t('users.password_change.password')" :required="true">
      <template v-slot:icon>
        <div class="icon" @click="toggleShowPassword">
          <span class="fi fi-eye" :class="{
      'fi-eye': !showPassword,
      'fi-eye-close': showPassword,
    }"></span>
        </div>
      </template>
    </sbs-control-input>

    <sbs-control-input v-model="confirmPassword" :type="showConfirmPassword ? 'text' : 'password'"
      :label="$t('users.password_change.confirm')" :required="true">
      <template v-slot:icon>
        <div class="icon" @click="toggleShowConfirmPassword">
          <span class="fi fi-eye" :class="{
      'fi-eye': !showConfirmPassword,
      'fi-eye-close': showConfirmPassword,
    }"></span>
        </div>
      </template>
    </sbs-control-input>

    <div class="btn btn1 btn-block" :class="loading ? 'wait' : ''" v-ripple.300 @click="change">
      {{ $t('users.password_change.change') }}
      <div v-if="loading" class="btn-loader"></div>
    </div>
  </div>
</template>

<script>
import sbsControlInput from "@/components/controls/input";

import validator from "@/plugins/validator";
import bus from "@/plugins/bus";
import rest from "@/plugins/rest";

export default {
  name: "sbs-user-password-change",
  components: {
    sbsControlInput,
  },
  data() {
    return {
      loading: false,
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
    };
  },

  methods: {
    /**
     * мена флага "Показать пароль"
     */
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },

    /**
     * Смена флага "Показать подтверждение пароля"
     */
    toggleShowConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },

    /**
     * Смена пароля
     */
    change() {
      //првоерка введённых данных
      if (!validator.check({ container: this.$el })) {
        return;
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      rest
        .call("user.password.change", {
          method: "post",
          data: {
            password: this.password,
            confirm_password: this.confirmPassword,
          },
        })
        .then((data) => {
          if (data.success) {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", {
              message: "Пароль успешно изменён.",
              type: "success",
            });
            this.$router.go(-1);

            this.password = "";
            this.confirmPassword = "";
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>