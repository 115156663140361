import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store';

import RequestsSummary from '../views/requests/requests.summary.vue'
import Requests from '../views/requests/requests.vue'
import RequestForm from '../views/requests/request.form.vue'
import RequestMaster from '../views/requests/request.master.vue'
import RequestMasterMuti from '../views/requests/request.master.multi.vue'
import RequestView from '../views/requests/request.view.vue'
import RequestsSearch from '../views/requests/requests.search.vue'
import RequestChangeResponsible from '../views/requests/request.change.responsible.vue'
import RequestChecklistExec from '../views/requests/request.checklist.exec.vue'
import RequestReportExec from '../views/requests/request.report.exec.vue'
import LoginView from '../views/auth/login.vue'
import RestoreView from '../views/auth/restore.vue'

import Equipment from '../views/equipment/equipment.vue'
import EquipmentView from '../views/equipment/equipment.view.vue'
import EquipmentSearch from '../views/equipment/equipment.search.vue'
import EquipmentForm from '../views/equipment/equipment.form.vue'

import ScannerQr from '../views/scanner.qr.vue'
import ScannerNfc from '../views/scanner.nfc.vue'
import Settings from '../views/settings.vue'
import More from '../views/more.vue'
import Departments from '../views/departments/departments.vue'
import DepartmentView from '../views/departments/department.view.vue'
import Accounts from '../views/accounts.vue'
import Profile from '../views/users/profile.vue'
import PictureEditor from '../views/picture.editor.vue'
import PasswordChange from '../views/users/password.change.vue'
import Users from '../views/users/users.vue'
import UserView from '../views/users/user.view.vue'
import TermsOfUse from '../views/terms.of.use.vue'
import UserAdd from '../views/users/user.add.vue'
import RequestTemplatesList from '../views/request.templates/list.vue'
import RequestTemplateForm from '../views/request.templates/form.vue'
import GUI from '../views/gui.vue'
import Home from '../views/home.vue'

import EducationPosts from '../views/education/posts.vue'
import EducationPostView from '../views/education/post.view.vue'
import EducationPostsSearch from '../views/education/posts.search.vue'
import EducationTrainings from '../views/education/trainings.vue'
import EducationTrainingsSearch from '../views/education/trainings.search.vue'
import EducationTrainingCategory from '../views/education/training.category.vue'
import EducationTrainingView from '../views/education/training.view.vue'
import EducationTrainingPassage from '../views/education/training.passage.vue'
import EducationTrainingPosts from '../views/education/training.posts.vue'
import EducationTrainingPostView from '../views/education/training.post.view.vue'
import EducationTrainingsMy from '../views/education/trainings.my.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true, hideMenu: true }
  },

  /**
   * Системное
   */

  {
    path: '/login/',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false, hideMenu: true }
  },
  {
    path: '/restore/',
    name: 'restore',
    component: RestoreView,
    meta: { requiresAuth: false, hideMenu: true }
  },
  {
    path: '/scanner-qr/',
    name: 'scanner-qr',
    component: ScannerQr,
    meta: { requiresAuth: true, hideMenu: true, skipConfirm: true }
  },
  {
    path: '/scanner-nfc/',
    name: 'scanner-nfc',
    component: ScannerNfc,
    meta: { requiresAuth: true, hideMenu: true, skipConfirm: true }
  },

  {
    path: '/picture-editor/',
    name: 'picture-editor',
    component: PictureEditor,
    meta: { requiresAuth: true, hideMenu: true }
  },

  {
    path: '/terms-of-use/',
    name: 'terms-of-use',
    component: TermsOfUse,
    meta: { requiresAuth: false, hideMenu: true, noCache: true }
  },

  {
    path: '/gui/',
    name: 'gui',
    component: GUI,
    meta: { requiresAuth: false, hideMenu: true}
  },

  /**
   * Сервис "Обслуживание"
   */

  {
    path: '/requests/summary/',
    name: 'requests-summary',
    component: RequestsSummary,
    meta: { requiresAuth: true, service: 'requests'  }
  },
  {
    path: '/requests/',
    name: 'requests',
    component: Requests,
    meta: { requiresAuth: true, service: 'requests' },
  },
  {
    path: '/requests/edit/:id',
    name: 'request-form',
    component: RequestForm,
    meta: { requiresAuth: true, service: 'requests', hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/requests/master',
    name: 'request-master',
    component: RequestMaster,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true }
  },
  {
    path: '/requests/master/multi',
    name: 'request-master-multi',
    component: RequestMasterMuti,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true }
  },
  {
    path: '/requests/view/:id',
    name: 'request-view',
    component: RequestView,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0, activeTab: route.params.activeTab })
  },
  {
    path: '/requests/change-responsible/:id',
    name: 'request-change-responsible',
    component: RequestChangeResponsible,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true, noCache: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/requests/checklist-execute/:id',
    name: 'request-checklist-execute',
    component: RequestChecklistExec,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/requests/report-execute/:id',
    name: 'request-report-execute',
    component: RequestReportExec,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true, noCache: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/requests/search/',
    name: 'requests-search',
    component: RequestsSearch,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true }
  },

  {
    path: '/requests/equipment/',
    name: 'equipment',
    component: Equipment,
    meta: { requiresAuth: true, service: 'requests'  }
  },
  {
    path: '/requests/equipment/view/:id',
    name: 'equipment-view',
    component: EquipmentView,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/requests/equipment/view/:id/request-master',
    name: 'equipment-request-master',
    component: RequestMaster,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true },
    props: route => ({ equipmentId: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/requests/equipment/search/',
    name: 'equipment-search',
    component: EquipmentSearch,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true }
  },
  {
    path: '/requests/equipment/edit/:id',
    name: 'equipment-form',
    component: EquipmentForm,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true },
     props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },

  {
    path: '/requests/templates/',
    name: 'request-templates',
    component: RequestTemplatesList,
    meta: { requiresAuth: true, service: 'requests', hideMenu: true }
  },
  {
    path: '/requests/templates/edit/:id',
    name: 'request-template-form',
    component: RequestTemplateForm,
    meta: { requiresAuth: true, service: 'requests' , hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },


  /**
   * Сервис "Обучение"
   */

  {
    path: '/education/',
    name: 'education-posts',
    component: EducationPosts,
    meta: { requiresAuth: true, service: 'education' }
  },
  {
    path: '/education/posts/search/',
    name: 'education-posts-search',
    component: EducationPostsSearch,
    meta: { requiresAuth: true, service: 'education', hideMenu: true }
  },
  {
    path: '/education/post/:id/',
    name: 'education-post-view',
    component: EducationPostView,
    meta: { requiresAuth: true, service: 'education', hideMenu: true, noCache: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/education/trainings/',
    name: 'education-trainings',
    component: EducationTrainings,
    meta: { requiresAuth: true, service: 'education' }
  },
  {
    path: '/education/trainings/search/',
    name: 'education-trainings-search',
    component: EducationTrainingsSearch,
    meta: { requiresAuth: true, service: 'education', hideMenu: true }
  },
  {
    path: '/education/trainings/category/:id/',
    name: 'education-training-category',
    component: EducationTrainingCategory,
    meta: { requiresAuth: true, service: 'education', hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/education/trainings/:id/',
    name: 'education-training-view',
    component: EducationTrainingView,
    meta: { requiresAuth: true, service: 'education', hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/education/trainings/:id/passage/',
    name: 'education-training-passage',
    component: EducationTrainingPassage,
    meta: { requiresAuth: true, service: 'education', hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/education/trainings/:id/posts/',
    name: 'education-training-posts',
    component: EducationTrainingPosts,
    meta: { requiresAuth: true, service: 'education', hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/education/trainings/:trainingId/posts/:id/',
    name: 'education-training-post-view',
    component: EducationTrainingPostView,
    meta: { requiresAuth: true, service: 'education', hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0, trainingId: Number.parseInt(route.params.trainingId) || 0 })
  },

  {
    path: '/education/trainings/my/:status/',
    name: 'education-training-my',
    component: EducationTrainingsMy,
    meta: { requiresAuth: true, service: 'education', hideMenu: true },
    props: route => ({ status: route.params.status })
  },
  

  /**
   * Общие экраны, завязанные на сервисы
   */

  {
    path: '/:service/more/',
    name: 'more',
    component: More,
    meta: { requiresAuth: true, }
  },
  {
    path: '/:service/departments/',
    name: 'departments',
    component: Departments,
    meta: { requiresAuth: true, hideMenu: true }
  },
  {
    path: '/:service/departments/view/:id',
    name: 'department-view',
    component: DepartmentView,
    meta: { requiresAuth: true, hideMenu: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/:service/departments/view/:id/users',
    name: 'department-users',
    component: Users,
    meta: { requiresAuth: true, hideMenu: true, noCache: true },
    props: route => ({ departmentId: Number.parseInt(route.params.id) || 0 })
  },
  {
    path: '/:service/departments/view/:id/users/add/',
    name: 'user-add',
    component: UserAdd,
    meta: { requiresAuth: false, hideMenu: true, noCache: true },
    props: route => ({ departmentId: Number.parseInt(route.params.id) || 0 })
  },
  
  {
    path: '/:service/users/view/:id',
    name: 'user-view',
    component: UserView,
    meta: { requiresAuth: true, hideMenu: true, noCache: true },
    props: route => ({ id: Number.parseInt(route.params.id) || 0 })
  },
  
  


  /**
   * Общие экраны без меню
   */

  {
    path: '/accounts/',
    name: 'accounts',
    component: Accounts,
    meta: { requiresAuth: true, hideMenu: true }
  },
  {
    path: '/accounts/add',
    name: 'accounts-add',
    component: LoginView,
    props: { mode: "add" },
    meta: { requiresAuth: true, hideMenu: true }
  },
  {
    path: '/profile/',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true, hideMenu: true }
  },
  {
    path: '/password-change/',
    name: 'password-change',
    component: PasswordChange,
    meta: { requiresAuth: true, hideMenu: true }
  },
  {
    path: '/settings/',
    name: 'settings',
    component: Settings,
    meta: { requiresAuth: true, hideMenu: true, noCache: true }
  },
  
  
]

//подписываемся на событие "возвтрата" (обязательно до инициализации router)
window.addEventListener('popstate', (event) => {

  //если есть колбэки на возврат
  if (store.getters.getBackStack.length > 0) {
    let callback = store.getters.getBackStack[store.getters.getBackStack.length - 1];
    callback();
    //запрещаем router'у реагировать на этот возврат
    event.stopImmediatePropagation();
  } else {
    store.commit('setIsNowGoBack', true);
  }
})

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.afterEach((to, from) => {

  //если не начальный экран инициализции приложения
  //(чтобы при загрузке переход к первому экрану был без анимации)
  if (from.name) {
    //если один из экранов не входит в меню
    if (!store.getters.isRouteInMenu(to.path) || !store.getters.isRouteInMenu(from.path)) {
      //применяем анимацию
      to.meta.transitionName = store.getters.getIsNowGoBack ? 'slide-right' : 'slide-left';
    } else {
      //иначе анимация не нужна
      to.meta.transitionName = "";
    }


  } else {
    to.meta.transitionName = "";
  }

  store.commit('setIsNowGoBack', false);

  // eslint-disable-next-line no-undef
  ym(90666751, 'hit', window.location.href, {
    title: to.meta.name
  });
})

router.beforeEach((to, from) => {

  //проверка необходимости авторизации
  if (to.meta.requiresAuth && !store.getters.isAuthorized) {
    return {
      path: '/login'
    }
  }

  //проверка на принятие правил использования
  if(to.name != "terms-of-use" && store.getters.isAuthorized && store.getters.isNativePlatform) {
    if(!store.getters.getTermsOfUseAccepted) {
      return {
        path: '/terms-of-use/'
      }
    }
  }

  //если переход на экран выбора сервисов и этот переход по сути при старте (from не измвестен)
  if(to.name == 'home' && (!from || !from.name)) {

    //если пользователю доступен всего один сервис
    if(store.getters.getServices.length == 1) {
      //то установим его как текущий выбранный
      store.commit("setService", store.getters.getServices[0].toUpperCase());
    }

    //если выбранный сервис был сохранён ранее
    if(store.getters.getService) {
      if (store.getters.getService == "REQUESTS") {
        return {
          path: '/requests/summary/'
        }
       
      } else if (store.getters.getService == "EDUCATION") {
        return {
          path: '/education/'
        }
      }
    }
  }

  //скрыть ли меню
  store.commit('setHideMenu', to.meta.hideMenu == true);

  //если по URL определили сервис
  if(to.params.service) {
    store.commit("setService", to.params.service.toUpperCase());
  } 
  //или если экран жёстко привязан к сервису
  else if (to.meta.service) {
    store.commit("setService", to.meta.service.toUpperCase());
  }

  //добавляем для html класс, который указывает на текущий экран
  document.documentElement.classList.add('view-' + to.name);
  document.documentElement.classList.remove('view-' + from.name);

});

export default router
