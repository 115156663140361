<template>
    <div class="sbs-edu-pit" v-if="item.tags && item.tags.length > 0">
        <div class="tag roboto-2-regular" v-for="tag in item.tags" :key="tag.id" @click.stop="onClick(tag)">#{{ tag.name
            }}</div>
    </div>
</template>

<script>

export default {
    name: "sbs-education-post-item-tags",
    components: {

    },
    data() {
        return {
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        }
    },

    methods: {

        onClick(tag) {
            let filter = {
                tag_id: [
                    {
                        id: tag.id,
                        name: tag.name,
                    },
                ],
            };

            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", {
                name: "EDUCATION_POSTS",
                value: filter
            });

        }
    }
}
</script>

<style lang="scss">
.sbs-edu-pit {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .tag {
        cursor: pointer;
        color: var(--news-card-news-card-secondary-text-color);
    }

}
</style>
