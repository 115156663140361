<template>
  <div class="sbs-message" :class="[visible ? 'show' : '', type == 'success' ? 'success' : 'error']" @click="hide">
    <div class="message" v-html="message"></div>
  </div>
</template>

<script>
import bus from "@/plugins/bus";

export default {
  name: "sbs-message",
  data() {
    return {
      message: "",
      visible: false,
      timer: 0,
      type: "error",
    };
  },
  computed: {

  },
  methods: {
    show(params) {
      if (this.timer > 0) {
        clearTimeout(this.timer);
        this.timer = 0;
      }

      this.message = params.message;
      this.visible = true;

      if (params.type) {
        this.type = params.type;
      } else {
        this.type = "error";
      }

      this.timer = setTimeout(this.hide, params.timeout && params.timeout > 0 ? params.timeout : 6000);
    },

    hide() {
      if (this.timer > 0) {
        clearTimeout(this.timer);
        this.timer = 0;
      }

      this.visible = false;
    },
  },
  mounted() {
    //подписваемся на событие показа сообщения
    bus.on("SBS_MESSAGE_E_SHOW", this.show);
  },
  unmounted() {
    //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
    bus.off("SBS_MESSAGE_E_SHOW", this.show);
  },
};
</script>

<style lang="scss">
.sbs-message {
  position: fixed;
  bottom: -100px;
  right: 15px;
  left: 15px;
  padding: 18px 15px;
  text-align: left;
  z-index: 199999;
  font-size: 14px;
  border-radius: 8px;

  opacity: 0;

  transition: bottom 0.2s ease-out, opacity 0.2s ease-out;

  &.show {
    bottom: calc(var(--safe-area-bottom, 0px) + 10px);
    opacity: 1;
  }

  &.success {
    background-color: var(--color-message-success-background);
    color: var(--color-message-success-text);
  }

  &.error {
    background-color: var(--color-message-error-background);
    color: var(--color-message-error-text);
  }
}
</style>