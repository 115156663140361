<template>
  <sbs-view :class="'sbs-v-requests'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">

    <template v-slot:header>
      <sbs-header :showBackBtn="false" :showLogo="true">
        <template v-slot:center>
          <div class="title">{{ $t('requests.list.title') }}</div>
        </template>
        <template v-slot:right>
          <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="onSearchClick">
            <span class="icon fi fi-search"></span>
          </div>
          <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="onFilterClick">
            <span class="icon fi fi-filter"></span>
            <div class="counter nunito-2-medium" v-if="filterCounter > 0">{{ filterCounter }}</div>
          </div>
        </template>
      </sbs-header>

    </template>

    <template v-slot:content>

      <div class="list">

        <div :class="{ visible: showNewRequests }" class="new-request btn btn1 slim" @click="reload">
          {{ $t('requests.list.new_request') }}
          <span class="fi fi-refresh icon"></span>

        </div>

        <div class="tabs">
          <sbs-tabs :items="tabs" v-model:active="currentTab" />
        </div>

        <template v-if="items != null">
          <!-- Если список пуст-->
          <template v-if="items.length == 0">

            <!-- Если не применён фильтр -->
            <template v-if="!filtered">
              <sbs-empty :title="$t('requests.list.empty.title')"
                :text="currentTab == 'unread' ? $t('requests.list.empty.unread') : $t('requests.list.empty.common')" />
            </template>

            <!-- Иначе применён фильтр -->
            <template v-else>
              <sbs-empty :title="$t('requests.list.empty.search_title')"
                :text="$t('requests.list.empty.search_text')" />
            </template>

          </template>

          <!-- Заявки -->
          <div v-else class="items">
            <sbs-request-item v-for="item in items" :key="item.id" :item="item" />
          </div>
        </template>
        <div ref="pager"></div>

        <!-- Загрузка -->
        <template v-if="loading">
          <div class="text-center">
            <sbs-loader-inline />
          </div>
        </template>
      </div>

      <sbs-requests-filter v-model:visible="showFilter" />

    </template>

    <template v-slot:footer>
      <!-- Кнопка -->
      <sbs-requests-widget />
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsTabs from "@/components/tabs.vue";
import sbsRequestItem from "@/components/requests/item.vue";
import sbsRequestsWidget from "@/components/requests/widget.vue";
import sbsEmpty from "@/components/empty.vue";
import sbsRequestsFilter from "@/components/requests/filter.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";

import { mapGetters } from "vuex";

export default {
  name: "sbs-v-requests",
  components: {
    sbsView,
    sbsHeader,
    sbsLoaderInline,
    sbsTabs,
    sbsRequestItem,
    sbsRequestsWidget,
    sbsEmpty,
    sbsRequestsFilter
  },
  data() {
    return {
      loading: true,
      items: null,
      pager: {
        size: 12,
        page: 1,
        cnt: 1,
      },
      currentTab: "",
      showNewRequests: false,
      checkNewRequestsTimer: 0,
      displayNfc: false,
      itemToTake: false,
      itemToStart: false,

      hideMenu: false,

      showFilter: false
    };
  },
  computed: {

    ...mapGetters({
      user: "getUserProfile",
      clientSettings: "getClientSettings",
      requestUnread: "getRequestUnread",
      requestUnreadCount: "getRequestUnreadCount",
      tab: "getRequestsTab",
      filterKey: "getRequestsTabFilterKey"
    }),

    isCommonTab() {
      //для системных вкладок фильтр должен быть общий
      return ["active", "responsible", "owner", "unread", "all"].includes(this.tab);
    },

    globalFilter() {
      return this.$store.getters.getFilter("REQUEST-" + this.filterKey);
    },

    filter() {
      return this.$store.getters.getFilter("REQUEST-" + this.filterKey);
    },

    filtered() {
      return Object.keys(this.filterApplied).length > 0;
    },

    filterCounter() {
      return Object.keys(this.filterApplied).length;
    },

    //Применённый фильтьр
    filterApplied() {
      let f = {};
      //фильтр по оборудованию
      if (this.filter.equipment && this.filter.equipment.length > 0) {
        f["equipment_id"] = this.filter.equipment.map((v) => v.id);
      }
      //фильтр по приоритету
      if (this.filter.priority && this.filter.priority.length > 0) {
        f["priority_id"] = this.filter.priority.map((v) => v.id);
      }
      //фильтр по типу
      if (this.filter.type && this.filter.type.length > 0) {
        f["type_id"] = this.filter.type.map((v) => v.id);
      }
      //фильтр по статусу
      if (this.filter.status && this.filter.status.length > 0) {
        f["status_id"] = this.filter.status.map((v) => v.id);
      }
      //фильтр по ответственному подразделению
      if (
        this.filter.responsibleDepartment &&
        this.filter.responsibleDepartment.length > 0
      ) {
        f["responsible_department_id"] = this.filter.responsibleDepartment.map(
          (v) => v.id
        );
      }
      //фильтр по ответственному сотруднику
      if (
        this.filter.responsibleUser &&
        this.filter.responsibleUser.length > 0
      ) {
        f["responsible_user_id"] = this.filter.responsibleUser.map((v) => v.id);
      }

      //фильтр по автору
      if (this.filter.createdBy && this.filter.createdBy.length > 0) {
        f["created_by"] = this.filter.createdBy.map((v) => v.id);
      }

      //фильтр по подразделению автора
      if (
        this.filter.createdDepartment &&
        this.filter.createdDepartment.length > 0
      ) {
        f["created_department_id"] = this.filter.createdDepartment.map(
          (v) => v.id
        );
      }

      //фильтр по датам
      let di = 100;
      ["created", "deadline"].forEach(code => {

        if (!this.filter[code] || this.filter[code].length == 0) {
          return;
        }

        let fd = {
          LOGIC: "OR",
        };

        let field = code == 'created' ? 'date_create' : 'deadline';

        this.filter[code].forEach((t, i) => {

          let fds = {};
          if (t.from) {
            fds[">=" + field] = t.from;
          }
          if (t.to) {
            fds["<" + field] = moment(t.to, "DD.MM.YYYY")
              .add(1, "d")
              .format("DD.MM.YYYY");
          }

          fd[i] = fds;

        });

        f[di] = fd;
        di++;
      })

      //фильтр по оценке
      if (this.filter.rating && this.filter.rating.length > 0) {
        f["rating"] = this.filter.rating.map((v) => v.id);
      }

      //фильтр - просрочено
      if (this.filter.overdue && this.filter.overdue.length > 0) {
        this.filter.overdue.forEach(item => {
          if (item.id == "Y") {
            f["<deadline"] = moment().format("DD.MM.YYYY HH:mm:ss");
          } else if (item.id == 'N') {
            f[">deadline"] = moment().format("DD.MM.YYYY HH:mm:ss");
          }
          f["!status_id"] = this.requestStatusFinished
        });
      }

      return f;
    },

    //фильтр с наложением фильтра по вкладке
    filterCombined() {
      let filter = Object.assign({}, this.filterApplied);

      //активные
      if (this.currentTab == "active") {
        filter["0"] = {
          "!status_id": this.requestStatusFinished,
        };
      }
      // мне (либо мои либо назначенные на моя подразделение без исполнителя)
      else if (this.currentTab == "responsible") {
        filter["0"] = {
          LOGIC: "OR",
          responsible_user_id: this.user ? this.user.id : false,
          0: {
            responsible_user_id: false,
            responsible_department_id: this.user ? this.user.department_id : 0,
          },
        };
      }
      // мне (либо мои либо назначенные на моя подразделение без исполнителя)
      else if (this.currentTab == "owner") {
        filter["0"] = {
          LOGIC: "OR",
          created_by: this.user ? this.user.id : false,
          created_department_id: this.user ? this.user.department_id : false,
        };
      }
      // не прочитанные
      else if (this.currentTab == "unread") {
        let ids = this.requestUnread.map(r => r.id);

        filter["0"] = {
          id: ids.length > 0 ? ids : -1
        };
      }

      return filter;
    },



    sort() {
      let sort = this.$store.getters.getSort("REQUEST-" + this.filterKey);
      if (!sort || Object.keys(sort).length == 0) {
        sort = {
          ID: "desc",
        }
      }
      return sort;
    },

    /**
     * Данные, передаваемые между экранами
     */
    savedFilters() {
      return this.$store.getters.getUserFiltersByType("REQUEST")
    },

    tabs() {
      let tabs = [
        {
          code: "active",
          name: this.$t('requests.list.tabs.active'),
        },
        {
          code: "responsible",
          name: this.$t('requests.list.tabs.responsible'),
        },
        {
          code: "owner",
          name: this.$t('requests.list.tabs.owner'),
        },
        {
          code: "unread",
          name: this.$t('requests.list.tabs.unread'),
          count: this.requestUnreadCount,
        },
        {
          code: "all",
          name: this.$t('requests.list.tabs.all')
        }
      ];

      //сохранённые фильтры
      this.savedFilters.forEach(item => {
        tabs.push({
          code: "saved-filter-" + item.id,
          name: item.name
        });
      });

      return tabs
    },

    //ИД статуса "Завершено"
    requestStatusFinished() {
      return this.clientSettings
        ? this.clientSettings.request_status_finished
        : false;
    },
  },
  watch: {
    filter: {
      handler() {
        if (this.$store.getters.isAuthorized) {
          this.reload();
        }
      },

      deep: true,
    },
    tab: function (newVal) {
      this.currentTab = newVal;
    },
    currentTab: function () {
      this.$store.commit("setRequestsTab", this.currentTab);
      this.reload();
    },
    user: function () {
      this.reload();
    },
  },

  methods: {
    refresh(resolve) {
      this.reload();
      setTimeout(() => {
        resolve();
      }, 300);
    },

    onFilterClick() {
      setTimeout(() => {
        this.showFilter = true;
      }, 300);
    },

    onSearchClick() {
      this.$router.push({
        name: "requests-search",
      });
    },

    /**
     * Загружает данные
     */
    async loadData() {
      //покажем экран загрузки
      this.loading = true;
      //скроем метку "есть новые заявки"
      this.showNewRequests = false;

      //ждём получение настроек клиента (чтобы знать ИД статусов)
      await this.waitClientSettings();

      //запрашиваем данные
      rest
        .call("request.list", {
          method: "post",
          data: {
            order: this.sort,
            paging: this.pager,
            filter: this.filterCombined,
            loadPermissions: "Y",
          },
        })
        .then((data) => {
          if (data.success) {
            if (this.pager.page == 1) {
              this.items = data.items;
            } else {
              this.items = this.items.concat(data.items);
            }
            this.pager = data.pager;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Обработка события сохранения заявки
     */
    onRequestSave(id) {
      let item = this.items.find((i) => i.id == id);

      //если заявка в списке отображения уже есть
      if (item) {
        this.syncRequest(item);
      } else {
        this.reload();
      }
    },

    /**
     * Синхронизация
     */
    syncRequest(item) {
      //запрашиваем данные
      rest
        .call("request.get", {
          method: "post",
          data: {
            id: item.id,
          },
        })
        .then((data) => {
          let index = this.items.indexOf(item);
          //если завка найдена
          if (data.success) {
            data.request.expanded = !!item.expanded;
            data.request.permissions = data.permissions;
            this.items[index] = data.request;
          }
          //иначе заявка не доступна и нужно её удалить из списка
          else {
            this.items.splice(index, 1);
          }
        });
    },

    /**
     * Перезагружает список
     */
    reload() {
      this.items = null;
      this.pager.page = 1;
      this.loadData();
    },

    onScroll(e) {
      let scroll = e.target.scrollTop;
      let height = e.target.offsetHeight;
      let offsetTop = this.$refs.pager.offsetTop;
      //если экран прокрутился до пейджера (высоту экрана за вычетом 70px под меню которые заререзрвированы)
      if (scroll + height - 70 > offsetTop) {
        //если экран не в стадии загрузки и есть следующие страницы
        if (!this.loading && this.pager.page < this.pager.cnt) {
          this.pager.page++;
          this.loadData();
        }
      }
    },

    /**
     * Обработка порлучения сообщения через вебсокет
     */
    onMessageReceive(data) {
      //если событие создания новой заявки
      if (data.event == "new_request") {
        //если такой заявки нет в списке
        if (!this.items.find((i) => i.id == data.requestId)) {
          this.showNewRequests = true;
        }
      }

    },

    /**
     * Ожидает получение клиентских настроек
     */
    waitClientSettings() {

      //создаём прамис, чтобы вызывающий компонент ждал
      return new Promise(resolve => {

        //если настройки уже прогрузились
        if (this.clientSettings) {
          resolve(true);
        } else {

          //следим за изменением настроек
          const watcher = this.$store.watch((state, getters) => { return getters.getClientSettings }, (newVal) => {
            //если не пустые 
            if (newVal) {
              //прекращаем отслеживание
              watcher();
              resolve(true);
            }
          });
        }

      });
    },

  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;

    this.loading = true;
    this.currentTab = this.tab;
    if (this.requestStatusFinished > 0) {
      //загружаем данные
      this.loadData();
    }
  },

  mounted() {
    //подписваемся на события
    bus.on("SBS_REQUEST_FORM_E_SAVE", this.onRequestSave);
    bus.on("SBS_REQUEST_E_UPDATED", this.onRequestSave);
    bus.on("SBS_WS_MESSAGE_E_RECEIVE", this.onMessageReceive);

    //подписываемся на скролинг экрана
    this.$refs.pager
      .closest(".view-content-scroll")
      .addEventListener("scroll", this.onScroll);
  },

  beforeUnmount() {
    this.$refs.pager
      .closest(".view-content-scroll")
      .removeEventListener("scroll", this.onScroll);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_REQUEST_FORM_E_SAVE", this.onRequestSave);
    bus.off("SBS_REQUEST_E_UPDATED", this.onRequestSave);
    bus.off("SBS_WS_MESSAGE_E_RECEIVE", this.onMessageReceive);
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
    });
  },
};
</script>

<style lang="scss">
.sbs-v-requests {

  //есть новая заявка
  .new-request {
    display: none;

    transform: scale(0, 0);
    transition: transform 0.5s cubic-bezier(0.25, 1.07, 0.54, 0.92);

    &.visible {
      display: inline-block;
      transform: scale(1, 1);
    }
  }

  .list {
    padding-bottom: 80px;
  }

}
</style>