<template>
  <div class="sbs-rs-nd">
    <canvas ref="canvas"></canvas>

    <div class="legend" v-if="statuses.length > 0">
      <div class="item" v-for="item in statuses" :key="item.id">
        <div class="color" :style="{ backgroundColor: item.color }"></div>
        <div class='name nunito-2-regular'>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend
);

import moment from "moment";
import bus from "@/plugins/bus";
import { shallowRef } from "vue";

export default {
  name: "sbs-requests-statistics-new-dynamics",
  components: {},
  data() {
    return {
      chart: false,
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {

    stacked() {
      return this.items.filter((i) => i.status_id > 0).length > 0;
    },

    statuses() {

      let statuses_ = this.items.map((i) => {
        return {
          id: i.status_id,
          name: i.status_name,
          color: window
            .getComputedStyle(document.documentElement)
            .getPropertyValue(i.status_color.replace("var(", "").replace(")", "")),
          icon: i.status_icon,
          sort: parseInt(i.status_sort),
        };
      });

      //уникальные статусы
      let statuses = [];
      statuses_.forEach((element) => {
        if (statuses.filter((s) => s.id == element.id).length === 0) {
          statuses.push(element);
        }
      });
      //сортируем статусы
      statuses.sort((a, b) => {
        if (a.sort < b.sort) {
          return -1;
        }
        if (a.sort > b.sort) {
          return 1;
        }
        return 0;
      });

      return statuses;
    }
  },
  methods: {
    draw() {
      let ctx = this.$refs.canvas;

      Chart.defaults.font.family = 'Nunito';
      this.chart = shallowRef(
        new Chart(ctx, {
          type: "bar",
          data: this.data(),
          options: this.options(),
        })
      );
    },

    refresh() {
      this.chart.options = this.options();
      this.chart.update();
    },

    data() {
      if (!this.stacked) {
        return this.dataSimple();
      }
      return this.dataStacked();
    },

    /**
     * Данные без группировки по статусам
     */
    dataSimple() {
      return {
        labels: this.items.map((i) =>
          moment(i.date_create_date, "DD.MM.YYYY hh:mm:ss").format("DD.MM")
        ),
        datasets: [
          {
            data: this.items.map((i) => i.cnt),
            backgroundColor: window
              .getComputedStyle(document.documentElement)
              .getPropertyValue("--orange-400"),
            borderColor: window
              .getComputedStyle(document.documentElement)
              .getPropertyValue("--orange-400"),
            borderWidth: 1,
          },
        ],
      };
    },

    /**
     * Данные с группировкой по статусам
     */
    dataStacked() {


      //уникальные даты
      let dates = this.items
        .map((i) => i.date_create_date)
        .filter((value, index, self) => self.indexOf(value) === index);

      //готовим наборы данных по датам для каждого статуса
      let dataSets = [];
      for (let key in this.statuses) {
        let status = this.statuses[key];
        let dataSet = {
          label: status.name,
          data: [],
          backgroundColor: status.color,
        };
        dates.forEach((date) => {
          let cnt = 0;
          let item = this.items.find(
            (i) => i.date_create_date == date && i.status_id == status.id
          );
          if (item) {
            cnt = parseInt(item.cnt);
          }

          dataSet.data.push(cnt);
        });

        dataSets.push(dataSet);
      }

      return {
        labels: dates.map((date) =>
          moment(date, "DD.MM.YYYY hh:mm:ss").format("DD.MM")
        ),
        datasets: dataSets,
      };
    },

    /**
     * Опции графика
     */
    options() {
      return {
        plugins: {
          legend: {
            display: false,
            position: "bottom",
            align: "start",
            labels: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-secondary-text-color"),
            },
          },
        },
        aspectRatio: this.stacked ? (this.windowWidth <= 767 ? 1.5 : 4) : 2,

        scales: {
          x: {
            stacked: this.stacked,
            ticks: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-secondary-text-color"),
            },
            grid: {
              borderColor: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-line-color"),
              display: false,
            },
          },
          y: {
            stacked: this.stacked,
            ticks: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-secondary-text-color"),
            },
            grid: {
              borderColor: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-line-color"),
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-line-color"),
            },
            beginAtZero: true,
          },
        },
      };
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.refresh();
    }
  },

  created() {
    this.windowWidth = window.innerWidth;
  },
  mounted() {
    this.draw();

    //подписываемся на событие завершения смены темы
    bus.on("SBS_APP_E_THEME_CHANGED", this.refresh);
    window.addEventListener("resize", this.onResize);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_APP_E_THEME_CHANGED", this.refresh);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
.sbs-rs-nd {
  .legend {
    margin-top: 17px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px 15px;

    .item {
      display: flex;
      column-gap: 5px;
      align-items: center;

      .color {
        width: 25px;
        height: 13px;
        border-radius: 5px;
      }

      .name {
        color: var(--news-card-news-card-primary-text-color);
        white-space: nowrap;
      }
    }
  }
}
</style>