<template>
  <div class="sbs-rs-cbcd">
    <canvas ref="canvas"></canvas>

    <div class="legend" v-if="itemsSorted.length > 0">
      <div class="item" v-for="item in itemsSorted" :key="item.created_department_id">
        <div class="cnt nunito-3-bold">{{ item.cnt }}</div>
        <div class="name nunito-3-regular">{{ item.created_department_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart,
  BarElement,
  BarController,
  PieController,
  ArcElement,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(
  BarElement,
  ArcElement,
  BarController,
  PieController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend
);

import bus from "@/plugins/bus";
import { shallowRef } from "vue";

export default {
  name: "sbs-requests-statistics-closed-by-created-departments",
  components: {},
  data() {
    return {
      chart: false,
      colors: [
        "#FFAD32",
        "#FFC972",
        "#FFBB52",
        "#FFF3D3",
        "#FFE5B3",
        "#FFD793",
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    stacked() {
      return this.items.filter((i) => i.status_id > 0).length > 0;
    },

    /**
     * Отсортированный и урезанный до 6шт список
     */
    itemsSorted() {

      let itemsSorted = this.items.map((i) => i);

      itemsSorted.sort((a, b) => parseFloat(b.cnt) - parseFloat(a.cnt));

      //ограничим кол-во выводимых данных
      let max = 5;
      if (itemsSorted.length > max) {
        let other = itemsSorted.slice(max);
        itemsSorted = itemsSorted.slice(0, max);

        let cnt = 0;
        other.forEach((item) => {
          cnt += parseFloat(item.cnt);
        });

        itemsSorted.push({ created_department_name: "Другие", cnt: cnt });
      }

      return itemsSorted;

    }
  },
  methods: {
    draw() {
      let ctx = this.$refs.canvas;

      Chart.defaults.font.family = 'Nunito';
      this.chart = shallowRef(
        new Chart(ctx, {
          plugins: [ChartDataLabels],
          type: "doughnut",
          data: this.data(),
          options: this.options(),

        })
      );
    },

    refresh() {
      this.chart.options = this.options();
      this.chart.update();
    },

    data() {

      let labels = this.itemsSorted.map((item) => item.created_department_name);
      let data = this.itemsSorted.map((item) => parseInt(item.cnt));

      return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: data.map((item, index) => this.colors[index]),
            borderWidth: 1,
            borderColor: window
              .getComputedStyle(document.documentElement)
              .getPropertyValue("--audit-cards-audit-cards-bg-color")
          },
        ],
      };
    },

    /**
     * Опции графика
     */
    options() {
      return {
        indexAxis: "y",
        responsive: true,
        aspectRatio: this.windowWidth <= 767 ? 1.1 : 4,
        plugins: {
          legend: {
            display: false,
            position: "bottom",
            align: "start",
            labels: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--news-card-news-card-secondary-text-color"),
            },
          },

          title: {
            display: false,
          },
          datalabels: {
            color: window
              .getComputedStyle(document.documentElement)
              .getPropertyValue("--platinum-600")
          }
        },
        cutout: "40%"
      };
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.refresh();
    }
  },

  created() {
    this.windowWidth = window.innerWidth;
  },

  mounted() {
    this.draw();

    //подписываемся на событие завершения смены темы
    bus.on("SBS_APP_E_THEME_CHANGED", this.refresh);
    window.addEventListener("resize", this.onResize);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_APP_E_THEME_CHANGED", this.refresh);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
.sbs-rs-cbcd {

  .legend {
    margin-top: 20px;

    .item {
      display: grid;
      grid-template-columns: 25% 1fr;
      column-gap: 5px;
      margin-bottom: 10px;

      .cnt {
        text-align: right;
        color: var(--service-color-1);
      }

      .name {
        color: var(--news-card-news-card-primary-text-color);
      }
    }
  }
}
</style>