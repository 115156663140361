<template>
  <sbs-control-input v-model="value" type="text" :label="label" :name="name" :required="required" :readonly="true"
    :classes="['time']" @click="onClick()">
    <template v-slot:icon>
      <div v-if="value == '' || hideClear" class="icon">
        <span class="fi fi-time"></span>
      </div>
      <div v-else-if="!hideClear" class="icon" @click.stop="clear">
        <span class="fi fi-close"></span>
      </div>
    </template>
  </sbs-control-input>

  <sbs-sheet v-model:visible="isOpen" :onlyHeaderSwipe="true" :maxWidth="400">
    <template v-slot:body>
      <div class="sbs-picker-time">
        <div class="pickers">
          <VueScrollPicker :options="hours" v-model="hour" />
          <VueScrollPicker :options="minutes" v-model="minute" />
        </div>
        <div class="buttons">
          <div class="btn btn-block btn2" @click="clear">{{ $t('controls.calendar.reset') }}</div>
          <div class="btn btn-block btn1" @click="apply">{{ $t('controls.calendar.apply') }}</div>
        </div>
      </div>
    </template>
  </sbs-sheet>
</template>

<script>
import sbsControlInput from "@/components/controls/input.vue";
import sbsSheet from '@/components/sheet.vue'
import { VueScrollPicker } from "vue-scroll-picker";

import moment from "moment";

import "vue-scroll-picker/lib/style.css";

export default {
  name: "sbs-control-time",
  components: {
    sbsControlInput,
    sbsSheet,
    VueScrollPicker,
  },
  data() {
    return {
      value: this.modelValue,
      isOpen: false,
      hour: this.getHour(),
      minute: this.getMinute(),
    };
  },
  emits: ["update:modelValue"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },

    name: {
      type: String,
      default() {
        return "";
      },
    },
    modelValue: {
      type: String,
      default() {
        return "";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hideClear: {
      type: Boolean,
      default() {
        return false;
      },
    },


  },
  computed: {
    hours() {
      return Array.from({ length: 24 }, (_, index) => ("0" + index).slice(-2));
    },
    minutes() {
      return Array.from({ length: 12 }, (_, index) =>
        ("0" + index * 5).slice(-2)
      );
    },
  },
  watch: {
    modelValue: function (newVal) {
      if (newVal !== this.value) {
        this.value = newVal;
      }
    },
    value: function () {
      this.$emit("update:modelValue", this.value);
    },
  },
  methods: {
    onClick() {
      this.isOpen = true;
    },

    getHour() {
      if (this.modelValue != undefined && this.modelValue != "") {
        return moment(this.modelValue, "HH:mm").format("HH");
      }
      return "00";
    },

    getMinute() {
      if (this.modelValue != undefined && this.modelValue != "") {
        return moment(this.modelValue, "HH:mm").format("mm");
      }
      return "00";
    },

    clear() {
      this.value = "";
      this.isOpen = false;
    },

    apply() {
      this.value =
        ("0" + this.hour).slice(-2) + ":" + ("0" + this.minute).slice(-2);
      this.isOpen = false;
    }
  },
};
</script>

<style lang="scss">
.sbs-picker-time {

  .pickers {
    display: flex;
  }

  .vue-scroll-picker {
    height: 170px;
  }

  .vue-scroll-picker-item {
    position: relative;
    color: var(--calendar-cells-cells-regular-unselected-text-color);
    font-size: 35px;
    padding: 8px 0px;
    transform: scale(1);

    &::after {
      content: ":";
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
    }
  }

  .vue-scroll-picker:last-of-type {
    .vue-scroll-picker-item {
      &::after {
        display: none;
      }
    }

  }

  .vue-scroll-picker-item-selected {
    color: var(--calendar-cells-cells-regular-unselected-text-color);
    transform: scale(1);
  }

  .vue-scroll-picker-layer-bottom {
    border-top: none;
    background: linear-gradient(to top,
        var(--bg) 0%,
        transparent 100%);
    height: calc(50% - 20px);
  }

  .vue-scroll-picker-layer-top {
    border-bottom: none;
    background: linear-gradient(to bottom,
        var(--bg) 0%,
        transparent 100%);
    height: calc(50% - 20px);
  }

  .vue-scroll-picker-layer-selection {
    top: calc(50% - 20px);
    bottom: calc(50% - 20px);
  }

  .buttons {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    column-gap: 10px;
  }
}
</style>
