<template>
  <div class="sbs-user-item" @click="onItemClick(item)">
    <div class="photo">
      <div v-if="item.photo" class="picture" :style="{
    backgroundImage: 'url(' + item.photo.preview + ')',
  }"></div>
      <span v-else class="icon fi fi-user"></span>
    </div>

    <div>
      <div v-if="item.last_name || item.name" class="fio nunito-4-bold">
        {{ item.last_name }} {{ item.name }}
      </div>

      <div v-if="item.work_position" class="position nunito-3-medium">
        {{ item.work_position }}
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "sbs-user-item",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {

    ...mapGetters({
      service: "getService"
    }),
  },

  methods: {
    /**
     * Переход к пользователю
     */
    onItemClick(item) {
      this.$router.push({
        name: "user-view",
        params: { id: item.id, service: this.service.toLowerCase() },
      });
    },
  },
};
</script>

<style lang="scss">
.sbs-user-item {
  position: relative;
  padding: 15px;
  border-radius: 15px;

  background: var(--audit-cards-audit-cards-bg-color);
  box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
  cursor: pointer;
  margin-bottom: 10px;

  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 50px 1fr;
  grid-gap: 0px 10px;

  .photo {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    .picture {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-size: cover;
      border-radius: 50%;
    }

    .icon {
      font-size: 29px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--news-card-news-card-secondary-text-color);
    }
  }

  .fio {
    color: var(--audit-cards-audit-cards-title-text-color);

  }

  .position {
    margin-top: 5px;

    color: var(--news-card-news-card-secondary-text-color);
  }
}
</style>