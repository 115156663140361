import store from '@/store'
import moment from "moment";

const utils = {

    fancybox: false,
    fancyboxWatchClose: false,

    /**
     * конвертирет цвет 3-х буквенный в 6-ти
     */
    convertHexColor(value) {
        if (value.length === 4) {
            value = "#" + value.substring(1, 4).split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }

        return value;
    },

    /**
     * Конвертирует строку в формате base64 в Blob
     * @param {string} b64Data строка в формате base64
     * @param {string} contentType тип файла
     * @param {int} sliceSize размер блока для обратботки
     * @returns Blob
     */
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    },

    /**
     * Поулчает из видео файла метаданные и превью
     * @param {Blob} file 
     * @returns 
     */
    generateVideoThumbnail(file) {

        return new Promise((resolve,reject) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement("video");

            // this is important
            video.autoplay = true;
            video.muted = true;

            var source = document.createElement('source');

            source.src = URL.createObjectURL(file)+"#t=0.1";
            source.type = "video/mp4";
            video.appendChild(source);

            let data = {};

            video.onloadedmetadata = () => {

                data = {
                    width: video.videoWidth,
                    height: video.videoHeight,
                    duration: video.duration
                };
            }

            video.onloadeddata = () => {

                let ctx = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                data.thumb = canvas.toDataURL("image/png");
                return resolve(data);
            };

            video.onerror = (e) => {
                reject(e);
            };
        });
    },

    /**
     * Устанавилвает Fancybox правильную позицию тулбара
     */
    setFancyboxToolTop(fancybox) {
        let safeArea = store.getters.getSafeArea;
        if(safeArea && safeArea.top > 0 ) {
            let toolbar = fancybox.$container.querySelector('.fancybox__toolbar');
            if(toolbar) {
                toolbar.style.top = safeArea.top+"1px";
            }
        }
    },

    /**
     * Обработка готовности Fancybox
     */
    fancyBoxOnReady(fancybox) {

        //устанавилваем правильную позицию тулбара
        let safeArea = store.getters.getSafeArea;
        if(safeArea && safeArea.top > 0 ) {
            let toolbar = fancybox.$container.querySelector('.fancybox__toolbar');
            if(toolbar) {
                toolbar.style.top = safeArea.top+"px";
            }
        }

        //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
        window.history.pushState({}, "", window.location.href + "?photoOpen");
        //добавляем закрытие в стэк обработчиков возврата назад
        store.commit("addToBackStack", this.facyBoxOnHistoryBack);
        this.fancybox = fancybox;
        this.fancyboxWatchClose = true;
    },

    /**
     * Обработка закрытия Fancybox
     */
    fancyBoxOnClose() {
        if (this.fancyboxWatchClose) {
            history.back();
        } else {
            //удаляем закрытие из стэка обработчиков возврата назад
            store.commit("removeFromBackStack", this.facyBoxOnHistoryBack);
        }
        
    },

    /**
     * Обработка нажатия назад в браузере
     */
    facyBoxOnHistoryBack() {
        utils.fancyboxWatchClose = false;
        utils.fancybox.close();
    },

    /**
     * Возвращает фильтр по датам
     */
    getFilterForDates(values, field) {

        let fd = {
            LOGIC: "OR",
        };

        values.forEach((t, i) => {
            let fds = {};
            if (t.from) {
                fds[">="+field] = t.from;
            }
            if (t.to) {
                fds["<"+field] = moment(t.to, "DD.MM.YYYY")
                    .add(1, "d")
                    .format("DD.MM.YYYY");
            }

            fd[i] = fds;
        });

        return fd;
    }
}

export default utils;