<template>
  <div class="sbs-departments-list" ref="container">
    <template v-if="items != null">

      <!-- Если список пуст-->
      <template v-if="items.length == 0">
        <sbs-empty :title="$t('departments.list.empty.title')" :text="$t('equipment.list.empty.text')" />
      </template>

      <!-- Список -->
      <div v-else class="items">
        <sbs-department-item v-for="item in items" :key="item.id" :item="item" />
      </div>
    </template>

    <!-- Загрузка -->
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsDepartmentItem from "@/components/departments/item.vue";
import sbsEmpty from "@/components/empty.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-departments-list",
  components: {
    sbsLoaderInline,
    sbsDepartmentItem,
    sbsEmpty
  },
  data() {
    return {
      loading: true,
      items: null,
    };
  },
  props: {
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
  },

  watch: {
    reload: function () {
      this.refresh();
    },
  },

  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;

      //запрашиваем данные
      rest
        .call("department.list", {
          method: "post",
          data: {
            filter: {
              section_id: 0,
              active: "Y",
            },
          },
        })
        .then((data) => {
          if (data.success) {
            this.items = data.items;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Перезагружает список
     */
    refresh() {
      this.items = null;
      this.loadData();
    },
  },

  created() {
    //загружаем данные
    this.loadData();
  },
};
</script>
