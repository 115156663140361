<template>
    <div class="sbs-requests-statistics-rbru">
        <div class="items">
            <template v-for="(item, index) in sortedItems" :key="item.responsible_user_id">
                <div v-if="index < 10 || user && user.id == item.responsible_user_id" class="item nunito-3-regular">
                    <div class="n">
                        <div class="index">
                            <span v-if="index < 3" class="icon fi"
                                :class="{ 'fi-first-place': index == 0, 'fi-second-place': index == 1, 'fi-third-place': index == 2 }"></span>
                            {{ index + 1 }}
                        </div>
                    </div>
                    <div>
                        <div class="avatar">
                            <div v-if="!item.responsible_user_photo" class="initials">
                                {{ item.responsible_user_last_name.charAt(0) + item.responsible_user_name.charAt(0) }}
                            </div>
                            <div v-else class="photo" :style="{
                backgroundImage: 'url(' + item.responsible_user_photo.preview + ')',
            }"></div>
                        </div>
                    </div>
                    <div>
                        <div class="fio">
                            {{ item.responsible_user_last_name }} {{ item.responsible_user_name }}
                            <template v-if="user && user.id == item.responsible_user_id">
                                ({{ $t('requests.summary.you') }})
                            </template>
                        </div>
                    </div>
                    <div>

                        <div class="rating">
                            <div class="value">{{ Math.round(item.rating_avg * 100) / 100 }}</div>
                            <div class="cnt">({{ item.cnt }})</div>
                            <div class="icon"><span class="fi fi-star-filled"></span></div>
                        </div>

                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: "sbs-requests-statistics-rating-by-responsible-users",
    components: {
    },
    data() {
        return {

        };
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        ...mapGetters({
            user: "getUserProfile",
        }),

        sortedItems() {
            let itemsSorted = this.items.map((i) => i);

            itemsSorted.sort((a, b) => parseFloat(b.rating_avg) - parseFloat(a.rating_avg));

            return itemsSorted;
        }
    },
    methods: {
    },

};
</script>


<style lang="scss">
.sbs-requests-statistics-rbru {

    color: var(--news-card-news-card-primary-text-color);


    .item {
        display: grid;
        grid-template-columns: 30px 30px 1fr auto;
        column-gap: 10px;
        align-items: center;
        margin-bottom: 15px;

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
            font-weight: 900;
        }

        &:nth-of-type(11) {
            padding-top: 16px;
            border-top: 1px solid var(--news-card-news-card-line-color);
        }

        .n {
            text-align: center;
            display: grid;
            justify-content: center;
            color: var(--news-card-news-card-secondary-text-color);
        }

        .index {
            position: relative;
            line-height: 1;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 24px;
            }
        }

        &:nth-of-type(1) {
            .index {
                color: #DAA100;
            }
        }

        &:nth-of-type(2) {
            .index {
                color: #C7C7C7;
            }
        }

        &:nth-of-type(2) {
            .index {
                color: #DCA380;
            }
        }

        .avatar {
            position: relative;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--news-card-news-card-secondary-text-color);

            .initials {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: var(--audit-cards-audit-cards-title-text-color);
            }

            .photo {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-size: cover;
            }
        }

        .rating {
            display: grid;
            grid-template-columns: auto auto 18px;
            align-items: center;
            column-gap: 5px;

            line-height: 1;

            .cnt {
                color: var(--news-card-news-card-secondary-text-color);
            }

            .icon {
                font-size: 17px;
                color: var(--orange-400);
            }
        }
    }
}
</style>