<template>
  <transition :name="allowAnimation ? (!isNowGoBack ? 'slide-menu' : 'slide-menu-back') : ''
    ">
    <div class="sbs-menu" v-if="visible">
      <div class="items container">
        <div v-for="item in itemsReduced" :key="item.id" class="item" :class="{ current: current === item.url }"
          :style="{ width: '25%' }" @click.prevent="onItemClick(item)" @touchstart.prevent="onItemClick(item)"
          v-ripple.300="'rgba(100, 100, 100, 0.15)'">
          <div class="icon-wrap">
            <div class="icon-inner">
              <span class="icon fi" :class="item.icon"></span>
              <div v-if="item.type == 'requests' && requestUnreadCount > 0" class="counter nunito-2-medium">
                {{ requestUnreadCount > 99 ? 99 : requestUnreadCount }}
              </div>
            </div>
          </div>

          <div class="name nunito-1-bold">{{ item.name }}</div>
        </div>

        <div v-if="serviceItems.length > 4" class="item" :class="{
    current: current.includes('/more/'),
  }" :style="{ width: '25%' }" @click.prevent="onItemMoreClick()" @touchstart.prevent="onItemMoreClick()"
          v-ripple.300="'rgba(100, 100, 100, 0.15)'">
          <div class="icon-wrap">
            <span class="icon fi fi-more"></span>
          </div>

          <div class="name nunito-1-bold">Ещё</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import localStorage from "@/plugins/localStorage";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  name: "sbs-menu",
  data() {
    return {
      allowAnimation: false,
    };
  },
  computed: {
    ...mapGetters({
      items: "getMenu",
      isNowGoBack: "getIsNowGoBack",
      requestUnreadCount: "getRequestUnreadCount",
      service: "getService"
    }),
    current() {
      return this.$route.path;
    },
    visible() {
      return store.getters.isAuthorized && !store.getters.isHideMenu;
    },

    /**
     * Пункты меню, относящиеся к текущему сервису
     */
    serviceItems() {
      return this.items.filter(item => item.service == this.service);
    },

    /**
     * Пункты меню, обрезанные не больше 4-х
     */
    itemsReduced() {
      return this.serviceItems.length > 4 ? this.serviceItems.slice(0, 3) : this.serviceItems;
    },

  },
  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      //запрашивает даные
      rest
        .call("system.menu", {}) //тихий режим
        .then((data) => {
          if (data.success) {
            this.$store.commit("setMenu", data.items);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },

    //клик по пункту
    onItemClick(item) {

      this.$router.push({ path: item.url });
    },

    /**
     * клик по пункту "Ещё"
     */
    onItemMoreClick() {
      this.$router.push({ path: "/" + this.service.toLowerCase() + "/more/" });
    },

    /**
     * обработка входа пользователя
     */
    onLogin() {
      //загружаем данные
      this.loadData();
    },
  },

  /**
   * Монтирование эксзепляра в DOM
   */
  mounted() {
    if (store.getters.isAuthorized) {
      //загружаем данные
      this.loadData();
    }

    setTimeout(() => {
      this.allowAnimation = true;
    }, 1000);

    //подписваемся на событие авторизации пользователя
    bus.on("SBS_APP_E_USER_LOGIN", this.onLogin);
  },

  /**
   * Создание экземпляра
   */
  async created() {
    let itemsSaved = await localStorage.get("menu");

    //если данные сохранены в локальном хранилище
    if (itemsSaved !== null) {
      this.$store.commit("setMenu", itemsSaved);
    }
  },
};
</script>

<style lang="scss">
.sbs-menu {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;

  padding: 0px 0px;
  padding-bottom: var(--safe-area-bottom);

  background-color: var(--bg);
  transition: background-color ease-in-out 0.15s;

  .items {
    padding: 0px;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .item {
      float: left;
      text-align: center;
      padding: 5px 0px;
      overflow: hidden;
      cursor: pointer;

      .icon-wrap {
        line-height: 1;
        margin-bottom: 6px;
        padding: 2px 0px 1px;
      }

      .icon-inner {
        display: inline-block;
        position: relative;
      }

      .icon {
        font-size: 20px;
        color: var(--bottom-navigation-tab-bottom-navigation-tab-standard-icon-color);
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        display: inline-block;
      }

      .counter {
        position: absolute;
        top: -4px;
        right: -14px;
        height: 20px;
        width: 20px;
        border-radius: 50%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1;

        color: var(--bottom-navigation-tab-bottom-navigation-tab-notification-text-color);

        border: 2px solid var(--bottom-navigation-tab-bottom-navigation-tab-standard-bg-color);
        background: var(--bottom-navigation-tab-bottom-navigation-tab-icon2-color);
      }

      .name {
        text-transform: uppercase;
        color: var(--bottom-navigation-tab-bottom-navigation-tab-standard-text-color);
      }

      &.current {
        .icon {
          color: var(--service-color-1);
          animation: sbs-menu-item-selected 0.3s ease 1;
        }

        .name {
          color: var(--bottom-navigation-tab-bottom-navigation-tab-pressed-text-color);
        }
      }
    }
  }
}

@keyframes sbs-menu-item-selected {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.3, 1.3);
  }

  100% {
    transform: scale(1, 1);
  }
}
</style>