<template>
  <div class="sbs-requests-widget">
    <div class="wrap">
      <div class="container">
        <div class="outer">
          <div class="inner">
            <div class="btn-widget" @click="onBtnClick">
              <span class="icon icon-add fi fi-add"></span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <sbs-control-select-sheet v-model:visible="open" :title="this.$t('requests.widget.title')" v-model="value"
    :list="list" :showCheck="false" :showButtons="false" />
</template>

<script>
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";

export default {
  name: "sbs-requests-widget",
  components: {
    sbsControlSelectSheet
  },
  data() {
    return {
      open: false,

      value: false,

      list: [
        {
          id: "form",
          name: this.$t('requests.widget.form'),
          icon: "fi-form",
        },
        {
          id: "master",
          name: this.$t('requests.widget.master'),
          icon: "fi-steps"
        },
        {
          id: "audit",
          name: this.$t('requests.widget.audit'),
          icon: "fi-copy"
        }
      ]
    };
  },

  watch: {

    value: function () {
      if (this.value && !!this.value.id) {

        setTimeout(() => {
          this.onValueChange();
        }, 400);

      }
    },
  },

  methods: {
    /**
     * Клик по кнопке
     */
    onBtnClick() {
      this.open = !this.open;
    },

    onValueChange() {

      if (this.value.id == "form") {
        //переход на экран добавления заявки
        this.$router.push({ name: "request-form", params: { id: 0 } });
      } else if (this.value.id == "master") {
        //переход на экран добавления заявки
        this.$router.push({ name: "request-master" });
      } else if (this.value.id == "audit") {
        //переход на экран мультизаявки
        this.$router.push({ name: "request-master-multi" });
      }

      this.value = false;
    },

  },
};
</script>

<style lang="scss">
.sbs-requests-widget {
  .wrap {
    position: absolute;
    bottom: 80px;
    right: 0px;
    left: 0px;
    z-index: 150;

    .outer {
      position: relative;
    }

    .inner {
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }

  .btn-widget {
    position: relative;

    width: 60px;
    height: 60px;
    border-radius: 15px;
    background: var(--service-color-1);
    box-shadow: 0px 8px 8px 0px var(--shadow-secondary);

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
    }

  }

}
</style>