<template>
  <sbs-view :class="'sbs-v-password-change'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:center>
          <div class="title title-small">{{ $t('users.password_change.title') }}</div>
        </template>
      </sbs-header>
    </template>
    <template v-slot:content>
      <sbs-user-password-change />
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsUserPasswordChange from "@/components/user/password.change.vue";

export default {
  name: "sbs-v-password-change",
  components: {
    sbsView,
    sbsHeader,
    sbsUserPasswordChange,
  },
  data() {
    return {
      hideMenu: false
    };
  },

  methods: {},

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },
};
</script>
