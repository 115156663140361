<template>
    <sbs-sheet v-model:visible="visibleInternal" :minHeight="showSearch ? 100 : 10" :noPadding="true">

        <template v-slot:body>
            <div class="sbs-control-select-sheet" :class="{ 'padding-bottom': canShowButtons }">

                <div class="top-pannel">
                    <div class="sheet-title">{{ title }}</div>

                    <div class="search-wrap" v-if="showSearch">
                        <sbs-control-search v-model="search" placeholder="Поиск" />
                    </div>
                </div>

                <template v-if="!loaded">
                    <div class="text-center">
                        <sbs-loader-inline />
                    </div>
                </template>

                <sbs-control-select-sheet-list :list="searchItems" :level="1" :showAll="!!this.search"
                    :multiple="multiple" :modelValue="value" :modelValues="values" :showCheck="showCheck"
                    @onOptionClick="onOptionClick" />

                <div class="bottom-panel" v-if="canShowButtons">
                    <div class="btn btn-block btn2" @click="reset">{{ $t('controls.select.reset') }}</div>
                    <div class="btn btn-block btn1" @click="apply">{{ $t('controls.select.apply') }}</div>
                </div>
            </div>
        </template>
    </sbs-sheet>
</template>

<script>
import sbsSheet from '@/components/sheet.vue'
import sbsControlSearch from "@/components/controls/search.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlSelectSheetList from "@/components/controls/select.sheet.list.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-control-select-sheet",
    components: {
        sbsSheet,
        sbsLoaderInline,
        sbsControlSearch,
        sbsControlSelectSheetList
    },
    emits: ["update:visible", "update:modelValue", "update:modelValues"],
    data() {
        return {
            visibleInternal: this.visible,
            value: typeof this.modelValue === 'object' ? this.modelValue : { id: this.modelValue, name: "" },
            values: this.modelValues,
            selected: this.modelValues.map((v) => v.id),

            search: "",
            loaded: false,
            items: [],

            hideButtons: false,
            blocking: false

        };
    },
    props: {
        title: {
            type: String,
            default() {
                return "";
            },
        },
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        method: {
            type: String,
            default() {
                return "";
            },
        },
        params: {
            type: Object,
            default() {
                return {};
            },
        },
        modelValue: {
            type: [Object, Boolean, String],
            default() {
                return {
                    id: "",
                    name: "",
                };
            },
        },
        modelValues: {
            type: Array,
            default() {
                return [];
            },
        },
        multiple: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showCheck: {
            type: Boolean,
            default() {
                return true;
            },
        },
        showButtons: {
            type: Boolean,
            default() {
                return true;
            },
        },

    },

    computed: {

        showSearch() {
            return this.items.length > 7;
        },

        searchItems() {
            if (this.search == "") {
                return this.items;
            }

            return this.items.filter(
                (item) => {
                    let str = item.name + (this.method == "users.list" ? (" " + item.last_name) : "")
                    return str.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
                }

            );
        },

        isApplyEnabled() {

            if (!this.multiple) {
                //если значение никакое не выбрано
                if (!this.value || !this.value.id) {
                    return false;
                }

                //ищем значение среди результатов поиска
                let item = this.searchItems.find((item) => item.id == this.value.id);

                //если выбранного значения нет в результатах поиска
                if (!item) {
                    return false;
                }
            }

            return true;
        },

        canShowButtons() {
            return this.isApplyEnabled && this.showButtons && !this.hideButtons;
        }
    },

    watch: {
        visibleInternal: function (newVal) {
            if (newVal != this.visible) {
                this.$emit("update:visible", newVal);
            }

        },
        visible: function (newVal) {
            if (newVal != this.visibleInternal) {
                this.visibleInternal = newVal;
            }
            if (newVal) {
                this.onOpen();
            }
        },

        params: {
            handler() {
                this.loaded = false;
            },
            deep: true,
        },

        modelValue: {
            handler() {
                if (!this.modelValue || !this.value || this.modelValue.id != this.value.id) {
                    this.value = typeof this.modelValue === 'object' ? this.modelValue : { id: this.modelValue, name: "" };
                }
            },
            deep: true,
        },

        selected: function (newVal) {
            let temp = this.items.filter((item) => newVal.includes(item.id));
            this.values = temp;
        },

        modelValues: {
            handler() {
                this.selected = this.modelValues.map((v) => v.id);
            },
            deep: true,
        },

        items: function () {
            this.calculateChilds();
            this.expandChilds();
        }
    },

    methods: {

        /**
         * Открытие диалога
         */
        onOpen() {
            //если метод получения задан
            if (this.method) {
                //загружаем REST методом
                this.load();
            } else {
                this.items = this.list;
                this.loaded = true;
            }

            this.hideButtons = false;
        },

        /**
         * Загрузка вариантов
         */
        load() {
            if (this.loaded) {
                return;
            }

            //запрашиваем данные
            rest
                .call(
                    this.method,
                    {
                        data: this.params,
                        method: "POST",
                    },
                    false
                ) //тихий режим если настройки уже были получены ранее
                .then((data) => {
                    if (data.success) {
                        this.items = data.items;
                        this.loaded = true;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                });
        },

        /**
         * Рассчитываем дочерние элементы
         */
        calculateChilds() {

            this.items.forEach((item, index) => {

                item.childs = [];
                if (item.depth_level) {
                    for (let i = index + 1; i < this.items.length; i++) {
                        if (this.items[i].depth_level <= item.depth_level) {
                            break;
                        }

                        if (this.items[i].depth_level == item.depth_level + 1) {
                            item.childs.push(this.items[i]);
                        }
                    }

                }


            });
        },

        /**
         * Раскрываем сразу элементы, внутри которых выбраны дочерние
         */
        expandChilds() {

            this.items.forEach(item => {
                item.expanded = this.isItemSelected(item) || this.hasChildsSelected(item)
            });

        },

        /**
         * Проверяет есть ли у пункта выбранные дочерние пункты
         */
        hasChildsSelected(item) {

            for (let i = 0; i < item.childs.length; i++) {
                let child = item.childs[i];
                if (this.isItemSelected(child) || this.hasChildsSelected(child)) {
                    return true;
                }

            }

            return false;
        },

        /**
         * Проверяет является ли элемент выбранным
         */
        isItemSelected(item) {
            if (!this.multiple) {
                return this.value && this.value.id == item.id;
            } else {
                return this.selected.includes(item.id);
            }
        },

        /**
         * Клик по опции
         * @param Strings id Ид опции
         */
        onOptionClick(id) {
            let valRef = this.items.find((i) => i.id == id);
            //получаем элемент оригинальным значением без реактивности
            let val = Object.assign({}, valRef);

            //обычный режим выбора "одно значение"
            if (!this.multiple) {

                if (this.blocking) {
                    return;
                }
                this.blocking = true;

                this.hideButtons = !this.value || !this.value.id;
                this.value = val;
                setTimeout(() => {
                    this.blocking = false;
                    this.apply();
                }, 300);
            }
            //множественный выбор 
            else {

                //если значение уже есть в списке
                if (this.selected.includes(val.id)) {
                    // то удалим
                    let index = this.selected.indexOf(val.id);
                    if (index !== -1) {
                        this.selected.splice(index, 1);
                        this.removeChildValues(val.id);
                    }

                } else {
                    //иначе добавим
                    this.selected.push(val.id);
                    //добавим нижестоящие по иерархии элементы
                    this.addChildValues(val.id);
                }

                //создаём новый массив, чтобы вочер смог отследить
                this.selected = this.selected.filter(() => true);

            }

        },

        /**
        * Удаляет все нижестоящие значения (по depth_level)
        */
        removeChildValues(id) {

            //в режим поиска не используем групповые операции
            if (this.search) {
                return;
            }

            let item = this.items.find(i => i.id == id);

            item.childs.forEach(i => {
                let index = this.selected.indexOf(i.id);
                if (index !== -1) {
                    this.selected.splice(index, 1);
                    this.removeChildValues(i.id);
                }

            })

        },

        /**
         * Добавляет все нижестоящие значения (по depth_level)
         */
        addChildValues(id) {

            //в режим поиска не используем групповые операции
            if (this.search) {
                return;
            }

            let item = this.items.find(i => i.id == id);

            item.childs.forEach(i => {
                //если ещё не в списке 
                if (!this.selected.includes(i.id)) {
                    this.selected.push(i.id);
                    this.addChildValues(i.id);
                }
            })

        },

        /**
         * Применение
         */
        apply() {
            //если кнопка применения не доступна
            if (!this.isApplyEnabled) {
                return;
            }

            if (!this.multiple) {
                this.value.r = Math.random();
                this.$emit("update:modelValue", typeof this.modelValue === 'object' || this.modelValue === false ? this.value : this.value.id);
            } else {
                this.$emit("update:modelValues", this.values);
            }

            this.visibleInternal = false;
        },

        /**
         * Сбросить
         */
        reset() {

            if (!this.multiple) {

                this.value = {
                    id: "",
                    name: "",
                };
                this.$emit("update:modelValue", this.value);
            } else {
                this.values = [];
                this.$emit("update:modelValues", this.values);
            }

            this.visibleInternal = false;
        },

        /**
         * Очищает поиск
         */
        clearSearch() {
            this.search = "";
        },

    },

    created() {
        this.calculateChilds();
        this.expandChilds();
    }
}
</script>

<style lang="scss">
.sbs-control-select-sheet {

    padding: 0px 10px 0px;

    &.padding-bottom {
        padding-bottom: 80px;
    }

    .top-pannel {
        position: sticky;
        top: 0px;
        padding: 20px 10px 0px;
        z-index: 10000;
        background-color: var(--bg);

        /* нужно чтобы распереть блок */
        &::after {
            content: "";
            display: block;
            height: 1px;
        }
    }

    .bottom-panel {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 20px 10px calc(var(--keyboard-height, 0px) + var(--safe-area-bottom, 0px) + 20px);

        z-index: 10;
        background-color: var(--bg);

        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 10px;
    }

    .search-wrap {
        position: relative;
        margin-bottom: 20px;
    }



    .list {

        .item {


            border-radius: 10px;
            background: var(--audit-cards-audit-cards-bg-color);
            box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
            margin-bottom: 5px;
            font-size: 14px;

            cursor: pointer;

            .item-top {

                padding: 15px;

                position: relative;
                display: flex;
                align-items: center;
                column-gap: 14px;

                .icon {
                    flex-shrink: 0;
                    font-size: 20px;
                    color: var(--service-color-1);
                }

                .name-wrap {
                    flex-grow: 1;
                }

                .name {
                    color: var(--news-card-news-card-primary-text-color);
                    min-height: 24px;
                    display: flex;
                    align-items: center;
                }

                .description {
                    color: var(--text-field-text-field-body-color-text);
                }

                .check {
                    flex-shrink: 0;
                    position: relative;
                    width: 25px;
                    height: 25px;

                    background: var(--chekbox-and-radiobutton-chekbox-dark-standard-pressed-bg-color);
                    border-radius: 7px;
                    box-shadow: 0px 2px 3px -1px var(--shadow-checkbox) inset;

                    .ic {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        line-height: 1;
                        color: var(--service-color-1);

                        &.dot {
                            background-color: currentColor;
                            width: 9px;
                            height: 9px;
                            border-radius: 50%;
                        }
                    }
                }

                .collapser {
                    flex-shrink: 0;
                    line-height: 1;
                    font-size: 18px;
                    color: var(--news-card-news-card-icon-color, #64748B);
                    padding-right: 5px;

                    .fi {
                        position: relative;
                        display: inline-block;
                        transition: transform 0.3s ease-in-out;
                    }
                }

            }

            .childs {
                overflow: hidden;
                max-height: 0px;

                transition: max-height 0.3s ease-in-out, padding-top 0.3s ease-in-out,
                    padding-bottom 0.3s ease-in-out;

                position: relative;
                padding: 0px 0px 0px 15px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    bottom: 20px;
                    left: 15px;
                    width: 1px;
                    background-color: var(--news-card-news-card-icon-color);
                    z-index: 10;
                }

                .item {
                    box-shadow: none;

                    .item-top {
                        padding: 5px 15px;
                    }
                }

                .item-parent {

                    .name {
                        color: var(--service-color-1);
                    }
                }
            }

            &.expanded {

                >.item-top {

                    .collapser {
                        .fi {
                            transform: rotate(180deg);
                        }

                    }
                }


                >.childs {
                    max-height: 2000px;
                    padding-top: 5px;
                    padding-bottom: 5px
                }
            }

        }
    }

}
</style>
