<template>
  <div class="sbs-requests-statuses" v-if="items.length > 0 || showEmpty">
    <div v-if="showTitle" :class="[titleClass]">{{ title }}</div>

    <template v-if="items.length > 0">
      <div class="row">
        <div class="col-4" v-for="item in items" :key="item.status_id">
          <div class="item" v-ripple.300 @click="onItemClick(item)">
            <div class="name nunito-3-regular">{{ item.status_name }}</div>
            <div class="cnti">
              <div>
                <span class="icon fi" :class="item.status_icon"></span>
              </div>
              <div>
                <div class="cnt nunito-6-bold">
                  {{ item.cnt >= 10000 ? "+9999" : item.cnt }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty-message" v-html="emptyMessage"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "sbs-requests-statuses",
  components: {},
  data() {
    return {};
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    prefilter: {
      type: Object,
      default() {
        return {};
      },
    },
    showEmpty: {
      type: Boolean,
      default() {
        return false;
      },
    },
    emptyMessage: {
      type: String,
      default() {
        return "";
      },
    },
    title: {
      type: String,
      default() {
        return "Заявки";
      },
    },
    titleClass: {
      type: String,
      default() {
        return "h1";
      },
    },
    showTitle: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  methods: {
    onItemClick(item) {
      let filter = Object.assign({}, this.prefilter, {
        status: [
          {
            id: item.status_id,
            name: item.status_name,
          },
        ],
      });

      //сохраняем Фильтр в текущем состоянии
      this.$store.commit("setFilter", {
        name: "REQUEST-common",
        value: filter
      });

      //меняем вкладку на экране заявок
      this.$store.commit("setRequestsTab", "all");

      setTimeout(() => {
        this.$router.push({ name: "requests" });
      }, 400);

    },
  },
};
</script>


<style lang="scss">
.sbs-requests-statuses {
  margin-bottom: 10px;

  .h1 {
    margin-bottom: 20px;
  }

  .row {
    margin: 0px -5px;

    >div {
      padding: 0px 5px;
    }
  }

  .item {

    padding: 9px 10px;
    margin-bottom: 5px;
    cursor: pointer;

    border-radius: 10px;
    background: var(--training-item-training-item-standard-color);
    box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
    color: var(--audit-cards-audit-cards-title-text-color);

    .name {
      text-align: right;
      margin-bottom: 3px;
    }

    .cnti {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 5px;
    }

    .icon {

      font-size: 20px;
      color: var(--service-color-1);
    }

    .cnt {
      text-align: right;
    }

  }
}
</style>
