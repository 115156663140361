<template>
    <sbs-view :class="'sbs-v-edu-training-category'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
        <template v-slot:header>
            <sbs-header>
                <template v-slot:center>
                    <div class="title">{{ category && !loading ? category.name : "" }}</div>
                </template>
            </sbs-header>
        </template>

        <template v-slot:content>

            <!-- Если список пуст-->
            <template v-if="items.length == 0 && !loading">
                <sbs-empty :title="$t('education.trainings.category.empty.title')"
                    :text="$t('education.trainings.category.empty.text')" />

            </template>

            <template v-else>
                <div class="items row">

                    <div class="col-6 col-sm-6 col-md-6 col-lg-4" v-for="item in items" :key="item.id">
                        <sbs-education-training-item :item="item" class="item" />
                    </div>

                </div>
            </template>

            <div ref="pager"></div>

            <!-- Загрузка -->
            <template v-if="loadingTrainings">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>

        </template>
    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEmpty from "@/components/empty.vue";
import sbsEducationTrainingItem from "@/components/education/training.item.vue"


import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-v-education-training-category",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsEmpty,
        sbsEducationTrainingItem
    },
    data() {
        return {
            hideMenu: false,

            loading: true,
            loadingTrainings: true,
            category: false,
            items: [],
            pager: {
                size: 18,
                page: 1,
                cnt: 1,
            },
        };
    },
    props: {
        id: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    watch: {
        id: function () {
            this.$refs.view.resetSavedScroll();
            this.category = false;
            this.items = [];
            this.pager.page = 1;
            this.loadData();
        },
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {

            //покажем экран загрузки
            this.loading = resolve ? false : true;

            this.loadCategory().then(() => {
                this.loadTrainings(resolve);
            }).catch(() => {
                this.loading = false;
                this.loadingTrainings = false;
                if (resolve) {
                    resolve();
                }
            });

        },

        /**
         * Загружает категорию
         */
        loadCategory() {
            return new Promise((resolve, reject) => {

                rest.call("education.category.get",
                    {
                        method: "post",
                        data: {
                            mode: "public",
                            id: this.id
                        },
                    }
                )
                    .then((data) => {
                        if (data.success) {
                            this.category = data.category;
                            resolve();
                        } else {
                            //показываем сообщение об ошибке
                            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                            reject();
                        }
                    })

            })
        },

        /**
         * Загружает тренировки
         */
        loadTrainings(resolve = false) {

            this.loadingTrainings = resolve ? false : true;

            let filter = {
                "category_id": this.category.id
            }

            //запрашиваем данные
            rest
                .call("education.training.list", {
                    method: "post",
                    data: {
                        mode: "public",
                        load_my_results: "Y",
                        paging: this.pager,
                        resize_options: {
                            PREVIEW: { WIDTH: 400, HEIGHT: 300 },
                            PRELOAD: "Y"
                        },
                        order: {
                            "publication_date": "desc"
                        },
                        filter: filter
                    },
                })
                .then((data) => {
                    if (data.success) {
                        if (this.pager.page == 1) {
                            this.items = data.items;
                        } else {
                            this.items = this.items.concat(data.items);
                        }
                        this.pager = data.pager;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    //скроем экран загрузки
                    this.loading = false;
                    this.loadingTrainings = false;
                });
        },

        /**
         * Перезагрузка данных
         */
        refresh(resolve) {
            this.pager.page = 1;
            this.loadData(resolve);
        },

        /**
        * Обработка срола списка
        */
        onScroll(e) {
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;
            //если экран прокрутился до пейджера (высоту экрана за вычетом 70px под меню которые заререзрвированы)
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (!this.loadingTrainings && this.pager.page < this.pager.cnt) {
                    this.pager.page++;
                    this.loadTrainings();
                }
            }
        },

        /**
         * Обработка сохранения результата тренировки
         */
        onTrainingResultSave(fields) {

            let item = this.items.find(i => i.id == fields.trainingId);
            if (item) {
                this.syncItem(item);
            }
        },

        /**
         * Синхронизирует данные тренировки
         */
        syncItem(item) {

            //запрашиваем данные
            rest
                .call("education.training.list", {
                    method: "post",
                    data: {
                        mode: "public",
                        load_my_results: "Y",
                        resize_options: {
                            PREVIEW: { WIDTH: 400, HEIGHT: 300 },
                            PRELOAD: "Y"
                        },
                        filter: {
                            id: item.id,
                        },
                    },
                })
                .then((data) => {
                    let index = this.items.indexOf(item);
                    if (data.success) {
                        this.items[index] = data.items[0];
                    }
                });
        }


    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.loadData();
    },

    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);

        //подписваемся на события
        bus.on("SBS_EDU_TRAINING_RESULT_E_SAVE", this.onTrainingResultSave);
    },

    beforeUnmount() {
        this.$refs.pager
            .closest(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },

    unmounted() {
        //отписываемся от событий
        bus.off("SBS_EDU_TRAINING_RESULT_E_SAVE", this.onTrainingResultSave);
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },
}
</script>

<style lang="scss">
.sbs-v-edu-training-category {

    .items {
        margin: 0px -10px;

        >div {
            padding: 0px 10px;
        }

        .item {
            min-height: calc(100% - 20px);
        }
    }

}
</style>