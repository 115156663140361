<template>
    <sbs-sheet v-model:visible="visibleInternal">

        <template v-slot:body>

            <div class="sbs-control-dates-sheet">

                <div class="sheet-title">{{ title }}</div>

                <div class="row">
                    <div class="col">
                        <div class="types">
                            <div v-for="t in types" :key="t.id" class="type">
                                <sbs-control-radio :value="t.id" v-model="type" :label="t.name" />
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="variants">
                            <div v-for="v in variants" :key="v.id" class="variant">
                                <sbs-control-radio :value="v.id" v-model="variant" :label="v.name" />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="type == 'interval'" class="interval">
                    <div class="row">
                        <div class="col">
                            <sbs-control-date v-model="from" label="Дата с" />
                        </div>
                        <div class="col">
                            <sbs-control-date v-model="to" label="Дата по" />
                        </div>
                    </div>
                </div>

                <div class="bottom-panel">
                    <div class="btn btn-block btn2" @click="reset">{{ $t('controls.select.reset') }}</div>
                    <div class="btn btn-block btn1" @click="apply">{{ $t('controls.select.apply') }}</div>
                </div>
            </div>
        </template>

    </sbs-sheet>
</template>

<script>
import sbsSheet from '@/components/sheet.vue'
import sbsControlRadio from "@/components/controls/radio.vue";
import sbsControlDate from "@/components/controls/date.vue";

import moment from "moment";
import types from "@/plugins/dates.variants";

export default {
    name: "sbs-control-dates-sheet",
    components: {
        sbsSheet,
        sbsControlRadio,
        sbsControlDate
    },
    emits: [
        "update:visible",
        "update:dateFrom",
        "update:dateTo",
        "update:variant",
        "update:value",
    ],
    data() {
        return {
            visibleInternal: this.visible,

            type: "",
            variant: "",
            from: "",
            to: "",
            types: types,
        };
    },
    props: {
        title: {
            type: String,
            default() {
                return "";
            },
        },
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        dateFrom: {
            type: String,
            default() {
                return "";
            },
        },
        dateTo: {
            type: String,
            default() {
                return "";
            },
        },
        value: {
            type: [Object, Boolean],
            default() {
                return false;
            },
        },
    },
    computed: {
        variants() {
            if (!this.types) {
                return [];
            }
            return this.types.find((t) => t.id == this.type).variants;
        },
    },
    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
        },

        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },

        type: function () {
            this.variant = "";
        },
        dateFrom: function (newVal) {
            if (this.from != newVal) {
                this.from = newVal;
                this.refresh();
            }
        },
        dateTo: function (newVal) {
            if (this.to != newVal) {
                this.to = newVal;
                this.refresh();
            }
        },
    },
    methods: {

        /**
         * Применить
         */
        apply() {
            if (this.type && this.variant) {
                let variant = this.variants.find((v) => v.id == this.variant);
                if (variant) {
                    this.from = variant.from;
                    this.to = variant.to;
                }
            }

            this.$emit("update:dateFrom", this.from);
            this.$emit("update:dateTo", this.to);
            if (this.to || this.from) {
                this.$emit("update:value", {
                    from: this.from,
                    to: this.to,
                    id: moment().format("x"),
                });
            } else {
                this.$emit("update:value", false);
            }

            this.visibleInternal = false;
        },

        /**
         * Сбросить
         */
        reset() {
            this.type = "";
            this.variant = "";
            this.from = "";
            this.to = "";
            this.$emit("update:dateFrom", this.from);
            this.$emit("update:dateTo", this.to);
            this.$emit("update:value", false);
            this.visibleInternal = false;
        },

        refresh() {
            //пробуем найти вариант, подходящий под выбранные даты
            var type = this.types.find((t) =>
                t.variants.find((v) => v.from == this.from && v.to == this.to)
            );
            //если есть такой предустановленный вариант
            if (type) {
                this.type = type.id;
                this.variant = type.variants.find(
                    (v) => v.from == this.from && v.to == this.to
                ).id;
            }
            //иначе если задана какая-то дата
            else if (this.from || this.to) {
                this.type = "interval";
            }
            //иначе никакие даты не заданы
            else {
                this.type = "";
            }
        },
    }
}
</script>

<style lang="scss">
.sbs-control-dates-sheet {

    .type {
        padding: 5px 0px;
    }

    .variant {
        padding: 5px 0px;
    }

    .interval {
        margin-top: 30px;
    }

    .bottom-panel {
        margin-top: 30px;

        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 10px;
    }
}
</style>