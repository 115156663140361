<template>
    <div class="sbs-control-calendar">

        <div class="nav">

            <div class="arrow" v-ripple.300 @click="prev">
                <span class="icon fi fi-arrow-left"></span>
            </div>

            <div class="select" :class="{ open: monthsOpen }" ref="month">
                <div class="value" @click="openMonths">
                    <div>
                        {{ selectedMonth ? selectedMonth.name : '' }}
                    </div>
                    <div>
                        <span class="icon fi fi-arrow-down"></span>
                    </div>
                </div>
                <div class="popup">
                    <perfect-scrollbar>
                        <div class="items">
                            <div class="item" v-for="m in months" :key="m.id" :class="{ selected: m.id == month }"
                                @click="setMonth(m.id)">
                                {{ m.name }}
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>

            <div class="select" :class="{ open: yearsOpen }" ref="year">
                <div class="value" @click="openYears">
                    <div>
                        {{ this.year }}
                    </div>
                    <div>
                        <span class="icon fi fi-arrow-down"></span>
                    </div>
                </div>
                <div class="popup">
                    <perfect-scrollbar>
                        <div class="items">
                            <div class="item" v-for="y in years" :key="y" :class="{ selected: y == year }"
                                @click="setYear(y)">
                                {{ y }}
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>

            <div class="arrow" v-ripple.300 @click="next">
                <span class="icon fi fi-arrow-right"></span>
            </div>

        </div>

        <div class="grid head">
            <div class="cell header" v-for="h in headers" :key="h">
                {{ h }}
            </div>

        </div>

        <div class="grid body">
            <div class="cell" v-for="(date, index) in dates" :key="index"
                :class="{ fiiled: date.month() + 1 == this.month, today: date.isSame(today), selected: selected && date.isSame(selected) }"
                @click="onDateClick(date)">
                {{ date.format('DD') }}
            </div>
        </div>



    </div>

</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import "vue3-perfect-scrollbar/style.css";

import moment from 'moment';

export default {
    name: "sbs-control-calendar",
    components: {
        PerfectScrollbar
    },
    emits: ["update:modelValue"],
    data() {
        return {
            value: this.modelValue,

            year: this.getDefaultYear(),
            month: this.getDefaultMonth(),

            monthsOpen: false,
            yearsOpen: false
        };
    },
    props: {
        modelValue: {
            type: String,
            default() {
                return "";
            },
        },
    },

    computed: {

        /**
         * Стартовая дата, от которой рисуем календарь
         */
        startDate() {
            //берем 1-е число выбранного месяца
            let date = moment({ year: this.year, month: this.month - 1, day: 1 });

            //корректируем 0-6 в 1-7
            let d = date.day() == 0 ? 7 : date.day();

            //если дата не Пн
            if (d != 1) {

                //получим предыдущий понедельник
                date.add(-1 * (d - 1), 'days')
            }

            return date;
        },

        /**
         * Последняя дата, до которой рендерим даты
         */
        endDate() {
            //берем последнее число выбранного месяца
            let date = moment({ year: this.year, month: this.month - 1, day: 1 });
            date.add(date.daysInMonth() - 1, 'days')

            //корректируем 0-6 в 1-7
            let d = date.day() == 0 ? 7 : date.day();

            //если дата не Вс
            if (d != 7) {
                //получим следующее Вс
                date.add((7 - d), 'days')
            }

            //уравновешиваем кол-во дней в календаре так, чтобы в итоге было 6 недель
            //иначе при листании месяцев календарь будет прыгать
            if (date.diff(this.startDate, 'days') < 41) {
                date.add(7, 'days')
            }

            return date;

        },

        /**
         * Заголовки Пн-Вс
         */
        headers() {
            let date = moment(this.startDate);

            let list = [];
            for (let i = 1; i <= 7; i++) {
                list.push(date.format("dd"));
                date.add(1, 'days');
            }

            return list;
        },

        /**
         * Даты для отображения в календаре
         */
        dates() {
            let list = [];
            let date = this.startDate.clone();
            while (date <= this.endDate) {
                list.push(date.clone());
                date.add(1, 'days');
            }

            return list;
        },

        /**
         * Месяца Яндварь-Февраль
         */
        months() {
            let list = [];

            let date = moment().startOf('year');
            for (let i = 1; i <= 12; i++) {
                list.push({
                    id: date.month() + 1,
                    name: date.format("MMMM")
                });

                date.add(1, 'month')
            }

            return list;
        },

        /**
         * Выбранный месяц (id/name)
         */
        selectedMonth() {
            return this.months.find(m => m.id == this.month);
        },

        years() {
            const currYear = new Date().getFullYear() + 2;
            const lastYear = 1980;
            return Array.from(
                { length: currYear - lastYear + 1 },
                (_, index) => lastYear + index
            ).reverse();
        },

        today() {
            return moment().startOf('day');
        },

        selected() {
            if (this.value != undefined && this.value != "") {
                return moment(this.value, "DD.MM.YYYY").startOf('day');
            }

            return false;
        }
    },

    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
        value: function () {
            this.$emit("update:modelValue", this.value);
            if (this.selected && this.selected.month() + 1 != this.month) {
                this.year = this.selected.year();
                this.month = this.selected.month() + 1;
            }
        },
    },

    methods: {
        getDefaultYear() {
            if (this.modelValue != undefined && this.modelValue != "") {
                return parseInt(moment(this.modelValue, "DD.MM.YYYY").format("YYYY"));
            }
            return new Date().getFullYear();
        },

        getDefaultMonth() {
            if (this.modelValue != undefined && this.modelValue != "") {
                return parseInt(moment(this.modelValue, "DD.MM.YYYY").format("MM"));
            }
            return new Date().getMonth() + 1;
        },

        onDateClick(date) {
            this.value = date.format("DD.MM.YYYY");
            this.monthsOpen = false;
            this.yearsOpen = false;
        },

        prev() {
            if (this.month == 1) {
                this.year -= 1;
                this.month = 12;
            } else {
                this.month -= 1;
            }
            this.monthsOpen = false;
            this.yearsOpen = false;
        },

        next() {
            if (this.month == 12) {
                this.year += 1;
                this.month = 1;
            } else {
                this.month += 1;
            }

            this.monthsOpen = false;
            this.yearsOpen = false;
        },

        openMonths() {
            this.monthsOpen = !this.monthsOpen;
            this.yearsOpen = this.yearsOpen && this.monthsOpen ? false : this.yearsOpen;
        },

        setMonth(month) {
            this.month = month;
            this.monthsOpen = false;
        },

        openYears() {
            this.yearsOpen = !this.yearsOpen;
            this.monthsOpen = this.monthsOpen && this.yearsOpen ? false : this.monthsOpen;
        },

        setYear(year) {
            this.year = year;
            this.yearsOpen = false;
        },


    },

}
</script>

<style lang="scss">
.sbs-control-calendar {

    .nav {
        display: flex;
        align-items: center;
        column-gap: 10px;
        justify-content: center;
        margin-bottom: 15px;

        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 46px;
            width: 46px;
            border-radius: 50%;

            background: var(--calendar-month-and-year-month-bg-color);
            border: 1px solid var(--calendar-month-and-year-month-border-color);

            filter: drop-shadow(0px 2px 4px var(--shadow-secondary));

            color: var(--service-color-1);

            cursor: pointer;

        }

        .select {
            position: relative;

            .value {
                display: flex;
                padding: 10px 12px;
                align-items: center;
                gap: 10px;

                border-radius: 8px;
                border: 1px solid var(--calendar-month-and-year-month-border-color);
                background: var(--calendar-month-and-year-month-bg-color);
                box-shadow: 0px 2px 4px 0px var(--shadow-secondary);

                color: var(--calendar-cells-cells-regular-unselected-text-color);
                cursor: pointer;

                font-family: Roboto;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.27;
                letter-spacing: -0.04px;

                .icon {
                    color: var(--service-color-1);
                    font-size: 13px;
                }

            }

            .popup {
                position: absolute;
                top: 200%;
                left: 0px;
                min-width: 140px;
                text-align: left;
                z-index: 100;

                transition: opacity 0.2s ease-out, top 0.2s ease, bottom 0.2s ease, visibility 0s linear 200ms;


                visibility: hidden;
                opacity: 0;
                cursor: default;

                border-radius: 10px;
                background: var(--calendar-cells-cells-regular-bg-color);
                box-shadow: 0px 8px 8px 0px var(--shadow-secondary);

                overflow: hidden;

                .ps {
                    max-height: 250px;
                    padding: 12px;
                }

                .items {
                    display: flex;
                    flex-direction: column;
                    row-gap: 15px;

                }

                .item {
                    padding: 5px 0px;
                    cursor: pointer;


                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.27;

                    &.selected {
                        color: var(--service-color-1);
                    }
                }
            }

            &.open {
                .popup {
                    visibility: visible;
                    top: 120%;

                    opacity: 1;

                    transition: opacity 0.2s ease-out, top 0.2s ease, bottom 0.2s ease, visibility 0s linear;

                }
            }
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(7, 0.1428fr);
        gap: 4px;
        margin-bottom: 10px;

        .cell {
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.27;
            letter-spacing: -0.04px;

            aspect-ratio: 1;
            min-height: 30px;

            color: var(--calendar-cells-cells-disabled-color);
            border-radius: 8px;
            cursor: pointer;

            &.fiiled {

                background: var(--calendar-cells-cells-regular-bg-color);
                box-shadow: 0px 2px 4px 0px var(--shadow-secondary, var(--shadow-secondary));
                color: var(--calendar-cells-cells-regular-unselected-text-color);

                border: 2px solid var(--calendar-cells-cells-regular-bg-color);
            }

            &.header {
                color: var(--calendar-cells-cells-regular-unselected-text-color);
                cursor: default;
            }

            &.today {
                border-color: var(--service-color-1);
                color: var(--service-color-1);
                background-color: transparent;
            }

            &.selected {
                border-color: var(--service-color-1);
                color: var(--calendar-cells-cells-regular-unselected-text-color);
                background-color: var(--service-color-1);

            }
        }
    }


}
</style>