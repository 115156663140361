<template>
  <div class="sbs-header">

    <div v-if="showLogo">
      <sbs-logo-mini />
    </div>

    <div v-if="canShowBackBtn">

      <div @click="onBtnBackClick" class="btn-back">
        <span class="fi fi-arrow-left"></span>
      </div>

    </div>
    <div class="col-center">
      <slot name="center">
      </slot>
    </div>

    <div class="col-right">
      <slot name="right"></slot>
    </div>
  </div>


</template>

<script>
import sbsLogoMini from "@/components/logo.mini.vue";

export default {
  name: "sbs-header",
  components: {
    sbsLogoMini
  },
  props: {
    showLogo: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showBackBtn: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    canShowBackBtn() {
      return window.history.length > 1 && this.showBackBtn;
    },
  },
  methods: {
    onBtnBackClick() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push("requests");
    },
  },
};
</script>

<style lang="scss">
.sbs-header {
  height: 55px;
  display: flex;

  align-items: center;

  margin: 0px -7px;

  >div {
    padding: 0px 7px;
  }

  line-height: 1;
  z-index: 100;

  .col-center {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  .title {
    font-family: Nunito;
    font-size: 24px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--top-navigation-top-navigation-title-color);
  }

  .title-small {
    font-size: 18px;
  }

  .btn-back {
    position: relative;
    font-size: 20px;
    color: var(--service-color-1);
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
    }
  }

  .col-right {
    display: flex;
    align-items: center;

    margin: 0px -8px;

    >div {
      padding: 0px 6px;
    }

    .action {
      position: relative;

      font-size: 20px;
      color: var(--service-color-1);
      cursor: pointer;

      width: 32px;
      height: 32px;

      border-radius: 50%;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1;
      }


      .counter {
        position: absolute;
        top: -4px;
        right: -7px;
        height: 20px;
        width: 20px;
        border-radius: 50%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1;

        color: var(--bottom-navigation-tab-bottom-navigation-tab-notification-text-color);

        border: 2px solid var(--bottom-navigation-tab-bottom-navigation-tab-standard-bg-color);
        background: var(--bottom-navigation-tab-bottom-navigation-tab-icon2-color);
      }

    }
  }
}
</style>