<template>
  <sbs-view :refreshDisabled="true" :class="'sbs-v-more'">

    <template v-slot:content>
      <sbs-user-view-menu />
      <sbs-menu-more />

      <div class="versions">
        <div class="version">
          {{ $t('more.web_version') }}: {{ webVersion }}
        </div>
        <div class="version" v-if="isNative && appVersion">
          {{ $t('more.app_version') }}: {{ appVersion }}
        </div>

      </div>
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsMenuMore from "@/components/menu.more.vue";
import sbsUserViewMenu from "@/components/user/view.menu.vue";

import { mapGetters } from "vuex";

export default {
  name: "sbs-v-more",
  components: {
    sbsView,
    sbsMenuMore,
    sbsUserViewMenu,
  },
  data() {
    return {
      webVersion: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    ...mapGetters({
      isNative: "isNativePlatform",
      appVersion: "getAppVersion"
    }),
  },

  methods: {},

};
</script>


<style lang="scss">
.sbs-v-more {
  .view-content {
    padding-top: 10px !important;
  }

  .version {
    font-size: 12px;
    color: var(--news-card-news-card-secondary-text-color);
  }
}
</style>
