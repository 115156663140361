<template>
  <sbs-view :class="'sbs-v-department-view'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">

    <template v-slot:header>
      <sbs-header>
        <template v-if="!loading && department" v-slot:center>
          <div class="title title-small" v-html="department.name"></div>
        </template>
      </sbs-header>
    </template>

    <template v-slot:content>

      <template v-if="loading">
        <div class="text-center">
          <sbs-loader-inline />
        </div>
      </template>

      <template v-if="!loading">
        <!-- Не найдена-->
        <div v-if="department == null">{{ $t("departments.view.not_found") }}</div>
        <!-- Иначе -->
        <div v-else>

          <div class="description" v-if="department.detail_text.trim() != ''" v-html="department.detail_text"></div>

          <template v-if="department.props">
            <sbs-props :props="department.props" />
            <br />
          </template>

          <div class="childs" v-if="childs.length > 0">
            <h2>{{ $t("departments.view.childs") }}</h2>
            <sbs-department-item v-for="item in childs" :key="item.id" :item="item" />
            <br />
          </div>

          <div class="equipment-types" v-if="equipment_types.length > 0">
            <h2>{{ $t("departments.view.equipment") }}</h2>
            <template v-for="item in equipment_types" :key="item.id">
              <div class="item" @click="onEquipmentTypeClick(item)">
                <div class="name nunito-3-bold">{{ item.type_name }}</div>
                <div class="cnt">{{ item.cnt }}</div>
                <span class="icon fi fi-arrow-right"></span>
              </div>
            </template>
            <br />
          </div>

          <sbs-requests-statuses :items="requests_created" :prefilter="prefilterOwn"
            :title="$t('departments.view.requests_own')" titleClass="h2" />

          <sbs-requests-statuses :items="requests_reponsible" :prefilter="prefilterResponsible"
            :title="$t('departments.view.requests_reponsible')" titleClass="h2" />

          <div class="users">
            <h2>{{ $t('departments.view.users') }}</h2>
            <template v-if="users.length > 0">
              <sbs-user-item v-for="item in users" :key="item.id" :item="item" />
            </template>

            <div v-if="permissions.includes('add_employees')" class="btn btn1 btn-block" @click="addUser" v-ripple.300>+
              {{ $t('departments.view.user_add') }}
            </div>
          </div>
        </div>
      </template>
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsProps from "@/components/props.vue";
import sbsDepartmentItem from "@/components/departments/item.vue";
import sbsRequestsStatuses from "@/components/requests/statuses.vue";
import sbsUserItem from "@/components/users/item.vue";


import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-v-department-view",
  components: {
    sbsView,
    sbsHeader,
    sbsLoaderInline,
    sbsProps,
    sbsDepartmentItem,
    sbsRequestsStatuses,
    sbsUserItem
  },
  data() {
    return {
      hideMenu: false,

      loading: true,
      department: null,
      childs: [],
      requests_created: [],
      requests_reponsible: [],
      equipment_types: [],
      users: [],
      permissions: []
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },

  computed: {
    prefilterOwn() {
      if (this.department == null) {
        return {};
      }
      return {
        createdDepartment: [
          {
            id: this.department.id,
            name: this.department.name,
          },
        ],
      };
    },
    prefilterResponsible() {
      if (this.department == null) {
        return {};
      }
      return {
        responsibleDepartment: [
          {
            id: this.department.id,
            name: this.department.name,
          },
        ],
      };
    },
  },

  watch: {
    id: function () {
      this.loadData();
    },
  },

  methods: {
    refresh(resolve) {
      this.loadData(resolve);
    },

    /**
     * Подготовка запросов
     */
    getBatchRequests() {
      return {
        department: {
          ENTITY: "department",
          METHOD: "get",
          PARAMS: {
            id: this.id,
          },
        },
        childs: {
          ENTITY: "department",
          METHOD: "list",
          PARAMS: {
            filter: {
              section_id: this.id,
              active: "Y",
            },
          },
        },
        requests_created: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: ["status_id", "cnt"],
            group: ["status_id"],
            order: { "status.sort": "asc" },
            filter: {
              created_department_id: this.id,
            },
          },
        },
        requests_reponsible: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: ["status_id", "cnt"],
            group: ["status_id"],
            order: { "status.sort": "asc" },
            filter: {
              responsible_department_id: this.id,
            },
          },
        },
        users: {
          ENTITY: "users",
          METHOD: "list",
          PARAMS: {
            filter: {
              department_id: this.id,
              active: "Y"
            },
          },
        },
        equipment_types: {
          ENTITY: "equipment",
          METHOD: "statistics",
          PARAMS: {
            select: { "type_name": "type.name", 0: "cnt", 1: "type_id" },
            group: ["type_id"],
            order: { "type_name": "asc" },
            filter: {
              department_id: this.id
            }

          },
        }
      };
    },

    /**
     * Загружает данные
     */
    loadData(resolve = false) {
      //покажем экран загрузки
      this.loading = true;
      this.department = null;

      //запрашиваем данные
      rest
        .batch(this.getBatchRequests())
        .then((arResults) => {
          //обработка результатов
          this.processResponse(arResults);
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
          if (resolve) {
            resolve();
          }
        });
    },

    /**
     * Обработка результата
     */
    processResponse(results) {
      for (var key in results) {
        let data = results[key];

        if (data.success) {
          if (key == "department") {
            this.department = data.department;
            this.permissions = data.permissions;
          } else if (key == "childs") {
            this.childs = data.items;
          } else if (key == "requests_created") {
            this.requests_created = data.items;
          } else if (key == "requests_reponsible") {
            this.requests_reponsible = data.items;
          } else if (key == "users") {
            this.users = data.items;
          } else if (key == 'equipment_types') {
            this.equipment_types = data.items;
          }
        } else {
          //показываем сообщение об ошибке
          bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          return;
        }
      }
    },

    /**
     * Пригласить сотрудника
     */
    addUser() {
      setTimeout(() => {
        this.$router.push({ name: "user-add" });
      }, 250);
    },

    /**
     * Клик по типу оборудования
     */
    onEquipmentTypeClick(item) {
      //сохраняем Фильтр в текущем состоянии
      this.$store.commit("setFilter", {
        name: "EQUIPMENT",
        value: {
          type_id: [
            {
              id: item.type_id,
              name: item.type_name,
            },
          ],
          department_id: [
            {
              id: this.id,
              name: this.department.name,
            }
          ]
        }
      });

      this.$router.push({ name: "equipment" });
    }
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
    //загружаем данные
    this.loadData();
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
    });
  },
};
</script>

<style lang="scss">
.sbs-v-department-view {
  .description {
    margin-bottom: 15px;
  }

  .equipment-types {
    .item {
      position: relative;
      padding: 15px 40px 15px 15px;
      border-radius: 15px;
      background: var(--audit-cards-audit-cards-bg-color);
      box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
      cursor: pointer;
      margin-bottom: 10px;

      .name {
        color: var(--audit-cards-audit-cards-title-text-color);
      }

      .cnt {
        position: absolute;
        top: 50%;
        right: 35px;
        transform: translateY(-50%);
        color: var(--news-card-news-card-secondary-text-color);
        line-height: 1;
      }

      .icon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        color: var(--service-color-1);
        line-height: 1;
      }
    }
  }
}
</style>