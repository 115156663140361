<template>
    <sbs-view :class="'sbs-v-edu-post-view'" :isMenuHidden="hideMenu" :noPaddingHorizontal="true" :noPaddingTop="true"
        @refresh="refresh" ref="view">
        <template v-slot:header>
            <sbs-header ref="header">
                <template v-slot:center>
                    <div class="title title-small" @click="$refs.header.onBtnBackClick">{{ $t('common.prev') }}</div>
                </template>
            </sbs-header>
        </template>

        <template v-slot:content>

            <template v-if="showLoading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>

            <template v-else>

                <template v-if="!post">
                    <div>{{ $t("education.post.not_found") }}</div>
                </template>

                <template v-else>

                    <sbs-education-post-post v-if="post.type == 'POST'" :item="post" :footerId="footerId" />

                    <sbs-education-post-test v-else-if="post.type == 'TEST'" :item="post" :footerId="footerId" />

                    <sbs-education-post-feedback v-else-if="post.type == 'FEEDBACK'" :item="post"
                        :footerId="footerId" />


                </template>

            </template>

        </template>

        <template v-slot:footer>
            <div :id="footerId"></div>
        </template>

    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationPostPost from "@/components/education/post.post.vue"
import sbsEducationPostTest from "@/components/education/post.test.vue"
import sbsEducationPostFeedback from "@/components/education/post.feedback.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-v-education-view",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsEducationPostPost,
        sbsEducationPostTest,
        sbsEducationPostFeedback
    },
    data() {
        return {
            hideMenu: false,
            loading: true,
            showLoading: true,
            post: null,
            footerId: "sbs-edu-post-view-footer"
        };
    },
    props: {
        id: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    watch: {
        id: function () {
            this.loadData();
        }
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            //покажем экран загрузки
            this.showLoading = resolve ? false : true;
            this.loading = true

            //запрашиваем данные
            rest
                .call("education.post.get", {
                    method: "post",
                    data: {
                        id: this.id,
                        select: ["user_photo", "user_name", "user_last_name"],
                        load_my_result: "Y",
                        resize_options: {
                            PREVIEW: { WIDTH: 800, HEIGHT: 600 },
                            PRELOAD: "Y"
                        },
                        load_my_reaction: "Y",
                        load_reactions: "Y"
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.post = data.post;
                        this.hit();
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    //скроем экран загрузки
                    this.loading = false;
                    this.showLoading = false;
                });
        },

        /**
         * Перезагрузка данных
         */
        refresh(resolve) {
            this.loadData(resolve);
        },


        /**
         * Фиксация просмотра
         */
        hit() {
            rest
                .call("education.post.hit", {
                    method: "post",
                    data: {
                        id: this.post.id
                    },
                });

        }
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.loadData();
    },


};
</script>

<style lang="scss"></style>