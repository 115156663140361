<template>
  <sbs-view :class="'sbs-v-home'" :refreshDisabled="true" :isMenuHidden="true" ref="view">
    <template v-slot:content>

      <div class="logo-wrap">
        <sbs-logo />
      </div>

      <div class='welcome nunito-8-bold'>
        {{ welcome }},<br />
        {{ connection.profile ? connection.profile.name : user.name }}!
      </div>

      <div class="row services">
        <div class="col-4 col-sm-3 col-md-2" v-for="service in availableServices" :key="service.id">

          <div class="service" @click="onServiceClick(service.id)">

            <div class="image" :style="{ backgroundImage: 'url(' + service.image + ')' }"></div>

            <div class="name nunito-3-regular">
              {{ service.name }}
            </div>

          </div>
        </div>
      </div>

    </template>
  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsLogo from "@/components/logo";

import moment from "moment";
import { mapGetters } from "vuex";

import imageRequests from "@/assets/images/services/requests.svg";
import imageEducation from "@/assets/images/services/education.svg";

export default {
  name: "sbs-v-home",
  components: {
    sbsView,
    sbsLogo
  },
  data() {
    return {
      reload: 0,
      hideMenu: false,
      time: false,

      services: [
        {
          id: "REQUESTS",
          name: this.$t('home.services.requests'),
          image: imageRequests
        },
        {
          id: "EDUCATION",
          name: this.$t('home.services.education'),
          image: imageEducation
        }
      ],
    };
  },

  computed: {
    ...mapGetters({
      userServices: "getServices",
      user: "getUserProfile",
      connection: "getConnection",
    }),

    availableServices() {

      let services = this.services.filter(s => this.userServices.includes(s.id))
      return services;
    },

    welcome() {
      let hours = this.time.hours();

      if (hours >= 0 && hours < 6) {
        return this.$t('home.hello');
      } else if (hours >= 6 && hours < 12) {
        return this.$t('home.good_morning');
      } else if (hours >= 12 && hours < 18) {
        return this.$t('home.good_day');
      } else {
        return this.$t('home.good_evening');
      }
    }
  },
  methods: {

    onServiceClick(id) {
      this.$store.commit("setService", id);

      if (id == "REQUESTS") {
        this.$router.push({ name: "requests-summary" });
      } else if (id == "EDUCATION") {
        this.$router.push({ name: "education-posts" });
      }
    }

  },


  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
    this.time = moment();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.time = moment();
    });
  },

};
</script>


<style lang="scss">
.sbs-v-home {

  .logo-wrap {
    width: 200px;
    margin: 50px auto 50px;
  }

  .welcome {
    color: var(--bg-invert);
    text-align: center;

    margin-bottom: 50px;
  }

  .services {
    margin: 0px -5px;
    justify-content: center;
    cursor: pointer;

    >div {
      padding: 0px 5px;
    }

    .service {
      position: relative;
      padding: 10px;

      .image {
        width: 70px;
        height: 70px;
        border-radius: 20px;
        margin: 0px auto 10px;
        box-shadow: 0px 8px 8px 0px var(--shadow-secondary);

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .name {
        color: var(--bg-invert);
        text-align: center;
      }
    }
  }
}
</style>
