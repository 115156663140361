<template>
    <sbs-view :class="['sbs-v-edu-training-passage', finish ? 'finish' : '']" :isMenuHidden="hideMenu"
        :refreshDisabled="true" :noPaddingHorizontal="true" :noPaddingTop="true" :noContainerWrap="true" ref="view">

        <template v-slot:header v-if="!finish">
            <sbs-header>
                <template v-slot:center>
                    <div class="title title-small">{{ training ? training.name : "" }}</div>
                </template>
            </sbs-header>
        </template>

        <template v-slot:content>

            <template v-if="loading">
                <sbs-loader-page />
            </template>

            <template v-else>

                <template v-if="!training">
                    <div class="container view-horizontal-padding">{{ $t("education.training.not_found") }}</div>
                </template>

                <template v-else>

                    <template v-if="!finish">

                        <div class="container ">

                            <div class="tabs" ref="tabs">

                                <div class="tab" v-for="item in items" :key="item.id"
                                    :class="{ 'current': item.id == currentPostId, locked: isLocked(item) }"
                                    @click="onTabClick(item)">
                                    <span class="fi" :class="{
        'fi-post-post': item.type == 'POST',
        'fi-post-test': item.type == 'TEST',
        'fi-comments': item.type == 'FEEDBACK'
    }"></span>
                                </div>

                            </div>

                            <div class="view-horizontal-padding">
                                <template v-if="postLoading">
                                    <br /><br />
                                    <div class="text-center">
                                        <sbs-loader-inline />
                                    </div>
                                </template>

                                <template v-else>

                                    <sbs-education-post-post v-if="post.type == 'POST'" :item="post" :trainingId="id"
                                        :passageMode="true" :footerId="footerId" @complete="onNextClick" />

                                    <sbs-education-post-test v-else-if="post.type == 'TEST'" :item="post"
                                        :trainingId="id" :passageMode="true" :footerId="footerId"
                                        @complete="onNextClick" />

                                    <sbs-education-post-feedback v-else-if="post.type == 'FEEDBACK'" :item="post"
                                        :passageMode="true" :trainingId="id" :footerId="footerId"
                                        @complete="onNextClick" />

                                </template>
                            </div>

                        </div>

                    </template>

                    <template v-else>
                        <div class="finish-block">
                            <div class="view-horizontal-padding">
                                <div class="circle-wrap">
                                    <div class="circle">
                                        <img :src="imageMedal" />
                                    </div>
                                </div>

                                <div class="info">
                                    <div class="title nunito-7-bold">{{ $t('education.training.passage.finish.title') }}
                                    </div>

                                    <div class="training-name nunito-3-regular">«{{ training.name }}»</div>
                                </div>

                            </div>
                        </div>
                    </template>

                </template>
            </template>

        </template>

        <template v-slot:footer>
            <div :id="footerId"></div>
            <template v-if="finish">

                <div class="finish-btns">
                    <div class="container">
                        <div class="btn btn1 btn-block" @click="complete">{{
        $t('education.training.passage.finish.complete') }}</div>
                    </div>
                </div>
            </template>
        </template>
    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderPage from "@/components/loader.page.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationPostPost from "@/components/education/post.post.vue"
import sbsEducationPostTest from "@/components/education/post.test.vue"
import sbsEducationPostFeedback from "@/components/education/post.feedback.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import imageMedalPath from "@/assets/images/medal.svg";

export default {
    name: "sbs-v-education-training-passage",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderPage,
        sbsLoaderInline,
        sbsEducationPostPost,
        sbsEducationPostTest,
        sbsEducationPostFeedback

    },
    data() {
        return {
            hideMenu: false,
            loading: true,
            postLoading: false,
            training: null,
            items: [],

            currentPostId: 0,
            post: false,
            finish: false,

            imageMedal: imageMedalPath,

            footerId: "sbs-edu-post-training-passage-footer"
        };
    },
    props: {
        id: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    watch: {
        id: function () {
            this.finish = false;
            this.loadData();
        },
        items: function () {
            let first = this.items.length > 0 ? this.items[0] : false;
            if (first) {
                this.currentPostId = first.id;
            }
        },

        currentPostId: function () {
            this.loadCurrentPost();
        }
    },

    methods: {

        getBatchRequests() {
            let requests = {
                training: {
                    ENTITY: "education",
                    METHOD: "training.get",
                    PARAMS: {
                        mode: "public",
                        id: this.id,
                        load_my_results: "Y",
                    },
                },

                posts: {
                    ENTITY: "education",
                    METHOD: "training.post.list",
                    PARAMS: {
                        mode: "public",
                        load_my_results: "Y",
                        training_id: this.id,
                        resize_options: {
                            PREVIEW: { WIDTH: 400, HEIGHT: 300 },
                            PRELOAD: "Y"
                        },
                    },
                }
            };

            return requests;
        },

        /**
         * Загружает данные
         */
        loadData(resolve = false) {

            let requests = this.getBatchRequests();

            //покажем экран загрузки
            this.loading = resolve ? false : true;

            rest.batch(requests)
                .then((results) => {
                    for (var key in results) {
                        let data = results[key];
                        if (data.success) {
                            if (key == "training") {
                                this.training = data.training;
                            } else if (key == "posts") {
                                this.items = data.items;
                            }
                        } else {
                            //показываем сообщение об ошибке
                            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                            return;
                        }

                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    //скроем экран загрузки
                    this.loading = false;
                });

        },

        /**
         * Загружает текущий пост
         */
        loadCurrentPost() {

            this.postLoading = true;

            //запрашиваем данные
            rest
                .call("education.training.post.get", {
                    method: "post",
                    data: {
                        mode: "public",
                        training_id: this.id,
                        id: this.currentPostId,
                        select: ["user_photo", "user_name", "user_last_name"],
                        load_my_result: "Y",
                        resize_options: {
                            PREVIEW: { WIDTH: 800, HEIGHT: 600 },
                            PRELOAD: "Y"
                        },
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.post = data.post;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.postLoading = false;
                });
        },

        /**
         * Возвращает флаг необходимости блокировки поста
         */
        isLocked(item) {

            let index = this.items.findIndex(p => p.id == item.id);
            if (index == 0) {
                return false;
            }

            let prevItem = this.items[index - 1];
            if (!prevItem.result || prevItem.result.status != 'S') {
                return true;
            }

            return false;
        },

        /**
         * Клик по табу
         */
        onTabClick(item) {
            if (this.isLocked(item)) {
                return;
            }

            this.currentPostId = item.id;
        },

        /**
         * Клик по "Далее" внутри задания (после его успешного прохождения)
         */
        onNextClick() {
            let index = this.training.data.posts.findIndex(p => p.id == this.currentPostId);
            //если ещё есть задания для прохождения
            if (index < this.training.data.posts.length - 1) {


                let next = this.training.data.posts[index + 1];
                this.currentPostId = next.id;
                this.scrollToTab();
            } else {
                this.finish = true;
            }
        },

        /**
         * Клик по кнопке "Завершить"
         */
        complete() {

            //возвращаемся на список постов тренировки
            this.$router.replace({
                name: "education-trainings"
            });

        },

        /**
         * Прокрутка меню табов до текущего
         */
        scrollToTab() {

            let index = this.items.findIndex(p => p.id == this.currentPostId);
            let nodes = this.$refs.tabs.querySelectorAll('.tab');
            if (!nodes || nodes.length <= index) {
                return;
            }
            let node = nodes[index];
            let left = node.offsetLeft - node.offsetParent.offsetLeft;

            this.$refs.tabs.scrollTo({ left: left - 15, behavior: 'smooth' });
        }


    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.loadData();
    },
}
</script>

<style lang="scss">
.sbs-v-edu-training-passage {

    --view-background-color: var(--passing-training-passing-training-bg-3-color);

    &.finish {
        .view-content-scroll {
            background: none;
        }
    }

    .tabs {

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0px;
            right: 0px;
            height: 52px;
            background: var(--passing-training-passing-training-bg-2-color);

        }

        &::-webkit-scrollbar {
            display: none;
        }

        white-space: nowrap;
        overflow-x: scroll;

        background: var(--passing-training-passing-training-bg-2-color);
        padding: 0px 15px 0px;

        .tab {
            margin-top: 6px;
            position: relative;
            z-index: 10;
            display: inline-block;
            color: var(--training-icon-training-icon-viewed-color);
            font-size: 18px;
            line-height: 1;
            cursor: pointer;

            padding: 10px 15px 16px;

            &.locked {
                cursor: not-allowed;
                color: var(--training-icon-training-icon-not-viewed-color);
            }

            &.current {
                border-radius: 15px 15px 0px 0px;
                background: var(--passing-training-passing-training-bg-3-color);
                color: var(--service-color-1);
            }
        }
    }

    .finish-block {
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 0px;
        left: 10px;
        max-width: 930px;
        margin: auto;

        border-radius: 15px;
        background: var(--passing-training-passing-training-bg-2-color);

        .circle-wrap {

            max-width: 350px;
            margin: 0px auto;
        }

        .circle {
            padding-top: 100%;
            position: relative;
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 229, 13, 0.40) 38%, rgba(255, 255, 255, 0.00) 86.33%);

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .info {
            margin-top: -40px;

            .title {
                color: var(--bg-invert);
                text-align: center;
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
            }

            .training-name {
                color: var(--bg-invert);
                text-align: center;
            }
        }
    }

    .finish-btns {
        padding: 15px 0px;

    }
}
</style>