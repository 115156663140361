<template>
  <div class="sbs-rcr">
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>

    <template v-else>
      <!-- Завка не найдена-->
      <div v-if="request == null">{{ $t('requests.delegate.not_found') }}</div>
      <div v-else>
        <sbs-control-select v-model="responsible_department_id" v-model:modelName="responsible_department_name"
          :label="$t('requests.delegate.responsible_department')" :required="true" name="responsible_department_id"
          method="department.list" :noLabelAnimation="true" :params="{
      filter: [
        {
          LOGIC: 'OR',
          active: 'Y',
          id: responsible_department_id,
        },
      ],
      forResponsible: 'Y',
    }" />
        <sbs-control-select v-model="responsible_user_id" v-model:modelName="responsible_user_name"
          v-model:modelItem="responsible_user" :label="$t('requests.delegate.responsible_user')"
          name="responsible_user_id" method="users.list" :noLabelAnimation="true" :params="{
      filter: { department_id: responsible_department_id },
    }" />

        <div class="buttons">
          <div class="container">
            <div class="page-padding">
              <div class="btn btn-block btn1" :class="saving ? 'wait' : ''" v-ripple.300 @click="apply">
                {{ $t('common.apply') }}
                <div v-if="saving" class="btn-loader"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlSelect from "@/components/controls/select.vue";

import validator from "@/plugins/validator";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-request-change-responsible",
  components: {
    sbsLoaderInline,
    sbsControlSelect,
  },
  data() {
    return {
      loading: true,
      saving: false,
      request: null,
      responsible_user: {},
      responsible_department_id: "",
      responsible_user_id: "",
      responsible_department_name: "",
      responsible_user_name: "",
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  watch: {
    selected: function (newVal) {
      let temp = this.items.filter((item) => newVal.includes(item.id));
      this.values = temp;
    },

    show: function (newVal) {
      this.visible = newVal;
      if (this.visible) {
        this.onOpen();
      }
    },

    modelValues: function () {
      this.selected = this.modelValues.map((v) => v.id);
    },

    responsible_department_id: function () {
      if (!this.loading) {
        this.responsible_user_id = "";
        this.responsible_user_name = "";
      }
    },
  },

  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;
      this.request = null;

      //запрашиваем данные
      rest
        .call("request.get", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            this.request = data.request;
            this.onLoad();
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Инициализация полей после загрузки
     */
    onLoad() {
      this.responsible_department_id = this.request.responsible_department_id;
      this.responsible_department_name =
        this.request.responsible_department_name;
      if (this.request.responsible_user_id > 0) {
        this.responsible_user_id = this.request.responsible_user_id;
        this.responsible_user_name =
          this.request.responsible_user_last_name +
          " " +
          this.request.responsible_user_name;
      }
    },

    /**
     * Валидация
     */
    validate() {
      //проверка введённых данных
      if (!validator.check({ container: this.$el })) {
        return false;
      }

      //если подразделение не изменилось, и при этом исполнитель сброшен
      if (
        this.request.responsible_department_id ==
        this.responsible_department_id &&
        !this.responsible_user_id
      ) {
        bus.emit("SBS_MESSAGE_E_SHOW", {
          message:
            this.$t('requests.delegate.nochange'),
        });
        return false;
      }

      return true;
    },

    /**
     * Сохранение
     */
    apply() {
      if (!this.validate()) {
        return;
      }

      if (this.saving) {
        return;
      }
      this.saving = true;

      rest
        .call("request.update", {
          method: "post",
          data: {
            id: this.id,
            fields: {
              responsible_department_id: this.responsible_department_id,
              responsible_user_id: this.responsible_user_id,
            },
          },
        })
        .then((data) => {
          if (data.success) {
            this.onSave();
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          this.saving = false;
        });
    },

    /**
     * Обработка успешного сохранения
     */
    onSave() {
      bus.emit("SBS_MESSAGE_E_SHOW", {
        message: this.$t('requests.delegate.success'),
        type: "success",
      });

      //генерим событие успешного обновления заявки
      bus.emit("SBS_REQUEST_E_UPDATED", this.id);
      bus.emit("SBS_REQUEST_VIEW_E_RELOAD");

      //переход обратно
      this.$router.go(-1);
    },
  },

  created() {
    //загружаем данные
    this.loadData();
  },

  mounted() { },
};
</script>

<style lang="scss">
.sbs-rcr {
  .buttons {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 15px 0px calc(var(--keyboard-height, 0px) + var(--safe-area-bottom, 0px) + 15px);
    background-color: var(--bg);
  }
}
</style>