<template>
    <sbs-view :class="'sbs-v-user-add'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
        <template v-slot:header>
            <sbs-header>
                <template v-slot:center>
                    <div class="title title-small">{{ $t('users.form.title') }}</div>
                </template>
            </sbs-header>
        </template>
        <template v-slot:content>

            <!-- Загрузка -->
            <template v-if="loading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>

            <template v-else>

                <!-- Не найден-->
                <template v-if="department == null">{{ $t('users.form.department_not_found') }}</template>
                <template v-else>

                    <!-- Форма -->
                    <template v-if="!saved">

                        <sbs-control-input type="text" :label="$t('users.form.fields.department')"
                            :modelValue="department.name" :readonly="true"></sbs-control-input>

                        <sbs-control-input type="text" :label="$t('users.form.fields.last_name')"
                            v-model="fields.last_name" :required="true">
                            <template v-if="isNativePlatform" v-slot:icon>
                                <div class="icon" @click="speechLastName">
                                    <span class="fi fi-mic"></span>
                                </div>
                            </template>
                        </sbs-control-input>

                        <sbs-control-input type="text" :label="$t('users.form.fields.name')" v-model="fields.name"
                            :required="true">
                            <template v-if="isNativePlatform" v-slot:icon>
                                <div class="icon" @click="speechName">
                                    <span class="fi fi-mic"></span>
                                </div>
                            </template>
                        </sbs-control-input>

                        <sbs-control-input type="text" :label="$t('users.form.fields.work_position')"
                            v-model="fields.work_position" :required="true">
                            <template v-if="isNativePlatform" v-slot:icon>
                                <div class="icon" @click="speechPosition">
                                    <span class="fi fi-mic"></span>
                                </div>
                            </template>
                        </sbs-control-input>

                        <sbs-control-select-multiple v-model="fields.role_id" :label="$t('users.form.fields.role')"
                            :list="roles" :required="true" />


                        <sbs-control-input type="tel" :label="$t('users.form.fields.phone')" v-model="fields.phone"
                            :required="true" :mask="{ mask: '+7 (###) ###-##-##' }">
                        </sbs-control-input>

                        <div class="btn btn1 btn-block btn-save" :class="saving ? 'wait' : ''" v-ripple.300
                            @click="save">
                            {{ $t('users.form.send') }}
                            <div v-if="saving" class="btn-loader"></div>
                        </div>

                    </template>

                    <!-- Успешное добавление -->
                    <template v-else>
                        <div class="success">
                            <div class="message">
                                {{ $t('users.form.success') }}
                            </div>
                            <div class="btn btn1 btn-block btn-reset" @click="reset">
                                {{ $t('users.form.add_more') }}
                            </div>
                        </div>
                    </template>

                </template>

            </template>

        </template>
    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlSelectMultiple from "@/components/controls/select.multiple.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import validator from "@/plugins/validator";

import { mapGetters } from "vuex";

export default {
    name: "sbs-v-user-add",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsControlInput,
        sbsControlSelectMultiple
    },
    data() {
        return {
            loading: true,
            saving: false,
            saved: false,
            department: null,
            roles: [],
            hideMenu: false,

            fields: {}

        };
    },
    computed: {
        ...mapGetters({
            isNativePlatform: "isNativePlatform",
        }),
    },
    props: {
        departmentId: {
            type: Number,
            default() {
                return 0;
            },
        },
    },

    methods: {

        reset() {
            this.saved = false;
            this.fields = {
                department_id: this.departmentId,
                last_name: "",
                name: "",
                work_position: "",
                phone: "",
                role_id: []
            }
        },

        /**
         * Подготовка запросов
         */
        getBatchRequests() {
            return {
                department: {
                    ENTITY: "department",
                    METHOD: "get",
                    PARAMS: {
                        id: this.departmentId,
                    },
                },
                roles: {
                    ENTITY: "dictionary",
                    METHOD: "role.list",
                    PARAMS: {}
                },
            };
        },

        /**
         * Загружает данные
         */
        loadData(resolve = false) {

            //покажем экран загрузки
            this.loading = resolve ? false : true;

            //запрашиваем данные
            rest
                .batch(this.getBatchRequests())
                .then((arResults) => {
                    //обработка результатов
                    this.processResponse(arResults);
                })
                .finally(() => {
                    //скроем экран загрузки
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });

        },

        /**
         * Обработка результата
         */
        processResponse(results) {
            for (var key in results) {
                let data = results[key];

                if (data.success) {
                    if (key == "department") {
                        this.department = data.department;
                        this.permissions = data.permissions;
                    } else if (key == "roles") {
                        this.roles = data.items;
                    }
                } else {
                    //показываем сообщение об ошибке
                    bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    return;
                }
            }
        },

        /**
         * Распознование речи для фамилии
         */
        speechLastName() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.fields.lastName = text;
                },
            });
        },

        /**
         * Распознование речи для имени
         */
        speechName() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.fields.name = text;
                },
            });
        },

        /**
         * Распознование речи для должности
         */
        speechPosition() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.fields.position = text;
                },
            });
        },

        /**
         * Перезагрузка страницы
         */
        refresh(resolve) {
            this.loadData(resolve)
        },

        /**
         * Сохранение
         */
        save() {

            if (this.saving) {
                return;
            }

            //проверка введённых данных
            if (!validator.check({ container: this.$el })) {
                return;
            }

            this.saving = true;

            //выполняем сохранение
            rest
                .call("users.add", {
                    method: "post",
                    data: this.fields,
                })
                .then((data) => {
                    if (data.success) {
                        this.saved = true;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.saving = false;
                });

        }



    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.reset();
        this.loadData();
    },
}

</script>

<style lang="scss">
.sbs-v-user-add {
    .success {
        .message {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
}
</style>
