<template>
    <sbs-view :class="'sbs-v-restore'" :isMenuHidden="hideMenu" :refreshDisabled="true" ref="view">

        <template v-slot:header>
            <sbs-header>
                <template v-slot:center>
                    <div class="title title-small">{{ $t('auth.restore.title') }}</div>
                </template>
            </sbs-header>
        </template>

        <template v-slot:content>

            <div class="notice">
                {{ $t('auth.restore.notice') }}
            </div>

            <form @submit.prevent="onSubmit" action="">

                <input type="submit" style="display: none;" />

                <sbs-control-input v-if="showServer" v-model="server" type="text" :label="$t('auth.restore.server')"
                    :mask="{ mask: 'U', tokens: { U: { pattern: /[0-9a-zA-Z\.\-\_]/, repeated: true } } }"
                    :required="true" :showRequired="false" />

                <sbs-control-input type="tel" :label="$t('auth.restore.phone')" v-model="phone" :required="true"
                    :mask="{ mask: '+7 (###) ###-##-##' }">
                </sbs-control-input>

                <div class="btn btn1 btn-block" :class="loading ? 'wait' : ''" v-ripple.300 v-on:click="onSubmit">
                    {{ $t('common.send') }}
                    <div v-if="loading" class="btn-loader"></div>
                </div>

            </form>

            <br />

            <div class="toggle-server">
                <div class="a" v-if="!showServer" @click="toggleServer">{{ $t('auth.restore.show_server') }}</div>
                <div class="a" v-else @click="toggleServer">{{ $t('auth.restore.default_server') }}</div>
            </div>


        </template>
    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsControlInput from "@/components/controls/input.vue";

import bus from "@/plugins/bus";
import validator from "@/plugins/validator";
import rest from "@/plugins/rest";
import localStorage from "@/plugins/localStorage";

export default {
    name: "sbs-v-restore",
    components: {
        sbsView,
        sbsHeader,
        sbsControlInput
    },
    data() {
        return {
            hideMenu: false,
            loading: false,
            phone: "",
            server: "",
            showServer: false,
            showSuccess: false
        };
    },
    props: {
    },
    methods: {

        onSubmit: function () {
            //првоерка введённых данных
            if (!validator.check({ container: this.$el })) {
                return;
            }

            let server = this.showServer ? this.server : process.env.VUE_APP_DEFAULT_SERVER;

            if (this.loading) {
                return;
            }
            this.loading = true;

            //запрашивает автооризацию
            rest
                .call(
                    "auth.restore.sms",
                    {
                        method: "post",
                        data: {
                            phone: this.phone
                        },
                    },
                    false,
                    "https://" + server + "/app/sbs/rest/"
                )
                .then((data) => {
                    //если ошибка 
                    if (!data.success) {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    } else {

                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: this.$t('auth.restore.success', { phone: this.phone }), type: 'success', timeout: 10000 });
                    }

                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Показать/скрыть сервер
         */
        toggleServer() {
            this.showServer = !this.showServer
        },
    },
    async created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        let lastServer = await localStorage.get("last_server");
        if (lastServer) {
            this.server = lastServer;
        }
    },

    /**
     * Обработка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.showSuccess = false;
        });
    },
};
</script>

<style lang="scss">
.sbs-v-restore {
    .notice {
        margin-bottom: 35px;
    }

    .toggle-server {
        text-align: center;
        margin-bottom: 10px;

        color: var(--news-card-news-card-secondary-text-color);

    }
}
</style>