<template>
    <div v-if="inited" class="sbs-acc" :class="{ expanded: expandedInternal, slim: slim }">

        <div class="clickable" v-ripple.300 @click="onClick">
            <slot name="left"></slot>
            <div class="title" :class="slim ? 'nunito-3-bold' : 'nunito-4-bold'">{{ title }}</div>

            <div class="collapser">
                <span class="fi fi-arrow-down"></span>
            </div>
        </div>

        <div class="collapsable">
            <div class="content">
                <slot></slot>
            </div>

        </div>

    </div>
</template>

<script>
import localStorage from "@/plugins/localStorage";

export default {
    name: "sbs-accordion",
    components: {},
    data() {
        return {

            expandedInternal: this.expanded,
            inited: false
        };
    },
    props: {
        title: {
            type: String,
            default() {
                return "";
            },
        },
        code: {
            type: String,
            default() {
                return "";
            },
        },
        expanded: {
            type: Boolean,
            default() {
                return true;
            }
        },
        remember: {
            type: Boolean,
            default() {
                return true;
            }
        },
        slim: {
            type: Boolean,
            default() {
                return false;
            }
        }

    },

    methods: {

        onClick() {
            this.expandedInternal = !this.expandedInternal;

            if (this.remember) {
                localStorage.set("accordion." + this.code, this.expandedInternal);
            }
        }

    },

    async created() {

        if (this.remember) {
            let expandedInternal = await localStorage.get("accordion." + this.code);
            if (expandedInternal != null) {
                this.expandedInternal = expandedInternal
            }
        }

        this.inited = true;
    }

};
</script>


<style lang="scss">
.sbs-acc {
    margin-bottom: 10px;

    border-radius: 15px;
    background: var(--audit-cards-audit-cards-bg-color);
    box-shadow: 0px 2px 4px 0px var(--shadow-secondary);

    overflow: hidden;

    .clickable {
        display: flex;
        column-gap: 15px;
        align-items: center;
        padding: 10px 50px 10px 18px;
        position: relative;
        cursor: pointer;

        min-height: 70px;

        .title {
            color: var(--audit-cards-audit-cards-title-text-color);
            flex-grow: 1;
        }

        .collapser {
            position: absolute;
            top: 50%;
            right: 18px;
            transform: translateY(-50%);
            color: var(--service-color-1);
            line-height: 1;
            font-size: 14px;


            .fi {
                position: relative;
                display: inline-block;
                transition: transform 0.3s ease-in-out;
            }
        }
    }

    .collapsable {
        overflow: hidden;
        max-height: 0px;

        transition: max-height 0.3s ease-in-out, padding-top 0.3s ease-in-out;

        .content {
            padding: 0px 18px 18px;
        }
    }

    &.expanded {

        .clickable {
            .collapser {
                .fi {
                    transform: rotate(180deg);
                }

            }
        }

        .collapsable {
            max-height: 800px;
        }
    }

    &.slim {
        .clickable {
            min-height: 50px;
        }
    }

}
</style>