<template>
    <div class="sbs-control-sort">

        <div class="items">
            <template v-for="(item, index) in value" :key="index">
                <div class="item">
                    <div>
                        <sbs-control-select v-model="item.id" :label="$t('controls.sort.field')" :required="true"
                            :list="fields" :noMargin="true" />
                    </div>
                    <div>
                        <div class="order" @click="changeOrder(item)">
                            <span class="fi" :class="item.order == 'asc' ? 'fi-sort-asc' : 'fi-sort-desc'"></span>
                        </div>
                    </div>
                </div>
            </template>

            <div class="add" @click="add">
                + {{ $t('controls.sort.add') }}
            </div>
        </div>

    </div>
</template>

<script>
import sbsControlSelect from "@/components/controls/select.vue";
import equal from 'equals';

export default {
    name: "sbs-control-sort",
    components: {
        sbsControlSelect,
    },
    emits: ["update:modelValue"],
    data() {
        return {
            value: [],

            orders: [
                {
                    id: "asc",
                    name: "По возрастанию",
                },
                {
                    id: "desc",
                    name: "По убыванию"
                }
            ]
        };
    },
    props: {
        modelValue: {
            type: Object,
            default() {
                return {};
            },
        },

        fields: {
            type: Array,
            default() {
                return []
            }
        }

    },
    computed: {

    },
    watch: {

        modelValue: function () {
            this.setValueFromModel();
        },

        value: {
            handler() {
                this.setModelByValue();
            },
            deep: true
        }
    },

    methods: {

        /**
         * Устанавливает внутренней значение как массив из входящего значения
         */
        setValueFromModel() {
            let value = [];

            Object.keys(this.modelValue).forEach(key => {
                value.push({
                    id: key,
                    order: this.modelValue[key]
                })
            })

            //проверим не совпадает ли с уже заданным, для предотвращения зацикливания через watch
            if (equal(value, this.value)) {
                return;
            }

            this.value = value;
        },

        /**
         * Устанавливает входящее значение как объект из массива внутреннго значения
         */
        setModelByValue() {
            let value = {};
            this.value.forEach(item => {
                value[item.id] = item.order;
            });

            //проверим не совпадает ли с уже заданным, для предотвращения зацикливания через watch
            if (equal(value, this.modelValue)) {
                return;
            }

            this.$emit("update:modelValue", value);
        },

        /**
         * Смена направления сортировки
         */
        changeOrder(item) {
            item.order = item.order == 'asc' ? 'desc' : 'asc';
        },

        /**
         * Добавляет сортировку
         */
        add() {

            let field = this.fields.find(() => 1 == 1);

            this.value.push({
                id: field.id,
                order: field.order ? field.order : "asc"
            });
        }
    },

    created() {
        this.setValueFromModel();
    }



}
</script>

<style lang="scss">
.sbs-control-sort {
    .item {
        display: flex;
        column-gap: 20px;
        align-items: center;
        margin-bottom: 15px;

        .order {
            padding: 5px;
            line-height: 1;
            cursor: pointer;
            color: var(--service-color-1);
            font-size: 20px;
        }
    }

    .add {
        display: inline-block;
        border-bottom: 1px dashed currentColor;
        cursor: pointer;
    }
}
</style>