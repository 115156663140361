<template>
  <div class="sbs-request-view-checklist">

    <div class="progress" ref="progress">
      <div class="percent">{{ percent }}%</div>

      <div class="processed" :style="{ width: percent + '%' }">
        <div class="wrap" :style="{ width: width + 'px' }">
          <div class="percent">{{ percent }}%</div>
        </div>
      </div>
    </div>

    <template v-for="item in items" :key="item.id">
      <sbs-accordion :title="item.name" :expanded="false" :slim="true" class="item">

        <template v-slot:left>
          <div class="status">
            <span v-if="item.status == 'F'" class="ic fi fi-save-bold"></span>
            <span v-if="item.status == 'C'" class="ic fi fi-close"></span>
          </div>
        </template>

        <div v-if="item.type == 'NUMBER'" class="prop">
          <div class="value">{{ item.value }}</div>
        </div>
        <div v-if="item.type == 'TEXT'" class="prop">
          <div class="value">{{ item.value }}</div>
        </div>
        <div v-if="item.type == 'PHOTO'" class="prop">
          <sbs-photo-swiper :photos="item.value" />
        </div>
        <div v-if="item.type == 'CHECKBOX'" class="prop">
          <div class="value" v-if="item.value == 'Y'">{{ $t('common.yes') }}</div>
          <div class="value" v-else-if="item.value == 'N'">{{ $t('common.no') }}</div>
        </div>

      </sbs-accordion>
    </template>




    <div v-if="permissions.includes('checklist')" class="btn btn1 btn-block btn-start" @click="execute">
      {{ $t('requests.view.checklist.start') }}
    </div>
  </div>
</template>

<script>
import sbsPhotoSwiper from "@/components/photo.swiper.vue";
import sbsAccordion from "@/components/accordion.vue";

export default {
  name: "sbs-request-view-checklist",
  components: {
    sbsPhotoSwiper,
    sbsAccordion
  },
  data() {
    return {
      width: 0
    };
  },
  props: {
    id: {
      type: [Number, String],
      default() {
        return 0;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    permissions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    percent() {
      let length = this.items.length;
      if (length == 0) {
        return 0;
      }
      return Math.round(
        (this.items.filter((i) => i.status != "N").length / length) * 100
      );
    },
  },
  methods: {

    updateWidth() {
      this.width = this.$refs.progress.clientWidth;
    },

    createResizeObserver() {
      this.resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (entry.target === this.$refs.progress) {
            this.updateWidth();
          }
        }
      });
      this.resizeObserver.observe(this.$refs.progress);
    },

    destroyResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },

    execute() {
      this.$router.push({
        name: "request-checklist-execute",
        params: { id: this.id },
      });
    },

    /**
     * Раскрывает/закрывает пункт
     */
    expand(item) {
      item.expanded = !item.expanded;
    },
  },

  mounted() {
    this.updateWidth();
    this.createResizeObserver();
  },
  beforeUnmount() {
    this.destroyResizeObserver();
  },
};
</script>

<style lang="scss">
.sbs-request-view-checklist {

  .progress {
    position: relative;
    height: 40px;
    border-radius: 8px;
    background-color: var(--audit-cards-audit-cards-bg-color);
    margin-bottom: 25px;

    overflow: hidden;

    .percent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;

      color: var(--audit-cards-audit-cards-title-text-color);
    }

    .processed {
      width: 0%;
      height: 100%;
      background-color: var(--service-color-1);
      overflow: hidden;
      position: relative;
      transition: width 0.5s ease-in-out;

      .wrap {
        height: 100%;
        position: relative;
      }

      .percent {
        color: var(--text-field-text-field-active-color-text);
      }
    }

  }

  .item {
    .status {
      position: relative;
      width: 25px;
      height: 25px;

      flex-shrink: 0;

      background: var(--chekbox-and-radiobutton-chekbox-dark-standard-pressed-bg-color);
      border-radius: 7px;
      box-shadow: 0px 2px 3px -1px var(--shadow-checkbox) inset;

      .ic {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1;
        color: var(--service-color-1);
      }
    }
  }

  .btn-start {
    margin-top: 40px;
  }
}
</style>