<template>
  <div :class="[form ? 'form-group' : '']">
    <div class="sbs-checkbox" :class="{ checked: checked, nolabel: label == '' }" @click.stop="true">
      <label>
        <div class="box">
          <span v-if="checked" class="ic fi fi-save-bold"></span>
        </div>

        <input type="checkbox" :class="required ? 'required' : ''" :name="name" :value="value" v-model="checked"
          @change="onChange" />

        <template v-if="label != ''">
          <div class="label roboto-4-regular">
            {{ label }}
          </div>
        </template>
        <template v-else> &nbsp; </template>
      </label>
    </div>
  </div>
</template>

<script>
import { toRaw } from "vue";

export default {
  name: "sbs-control-checkbox",
  data() {
    return {
      checked: !this.multiple
        ? this.modelValue == this.value
        : this.values.includes(this.value),
    };
  },
  emits: ["update:modelValue", "update:values", "change"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
    value: {
      type: [String, Number, Boolean],
      default() {
        return "";
      },
    },
    defaultValue: {
      type: [String, Number, Boolean],
      default() {
        return "";
      },
    },
    modelValue: {
      type: [String, Number, Boolean],
      default() {
        return "";
      },
    },
    values: {
      type: Array,
      default() {
        return [];
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    form: {
      type: Boolean,
      default() {
        return true;
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    modelValue: function (newVal) {
      this.checked = newVal == this.value;
    },
    values: function (newVal) {
      this.checked = newVal.includes(this.value);
    },
    checked: function () {

      if (!this.multiple) {
        this.$emit("update:modelValue", this.checked ? this.value : this.defaultValue);
      } else {
        //берём реальный массив текущих значений
        let values = toRaw(this.values);
        //если данный вариант выбран
        if (this.checked) {
          //если значения варианта ещё нет в списке
          if (!values.includes(this.value)) {
            //доабвим его
            values.push(this.value);
            //создаём новый массив, чтобы вочер смог отследить
            values = values.filter(() => true);
          }
        }
        //иначе вариант не выбран
        else {
          //если значение варианта в списке
          if (values.includes(this.value)) {
            //то удалим его
            values = values.filter((val) => val !== this.value);
          }
        }
        this.$emit("update:values", values);
      }
    },
  },
  methods: {
    onChange() {
      this.$emit("change", this.value);
    }
  },
};
</script>

<style lang="scss">
.sbs-checkbox {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 37px;
  margin: 5px 0px;

  &.nolabel {
    width: 20px;
    height: 20px;
    padding-left: 0px;
  }

  input {
    display: none;
  }

  label {
    margin-bottom: 0px;
    cursor: pointer;
    font-weight: normal;
  }

  .box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;

    width: 25px;
    height: 25px;

    background: var(--chekbox-and-radiobutton-chekbox-dark-standard-pressed-bg-color);
    border-radius: 7px;
    box-shadow: 0px 2px 3px -1px var(--shadow-checkbox) inset;

    .ic {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1;
      color: var(--service-color-1);
    }
  }

}
</style>