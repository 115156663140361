<template>
    <sbs-sheet v-model:visible="visibleInternal">
        <template v-slot:body>

            <div class="sbs-requests-filter">

                <div class="sheet-title">
                    {{ $t('common.filter') }}
                    <div class="action" v-ripple.300 @click="onSettingsClick">
                        <span class="icon fi fi-settings"></span>
                    </div>
                </div>

                <template v-if="loading">
                    <sbs-loader-inline />
                </template>
                <template v-else>

                    <div class="filters">

                        <sbs-control-filter :label="$t('requests.filter.fields.equipment')" method="equipment.list"
                            v-model="filter.equipment" />

                        <sbs-control-filter :label="$t('requests.filter.fields.priority')"
                            method="dictionary.request.priorities.list" v-model="filter.priority" />

                        <sbs-control-filter :label="$t('requests.filter.fields.type')"
                            method="dictionary.request.type.list" v-model="filter.type" />

                        <sbs-control-filter :label="$t('requests.filter.fields.status')"
                            method="dictionary.request.statuses.list" v-model="filter.status" />

                        <sbs-control-filter :label="$t('requests.filter.fields.responsible_department')"
                            method="dictionary.departments.list" v-model="filter.responsibleDepartment"
                            :params="{ filter: [{ active: 'Y' }] }" />

                        <sbs-control-filter :label="$t('requests.filter.fields.responsible_user')" method="users.list"
                            v-model="filter.responsibleUser" :params="responsibleUserParams" />

                        <sbs-control-filter :label="$t('requests.filter.fields.created_by')" method="users.list"
                            v-model="filter.createdBy" />

                        <sbs-control-filter :label="$t('requests.filter.fields.created_department')"
                            method="dictionary.departments.list" v-model="filter.createdDepartment"
                            :params="{ filter: [{ active: 'Y' }] }" />

                        <sbs-control-filter-dates :label="$t('requests.filter.fields.created')"
                            v-model="filter.created" />

                        <sbs-control-filter-dates :label="$t('requests.filter.fields.deadline')"
                            v-model="filter.deadline" />

                        <sbs-control-filter :label="$t('requests.filter.fields.overdue')" :items="boolList"
                            v-model="filter.overdue" />

                        <sbs-control-filter v-if="statistics.ratings.length > 0"
                            :label="$t('requests.filter.fields.rating')" :items="statistics.ratings"
                            v-model="filter.rating" />
                    </div>

                    <div class="h2">{{ $t('requests.filter.sort') }}</div>

                    <sbs-control-sort v-model="sort" :fields="sortFields" />

                    <div class="buttons">
                        <div class="btn btn-block btn2" @click="clear">{{ $t('common.clear') }}</div>
                        <div class="btn btn-block btn1" @click="apply">{{ $t('common.apply') }}</div>
                    </div>

                </template>

            </div>

            <sbs-control-select-sheet v-model:visible="showSettings" :title="this.$t('requests.filter.actions.title')"
                v-model="selectedAction" :list="actions" :showCheck="false" :showButtons="false" />

            <sbs-sheet v-model:visible="showSaveAs">
                <template v-slot:body>

                    <div class="sheet-title">{{ $t('requests.filter.actions.new_filter') }}</div>

                    <div ref="filter_save_form">
                        <sbs-control-input v-model="filterName" type="text"
                            :label="$t('requests.filter.actions.filter_name')" :required="true" />
                        <div class="btn btn-block btn1" :class="filterSaving ? 'wait' : ''" @click="addFilter">
                            {{ $t('common.save') }}
                            <div v-if="filterSaving" class="btn-loader"></div>
                        </div>
                    </div>

                </template>
            </sbs-sheet>

        </template>
    </sbs-sheet>
</template>

<script>
import sbsSheet from '@/components/sheet.vue'
import sbsControlFilter from "@/components/controls/filter";
import sbsControlFilterDates from "@/components/controls/filter.dates";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlSort from "@/components/controls/sort.vue";

import validator from "@/plugins/validator";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "sbs-requests-filter",
    components: {
        sbsSheet,
        sbsControlFilter,
        sbsControlFilterDates,
        sbsControlInput,
        sbsControlSelectSheet,
        sbsLoaderInline,
        sbsControlSort,

    },
    emits: ["update:visible"],
    data() {
        return {
            visibleInternal: this.visible,

            filter: [],
            sort: {},
            reset: 0,
            loading: false,
            loaded: false,

            statistics: {
                ratings: []
            },

            showSettings: false,
            selectedAction: false,
            showSaveAs: false,
            filterName: "",
            filterSaving: false,
            actionsLoading: false,

            boolList: [
                {
                    "id": "Y",
                    "name": "Да"
                },
                {
                    "id": "N",
                    "name": "Нет"
                },
            ],

            sortFields: [
                {
                    id: "ID",
                    name: "Дата создания",
                    order: "desc"
                },
                {
                    id: "DATE_UPDATE",
                    name: "Дата изменения"
                },
                {
                    id: "DATE_STATUS_UPDATE",
                    name: "Дата изменения статуса"
                },
                {
                    id: "DATE_CLOSE",
                    name: "Дата завершения"
                },
                {
                    id: "STATUS.SORT",
                    name: "Статус"
                },
                {
                    id: "PRIORITY.SORT",
                    name: "Приоритет"
                }
            ]
        };
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    computed: {
        ...mapGetters({
            tab: "getRequestsTab",
            filterKey: "getRequestsTabFilterKey"
        }),

        globalFilter() {
            return this.$store.getters.getFilter("REQUEST-" + this.filterKey);
        },

        globalSort() {
            return this.$store.getters.getSort("REQUEST-" + this.filterKey);
        },

        savedFilterId() {
            //если код вкладки подразумевает сохранённый фильтр
            if (this.tab.includes("saved-filter")) {
                let id = this.tab.replace(/[^0-9]/g, "");
                return id;
            }

            return 0;
        },

        responsibleUserParams() {
            let params = {};

            if (this.filter.responsibleDepartment && this.filter.responsibleDepartment.length > 0) {
                params.filter = {
                    department_id: this.filter.responsibleDepartment.map(i => i.id)
                }
            }
            return params;

        },

        actions() {
            let list = [];

            list.push({
                id: "save-as",
                name: this.$t("requests.filter.actions.save-as"),
                icon: "fi-save"
            });

            if (this.savedFilterId > 0) {

                list.push({
                    id: "save",
                    name: this.$t("requests.filter.actions.save"),
                    icon: "fi-save"
                });
            }
            if (this.savedFilterId > 0) {

                list.push({
                    id: "delete",
                    name: this.$t("requests.filter.actions.delete"),
                    icon: "fi-close"
                });
            }

            return list;
        }
    },

    watch: {
        visibleInternal: function (newVal) {
            if (newVal != this.visible) {
                this.$emit("update:visible", newVal);
            }

        },
        visible: function (newVal) {
            if (newVal != this.visibleInternal) {
                this.visibleInternal = newVal;
            }
            if (newVal) {
                if (!this.loaded) {
                    this.loadData();
                }
                this.setDefaults();
                this.setFromGlobalFilter();
            }
        },

        selectedAction: function () {
            if (this.selectedAction && !!this.selectedAction.id) {
                this.onActionClick();
            }
        },
    },


    methods: {

        /**
         * Подготовка запросов
         */
        getBatchRequests() {
            return {
                ratings: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: ["rating", "cnt"],
                        group: ["rating"],
                        order: { "rating": "asc" },
                    },
                },

            }
        },

        /**
         * Загрузка данных
         */
        loadData() {

            this.loading = true;

            rest.batch(this.getBatchRequests())
                .then((data) => {
                    this.processResponse(data);
                })
                .finally(() => {
                    this.loading = false;
                    this.loaded = true;
                });

        },

        /**
         * Обработка результата
        */
        processResponse(results) {
            for (var key in results) {
                let data = results[key];

                if (data.success) {
                    if (key == "ratings") {
                        this.setRatings(data.items);
                    }

                } else {

                    //показываем сообщение об ошибке
                    bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    return;

                }

            }
        },

        /**
         * Устанавливает статусы заявок
         */
        setRatings(items) {
            this.statistics.ratings = items.map(item => {
                return { id: item.rating, name: item.rating ? item.rating : "не задана", count: item.cnt }
            });

        },

        setDefaults() {
            this.filter = {
                equipment: [],
                priority: [],
                type: [],
                status: [],
                responsibleDepartment: [],
                responsibleUser: [],
                createdBy: [],
                createdDepartment: [],
                created: [],
                deadline: [],
                rating: [],
                overdue: []
            };

            this.sort = {
                ID: "desc"
            };
        },

        apply() {
            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", {
                name: "REQUEST-" + this.filterKey,
                value: this.filter
            });

            //сохраняем сортировку в текущем состоянии
            this.$store.commit("setSort", {
                name: "REQUEST-" + this.filterKey,
                value: this.sort
            });

            this.visibleInternal = false;
        },

        /**
         * Сбросить фильтр
         */
        clear() {
            //сбрасываем фильтр
            this.setDefaults();

            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", {
                name: "REQUEST-" + this.filterKey,
                value: this.filter
            });

            //сохраняем сортировку в текущем состоянии
            this.$store.commit("setSort", {
                name: "REQUEST-" + this.filterKey,
                value: this.sort
            });

            this.visibleInternal = false;
        },

        /**
         * Приводит данные экрана в соответствии с глобально установленным фильтром
         */
        setFromGlobalFilter() {
            console.log("set from global");
            for (let code in this.filter) {
                if (this.globalFilter[code]) {
                    this.filter[code] = this.globalFilter[code];
                } else {
                    this.filter[code] = this.filter[code] instanceof Array ? [] : "";
                }
            }

            if (this.globalSort && Object.keys(this.globalSort).length > 0) {
                this.sort = this.globalSort;
            }
        },

        /**
         * Клик по иконке "Настройки"
         */
        onSettingsClick() {
            setTimeout(() => {
                this.showSettings = true;
            }, 300);

        },

        /**
         * Выбор действия в настройках
         */
        onActionClick() {

            //сохранить как
            if (this.selectedAction.id == "save-as") {
                setTimeout(() => {
                    this.showSaveAs = true;
                }, 400);

            }
            //сохранить
            else if (this.selectedAction.id == "save") {
                this.saveFilter();
            }
            //удалить
            else if (this.selectedAction.id == "delete") {
                this.deleteFilter();
            }

            setTimeout(() => {
                this.selectedAction = false;
            }, 400);

        },


        /**
         * Добавление фильтра
         */
        addFilter() {

            if (!validator.check({ container: this.$refs.filter_save_form })) {
                return;
            }

            if (this.filterSaving) {
                return;
            }
            this.filterSaving = true;

            //выполняем запрос
            rest
                .call("user.filter.add", {
                    method: "post",
                    data: {
                        fields: {
                            name: this.filterName,
                            type: "REQUEST",
                            filter: this.filter,
                            sort: this.sort
                        }
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.showSaveAs = false;
                        //показываем сообщение об успехе
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: "Фильтр успешно сохранён", type: "success" });

                        //добавим сохранённый фильтр в глобальное состояние
                        let item = {
                            id: data.id,
                            name: this.filterName,
                            type: "REQUEST",
                            filter: this.filter
                        }
                        this.$store.dispatch("addUserFilter", item);


                        let vm = this;
                        let isWasCommon = this.filterKey == "common";
                        setTimeout(() => {

                            //поменяем текущую вкладку в списке заявок на новых сохранённый фильтр
                            vm.$store.commit("setRequestsTab", "saved-filter-" + item.id);

                            //если сохранение запустили с системной вкладки заявкок (Активное, Мне и пр.)
                            if (isWasCommon) {
                                //то сбросим у них фильтр
                                vm.$store.commit("setFilter", {
                                    name: "REQUEST-common",
                                    value: {}
                                });

                                //то сбросим у них сортировку
                                vm.$store.commit("setSort", {
                                    name: "REQUEST-common",
                                    value: {}
                                });
                            }

                            //переход на предыдущий экран
                            vm.$router.go(-1);

                        }, 100);

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.filterSaving = false;
                });


        },

        /**
         * Сохранение фильтра
         */
        saveFilter() {

            this.actionsLoading = true;

            //выполняем запрос
            rest
                .call("user.filter.update", {
                    method: "post",
                    data: {
                        id: this.savedFilterId,
                        fields: {
                            filter: this.filter,
                            sort: this.sort
                        }
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.showSettings = false;
                        //показываем сообщение об успехе
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: "Фильтр успешно сохранён", type: "success" });
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.actionsLoading = false;
                });

        },

        /**
         * Удаление фильтра
         */
        deleteFilter() {

            this.actionsLoading = true;

            //выполняем запрос
            rest
                .call("user.filter.delete", {
                    method: "post",
                    data: {
                        id: this.savedFilterId
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.showSettings = false;
                        //показываем сообщение об успехе
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: "Фильтр успешно удалён", type: "success" });

                        //удаляем из глобального состояния сохранённый фильтр
                        this.$store.commit("removeUserFilter", this.savedFilterId);
                        //устанавливаем активной вкладку "Активные"
                        this.$store.commit("setRequestsTab", "active");
                        //переход на предыдущий экран
                        this.$router.go(-1);

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.actionsLoading = false;
                });
        }

    },

    created() {
        //подписваемся на события
        bus.on("SBS_REQUEST_FORM_E_SAVE", this.loadData);
        bus.on("SBS_REQUEST_E_UPDATED", this.loadData);
    },


};
</script>

<style lang="scss">
.sbs-requests-filter {
    position: relative;
    padding-bottom: 80px;

    .sheet-title {
        position: relative;

        .action {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);

            width: 32px;
            height: 32px;
            border-radius: 50%;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                line-height: 1;
                color: var(--service-color-1);
            }
        }
    }

    .buttons {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 15px 20px calc(var(--keyboard-height, 0px) + var(--safe-area-bottom, 0px) + 20px);

        z-index: 10;
        background-color: var(--bg);

        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 10px;
    }

}
</style>