<template>
    <div class="sbs-empty">
        <div class="title">{{ title }}</div>
        <div class="text"> {{ text }}</div>
    </div>
</template>

<script>

export default {
    name: "sbs-empty",
    components: {
    },
    props: {
        title: {
            type: String,
            default() {
                return "";
            },
        },
        text: {
            type: String,
            default() {
                return "";
            },
        },
    },
};
</script>

<style lang="scss">
.sbs-empty {
    text-align: center;

    .title {
        color: var(--service-color-1);
        margin-bottom: 15px;
        font-size: 18px;
    }

    .text {
        font-size: 16px;
        margin-bottom: 15px;
    }
}
</style>