<template>
  <div class="sbs-control-photos form-group  form-group-label" :class="{ 'no-label': !label }">

    <template v-if="label != ''">
      <div class="floating-label" v-on:click="onLabelClick">
        {{ label }}
        <span v-if="required && needShowRequired">*</span>
      </div>
    </template>

    <div v-if="photos.length > 0" class="items" :class="{ 'justify-content-center': photos.length == 0 }">
      <div class="item" v-for="photo in photos" :key="photo.id" :style="{
    backgroundImage: photo.data
      ? 'url(data:image/jpeg;base64,' + photo.data + ')'
      : 'url(' + photo.preview + ')',
  }">

        <div class="delete" @click="deletePhoto(photo.id)">
          <span class="ic fi fi-close"></span>
        </div>

      </div>

    </div>

    <div class="controls nunito-3-bold">

      <div class="control " v-ripple.300 @click="getPicture">
        <div>
          <span class="ic fi fi-camera"></span>
        </div>
        <div>
          {{ $t('controls.photos.photo') }}
        </div>
      </div>

      <div class="control" v-ripple.300 @click="getPictureFromGallery">
        <div>
          <span class="ic fi fi-photo-library"></span>
        </div>
        <div>
          {{ $t('controls.photos.gallery') }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import bus from "@/plugins/bus";
import moment from "moment";
import resizebase64 from "@/plugins/resizebase64";

export default {
  name: "sbs-control-photos",
  emits: ["update:modelValue"],
  data() {
    return {
      photos: this.modelValue,
      blocking: false
    };
  },
  props: {
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default() {
        return "";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showRequired: {
      type: Boolean,
      default() {
        return true;
      },
    },
    disableGallery: {
      type: Boolean,
      default() {
        return false;
      },
    }
  },

  computed: {
    needShowRequired() {
      return this.required && this.showRequired;
    },
  },

  watch: {
    modelValue: function () {
      this.photos = this.modelValue
    }
  },

  methods: {
    /**
     * Получает фото с камеры
     */
    getPicture() {
      if (this.blocking) {
        return;
      }
      this.blocking = true;
      setTimeout(() => {

        bus.emit("SBS_CAMERA_E_GET_PICTURE", {
          resolve: this.onGetPicture,
        });

        this.blocking = false;

      }, 500);
    },

    /**
     * Получает фото с галереи
     */
    getPictureFromGallery() {

      if (this.blocking) {
        return;
      }
      this.blocking = true;

      if (this.disableGallery) {
        //показываем сообщение об ошибке
        bus.emit("SBS_MESSAGE_E_SHOW", { message: "Для данного поля выбор фото из галлереи отключен." });
        return;
      }

      setTimeout(() => {

        bus.emit("SBS_CAMERA_E_GET_PICTURE", {
          resolve: this.onGetPicture,
          photoLibrary: true,
        });

        this.blocking = false;

      }, 500);
    },

    /**
     * Обработка получения фото с камеры
     */
    async onGetPicture(imageData) {
      var newImg = await resizebase64(
        "data:image/jpeg;base64," + imageData,
        1200,
        1200
      );

      let item = {
        id: moment().format("x"),
        data: newImg.replace("data:image/jpeg;base64,", ""),
      };
      this.photos.push(item);
      this.$emit("update:modelValue", this.photos);
    },

    /**
     * Удаление фото
     */
    deletePhoto(id) {
      this.photos = this.photos.filter((p) => p.id != id);
      this.$emit("update:modelValue", this.photos);
    },
  },
};
</script>

<style lang="scss">
.sbs-control-photos {

  padding-top: 20px;

  &.no-label {
    padding-top: 0px;
  }

  .floating-label {
    top: 0px;
    left: 0px;
  }

  .items {
    margin-bottom: 30px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .item {
      position: relative;
      width: 150px;
      height: 105px;

      background-color: #000;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      .delete {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 24px;
        height: 24px;
        border-radius: 4px;

        cursor: pointer;

        border-radius: 4px;
        background: var(--audit-cards-audit-cards-bg-color);
        box-shadow: 0px 2px 4px 0px var(--shadow-secondary);

        .ic {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: var(--text-field-text-field-active-color-text);
          font-size: 10px;
        }
      }
    }

  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;

    .control {
      padding: 17px 25px;
      border-radius: 15px;
      background: var(--audit-cards-audit-cards-bg-color);
      box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
      cursor: pointer;
      text-align: center;

      display: flex;
      width: 140px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 7px;

      color: var(--service-color-1);

      text-align: center;

      .ic {
        font-size: 18px;
      }
    }
  }


}
</style>