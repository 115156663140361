<template>
    <Teleport to="body">
        <div class="sbs-loader-page">
            <div class="loader">
                <sbs-loader-inline />
            </div>
        </div>
    </Teleport>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";

export default {
    name: 'sbs-loader-page',
    components: {
        sbsLoaderInline,
    },
}
</script>

<style lang="scss">
.sbs-loader-page {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    background-color: var(--bg);

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>