<template>
    <sbs-sheet v-model:visible="visibleInternal">
        <template v-slot:body>

            <sbs-education-post-comments :postId="postId" :private="this.private" :titleClass="'sheet-title'"
                :sheetMode="true" />
        </template>
    </sbs-sheet>
</template>

<script>
import sbsSheet from '@/components/sheet.vue'
import sbsEducationPostComments from "@/components/education/post.comments.vue";

export default {
    name: "sbs-education-post-comments-sheet",
    components: {
        sbsSheet,
        sbsEducationPostComments
    },
    emits: ["update:visible"],
    data() {
        return {
            visibleInternal: this.visible,
        };
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        postId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        private: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },


    watch: {
        visibleInternal: function (newVal) {
            if (newVal != this.visible) {
                this.$emit("update:visible", newVal);
            }

        },
        visible: function (newVal) {
            if (newVal != this.visibleInternal) {
                this.visibleInternal = newVal;
            }

        },
    },



};

</script>