<template>
    <div class="sbs-edu-post-post" :class="{ 'passage-mode': passageMode }">

        <div class="post-view" :class="{ 'view-horizontal-padding': !passageMode }">

            <div class="author-date" v-if="!passageMode">
                <div class="date roboto-2-regular">{{ formatDate() }}</div>
                <div class="author nunito-2-bold"
                    @click="goTo('user-view', { id: item.created_by, service: service.toLowerCase() })">
                    <div>
                        <div class="avatar">
                            <div v-if="!item.user_photo" class="initials">
                                {{ item.user_last_name.charAt(0) + item.user_name.charAt(0) }}
                            </div>
                            <div v-else class="photo"
                                :style="{ backgroundImage: 'url(' + item.user_photo.preview + ')' }">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="fio">
                            {{ item.user_last_name }} {{ item.user_name }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="name nunito-5-bold" v-html="item.name"></div>

            <div v-if="item.picture" class="picture preloaded" v-lazy:background-image="item.picture.preview"
                @click="openPhoto(item.picture.id)"
                :style="{ paddingTop: item.picture.height / item.picture.width * 100 + '%' }">

                <div class="preload" v-if="item.picture.preload"
                    :style="{ backgroundImage: 'url(\'' + item.picture.preload + '\')' }">
                </div>

                <div class="loader">
                    <sbs-loader-inline />
                </div>
            </div>

            <sbs-education-post-post-blocks :item="item" />

            <template v-if="trainingId <= 0">
                <div class="stat">
                    <sbs-education-post-item-stat :item="item" />
                </div>
            </template>

            <template v-if="trainingId > 0">
                <sbs-teleport-wrapper :to="'#' + this.footerId">
                    <div class="sbs-edu-post-post-btns">
                        <div class="container">
                            <div class="btn btn1 btn-block" @click="onNextClick" :class="saving ? 'wait' : ''"
                                v-ripple.300>
                                {{ $t("common.next") }}
                                <div v-if="saving" class="btn-loader"></div>
                            </div>
                        </div>
                    </div>
                </sbs-teleport-wrapper>
            </template>

        </div>

        <template v-if="item.comments_enabled == 'Y'">
            <div class="comments" :class="{ 'view-horizontal-padding': !passageMode }">
                <sbs-education-post-comments :postId="item.id" :private="item.comments_private == 'Y'" />
            </div>

        </template>

    </div>


</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsTeleportWrapper from "@/components/teleport-wrapper.vue";
import sbsEducationPostComments from "@/components/education/post.comments.vue";
import sbsEducationPostItemStat from "@/components/education/post.item.stat.vue";
import sbsEducationPostPostBlocks from "@/components/education/post.post.blocks.vue";

import moment from "moment";
import utils from "@/plugins/utils";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import { mapGetters } from "vuex";

export default {
    name: "sbs-education-post-post",
    components: {
        sbsLoaderInline,
        sbsTeleportWrapper,
        sbsEducationPostComments,
        sbsEducationPostItemStat,
        sbsEducationPostPostBlocks
    },
    emits: ["complete"],
    data() {
        return {
            saving: false,

        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        trainingId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        passageMode: {
            type: Boolean,
            default() {
                return false
            }
        },
        footerId: {
            type: String,
            default() {
                return ""
            }
        }
    },

    computed: {
        ...mapGetters({
            server: "getServer",
            user: "getUserProfile",
            service: "getService"
        }),

        blocks() {
            if (!this.item.data || !this.item.data.blocks) {
                return [];
            }

            return this.item.data.blocks;
        },

        gallery() {
            let images = [];

            if (this.item.picture.preview) {
                images.push({
                    src: this.item.picture.full ? this.item.picture.full : this.item.picture.src,
                    type: "image",
                    width: this.item.picture.width,
                    height: this.item.picture.height,
                    thumb: this.item.picture.preview ? this.item.picture.preview : this.item.picture.src,
                    caption: this.item.name,
                    id: this.item.picture.id,
                });
            }

            return images;
        },
    },

    methods: {

        /**
         * Переход к просмотру 
         */
        goTo(name, params) {
            this.$router.push({
                name: name,
                params: params
            });
        },

        formatDate() {
            let date = moment(this.item.publication_date, "DD.MM.YYYY HH:mm:ss");
            let days = moment().diff(date, 'days');
            if (days <= 3) {
                return date.fromNow();
            }
            return date.format("DD MMMM YYYY");
        },

        /**
         * Открыть фото на просмотр
         */
        openPhoto(id) {
            let start = this.gallery.findIndex((i) => i.id == id);

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 0, //иначе видео mp4 слева/справа начинает сразу воспроизводиться
                infinite: false,
                Toolbar: {
                    display: [
                        { id: "prev", position: "center" },
                        { id: "counter", position: "center" },
                        { id: "next", position: "center" },
                        "zoom",
                        "slideshow",
                        "thumbs",
                        "close",
                    ],
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        utils.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        utils.fancyBoxOnClose();
                    },
                },
            });
        },

        /**
         * Клик Далее - в режиме тренировки
         */
        onNextClick() {
            if (this.saving) {
                return;
            }

            this.saving = true;

            let fields = {
                id: this.item.id,
                training_id: this.trainingId,
                status: "S",
                data: {}
            };

            //сохраняем результат
            rest
                .call("education.post.result", {
                    method: "post",
                    data: fields,
                })
                .then((data) => {
                    if (!data.success) {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    } else {

                        //генерим событие успешного сохранения результата
                        bus.emit("SBS_EDU_POST_RESULT_E_SAVE", fields);
                        this.$emit("complete");
                    }
                }).finally(() => {
                    this.saving = false;
                });


        },

    },
};
</script>

<style lang="scss">
.sbs-edu-post-post {
    position: relative;

    .post-view {

        background: var(--news-card-news-card-bg-color);
        box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 15px;

        .author-date {
            margin-bottom: 10px;

            .author {
                display: flex;
                column-gap: 10px;
                align-items: center;

                .avatar {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: var(--news-card-news-card-line-color);

                    .initials {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: var(--audit-cards-audit-cards-title-text-color);
                    }

                    .photo {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                        background-size: cover;
                    }
                }

                .fio {
                    color: var(--news-card-news-card-secondary-text-color);
                }
            }

            .date {
                color: var(--news-card-news-card-secondary-text-color);
                float: right;
            }
        }

        .name {
            color: var(--news-card-news-card-primary-text-color);
            margin-bottom: 10px;
        }

        .picture {
            height: 0px;
            padding-top: 60%;
            background-size: cover;
            background-position: center center;
            margin-bottom: 15px;
            border-radius: 12px;
        }
    }

    .comments {
        background: var(--news-card-news-card-bg-color);
        box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
        padding-top: 15px;
        padding-bottom: 0px;
    }

    &.passage-mode {
        .post-view {
            background: transparent;
            box-shadow: none;
        }

        .comments {
            background: transparent;
            box-shadow: none;
            padding-top: 0px;
        }
    }

}

.sbs-edu-post-post-btns {
    padding: 15px 0px;

}
</style>