<template>
    <div class="sbs-rt-ff">
        <sbs-control-input v-model="fields.name" type="text" :label="$t('requests.templates.form.fields.name')"
            :required="true" :noLabelAnimation="setFieldsFilled">
            <template v-if="isNativePlatform" v-slot:icon>
                <div class="icon" @click="speechName">
                    <span class="fi fi-mic"></span>
                </div>
            </template>
        </sbs-control-input>

        <sbs-control-textarea v-model="fields.description" :label="$t('requests.templates.form.fields.description')"
            :required="false" :rows="5" :noLabelAnimation="setFieldsFilled">
            <template v-if="isNativePlatform" v-slot:icon>
                <div class="icon" @click="speechDescription">
                    <span class="fi fi-mic"></span>
                </div>
            </template>
        </sbs-control-textarea>

        <sbs-control-select v-model="fields.equipment_id" v-model:modelName="info.equipment_name"
            :label="$t('requests.templates.form.fields.equipment_id')" :required="false" name="equipment_id"
            method="equipment.list" :noLabelAnimation="setFieldsFilled" />

        <div class="equipment-buttons" v-if="isNativePlatform">

            <div class="btn btn-block btn1 slim" @click="onQrClick">
                <div class="text-wrap">
                    <span class="icon fi fi-qr-scanner"></span>
                    {{ $t('requests.templates.form.scan-qr') }}
                </div>
            </div>

            <div class="btn btn-block btn1 slim" @click="onNfcClick">
                <div class="text-wrap">
                    <span class="icon fi fi-nfc"></span>
                    {{ $t('requests.templates.form.scan-nfc') }}
                </div>
            </div>

        </div>


        <sbs-control-select v-model="fields.responsible_department_id"
            v-model:modelName="info.responsible_department_name"
            :label="$t('requests.templates.form.fields.responsible_department_id')" :required="true"
            name="responsible_department_id" method="department.list" :params="{
            filter: [
                {
                    LOGIC: 'OR',
                    active: 'Y',
                    id: this.fields.responsible_department_id,
                },
            ],
            forResponsible: 'Y',
        }" :multiple="false" :noLabelAnimation="setFieldsFilled" />

        <sbs-control-select v-model="fields.type_id" v-model:modelName="info.type_name"
            :label="$t('requests.templates.form.fields.type_id')" :required="true" name="type_id"
            method="dictionary.request.type.list" :noLabelAnimation="setFieldsFilled" :params="{
            filter: [
                {
                    LOGIC: 'OR',
                    ACTIVE: 'Y',
                    ID: this.fields.type_id,
                },
            ],
            forRequestCreate: 'Y',
        }" />

        <sbs-control-select v-model="fields.priority_id" v-model:modelName="info.priority_name"
            :label="$t('requests.templates.form.fields.priority_id')" :required="true" name="type_id"
            method="dictionary.request.priorities.list" :noLabelAnimation="setFieldsFilled" />

        <sbs-control-checkbox v-model="fields.report_required" value="Y"
            :label="$t('requests.templates.form.fields.report_required')" />

        <sbs-accordion :title="$t('requests.templates.form.plaining')" :code="'requests.templates.form.plaining'">

            <sbs-control-time-duration v-model="fields.start_date_plan_after"
                :label="$t('requests.templates.form.fields.start_date_plan_after')" />
            <sbs-control-time-duration v-model="fields.end_date_plan_after"
                :label="$t('requests.templates.form.fields.end_date_plan_after')" />
            <sbs-control-time v-model="fields.duration_plan"
                :label="$t('requests.templates.form.fields.duration_plan')" />

        </sbs-accordion>

    </div>
</template>

<script>
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlTextarea from "@/components/controls/textarea.vue";
import sbsControlSelect from "@/components/controls/select.vue";
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import sbsControlTimeDuration from "@/components/controls/time.duration.vue";
import sbsControlTime from "@/components/controls/time.vue";
import sbsAccordion from "@/components/accordion.vue";

import bus from "@/plugins/bus";
import { mapGetters } from "vuex";

export default {
    name: "sbs-request-template-form-fields",
    components: {
        sbsControlInput,
        sbsControlTextarea,
        sbsControlSelect,
        sbsControlCheckbox,
        sbsControlTimeDuration,
        sbsControlTime,
        sbsAccordion
    },
    emits: ["update:modelValue", "update:modelNames"],
    data() {
        return {
            fields: this.modelValue,
            info: this.modelNames,
            setFieldsFilled: false,
            timePlannigExpanded: false
        };
    },
    props: {
        modelValue: {
            type: Object,
            default() {
                return {};
            },
        },
        modelNames: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        ...mapGetters({
            isNativePlatform: "isNativePlatform",
        }),
    },
    watch: {
        modelValue: function () {
            this.fields = this.modelValue;
        },
        fields: {
            handler() {
                this.$emit("update:modelValue", this.fields);
            },
            deep: true,
        },
        modelNames: function () {
            this.info = this.modelNames;
        },
        info: {
            handler() {
                this.$emit("update:modelNames", this.info);
            },
            deep: true,
        },


    },

    methods: {

        /**
         * Распознование речи для навания
         */
        speechName() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.fields.name = text;
                },
            });
        },

        /**
         * Распознование речи для описания
         */
        speechDescription() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.fields.description = text;
                },
            });
        },

        /**
         * Клик по иконке NFC
         */
        onNfcClick() {
            let vm = this;
            bus.emit("SBS_NFC_SCANNER_E_OPEN", {
                resolve: function (item) {
                    vm.fields.equipment_id = item.id;
                    vm.info.equipment_name = item.name;
                },
            });
        },

        /**
         * Клик по иконке QR
         */
        onQrClick() {
            let vm = this;
            bus.emit("SBS_QR_SCANNER_E_OPEN", {
                resolve: function (item) {
                    vm.fields.equipment_id = item.id;
                    vm.info.equipment_name = item.name;
                },
            });
        },

        /**
         * Клик по раскрывашке "Планирование времени"
         */
        onTimePlanningClick() {
            this.timePlannigExpanded = !this.timePlannigExpanded;
        }
    }

}

</script>

<style lang="scss">
.sbs-rt-ff {

    .equipment-buttons {
        margin-top: -10px;
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 10px;
        align-items: center;
        margin-bottom: 20px;
    }


}
</style>