<template>
  <sbs-view :class="'sbs-v-requests-search'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:center>
          <div class="title title-small">{{ $t('requests.search.title') }}</div>
        </template>
      </sbs-header>
    </template>
    <template v-slot:content>

      <div class="search-wrap">

        <sbs-control-search v-model="search" :placeholder="$t('requests.search.placeholder')" />

        <div class="active">
          <sbs-control-checkbox v-model="active" :value="true" :label="$t('requests.search.active')" />
        </div>
      </div>

      <!-- Загрузка -->
      <template v-if="loading">
        <div class="text-center">
          <sbs-loader-inline />
        </div>
      </template>

      <template v-else>

        <template v-if="items.length > 0">
          <div class="items">
            <sbs-request-item v-for=" item  in  items " :key="item.id" :item="item" />
          </div>
        </template>
        <template v-else-if="search && !timer">

          <sbs-empty :title="$t('requests.search.empty.title')" :text="$t('requests.search.empty.text')" />

        </template>
      </template>


    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsControlSearch from "@/components/controls/search.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsRequestItem from "@/components/requests/item.vue";
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import sbsEmpty from "@/components/empty.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
  name: "sbs-v-requests-search",
  components: {
    sbsView,
    sbsHeader,
    sbsControlSearch,
    sbsLoaderInline,
    sbsRequestItem,
    sbsControlCheckbox,
    sbsEmpty
  },
  data() {
    return {
      loading: false,
      search: "",
      active: true,
      items: [],
      timer: false,
      pager: {
        size: 50,
        page: 1,
        cnt: 1,
      },

      hideMenu: false
    };
  },

  computed: {
    ...mapGetters({
      isNativePlatform: "isNativePlatform",
      clientSettings: "getClientSettings",
    }),

    //ИД статуса "Завершено"
    requestStatusFinished() {
      return this.clientSettings
        ? this.clientSettings.request_status_finished
        : false;
    },
  },

  watch: {
    search: function () {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.timer = false;
        this.applySearch();
      }, 500);
    },
    active: function () {
      this.applySearch();
    },
  },

  methods: {


    /**
     * Поиск
     */
    applySearch() {
      if (this.search.trim() == "") {
        this.items = [];
        return;
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      let preFilter = {};
      if (this.active) {
        preFilter["!status_id"] = this.requestStatusFinished;
      }

      //запрашиваем данные
      rest
        .call("request.search", {
          method: "post",
          data: {
            paging: this.pager,
            q: this.search,
            filter: preFilter,
            loadPermissions: "Y",
          },
        })
        .then((data) => {
          if (data.success) {
            if (this.pager.page == 1) {
              this.items = data.items;
            } else {
              this.items = this.items.concat(data.items);
            }
            this.pager = data.pager;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Переход к заявке
     */
    onItemClick(item) {
      this.$router.replace({
        name: "request-view",
        params: { id: item.id },
      });
    },

    /**
     * Распознование речи для навания
     */
    speech() {
      bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
        resolve: (text) => {
          this.search = text;
        },
      });
    },

    /**
     * Обработка события сохранения заявки
     */
    onRequestSave(id) {
      let item = this.items.find((i) => i.id == id);

      //если заявка в списке отображения уже есть
      if (item) {
        this.syncRequest(item);
      }
    },

    /**
     * Синхронизация
     */
    syncRequest(item) {
      //запрашиваем данные
      rest
        .call("request.get", {
          method: "post",
          data: {
            id: item.id,
          },
        })
        .then((data) => {
          let index = this.items.indexOf(item);
          //если завка найдена
          if (data.success) {
            data.request.expanded = !!item.expanded;
            data.request.permissions = data.permissions;
            this.items[index] = data.request;
          }
          //иначе заявка не доступна и нужно её удалить из списка
          else {
            this.items.splice(index, 1);
          }
        });
    },

  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  mounted() {
    //подписваемся на события
    bus.on("SBS_REQUEST_E_UPDATED", this.onRequestSave);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_REQUEST_E_UPDATED", this.onRequestSave);
  },
};
</script>

<style lang="scss">
.sbs-v-requests-search {

  .search-wrap {
    position: relative;
    margin-bottom: 30px;

    .active {
      margin-top: 15px;
    }
  }
}
</style>