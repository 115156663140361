<template>
    <div class="sbs-control-select form-group form-group-label form-control-icon" :class="[
        value.id && displayValue ? 'filled' : '',
        noLabelAnimation && value.id ? 'no-label-animation' : '',
        noMargin ? 'no-margin' : ''
    ]">
        <template v-if="label != ''">
            <div class="floating-label" v-on:click="onLabelClick">
                {{ label }}
                <span v-if="required && needShowRequired">*</span>
            </div>
        </template>

        <div class="form-control" v-on:click="onClick">
            <div v-html="displayValue"></div>

            <div class="icon">
                <span class="fi fi-more"></span>
            </div>
        </div>

        <input type="hidden" :class="required ? 'required' : ''" v-model="value.id" />


        <sbs-control-select-sheet v-model:visible="showDialog" :title="label" :method="method" v-model="value"
            :params="params" :list="list" :showButtons="showButtons" />
    </div>
</template>


<script>
import sbsControlSelectSheet from "@/components/controls/select.sheet.vue";

export default {
    name: "sbs-control-select",
    components: {
        sbsControlSelectSheet
    },
    data() {
        return {
            value: {
                id: this.modelValue,
                name: this.modelName,
            },
            showDialog: false,
        };
    },
    emits: ["update:modelValue", "update:modelName", "update:modelItem"],
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        type: {
            type: String,
            default() {
                return "text";
            },
        },
        name: {
            type: String,
            default() {
                return "";
            },
        },
        modelValue: {
            type: [String, Number],
            default() {
                return "";
            },
        },

        modelName: {
            type: String,
            default() {
                return "";
            },
        },

        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showRequired: {
            type: Boolean,
            default() {
                return true;
            },
        },
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        method: {
            type: String,
            default() {
                return "";
            },
        },
        multiple: {
            type: Boolean,
            default() {
                return false;
            },
        },
        noLabelAnimation: {
            type: Boolean,
            default() {
                return false;
            },
        },
        params: {
            type: Object,
            default() {
                return {};
            },
        },
        noMargin: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showButtons: {
            type: Boolean,
            default() {
                return true;
            },
        }
    },
    computed: {
        needShowRequired() {
            return this.required && this.showRequired;
        },
        displayValue() {
            let text = this.value ? this.value.name : "";
            if (!this.value) {
                return text;
            }
            if (this.method == "users.list") {
                if (this.value.last_name) {
                    text = this.value.last_name + " " + this.value.name;
                }
            } else {
                let selected = this.list.find(i => i.id == this.value.id);
                if (selected) {
                    text = selected.name;
                }
            }

            return text;
        },
    },
    watch: {
        modelValue: function (newVal) {
            if (newVal != this.value.id) {
                this.value.id = newVal;
            }
        },
        modelName: function (newVal) {
            if (newVal != this.value.name) {
                this.value.name = newVal;
            }
        },
        value: function () {
            this.$emit("update:modelValue", this.value.id);
            this.$emit("update:modelName", this.value.name);
            this.$emit("update:modelItem", this.value);
        },
    },
    methods: {
        //кли по контролу
        onClick() {
            this.showDialog = true;
        },
        onLabelClick() {
            if (this.value.id == "") {
                this.showDialog = true;
            }
        },
    },
};
</script>

<style lang="scss">
.sbs-control-select {
    .form-control::before {
        content: "\00a0";
    }

    &.filled {
        .form-control::before {
            display: none;
        }
    }

    &.no-margin {
        margin-bottom: 0px;
    }
}
</style>
