<template>
    <sbs-sheet v-model:visible="visibleInternal">
        <template v-slot:body>
            <div class="sbs-request-rate">

                <template v-if="loading">
                    <div class="text-center">
                        <sbs-loader-inline />
                    </div>
                </template>

                <template v-else>
                    <div ref="form">
                        <sbs-control-stars label="Оценка" :required="true" v-model="fields.rating"
                            v-model:error="errors.rating" v-model:valid="valids.rating" />

                        <sbs-control-textarea v-model="fields.ratingComment" label="Комментарий" :required="false"
                            :rows="3" v-model:error="errors.ratingComment" v-model:valid="valids.ratingComment">

                            <template v-if="isNativePlatform" v-slot:icon>
                                <div class="icon" @click="speechField('ratingComment')">
                                    <span class="fi fi-mic"></span>
                                </div>
                            </template>
                        </sbs-control-textarea>

                        <div class="btn btn1 btn-block" :class="saving ? 'wait' : ''" v-ripple.300 @click="save">
                            Сохранить
                            <div v-if="saving" class="btn-loader"></div>
                        </div>

                    </div>

                </template>
            </div>
        </template>
    </sbs-sheet>
</template>

<script>

import sbsSheet from '@/components/sheet.vue'
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlTextarea from "@/components/controls/textarea.vue";
import sbsControlStars from "@/components/controls/stars.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";

import { mapGetters } from "vuex";

export default {
    name: "sbs-request-rate",
    emits: ["update:visible"],
    components: {
        sbsSheet,
        sbsLoaderInline,
        sbsControlTextarea,
        sbsControlStars
    },
    data() {
        return {
            visibleInternal: this.visible,
            saving: false,
            loaded: false,
            loading: false,

            fields: {
                rating: "",
                ratingComment: "",
            },

            errors: {
                rating: false,
                ratingComment: false,
            },
            valids: {
                rating: true,
                ratingComment: true,
            }

        }
    },
    props: {
        id: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        ...mapGetters({
            isNativePlatform: "isNativePlatform",
        }),
    },
    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
        },
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);

            if (this.visibleInternal) {
                this.onOpen();
            }
        }
    },

    methods: {

        /**
         * Дейтвие при открытии
         */
        onOpen() {

            if (!this.loaded) {
                this.loadData();
            }

        },

        /**
         * Загрузка данных о текущей оценке
         */
        loadData() {

            //покажем экран загрузки
            this.loading = true;

            //запрашиваем данные
            rest
                .call("request.get", {
                    method: "post",
                    data: {
                        id: this.id
                    }
                })
                .then((data) => {
                    if (data.success) {
                        this.fields.rating = data.request.rating;
                        this.fields.ratingComment = data.request.rating_comment;

                        this.loaded = true;

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    //скроем экран загрузки
                    this.loading = false;
                });
        },


        /**
         * Распознование речи для поля ввода
         */
        speechField(field) {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this[field] = text;
                },
            });
        },

        /**
         * Сохранение оценки
         */
        save() {

            //првоерка введённых данных
            if (!this.checkValues()) {
                return;
            }

            if (this.saving) {
                return;
            }
            this.saving = true;

            //выполняем сохранение
            rest
                .call("request.rate", {
                    method: "post",
                    data: {
                        id: this.id,
                        rating: this.fields.rating,
                        rating_comment: this.fields.ratingComment
                    }
                })
                .then((data) => {
                    if (data.success) {
                        //показываем сообщение
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: "Оценка сохранена.", type: "success" });
                        this.visibleInternal = false;

                        let vm = this;
                        setTimeout(() => {
                            vm.reset();
                            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
                            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
                        }, 400)

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.saving = false;
                });

        },

        /**
         * Проверяет значения
         */
        checkValues(showErrors = true) {

            Object.keys(this.fields).forEach((field) => {
                let error = !this.valids[field];

                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors[field] = error;
                }
            });

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }

            return true;
        },

        /**
         * Сброс полей
         */
        reset() {
            this.fields = {
                rating: "",
                ratingComment: "",
            };

            this.valids = {
                rating: true,
                ratingComment: true,
            }

            this.errors = {
                rating: false,
                ratingComment: false,
            }
        }

    },

}
</script>

<style lang="scss"></style>