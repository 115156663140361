<template>
  <sbs-view :class="'sbs-v-request-checklist-exec'" :isMenuHidden="hideMenu" :refreshDisabled="true">

    <template v-slot:header>
      <sbs-header>
        <template v-slot:center>
          <div v-if="!!request" class="title title-small">{{ $t('requests.checklist.title', {
    step: step, size: items.length
  }) }}
          </div>
        </template>
        <template v-slot:right>
          <div class="action" v-ripple.300 @click="close">
            <span class="icon fi fi-close"></span>
          </div>
        </template>
      </sbs-header>
    </template>

    <template v-slot:content>

      <template v-if="loading">
        <div class="text-center">
          <sbs-loader-inline />
        </div>
      </template>

      <template v-else>
        <template v-if="stage == 'start_by_nfc'">
          <div class="need-nfc-start">
            {{ $t('requests.checklist.need_scan_nfc') }}
            <br /><br />
            <div class="btn btn-block btn1" @click="scanNfc">{{ $t('requests.checklist.scan_nfc') }}
            </div>
          </div>
        </template>

        <template v-if="stage == 'steps'">

          <div class="items">
            <div class="item" v-for="(item, index) in items" :key="item.id" v-show="index == step - 1"
              :ref="setItemRef">
              <div class="name">{{ item.name }}</div>

              <template v-if="item.type == 'TEXT'">

                <sbs-control-textarea v-model="items[index].value" :label="$t('requests.checklist.comment')"
                  :required="true" :rows="5" />

              </template>

              <template v-else-if="item.type == 'PHOTO'">

                <sbs-control-photos v-model="item.value" :label="$t('requests.checklist.photo')"
                  :class="{ 'form-group-error': photoError }" :required="true"
                  :disableGallery="item.options && typeof item.options === 'object' && item.options.disable_gallery == 'Y'" />

              </template>

              <template v-else-if="item.type == 'NUMBER'">

                <sbs-control-input v-model="items[index].value" type="text" :label="$t('requests.checklist.number')"
                  :required="true" />

              </template>

              <template v-else-if="item.type == 'CHECKBOX'">

                <div>
                  <sbs-control-radio v-model="items[index].value" value="Y" :label="$t('common.yes')" :form="true"
                    :required="true" />
                </div>
                <div>
                  <sbs-control-radio v-model="items[index].value" value="N" :label="$t('common.no')" :form="true"
                    :required="true" />
                </div>

              </template>

            </div>
          </div>


        </template>
      </template>
    </template>

    <template v-if="!loading" v-slot:footer>

      <div class="sbs-rce-btns">
        <div class="container">
          <div class="page-padding">
            <div class="row">
              <div class="col">
                <div class="btn btn-block btn2" :class="savingCancel ? 'wait' : ''" v-ripple.300 @click="cancel">
                  {{ $t('requests.checklist.failed') }}
                  <div v-if="savingDone" class="btn-loader"></div>
                </div>
              </div>
              <div class="col">
                <div class="btn btn-block btn1" :class="savingDone ? 'wait' : ''" v-ripple.300 @click="done">
                  {{ $t('requests.checklist.passed') }}
                  <div v-if="savingDone" class="btn-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlTextarea from "@/components/controls/textarea.vue";
import sbsControlPhotos from "@/components/controls/photos.vue";
import sbsControlRadio from "@/components/controls/radio.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import validator from "@/plugins/validator";

export default {
  name: "sbs-v-request-checklist-exec",
  components: {
    sbsView,
    sbsHeader,
    sbsLoaderInline,
    sbsControlInput,
    sbsControlTextarea,
    sbsControlPhotos,
    sbsControlRadio
  },
  data() {
    return {
      reload: 0,
      step: 1,
      skipBack: true,
      hideMenu: false,

      loading: false,
      request: null,
      items: [],
      itemRefs: [],
      photoError: false,
      savingDone: false,
      savingCancel: false,
      displayNfc: false,
      confirm: false,
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },

  computed: {
    index() {
      return this.step - 1;
    },
    current() {
      return this.items[this.index];
    },
    stage() {
      if (this.displayNfc) {
        return "start_by_nfc";
      }
      return "steps";
    },
  },

  methods: {
    /**
     * Закрывает экран
     */
    close() {
      this.closing = true;
      //переход обратно
      this.$router.go(-1);
    },

    /**
     * Загрузка данных
     */
    loadData() {
      if (this.loading) {
        return;
      }

      //покажем экран загрузки
      this.loading = true;
      this.request = null;
      this.items = [];
      this.photoError = false;

      //запрашиваем данные
      rest
        .call("request.get", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            this.onLoad(data);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Обработка загрузки данных о заявке
     */
    onLoad(data) {
      this.request = data.request;
      this.items = data.checklist;

      //если можно начать работу по заявке
      if (data.permissions.includes("start")) {
        //если для старта НЕ нужен NFC
        if (!data.need_start_by_nfc) {
          this.startImmediatly();
        } else {
          this.displayNfc = true;
        }
      }
    },

    /**
     * Сканировать NFC метку
     */
    scanNfc() {
      let vm = this;

      bus.emit("SBS_NFC_SCANNER_E_OPEN", {
        resolve: function (item) {
          if (item.id == vm.equipmentId) {
            vm.displayNfc = false;
            vm.startImmediatly();
          } else {
            bus.emit("SBS_MESSAGE_E_SHOW", {
              message: this.$t('requests.checklist.no_nfc_id'),
            });
          }
        },
      });
    },

    /**
     * Начать выполнение
     */
    startImmediatly() {
      rest
        .call("request.start", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },

    /**
     * Запоминает ссылки на элементы
     */
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },

    /**
     * Выполнено
     */
    done() {
      if (!this.check()) {
        return;
      }

      if (this.savingDone || this.savingCancel) {
        return;
      }
      this.savingDone = true;

      this.items[this.index].status = "F";

      this.save();
    },

    /**
     * Не выполнено
     */
    cancel() {
      if (!this.check()) {
        return;
      }

      if (this.savingDone || this.savingCancel) {
        return;
      }
      this.savingCancel = true;

      this.items[this.index].status = "C";

      this.save();
    },

    /**
     * Проверки
     */
    check() {

      this.photoError = this.current.type == "PHOTO" && this.current.value.length == 0;

      //проверка введённых данных
      if (
        !validator.check({ container: this.itemRefs[this.index] }) ||
        this.photoError
      ) {
        return false;
      }

      return true;
    },

    /**
     * Сохраняет данные по текущему пункту
     */
    save() {
      //выполняем сохранение
      rest
        .call("request.checklist.item.update", {
          method: "post",
          data: this.items[this.index],
        })
        .then((data) => {
          if (data.success) {
            this.onSave();
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.savingDone = false;
          this.savingCancel = false;
        });
    },

    /**
     * Обработка успешного сохранения
     */
    onSave() {
      let vm = this;
      if (this.index < this.items.length - 1) {
        this.step += 1;
      } else {
        //генерим событие успешного обновления заявки
        bus.emit("SBS_REQUEST_E_UPDATED", this.id);

        //разрешает экрану переходить на шаг назад
        this.skipBack = false;

        //спрашиваем завершить ли заявку
        bus.emit("SBS_CONFIRM_E_SHOW", {
          message: this.$t('requests.checklist.copmlete'),
          resolveText: this.$t('common.yes'),
          rejectText: this.$t('common.no'),
          resolve: function (value) {
            if (value) {
              vm.complete();
            }
            //переход обратно
            vm.$router.go(-1);
          },
          reverse: false,
        });
      }
    },

    /**
     * Завершить заявку
     */
    complete() {
      //выполняем запрос
      rest
        .call("request.complete", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
    this.loadData();
  },

  /**
   * Вызывается перед переходом в этот экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //Если возврат с экрана сканера QR или NFC
      if (from.meta.skipConfirm) {
        return;
      }
      vm.skipBack = true;
      vm.closing = false;
      vm.step = 1;
      vm.loadData();

    });
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave(to) {
    //если переход на экран сканеров, камеры ИЛИ если не заблокирован переход назад
    if (to.meta.skipConfirm || !this.skipBack) {
      return true;
    }

    //если шаг 1 или закрытие
    if (this.step == 1 || this.closing) {
      return true;
    }

    //иначе делаем шаг назад
    this.step--;
    return false;
  },

  beforeUpdate() {
    this.itemRefs = [];
  },
};
</script>

<style lang="scss">
.sbs-v-request-checklist-exec {

  .need-nfc-start {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 300px;
  }

  .item {
    .name {
      margin-bottom: 40px;
      font-size: 16px;
    }
  }

  .sbs-rce-btns {
    padding: 15px 0px;
    z-index: 1000;
    background-color: var(--bg);

    .btn {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
