<template>
  <div class="sbs-control-filter-dates" v-ripple.300 @click="onClick">
    <div class="label nunito-4-regular">
      {{ this.label }}
      <div class="icon">
        <span class="fi fi-arrow-right"></span>
      </div>
    </div>
    <TransitionGroup v-if="values.length > 0" class="values" name="values" tag="div">
      <div v-for="value in values" :key="value.id" class="value nunito-4-regular" @touchstart.stop="void (0)"
        @click.stop="removeValue(value.id)">
        {{ formatName(value) }}
        <span class="icon fi fi-close"></span>
      </div>
    </TransitionGroup>

    <sbs-control-dates-sheet v-model:visible="showDialog" :title="label" v-model:value="value" v-model:dateFrom="from"
      v-model:dateTo="to" />

  </div>
</template>


<script>
import sbsControlDatesSheet from "@/components/controls/dates.sheet.vue";

import types from "@/plugins/dates.variants";

export default {
  name: "sbs-control-filter-dates",
  components: {
    sbsControlDatesSheet,
  },
  data() {
    return {
      values: this.modelValue,
      value: false,
      from: "",
      to: "",
      showDialog: false,
      blocking: false
    };
  },
  emits: ["update:dateFrom", "update:dateTo", "update:modelValue"],
  props: {
    label: {
      type: String,
      default() {
        return "Метка";
      },
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    modelValue: function (newVal) {
      this.values = newVal;
    },

    values: function () {
      this.$emit("update:modelValue", this.values);
    },

    value: function (newVal) {
      if (newVal) {
        //пробуем найти такео значение в списке
        let i = this.values.find(
          (i) => i.from == newVal.from && i.to == newVal.to
        );
        if (!i) {
          this.values.push(newVal);
          this.from = "";
          this.to = "";
        }
      }
    },
  },

  methods: {
    /**
     * Клик по фильтру
     */
    onClick() {
      if (this.blocking) {
        return;
      }
      this.blocking = true;

      setTimeout(() => {

        this.showDialog = true;
        this.blocking = false;

      }, 500);
    },

    removeValue(id) {
      this.values = this.values.filter((item) => item.id != id);
    },

    formatName(item) {
      let type = types.find(
        (t) =>
          t.variants.filter((v) => v.from == item.from && v.to == item.to)
            .length > 0
      );
      if (type) {
        let variant = type.variants.find(
          (v) => v.from == item.from && v.to == item.to
        );
        if (variant) {
          return variant.nameFull;
        }
      }
      return "с " + item.from + " по " + item.to;
    },
  },
};
</script>

<style lang="scss">
.sbs-control-filter-dates {

  padding: 20px 20px;

  border-radius: 15px;
  background: var(--audit-cards-audit-cards-bg-color);
  box-shadow: 0px 2px 4px 0px var(--shadow-secondary);
  margin-bottom: 10px;
  cursor: pointer;

  .label {
    position: relative;

    color: var(--text-field-text-field-body-color-text);


    padding-right: 50px;

    .icon {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      color: var(--service-color-1);
    }
  }

  .values-enter-active,
  .values-leave-active {
    transition: all 0.5s ease;
  }

  .values-enter-from,
  .values-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .values {
    margin-top: 10px;

    display: flex;
    gap: 5px 5px;
    flex-wrap: wrap;
    align-items: center;

    .value {
      display: flex;
      align-items: center;
      column-gap: 4px;
      text-transform: none;
      padding: 8px 15px 8px 15px;
      border-radius: 20px;
      background: var(--tag-tag-bg-orange-color);
      color: var(--tag-tag-text-orange-color);

      text-align: left;

      .icon {
        font-size: 10px;
      }
    }
  }
}
</style>
