<template>
  <sbs-sheet v-model:visible="visibleInternal">
    <template v-slot:body>
      <div class="sbs-request-viewers">
        <div class="sheet-title">{{ $t('requests.view.viewers_list') }}</div>

        <template v-if="loading">
          <div class="text-center">
            <sbs-loader-inline />
          </div>
        </template>

        <template v-else-if="items === false"> </template>

        <template v-else>
          <div class="item" v-for="item in items" :key="item.id" @click="onItemClick(item)">
            <div class="photo">
              <div v-if="item.user_photo" class="picture" :style="{
    backgroundImage: 'url(' + item.user_photo.preview + ')',
  }"></div>
              <span v-else class="icon fi fi-user"></span>
            </div>

            <div>
              <div v-if="item.user_last_name || item.user_name" class="fio nunito-3-bold">
                {{ item.user_last_name }} {{ item.user_name }}
              </div>

              <div v-if="item.user_work_position" class="position nunito-1-bold">
                {{ item.user_work_position }}
              </div>
            </div>
            <div>
              <div v-if="item.date" class="date nunito-2-regular">
                {{
    moment(item.date, "DD.MM.YYYY hh:mm:ss").format("DD.MM.YYYY")
  }}
                <br />
                {{ moment(item.date, "DD.MM.YYYY hh:mm:ss").format("HH:mm") }}
              </div>
            </div>

            <span class="arrow fi fi-arrow-right"></span>
          </div>
        </template>
      </div>
    </template>
  </sbs-sheet>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsSheet from '@/components/sheet.vue'

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";

import { mapGetters } from "vuex";

export default {
  name: "sbs-request-viewers",
  components: {
    sbsLoaderInline,
    sbsSheet,
  },
  emits: ["update:visible"],
  data() {
    return {
      visibleInternal: this.visible,
      loading: true,
      loaded: false,
      items: false,
      moment: moment,
    };
  },
  props: {
    id: {
      type: [Number, String],
      default() {
        return 0;
      },
    },
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  computed: {

    ...mapGetters({
      service: "getService"
    }),
  },
  watch: {
    id: function () {
      this.loadData();
    },
    visibleInternal: function (newVal) {
      if (newVal != this.visible) {
        this.$emit("update:visible", newVal);
      }
      //если открывается и данные ещё не загружены
      if (newVal && !this.loaded) {
        //делаем задержку чтобы не мелькало колесо прокрутки
        setTimeout(() => {
          this.loadData();
        }, 500);
      }
    },
    visible: function (newVal) {
      if (newVal != this.visibleInternal) {
        this.visibleInternal = newVal;
      }
    },
  },

  methods: {
    /**
     * Загрузака данных
     */
    loadData() {
      this.loading = true;

      //запрашиваем данные
      rest
        .call("request.viewers.list", {
          method: "post",
          data: {
            request_id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            this.items = data.items;
            this.loaded = true;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Переход к пользователю
     */
    onItemClick(item) {
      this.visibleInternal = false;

      let vm = this;
      setTimeout(() => {

        vm.$router.push({
          name: "user-view",
          params: { id: item.user_id, service: vm.service.toLowerCase() },
        });
      }, 300);

    },
  },
};
</script>


<style lang="scss">
.sbs-request-viewers {

  .item {
    position: relative;
    padding: 10px 50px 10px 10px;

    border-bottom: 1px solid var(--news-card-news-card-line-color);

    &:last-of-type {
      border-bottom: 0px;
    }

    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr 65px;
    grid-gap: 0px 10px;

    .photo {
      position: relative;
      width: 40px;
      height: 40px;

      border-radius: 50%;

      .picture {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-size: cover;
        border-radius: 50%;
      }

      .icon {
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--news-card-news-card-secondary-text-color);
      }
    }

    .fio {
      color: var(--audit-cards-audit-cards-title-text-color);
      margin-bottom: 3px;
    }

    .position {
      color: var(--news-card-news-card-secondary-text-color);
    }

    .date {
      color: var(--news-card-news-card-secondary-text-color);
    }

    .arrow {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      color: var(--service-color-1);
    }
  }
}
</style>
